import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 10rem;
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex?.header};
  color: ${({ theme }) => theme.colors?.text.tertiary};
  background-color: ${({ theme }) => theme.colors?.danger};
  width: 100%;
  height: 10rem;
  position: fixed;
  padding: 2rem;
`;
export const H2 = styled.h2`
  font-weight: bold;
`;
