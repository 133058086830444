import React from 'react';

import * as S from './styles';

export default function Footer() {
  return (
    <S.Container>
      <div>
        <S.P>SBrasil Soluções © 2023</S.P>
      </div>
    </S.Container>
  );
}
