import React, { useEffect, useRef } from 'react';

import { BlipChat } from 'blip-chat-widget';

import { keyBlip, linkBlip } from '../../../../../variables';

interface IBlip {
  email: string;
  name: string;
  phone: string;
  onClose: boolean;
}

export default function Blip({ email, name, phone, onClose }: IBlip) {
  const blipChatRef = useRef(null);

  useEffect(() => {
    const initializeBlipChat = () => {
      const blipChat = new BlipChat();
      blipChat
        .withAppKey(keyBlip)
        .withButton({
          color: '#143687',
          icon: '',
        })
        .withCustomCommonUrl(linkBlip)

        .withAccount({
          fullName: name,
          email: email,
          phoneNumber: phone,
          extras: { interno: 'aluno' },
        })
        .build();

      blipChatRef.current = blipChat;

      return blipChat;
    };

    const blipChatInstance = initializeBlipChat();

    return () => {
      blipChatInstance.destroy(); // Destruir a instância do BlipChat quando o componente for removido
      localStorage.removeItem('blipSdkUAccount');
    };
  }, []);

  useEffect(() => {
    if (!blipChatRef.current) return;

    if (onClose) {
      const handleBlipChatClose = () => {};

      const script = document.createElement('script');
      script.textContent = `
        if (window.addEventListener) {
          window.addEventListener("blip-chat-widget-closed", ${handleBlipChatClose});
        } else {
          window.attachEvent("onBlipChatWidgetClosed", ${handleBlipChatClose});
        }
      `;

      document.body.appendChild(script);

      return () => {
        if (window.removeEventListener) {
          window.removeEventListener(
            'blip-chat-widget-closed',
            handleBlipChatClose,
          );
        }

        document.body.removeChild(script);
      };
    }
  }, [onClose]);

  return <></>;
}
