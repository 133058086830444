import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';
import moment from 'moment/moment';

import {
  IStudentContextProps,
  StudentProviderContext,
} from '../../StudentProvider';
import PersonalInformation from '../../PersonalInformation';
import { errorToast, successToast } from '../../../common/Toast';
import { Container, Section } from '../styles';
import * as apiService from '../../../../services/api/student/api.service';
import { Footer, Header } from '../../Layout';
import { ContractCard } from '../index';

interface RequestData {
  data_ultima_conferencia: string;
  polo_id?: number | null;
  raca?: number | null;
}

export default function ContractUnsigned() {
  const navigate = useNavigate();
  const context = useContext(StudentProviderContext);
  const {
    aluno,
    registrationWithoutContracts,
    activeStep,
    setActiveStep,
    handleAlunoChange,
    signContract,
    setIsLoading,
    setRedirectTo,
    redirectTo,
  } = context as IStudentContextProps;

  const steps = ['Confirmar Dados', 'Assinar Contratos'];
  const [poloAluno, setPoloAluno] = useState<number>();
  const [raca, setRaca] = useState<number>();
  const [originalPoloAluno, setOriginalPoloAluno] = useState<number>();

  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(true);
  const [cellphoneNumberConfirmed, setCellphoneNumberConfirmed] =
    useState<boolean>(true);

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo);
      setRedirectTo('');
    }
  }, [redirectTo, navigate]);

  const handleNext = () => {
    if (activeStep === 0) {
      if (aluno?.aluno_graduacao && poloAluno! <= 0) {
        errorToast('Polo é obrigatório!');
        return;
      }
      if (raca! <= 0) {
        errorToast('Cor/Raça é obrigatório!');
        return;
      }
    } else if (activeStep === 1) {
      setIsLoading(true);

      const data: RequestData = {
        data_ultima_conferencia: moment().format('YYYY-MM-DD'),
        polo_id: poloAluno,
        raca: +raca!,
      };
      apiService
        .authenticatedRequest('/student/my-data/update', 'POST', data)
        .then((response) => {
          if (response.result) {
            successToast('Portal Desbloqueado para utilização!');
            aluno.data_ultima_conferencia = moment().format('YYYY-MM-DD');

            const newAlunoData = { ...aluno, polo_id: poloAluno, raca: +raca! };

            handleAlunoChange(newAlunoData);
            setRedirectTo('/aluno/dashboard');
          } else {
            setActiveStep(0);
            errorToast(
              'Erro ao atualizar dados do aluno!\n' + response.message,
            );
          }
        })
        .catch((error) => {
          aluno.data_ultima_conferencia = '';
          errorToast(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (activeStep === 2) {
      window.location.reload();
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep && activeStep - 1);
  };

  const handlePoloAlunoChange = (value: number) => {
    setPoloAluno(value);
  };

  useEffect(() => {
    setPoloAluno(aluno?.polo_id);
    setOriginalPoloAluno(aluno?.polo_id);
    setRaca(aluno?.raca);
  }, [aluno]);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <PersonalInformation
              aluno={aluno}
              handleEmailConfirmed={handleEmailConfirmed}
              handleCellphoneNumberConfirmed={handleCellphoneNumberConfirmed}
              handlePoloAlunoChange={handlePoloAlunoChange}
              setRaca={setRaca}
              raca={raca}
              poloAluno={poloAluno}
              originalPoloAluno={originalPoloAluno}
            />
          </>
        );
      case 1:
        if (registrationWithoutContracts?.length === 0) {
          return (
            <Typography
              align="center"
              variant="h5"
              style={{ top: 'auto', bottom: '0', textAlign: 'center' }}
            >
              Não há contratos para serem assinados.
            </Typography>
          );
        }
        return (
          <>
            <div className="row col col-12 mx-2 d-flex justify-content-center">
              <div
                className="alert-success col-xs-6 mt-1 p-3"
                style={{ textAlign: 'center', fontSize: 12 }}
              >
                <strong>
                  Prezado(a) Aluno(a), para iniciar o seu curso você deve ler e
                  assinar o(s) contrato(s).
                </strong>
              </div>
            </div>
            {registrationWithoutContracts?.map((contract, index) => (
              <ContractCard
                key={index}
                contract={contract}
                signContract={signContract}
              />
            ))}
          </>
        );

      default:
        return (
          <Typography
            align="center"
            variant="h5"
            style={{ top: 'auto', bottom: '0', textAlign: 'center' }}
          >
            Finalizar.
          </Typography>
        );
    }
  };

  const handleCellphoneNumberConfirmed = (
    bool: boolean | ((prevState: boolean) => boolean),
  ) => {
    setCellphoneNumberConfirmed(bool);
  };

  const handleEmailConfirmed = (
    bool: boolean | ((prevState: boolean) => boolean),
  ) => {
    setEmailConfirmed(bool);
  };

  return (
    <Section>
      <Header />
      <div
        className="animated fadeInDown container justify-content-center"
        style={{ flexWrap: 'wrap', minHeight: 465 }}
      >
        <div
          className="row col col-xs-12 py-2"
          style={{ marginLeft: '0', display: 'flex' }}
        >
          <Container>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Container>
          <>
            {activeStep < steps.length && (
              <>
                {renderStepContent(activeStep)}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 2,
                    fontSize: 50,
                  }}
                >
                  <button
                    color="info"
                    className="btn btn-primary"
                    disabled={activeStep === 0 || activeStep === 2}
                    onClick={handleBack}
                  >
                    Voltar
                  </button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <button
                    onClick={handleNext}
                    className="btn btn-primary"
                    disabled={
                      (activeStep === 0 &&
                        !emailConfirmed &&
                        !cellphoneNumberConfirmed) ||
                      (activeStep === 1 &&
                        registrationWithoutContracts.length > 0) ||
                      activeStep === 2
                    }
                  >
                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                  </button>
                </Box>
              </>
            )}
          </>
        </div>
      </div>
      <Footer />
    </Section>
  );
}
