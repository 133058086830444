import { Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

export const DivButtons = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerFile = styled.div`
  max-width: 25rem;
  min-height: 2rem;
  word-wrap: break-word;
  flex-direction: column;
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  word-break: break-all;
`;

export const BoxStyle = styled(Box)`
  width: 10rem;
  padding: 1rem 0 0 0;
`;

export const InputDate = styled.input`
  width: 20rem;
  height: 32px;
  padding: 8px 10px;
  border: 1px solid;
  border-radius: 3px;
  background: #fff;

  @media screen and (max-width: 1200px) {
    width: 15rem;
  }

  font:
    13px/16px 'Open Sans',
    Helvetica,
    Arial,
    sans-serif;
`;

export const InputNumber = styled.input`
  max-width: 8rem;
  width: 100%;
  height: 32px;
  padding: 8px 10px;
  border: 1px solid;
  border-radius: 3px;
  background: #fff;

  font:
    13px/16px 'Open Sans',
    Helvetica,
    Arial,
    sans-serif;
`;

export const Style = styled.div`
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
