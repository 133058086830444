import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SubtitleTitle = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    flex-direction: column;
    align-items: start;
  }
`;

export const CardContainer = styled.div``;

export const Docs = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  gap: 2rem;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    flex-direction: column;
    align-items: center;

    .divider {
      display: none;
    }
  }
`;

export const A = styled.a`
  color: ${({ theme }) => theme.colors?.secondary};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const Divider = styled.span`
  color: ${({ theme }) => theme.colors?.secondary};
`;
