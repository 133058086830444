import React, { useEffect, useState } from 'react';

import {
  IBankAccount,
  IBillet,
} from '../../../interfaces/student/installments.interface';
import {
  BodyTable,
  HeaderTable,
} from '../../../components/student/Table/PaymentsTable';
import { InstallmentsAdvance, InstallmentsNegotiation } from './components';
import { LoaderComponent } from '../../../components/student/Loaders';
import HeaderPages from '../../../components/student/HeaderPages';
import { ModalCustom } from '../../../components/student/Modals';
import ChildrenBody from './components/ChildrenBody';
import { AlertBar } from '../../../components/student/Alerts';
import { authStudentService } from '../../../services/auth';
import { Navigate } from 'react-router-dom';
import { ContainerTable } from './styles';
import { loadInvoices } from './common';

export default function HistoricoFinanceiro() {
  const [allActiveBilletsOpen, setAllActiveBilletsOpen] = useState<IBillet[]>(
    [],
  );
  const [activeBilletsAntecipation, setActiveBilletsAntecipation] = useState<
    IBillet[]
  >([]);
  const [paidBillets, setPaidBillets] = useState<IBillet[]>([]);
  const [expiredBillets, setExpiredBillets] = useState<IBillet[]>([]);
  useState<number>(0);
  const [expiredBilletsForNegotiation, setExpiredBilletsForNegotiation] =
    useState<IBillet[]>([]);
  const [maximumNumberOfParcel, setMaximumNumberOfParcel] = useState<number>(0);
  const [bankAccount, setBankAccount] = useState<IBankAccount[]>([]);
  const [billetGenerated, setBilletGenerated] = useState<string>('');
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!authStudentService.getLoggedUser()) {
      return setRedirectTo('/new/aluno');
    }

    loadBillets();
  }, []);

  const loadBillets = async () => {
    setLoading(true);

    try {
      const { data } = await loadInvoices();

      setPaidBillets(data.paidBillets);
      setExpiredBillets(data.expiredBillets);
      setAllActiveBilletsOpen(data.allActiveBilletsOpen);
      setActiveBilletsAntecipation(data.activeBilletsAntecipation);
      setExpiredBilletsForNegotiation(data.expiredBilletsForNegotiation);
      setBankAccount(data.contaBancaria);
      setMaximumNumberOfParcel(data.numeroParcelasMaxima);
    } catch {
      throw new Error('Erro ao buscar os Boletos, Tente novamente mais tarde!');
    }

    setLoading(false);
  };

  const handleBillets = (newValue): void => {
    setBilletGenerated(newValue);

    loadBillets();
  };

  return redirectTo ? (
    <Navigate to={redirectTo} />
  ) : (
    <>
      <HeaderPages title="Histórico Financeiro" />
      <>
        {/* ---> ALERT DE ANTECIPAÇÃO PARA FATURAS EM ABERTO <--- */}
        {activeBilletsAntecipation.length > 1 &&
          expiredBillets.length === 0 &&
          expiredBilletsForNegotiation.length === 0 && (
            <InstallmentsAdvance
              activeBilletsProp={handleBillets}
              advanceBilletsProp={activeBilletsAntecipation}
              bankAccountProp={bankAccount}
              numberOfParcels={maximumNumberOfParcel}
            />
          )}

        {/* -----> ALERT DE NEGOCIAÇÃO PARA BOLETOS VENCIDOS <----- */}
        {expiredBilletsForNegotiation.length > 1 && (
          <InstallmentsNegotiation
            activeBilletsProp={handleBillets}
            billetsForNegotiationProp={expiredBilletsForNegotiation}
            bankAccountProp={bankAccount}
            numberOfParcel={maximumNumberOfParcel}
          />
        )}

        {/* ----PAGAMENTOS EM ABERTO---- */}
        {loading ? (
          <LoaderComponent text={'Carregando'} />
        ) : (
          <ContainerTable className="firstTable">
            {allActiveBilletsOpen.length > 0 && (
              <>
                <AlertBar
                  color={'info'}
                  message={
                    <p>
                      Após a emissão o boleto estará disponível para pagamento
                      em até 1 hora. <br />
                      Para o pagamento, o prazo de compensação da fatura é de
                      até <b>3 dias úteis.</b>
                    </p>
                  }
                />
                <HeaderTable
                  tableTitle={'Pagamentos Abertos'}
                  colorTable={'#001b4f'}
                >
                  <ChildrenBody
                    allActiveBilletsOpen={allActiveBilletsOpen}
                    expiredBillets={expiredBillets}
                    load={setLoading}
                  />
                </HeaderTable>
              </>
            )}
          </ContainerTable>
        )}

        {/* ----PAGAMENTOS LIQUIDADOS---- */}
        <ContainerTable>
          {paidBillets.length > 0 && (
            <HeaderTable
              tableTitle={'Pagamentos Liquidados'}
              colorTable={'#00352c'}
            >
              {paidBillets.map((data) => {
                const dueValue = data.vencimento_valor_atualizado
                  ? data.vencimento_valor_atualizado
                  : data.vencimento_valor;
                return (
                  <BodyTable
                    id={data.id}
                    key={data.id}
                    color="green"
                    accountName={data.plano_contas_nome}
                    description={data.observacao}
                    dueDate={data.vencimento_data}
                    dueDateValue={dueValue}
                    status="Liquidado"
                  />
                );
              })}
            </HeaderTable>
          )}
        </ContainerTable>
      </>
      {billetGenerated && (
        <ModalCustom
          modalOpen={true}
          newValue={billetGenerated}
          title={'Sua fatura foi gerada'}
          text={'Gostaria de fazer o download?'}
        />
      )}
    </>
  );
}
