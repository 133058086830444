import React, { ReactNode } from 'react';

import * as S from './styles';

import { HeaderCard } from '../components';
import CardBasic from '../CardBasic';

interface Props {
  icon: ReactNode;
  title: ReactNode;
  children: ReactNode;
  className?: string;
  justify?: 'center' | 'start' | 'end';
}

export default function CardInfo({
  icon,
  title,
  children,
  className,
  justify,
}: Props) {
  return (
    <CardBasic className={className}>
      <HeaderCard icon={icon} justify={justify}>
        <S.H4>{title}</S.H4>
      </HeaderCard>
      {children}
    </CardBasic>
  );
}
