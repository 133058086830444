import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import studentDataService from '../../../../../services/student/student.data.service';
import {
  convertStringToMoment,
  expiredTicket,
  toLocaleString,
} from '../../../../../util/pageUtil';
import ButtonPrint from '../../../Buttons/ButtonPrint';
import { authenticatedRequest } from '../../../../../services/api/agent/api.agent.service';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../ProviderAgent';
import TableBasic from '../../../../newStudent/Table';
import { IFinancial } from '../../../../../services/agent/student/interface';

export default function TabFinancial({ data }) {
  const context = useContext(ProviderAgentContext);
  const { isLoading, setIsLoading } = context as IAgentContextProps;

  const [financial, setFinancial] = useState<IFinancial[]>();

  const handlePrintOut = async (id: number) => {
    const { result, data } = await authenticatedRequest(
      `/student/bank-billets/view/${id}`,
    );
    if (result) {
      window.open(data, '_blank');
    }
  };

  const formatFinancialData = (financial) => {
    if (!financial) return [];

    return financial.map((item) => ({
      Descrição: {
        item: <div dangerouslySetInnerHTML={{ __html: item.observation }} />,
        style: {
          color: item.paymentDate
            ? 'green'
            : expiredTicket(item.dueDate)
            ? 'red'
            : 'black',
          width: '75%',
        },
      },
      Vencimento: {
        item: (
          <div>
            {convertStringToMoment(item.dueDate, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            )}
            <br />
            {toLocaleString(item.value)}
          </div>
        ),
        style: {
          color: item.paymentDate
            ? 'green'
            : expiredTicket(item.dueDate)
            ? 'red'
            : 'black',
        },
      },

      Situação: {
        item: item.paymentDate ? (
          <div>
            {'Liquidado'}
            <br />
            {convertStringToMoment(item.paymentDate, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            )}
            <br />
            {toLocaleString(item.value)}
          </div>
        ) : (
          <div>{expiredTicket(item.dueDate) ? 'Vencido' : 'Em Aberto'}</div>
        ),
        style: {
          color: item.paymentDate
            ? 'green'
            : expiredTicket(item.dueDate)
            ? 'red'
            : 'black',
        },
      },
      print: {
        item: item.print && !item.paymentDate && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonPrint
              color="green"
              onClick={() => handlePrintOut(item.print)}
            />
          </div>
        ),
      },
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchFinancial = async () => {
      const response = await studentDataService.financial({ login: data });

      if (response.data) {
        setFinancial(response.data);
        setIsLoading(false);
      }
    };
    fetchFinancial();
  }, []);

  const financialData = {
    title: ['Descrição', 'Vencimento', 'Situação', ''],
    content: formatFinancialData(financial),
  };

  return (
    <S.DivTable>
      <TableBasic data={financialData} titleLoading={isLoading} />
    </S.DivTable>
  );
}
