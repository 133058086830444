import { Paper } from '@mui/material';
import styled from 'styled-components';

export const AccordionSummary = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 0;
`;

export const PaperStyled = styled(Paper)`
  margin-bottom: 5rem;
`;
