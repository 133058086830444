import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 20rem;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    padding: 0 5rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    padding: 0 2rem;
  }
`;
