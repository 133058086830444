import styled from 'styled-components';

export const Section = styled.div`
  padding-bottom: 7rem;
  input:disabled {
    color: #aaaaaa;
  }
`;

export const ContainerCard = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
`;

export const ContainerCardContract = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled.section`
  margin: 2rem 0 2.5rem;
  font-size: 50px !important;

  .css-z7uhs0-MuiStepConnector-line {
    border-color: #bdbdbd;
    border-top-style: solid;
    border-top-width: 3px;
  }

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    font-size: 3rem;
  }

  .css-117w1su-MuiStepIcon-text,
  .css-1hv8oq8-MuiStepLabel-label {
    font-size: 1.5rem;
  }
`;
