import styled from 'styled-components';

export const Container = styled.section`
  margin-bottom: 20px;

  > .section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 0 0;
    padding: 10px;
    width: 100%;
    border-color: #953b39;
    background-color: #c26565;
    color: #fff;
    text-shadow: none;
    font-size: 13px !important;
  }
  .section-body {
    border-width: 1px 1px 2px;
    border-style: solid;
    border-color: #ccc;
  }
  div {
    align-items: center;
  }
  h4 {
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
  }

  svg {
    margin-left: 0.3em;
    float: right !important;
    font-size: 3em;
  }
`;

export const Docs = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 10px 10px;
  overflow-y: auto;
  border-top: none !important;
  border: 0.05rem;
  border-style: solid;
  border-color: #ccc;

  > div {
    display: flex;
    flex-direction: row;
  }
  svg {
    color: #a90329;
    font-size: 2em;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    margin-right: 0.5rem;
  }
`;
