import React, { useState } from 'react';
import HeaderPages from '../../../components/student/HeaderPages';
import PersonalInformation from '../../../components/student/PersonalInformation';

export default function MyData() {
  const alunoData = localStorage.getItem('alunoStorage');
  const aluno = alunoData && JSON.parse(alunoData);

  const [poloAluno, setPoloAluno] = useState<number>(aluno?.polo_id);

  const handlePoloAlunoChange = (value: number) => {
    setPoloAluno(value);
  };

  return (
    <>
      <HeaderPages title="Meus Dados" />
      <PersonalInformation
        handlePoloAlunoChange={handlePoloAlunoChange}
        poloAluno={poloAluno}
      />
    </>
  );
}
