import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const BoxModal = styled.div`
  width: 100vw;
`;

export const Container = styled(Modal)`
  max-width: 30%;
  max-height: 150px;

  @media screen and (max-width: 990px) {
    max-width: 50%;
  }

  @media screen and (max-width: 570px) {
    max-width: 80%;
  }

  .ui.header {
    font-size: 2rem;
    padding: 1rem;
  }
  .ui.header i {
    font-size: 2rem;
  }

  p {
    font-size: 1.5rem;
  }

  .ui.button,
  .ui.buttons .button,
  .ui.buttons .or {
    font-size: 1.3rem;
  }
`;
