import React, { ReactNode } from 'react';

import { Container } from './styles';

interface Props {
  link: () => void;
  color?: string;
  icon: ReactNode;
}

export default function ButtonQuit({ link, color, icon }: Props) {
  return (
    <Container type="button">
      <span className={color} onClick={link}>
        {icon}
      </span>
    </Container>
  );
}
