import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  margin-right: 2rem;
`;

export const ContainerTable = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  background-color: #fbfbfb;
  margin-right: 5rem !important;
  padding: 3rem 2rem;
  overflow: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  min-width: 440px;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid #ddd;
`;

export const StyledTh = styled.th`
  text-align: start;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  background-color: #fff !important;
  border: 1px solid #ddd;
`;

export const StyledTd = styled.td`
  text-align: start;
  vertical-align: middle;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  background-color: #fff !important;
  border-right: 1px solid #ddd;

  .button {
    cursor: pointer;
  }
`;

export const StyledTdAction = styled.td`
  text-align: center;
  vertical-align: middle;
  width: 5.5rem;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  background-color: #fff !important;
  border-right: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
`;
