import React from 'react';

import { Typography, Box } from '@mui/material';

import { Container } from './styles';

interface Props {
  studentName: string;
  companyName: string;
}

export default function NegotiationTerm({ studentName, companyName }: Props) {
  return (
    <Box sx={{ width: '100%', maxWidth: 500 }}>
      <Container>
        <Typography variant="h5" gutterBottom className="tituloTermo">
          TERMOS E CONDIÇÕES DE NEGOCIAÇÃO FINANCEIRA
        </Typography>
        <Typography variant="body1" gutterBottom className="textoTermo">
          Ao clicar no botão{' '}
          <span className="destaqueTexto">{'Li e concordo'}</span>, eu,{' '}
          <span className="destaqueTexto">{studentName}</span> assumo as
          obrigações financeiras acordadas, e declaro ter negociado com a{' '}
          <span className="destaqueTexto">{companyName}</span> os meus débitos e
          estou ciente das condições de pagamento, incluindo a forma de
          pagamento, data de vencimento e o valor das parcelas.
        </Typography>
      </Container>
    </Box>
  );
}
