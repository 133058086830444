import React from 'react';

import * as S from './styles';
import { sieadImagesFolderUrl } from '../../../variables';

export const DeclarationDischarge = ({ incomeTax }) => {
  const logo = `${sieadImagesFolderUrl}c81e728d9d4c2f636f067f89cc14862c.jpg`;
  return (
    <S.Container>
      <S.PTitle>
        <img
          src={logo}
          alt="Logomarca"
          style={{ width: '100%', maxWidth: '15rem' }}
        />
      </S.PTitle>
      <div dangerouslySetInnerHTML={{ __html: incomeTax }} />
      {incomeTax && (
        <S.P>
          <b>
            Rua Osório Santos, 207 – – Bairro Nogueira Machado – CEP 35.680-229
            – Itaúna/MG
          </b>

          <br />
          <b>
            Telefone:0800 942 5006 – – Site:{' '}
            <a href="https://famart.edu.br">https://famart.edu.br</a>
          </b>
        </S.P>
      )}
    </S.Container>
  );
};
