import axios from 'axios';

import { infoToast } from '../../../components/common/Toast';
import * as apiService from '../../api/student/api.service';
import { apiUrl } from '../../../variables';

interface IDataRegister {
  login: string;
  senha: string;
  confirmacaoSenha: string;
  tipo_usuario: number;
}

const authStudentService = {
  async authenticate(data: object) {
    localStorage.clear();
    return await apiService.unauthenticatedRequest(`/login`, 'POST', data);
  },

  async refreshToken() {
    localStorage.setItem('lastRefreshToken', new Date().getTime().toString());

    const response = await apiService.authenticatedRequest(
      '/refresh-token',
      'POST',
    );
    if (response?.result) {
      authStudentService.setLoggedUser(response.access_token);
      localStorage.setItem('userStudent', response.access_token);
      return response;
    }
  },

  async register(data: IDataRegister) {
    const endpoint = `${apiUrl}/student/create-password-first-login`;
    return axios.post(endpoint, data);
  },

  setLoggedUser(data: string) {
    localStorage.setItem('userStudent', data);
    localStorage.setItem('lastRefreshToken', new Date().getTime().toString());
  },

  getLoggedUser() {
    localStorage.getItem('userStudent');
    return localStorage.getItem('userStudent');
  },

  cleanLoggerUser(e: boolean = false) {
    localStorage.clear();
    if (e) {
      infoToast('Sua sessão expirou, faça login novamente.');
    }

    if (window.location.pathname.includes('/agenciador')) {
      return (window.location.href = '/agenciador');
    } else {
      return (window.location.href = '/new/aluno');
    }
  },
};

export default authStudentService;
