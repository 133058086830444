import React from 'react';
import Select from 'react-select';

import { Container } from './styles';
import { IPayment } from '../../../../interfaces/student/installments.interface';

interface Props {
  payment: IPayment[];
  title: string;
  label: string;
  defaultValue?: IPayment;
  onChange: (e) => void;
  value?: IPayment;
}

export default function BasicSelect({
  payment,
  title,
  label,
  defaultValue,
  onChange: change,
  value,
}: Props) {
  return (
    <Container>
      <label>{label}</label>
      <Select
        className="select"
        options={payment}
        placeholder={title}
        onChange={(selectedOption) => change(selectedOption)}
        defaultValue={defaultValue}
        value={value ? value : title}
      />
    </Container>
  );
}
