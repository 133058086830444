import styled from 'styled-components';

export const Container = styled.section`
  margin-bottom: 0.1rem;

  > section {
    margin-top: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }

    .check {
      font-size: 20px;
    }

    .text {
      margin: 0.5rem 1.5rem 0;
    }
  }

  section.success {
    background-color: ${({ theme }) => theme.alert.success.main};
    color: ${({ theme }) => theme.fontColor.success.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.success.main};
  }
  section.info {
    background-color: ${({ theme }) => theme.alert.info.main};
    color: ${({ theme }) => theme.fontColor.info.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.info.main};
  }
  section.warning {
    background-color: ${({ theme }) => theme.alert.warning.main};
    color: ${({ theme }) => theme.fontColor.warning.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.warning.main};
  }
  section.danger {
    background-color: ${({ theme }) => theme.alert.danger.main};
    color: ${({ theme }) => theme.fontColor.white.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.danger.main};
  }
`;
