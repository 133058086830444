import styled from 'styled-components';

export const FirstAccess = styled.div`
  background: white;
  width: 100%;
  left: 0;
  border-radius: 5px;
  position: absolute;
  bottom: 100%;
  border-top: 100vh solid white;
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
`;

export const InputContainer = styled.form`
  position: relative;
  padding-bottom: 0.3rem;
`;
export const InputContent = styled.div``;

export const Section = styled.section`
  :hover {
    border-color: #aaa;
  }
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  border-radius: 10px;
  height: 3em;
  font-size: 1.1em;
  outline: 0;
  border-style: solid;
  appearance: none !important;
  padding: 0 10px;
  margin: 5px 0;
  transition: all 0.2s ease-out 0s;

  @media screen and (max-device-width: 1080px), screen and (max-width: 500px) {
    font-size: 12pt !important;
  }

  :focus,
  :hover {
    border-color: #aaa;
  }
`;

export const ButtonInput = styled.button`
  width: 100%;
  border-radius: 10px;
  height: 3em;
  font-size: 1.1em;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  appearance: none !important;
  color: white;
  background-color: #1f80e1;
  cursor: pointer;

  @media screen and (max-device-width: 1080px), screen and (max-width: 500px) {
    font-size: 12pt !important;
  }
  :hover {
    background-color: #0f6fd0;
  }
`;

export const Alert = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  text-align: center;
  padding: 10px;

  div {
    margin: auto;
  }
`;
