import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 2rem;
`;

export const Content = styled.div``;

export const Title = styled.h6``;

export const Subtitle = styled.h6`
  color: ${({ theme }) => theme.colors?.text.info01};

  &:hover {
    color: ${({ theme }) => theme.colors?.text.info02};
  }
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

interface Props {
  color?: 'danger' | 'secondary' | 'success';
}
export const Value = styled.h6<Props>`
  color: ${(props) =>
    props.color
      ? props.theme.colors?.[props.color ?? 'secondary']
      : props.theme.colors?.text.secondary};
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  gap: 0.5rem;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem 0;
  gap: 1rem;
`;

export const Div = styled.div`
  display: flex;

  gap: 0.4rem;
`;

export const DivExpand = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.colors?.primary};
`;

export const Focus = styled.div`
  opacity: 0;

  animation: fadeIn 1s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 1;
`;
