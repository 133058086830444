import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #e5e5e5;
  gap: 1rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 2rem 0 2rem;
  gap: 1rem;
`;

export const Title = styled.b`
  font-size: medium;
`;

export const TextItems = styled.p``;

export const Table = styled.div`
  display: flex;
  justify-content: start;

  padding: 2rem 0;
  width: 100%;
  overflow: auto;
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`;
