import styled from 'styled-components';

export const Container = styled.div``;

export const Text = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2rem 3rem;

  gap: 1rem;
`;

export const Buttons = styled.div`
  display: flex;

  width: 30rem;
  gap: 1rem;
`;

export const H4 = styled.h4``;

export const H6 = styled.h6``;
