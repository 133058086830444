import React, { useContext } from 'react';

import * as S from './styles';

import { phoneService, sieadImagesFolderUrl } from '../../../../../variables';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../ProviderNewStudent';

export default function Footer() {
  const { isSidebarOpen } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const logo = `${sieadImagesFolderUrl}white_logo.png`;

  return (
    <S.Footer style={{ display: isSidebarOpen ? 'none' : 'flex' }}>
      <S.Social>
        <S.Icon
          src="/assets/img/socialNetwork/facebook.png"
          alt="facebook_img"
          width="30px"
          onClick={() =>
            window.open('https://www.facebook.com/faculdadefamart')
          }
        />
        <S.Icon
          src="/assets/img/socialNetwork/instagram.png"
          alt="instagram_img"
          width="30px"
          onClick={() =>
            window.open('https://www.instagram.com/faculdadefamart')
          }
        />
        <S.Icon
          src="/assets/img/socialNetwork/youtube.png"
          alt="youtube_img"
          width="30px"
          onClick={() =>
            window.open('https://www.youtube.com/@FaculdadeFamart')
          }
        />
        <S.Icon
          src="/assets/img/socialNetwork/tiktok.png"
          alt="tiktok_img"
          width="30px"
          onClick={() => window.open('https://www.tiktok.com/@faculdadefamart')}
        />
        <S.Icon
          src="/assets/img/socialNetwork/linkedln.png"
          alt="linkedln"
          width="30px"
          onClick={() =>
            window.open(
              'https://www.linkedin.com/school/faculdadefamart/mycompany/',
            )
          }
        />
      </S.Social>
      <S.InfoImage>
        <S.Info>
          <S.P>
            Em caso de dúvidas, entre em contato com a Central do Aluno da
            Faculdade Famart pelo contato <S.B>{phoneService}.</S.B>
          </S.P>
        </S.Info>
        <S.ImgContainer>
          <S.Img src={logo} alt="logo" width="150px" />
        </S.ImgContainer>
      </S.InfoImage>
    </S.Footer>
  );
}
