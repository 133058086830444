import styled from 'styled-components';

export const Container = styled.button`
  background-color: #fff;
  border-style: solid 1px;
  border: 0;
  padding: 0.8rem;
  margin-top: 1.6rem;
  border-radius: 0.5rem;
  font-weight: 500;
  &:disabled {
    opacity: 0.5;
  }
  svg {
    font-size: 2rem;
  }

  :hover {
    background-color: #ccc;
    opacity: 0.8;
  }
`;
