import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0.5rem 0;
  min-width: 16rem;

  > .select {
    padding-right: 1.5rem;
  }
`;
