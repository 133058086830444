import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.colors?.backgroundColor};
  filter: grayscale(${(props) => props.theme.gray});
`;

export const Main = styled.div``;

export const TemporarySwitchToChangeLayout = styled.div`
  padding-left: 25rem;

  @media screen and (max-width: ${({ theme }) => theme.size?.large}) {
    display: none;
  }
`;
