import React, { ReactNode } from 'react';

import { Container } from './styles';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  link?: () => Promise<void>;
  icon?: ReactNode;
  title: string;
  color: string;
  disabled?: boolean;
}

export default function BasicButton({
  type,
  link,
  icon,
  title,
  color,
  disabled,
}: Props) {
  return (
    <Container type={type || 'button'} onClick={link} disabled={disabled}>
      <>
        <span className={color}>
          {icon}
          <span>{title}</span>
        </span>
      </>
    </Container>
  );
}
