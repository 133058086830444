import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: ${({ theme }) => theme.colors?.text.primary};
  }
`;

export const StyledError = styled.p`
  color: red;
`;

interface IInputContainer {
  sizeHeight: string;
  disabled?: boolean;
}
export const InputContainer = styled.div<IInputContainer>`
  position: relative;
  border-radius: 10px;
  height: ${(props) => props.sizeHeight};

  background-color: ${({ theme }) => theme.colors?.gray02};
  border: ${(props) =>
    props.disabled
      ? '1px solid' + props.theme.colors?.gray02
      : '1px solid' + props.theme.colors?.gray02};

  box-shadow: ${(props) =>
    props.disabled ? 'none' : 'inset 0px 3px 6px #00000029'};
`;

export const LeftBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors?.backgroundColor};

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3rem;
  z-index: 1;

  background-color: ${({ theme }) => theme.colors?.secondary};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: inset 0px 3px 6px #00000029;
`;

interface IStyledField {
  leftIcon?: boolean;
  align?: 'center' | 'start' | 'end';
  sizeHeight?: string;
}

const commonStyles = css<IStyledField>`
  border-radius: 10px;
  padding: 1rem;
  height: 100%;
  width: 100%;
  text-align: ${(props) => props.align};
  background-color: transparent;
  border: none;
  padding-left: ${(props) => (props.leftIcon ? '4rem' : '1rem')};
  &:focus {
    outline: none;
  }
`;

export const MaskedField = styled(InputMask)<IStyledField>`
  ${commonStyles}
  /* Estilos para remover as setas do input number */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  appearance: none;
`;

export const StyledField = styled.input<IStyledField>`
  ${commonStyles}
  /* Estilos para remover as setas do input number */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  appearance: none;
`;

interface IInputStyledFieldText {
  sizeHeight?: string;
  leftIcon?: boolean;
  align?: 'center' | 'start' | 'end';
}
export const StyledFieldText = styled.textarea<IInputStyledFieldText>`
  ${commonStyles}

  position: relative;
  border-radius: 10px;
  height: ${(props) => (props.sizeHeight ? props.sizeHeight : '4rem')};
  background-color: ${({ theme }) => theme.colors?.gray01};
  border: 1px solid ${({ theme }) => theme.colors?.gray02};
  box-shadow: inset 0px 3px 6px #00000029;
`;

export const DivSelect = styled.div``;

export const StyledSelect = styled.select<IStyledField>`
  ${commonStyles}
  position: relative;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors?.text.secondary
      : props.theme.colors?.text.primary};

  border-right: 1.5rem solid transparent;
  * {
    border-radius: 15px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors?.gray01};
    border: none;
  }
`;

export const EyeIcon = styled.div`
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors?.gray04};
`;
