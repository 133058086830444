import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  color: ${({ theme }) => theme.colors?.text.primary};

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    .button_header {
      display: none;
    }
  }
`;

export const Title = styled.h2``;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 1rem;

  height: 10vh;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    margin-right: 2rem;
  }
`;

interface ISubtitle {
  isString?: boolean;
}
export const Subtitle = styled.div<ISubtitle>`
  max-width: ${(props) => (props.isString ? '80rem' : '100%')};
  color: ${({ theme }) => theme.colors?.text.secondary};
`;
