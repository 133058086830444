import React, { useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import * as S from './styles';
import { IRegistrationColumns, IRegistrationRows } from '../..';
import {
  RowText,
  RowTextBold,
  TitleTable,
} from '../../../../../components/student/Table/components';

import contractService from '../../../../../services/contract/contract.service';
import { warningToast } from '../../../../../components/common/Toast';
import { Button } from '../../../../../components/student/Buttons';
import { LoaderSimpleCenter } from '../../../../../components/common/Loaders';

interface IProps {
  columns: IRegistrationColumns;
  rows: IRegistrationRows[];
}

export interface IDataContract {
  contrato_id: number;
  curso_documento_tipo_id: number;
}

export default function RegistrationTable({ columns, rows }: IProps) {
  const [isloading, setIsLoading] = useState(false);

  const printRegistration = async (row: IRegistrationRows) => {
    setIsLoading(true);

    const response = await contractService.getContractPdf({
      contrato_id: row.id,
      curso_documento_tipo_id: row.curso_documento_tipo_id,
    });

    if (response.result) {
      window.open(response.data);
    } else {
      response.message.forEach((text) => {
        warningToast(text);
      });
    }
    setIsLoading(false);
  };

  return isloading ? (
    <div style={{ paddingTop: '20rem' }}>
      <LoaderSimpleCenter text="Carregando" />
    </div>
  ) : (
    <S.TableContainerStyle>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TitleTable>{columns.title}</TitleTable>
            </TableCell>
            <S.CellRow align="right">
              <S.Cell>
                <TitleTable>{columns.titleAction}</TitleTable>
              </S.Cell>
            </S.CellRow>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => {
            return (
              <TableRow key={row.id}>
                <S.TableCellStyle scope="row">
                  <div>
                    <RowTextBold align="left">{row.nome}</RowTextBold>
                  </div>
                  <RowText align="left">{row.curso_documento_tipo}</RowText>
                </S.TableCellStyle>
                <TableCell component="th" scope="row" align="right">
                  <S.ContainerLine>
                    <Button
                      link={() => printRegistration(row)}
                      icon={<DownloadIcon />}
                      color={'info'}
                    />
                  </S.ContainerLine>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </S.TableContainerStyle>
  );
}
