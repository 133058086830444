import React, { useEffect } from 'react';

import { DocumentsTable } from '../../../../../components/student/Table';
import { INoticeRows } from '../../../../../components/student/Info/Notice';
import { statementService } from '../../../../../services';
import { Notice } from '../../../../../components/student/Info';
import { AlertBar } from '../../../../../components/student/Alerts';

interface IRegistrationColumns {
  title: string;
  titleAction: string;
}

interface IRegistrationRows {
  title: string;
  subtitle: string;
  action: string;
  link: string;
  html?: string | null;
}

export default function RegistrationStatement() {
  const columnsContracts: IRegistrationColumns = {
    title: 'Curso',
    titleAction: 'Declaração',
  };

  const rows: IRegistrationRows[] = [
    {
      title: 'Terapia da Constelação Familiar Sistêmica',
      subtitle: 'PÓS-GRADUAÇÃO LATO SENSU',
      action: 'download',
      link: 'https://mag.wcoomd.org/uploads/2018/05/blank.pdf',
      html: '',
    },
    {
      title: 'MBA em Gestão e Tecnologia de Resíduos',
      subtitle: 'MBA LATO SENSU',
      action: 'print',
      link: 'https://siead.faculdadefamart.localhost/aluno/matricula/contrato/id/10629',
      html: '<div><h1>Impressão</h1></div>',
    },
    {
      title: 'Técnico de Informática',
      subtitle: 'CAPACITAÇÃO PROFISSIONAL',
      action: 'print',
      link: 'https://siead.faculdadefamart.localhost/aluno/matricula/contrato/id/10629',
      html: '<div><h1>Impressão</h1></div>',
    },
  ];

  const rowsNotice: INoticeRows[] = [
    {
      subtitle: 'Situação Financeira: ',
      text: 'Adimplente',
    },
    {
      subtitle: 'Documentos Entregues: ',
      text: 'RG, CPF e Histórico Ensino Médio(ou equivalentes).',
    },
  ];

  const rowsNoticePos: INoticeRows[] = [
    {
      subtitle: 'Situação Financeira: ',
      text: 'Adimplente',
    },
    {
      subtitle: 'Documentos Entregues: ',
      text: 'Diploma de Curso Superior (Cópia simples) e Carteira de Identidade (Cópia Simples).',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await statementService.statementShow();
    };

    fetchData();
  }, []);

  return (
    <>
      <Notice
        title="Requisitos para emissão de cursos de Graduação:"
        rows={rowsNotice}
      />

      <Notice
        title="Requisitos para emissão de cursos de Pós-Graduação:"
        rows={rowsNoticePos}
      />

      <DocumentsTable rows={rows} columns={columnsContracts} />

      <AlertBar
        color={'info aviso'}
        message={
          <p>
            Caso tenha alguma dúvida para emitir a declaração, entre em contato
            com a gente pelo chat no canto inferior direito.
          </p>
        }
      />
    </>
  );
}
