import styled from 'styled-components';

export const Container = styled.div`
  font-size: 15px;
  padding: 2rem 0;

  #conteudo_impressao > p > span > span {
    font-size: 12px;
    line-height: normal;
  }
`;

export const PTitle = styled.p`
  text-align: center;
  margin-bottom: 0.5rem;
  line-height: 100%;
`;

export const P = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: 1.5rem;
  line-height: 100%;
`;

export const Footer = styled.div`
  p {
    text-align: center;
    margin-top: 1.95in;
    margin-bottom: 0in;
    line-height: 100%;
  }
`;
