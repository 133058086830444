import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import BasicModal from '../BasicModal';
import { ButtonSimple } from '../../Buttons';
import { authNewStudentService } from '../../../../services/auth';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
}
export default function SignOutConfirmModal({
  isOpen,
  setIsOpen,
  handleClose,
}: Props) {
  return (
    <S.Container>
      <BasicModal isOpen={isOpen} setIsOpen={setIsOpen} isFloat>
        <S.Content>
          <S.Title>Deseja realmente sair?</S.Title>
          <S.Buttons>
            <ButtonSimple
              color="gray"
              heightSize="small"
              widthSize="xmedium"
              onClick={handleClose}
            >
              Cancelar
            </ButtonSimple>
            <ButtonSimple
              color="primary"
              heightSize="small"
              widthSize="xmedium"
              onClick={() => authNewStudentService.cleanLoggerUser()}
            >
              Sair
            </ButtonSimple>
          </S.Buttons>
        </S.Content>
      </BasicModal>
    </S.Container>
  );
}
