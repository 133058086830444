import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerFocus = styled.div`
  display: flex;

  padding: 1rem 0;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 12px;

  tr td:last-child,
  tr th:last-child {
    border-right: none;
  }
  tr:last-child > td {
    border-bottom: none;
  }
`;

interface Props {
  textAlign?: 'center' | 'left' | 'right' | 'justify' | 'char';
  noPadding?: boolean;
}
export const Th = styled.th<Props>`
  text-align: ${(props) => props.align};
  padding: ${(props) => (!props.noPadding ? '1.5rem 2rem' : '0')};
  color: ${({ theme }) => theme.colors?.text.secondary};

  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
`;

export const Td = styled.td<Props>`
  text-align: ${(props) => props.align};
  padding: ${(props) => (!props.noPadding ? '0.5rem 2rem' : '0')};

  color: ${({ theme }) => theme.colors?.text.secondary};
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
`;

export const Row = styled.tr``;

export const InputFile = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors?.primary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors?.backgroundColor};

  cursor: pointer;
  margin-top: 0.5rem;

  max-width: 15rem;
  width: 100%;
  height: 3rem;
`;

export const NameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  input[type='file'] {
    display: none;
  }
`;

export const Text = styled.div`
  max-width: 15rem;
  width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: end;

  width: 9rem;

  gap: 1rem;
`;

export const ButtonDownload = styled.a`
  color: ${({ theme }) => theme.colors?.primary};
  cursor: pointer;
`;

export const ButtonSave = styled.div`
  color: ${({ theme }) => theme.colors?.success};
  cursor: pointer;
`;

export const ButtonDelete = styled.div`
  color: ${({ theme }) => theme.colors?.danger};
  cursor: pointer;
  padding-left: 1rem;
`;
