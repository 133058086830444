import styled from 'styled-components';

export const Header = styled.div`
  .aviso {
    z-index: 9999;
    font-weight: bolder;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    width: 100%;
    min-height: 8.3rem;
    margin-top: 0px;
    position: fixed;
    padding: 1.2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  section.default {
    background-color: ${({ theme }) => theme.alert.default.main};
    color: ${({ theme }) => theme.fontColor.default.main};
  }
  section.success {
    background-color: ${({ theme }) => theme.alert.success.main};
    color: ${({ theme }) => theme.fontColor.success.main};
  }
  section.info {
    background-color: ${({ theme }) => theme.alert.info.main};
    color: ${({ theme }) => theme.fontColor.info.main};
  }
  section.warning {
    background-color: ${({ theme }) => theme.alert.warning.main};
    color: ${({ theme }) => theme.fontColor.warning.main};
  }
  section.danger {
    background-color: ${({ theme }) => theme.alert.danger.main};
    color: ${({ theme }) => theme.fontColor.white.main};
  }
`;
