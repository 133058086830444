import { toast, ToastContent } from 'react-toastify';

interface ToastOptions {
  position?:
    | 'top-right'
    | 'top-center'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left';
  theme?: 'light' | 'dark';
  autoClose?: number;
  hideProgressBar?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  toastId?: number;
}

export function infoToast(message: ToastContent, id?: number): void {
  toast.info(message, {
    position: 'top-right',
    theme: 'light',
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: false,
    draggable: true,
    toastId: id,
  });
}

export function successToast(message: ToastContent, id?: number): void {
  toast.success(message, {
    position: 'top-right',
    theme: 'light',
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: false,
    draggable: true,
    toastId: id,
  });
}

export function warningToast(
  message: ToastContent,
  id?: number,
  options: ToastOptions = {},
): void {
  const defaultOptions: ToastOptions = {
    position: 'top-right',
    theme: 'light',
    autoClose: 5000, // Definido como false para remover o autoClose
    hideProgressBar: false,
    pauseOnHover: false,
    draggable: true,
    toastId: id,
  };

  const toastOptions = { ...defaultOptions, ...options };

  toast.warning(message, toastOptions);
}

export function errorToast(
  message: ToastContent,
  id?: number,
  autoClose?: boolean,
): void {
  toast.error(message, {
    position: 'top-right',
    theme: 'light',
    autoClose: autoClose ? false : 5000,
    hideProgressBar: false,
    pauseOnHover: false,
    draggable: true,
    toastId: id,
  });
}
