import React, { useState } from 'react';

import * as S from './styles';

import HeaderPages from '../../../components/student/HeaderPages';
import { Form } from 'react-bootstrap';
import { Capacitation, Graduation } from './components';

export default function MyCertificate() {
  const [selected, setSelected] = useState<number | string>(0);
  const options = [
    { title: '[ Selecione o que deseja emitir ]', value: 0 },
    { title: 'Capacitação', value: 1 },
    {
      title: 'Graduação',
      value: 2,
      options: [
        { value: '0', title: '[ Selecione o curso ]' },
        {
          value: '28146',
          title:
            'Gestão Escolar Integrada com ênfase em Administração, Supervisão, Orientação e Inspeção Escolar',
        },
      ],
    },
    {
      title: 'Pós-Graduação',
      value: 3,
    },
  ];

  return (
    <>
      <HeaderPages title="Meus Certificados"></HeaderPages>
      <S.Container>
        <Form.Select
          style={{
            backgroundColor: 'white',
            minHeight: '3rem',
            fontSize: '13px',
          }}
          onChange={(e) => setSelected(e.target.value)}
        >
          {options.map((item, index) => {
            return (
              item && (
                <option
                  key={index}
                  value={item.value !== undefined ? item.value.toString() : ''}
                >
                  {item.title}
                </option>
              )
            );
          })}
        </Form.Select>
        <S.Content>
          {+selected === 1 && <Capacitation />}
          {+selected === 2 && <Graduation />}
          {+selected === 3 && <Graduation />}
        </S.Content>
      </S.Container>
    </>
  );
}
