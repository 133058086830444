import { defaultReturn } from '../../../util/newStudent';
import * as api from '../../api/newStudent/apiStudent.service';

export interface IDataContracts {
  courseDocumentTypeId: number;
  registrationId: number;
  courseName: string[];
  courseType: string;
  signedDate: string;
  url: string | null;
}

export interface IDataSignedContractObject {
  contracts: IDataContracts[];
  acceptanceTerm: IDataContracts[];
}

export interface IDataUnsignedContracts {
  id: number;
  courseName: string;
  courseTypeName: string;
  registrationDate: string;
}

export const contractServiceNewStudent = {
  async signedContracts(): Promise<{
    data: IDataSignedContractObject;
    result: boolean;
    message: string[];
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/new/student/contract/signed-contracts',
        'GET',
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async unsignedContracts(): Promise<{
    data: IDataUnsignedContracts[];
    result: boolean;
    message: string[];
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/new/student/contract/unsigned-contracts',
        'GET',
      );
      return request;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getContractContent(id: number) {
    try {
      const request = await api.authenticatedRequest(
        '/student/contract/get-contract-content/' + id,
        'GET',
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getContractPdf(data: {
    courseDocumentTypeId: number | null;
    contractId: number | null;
  }) {
    try {
      const request = await api.authenticatedRequest(
        '/student/contract/get-contract-pdf',
        'POST',
        data,
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async signContract(id: number) {
    try {
      const request = await api.authenticatedRequest(
        '/student/contract/sign-contract/' + id,
        'PUT',
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },
};
