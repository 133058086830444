import styled from 'styled-components';

export const Container = styled.div``;

export const DivFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  gap: 1rem;
`;

export const BtnAction = styled.div`
  display: flex;
  gap: 1rem;
`;

export const BtnSuccess = styled.div`
  color: ${({ theme }) => theme.colors?.success};
  cursor: pointer;
  width: 2rem;
`;

export const BtnDanger = styled.div`
  color: ${({ theme }) => theme.colors?.danger};
  cursor: pointer;
  width: 2rem;
`;

export const BtnDownload = styled.div`
  color: ${({ theme }) => theme.colors?.success};
  cursor: pointer;
  width: 2rem;
`;

interface IInputFile {
  index?: number;
}
export const InputFile = styled.label<IInputFile>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) =>
    props.index === 0
      ? props.theme.colors?.primary
      : props.theme.colors?.secondary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors?.backgroundColor};
  cursor: pointer;
  margin-top: 0.5rem;

  width: 100%;
  height: 3rem;
  max-width: 20rem;

  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    max-width: 12rem;
  }
`;

export const DivName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const H6 = styled.h6`
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`;
