import React, { ReactNode } from 'react';

import { Container } from './styles';

interface Props {
  link: () => void;
  color: string;
  icon: ReactNode;
  title?: string;
}
export default function Button({ link, color, icon, title }: Props) {
  return (
    <Container onClick={link}>
      <span className={color}>
        {icon} {title}
      </span>
    </Container>
  );
}
