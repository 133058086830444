import React, { ReactNode } from 'react';

import TableCell from '@mui/material/TableCell';

import { MyTableCell, MyTableRow, RowText } from '../../../components';

interface BodyTableProps {
  id: number;
  icon?: ReactNode;
  color: string;
  accountName: string;
  description: string;
  dueDate: string;
  dueDateValue: string;
  status: string;
  button?: ReactNode;
}

export default function BodyTable({
  id,
  icon,
  color,
  status,
  button,
  dueDate,
  accountName,
  description,
  dueDateValue,
}: BodyTableProps) {
  return (
    <MyTableRow key={id}>
      <MyTableCell component="th" scope="lancamento" sx={{ color: color }}>
        <RowText align="left">
          {icon} {accountName}
        </RowText>
      </MyTableCell>
      <MyTableCell
        align="left"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
        sx={{ color: color, fontSize: '134px' }}
      ></MyTableCell>
      <TableCell align="center" sx={{ color: color }}>
        <RowText align="left">{dueDate}</RowText>
      </TableCell>
      <TableCell align="center" sx={{ color: color }}>
        <RowText align="left">R$ {dueDateValue}</RowText>
      </TableCell>
      <TableCell align="center" sx={{ color: color }}>
        <RowText align="left">{status}</RowText>
      </TableCell>
      <TableCell align="center" sx={{ color: color }}>
        <RowText align="left">{button}</RowText>
      </TableCell>
    </MyTableRow>
  );
}
