import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';

import BasicModal from '../BasicModal';
import InputDataConfirm from './Input';
import ConfirmMyData from './Confirm';
import Divider from '../../../common/Divider';
import { handleMaxWidth } from '../../../../util/newStudent';
import { useTheme } from 'styled-components';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleOnClose?: () => void;
  newField: { email?: string; phone?: string | number };
  type: 'email' | 'phone';
}

export default function MyDataConfirmModal({
  isOpen,
  setIsOpen,
  newField,
  type,
}: Props) {
  const theme = useTheme();
  return (
    <BasicModal setIsOpen={setIsOpen} isOpen={isOpen} isFloat={true}>
      <S.Container>
        <ConfirmMyData type={type} />
        <Divider
          isVertical={handleMaxWidth(theme.size?.medium) ? false : true}
        />
        <InputDataConfirm setIsOpen={setIsOpen} newField={newField} />
      </S.Container>
    </BasicModal>
  );
}
