import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { Header, Modal } from 'semantic-ui-react';

import * as S from './styles';

interface Props {
  title: string;
  children: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ModalContract({
  title,
  children,
  open,
  setOpen,
}: Props) {
  return (
    <>
      <S.Container
        closeIcon
        open={open}
        onClose={() => setOpen(false)}
        style={{
          position: 'fixed',
          display: 'flex',
          margin: 'auto',
          fontSize: '1rem',
        }}
      >
        <Header icon="archive" content={title} />
        <Modal.Content image scrolling>
          {children}
        </Modal.Content>
      </S.Container>
    </>
  );
}
