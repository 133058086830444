import * as apiService from '../api/student/api.service';

const maintenanceService = {
  async checkMaintenance(): Promise<{
    data: {
      server_under_maintenance: boolean;
      warning_instability: boolean;
      allowed_ip: string[];
    };
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .unauthenticatedRequest(`/services`, 'GET')
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },
};

export default maintenanceService;
