import styled from 'styled-components';

export const Container = styled.div``;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const KeyValueItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding-bottom: 1rem;
`;

interface Props {
  color?: 'danger' | 'success' | 'warning';
}
export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6<Props>`
  color: ${(props) =>
    props.color
      ? props.theme.colors?.[props.color]
      : props.theme.colors?.text.secondary};
`;

export const DivAnexos = styled.div``;

export const H6 = styled.h6``;
