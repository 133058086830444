import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';

import { ButtonIcon } from '../../../../../components/agent/Buttons';
import * as S from './styles';
import { ModalEdit } from './components';
import { numberToReal } from '../../../../../util/agent/money';
import { convertStringToMoment } from '../../../../../util/pageUtil';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../../../components/agent/ProviderAgent';
import { ModalStudentInfo } from '../../../../../components/agent/ModalSearchNegotiation/components';
import { bankBilletsService } from '../../../../../services/agent/';
import { IFiltered } from '../../../../../services/agent/sale/interface';

interface Props {
  list: IFiltered[];
  setModalEditOpen: Dispatch<SetStateAction<boolean>>;
  modalEditOpen: boolean;
}

export default function ListMyBusiness({
  list,
  setModalEditOpen,
  modalEditOpen,
}: Props) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [paidRegistration, setPaidRegistration] = useState<string>('R$ 0,00');

  const [notPaidRegistrationTotal, setNotPaidRegistrationTotal] =
    useState<string>('R$ 0,00');

  const [infoEdit, setInfoEdit] = useState({ id: 0, cpf: '' });

  const handleSearchStudent = (value: string) => {
    setIsOpen(true);

    setSearch(value);
  };

  useEffect(() => {
    let paidTotal = 0;
    let notPaidTotal = 0;
    list.forEach((row) => {
      const registration = row?.registration;
      if (registration?.value !== undefined) {
        if (registration?.paid) {
          paidTotal += registration.value;
        } else {
          notPaidTotal += registration.value;
        }
      }
    });
    const formattedPaid = `R$ ${paidTotal.toFixed(2).replace('.', ',')}`;
    setPaidRegistration(formattedPaid);

    const formattedNotPaid = `R$ ${notPaidTotal.toFixed(2).replace('.', ',')}`;
    setNotPaidRegistrationTotal(formattedNotPaid);
  }, [list]);

  const handlePrintOut = async (id: number | undefined) => {
    setIsLoading(true);
    const { result, data } = await bankBilletsService.bankBilletsView(id!);
    if (result) {
      window.open(data, '_blank');
    }
    setIsLoading(false);
  };

  return (
    <S.Container>
      <S.ContainerTable>
        <S.StyledTable>
          <thead>
            <tr>
              <S.StyledTh>Acesso</S.StyledTh>
              <S.StyledTh>Nome</S.StyledTh>
              <S.StyledTh style={{ width: '12rem' }}>CPF</S.StyledTh>
              <S.StyledTh style={{ textAlign: 'center' }}>Matrícula</S.StyledTh>
              <S.StyledTh colSpan={5}></S.StyledTh>
            </tr>
          </thead>
          <tbody>
            {list.map((row, index) => (
              <tr key={index}>
                <S.StyledTd
                  style={{
                    color: row.access ? 'green' : 'red',
                    width: '20px',
                    textAlign: 'center',
                  }}
                >
                  {row.access ? <ThumbUpAltIcon /> : <ThumbDownAltIcon />}
                </S.StyledTd>
                <S.StyledTd>
                  <a
                    className="button"
                    onClick={() =>
                      handleSearchStudent(row.cpf ? row.cpf : row.email)
                    }
                  >
                    <b>{row.name}</b>
                  </a>
                  <br />
                  <span>
                    {convertStringToMoment(row.startDate, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY',
                    )}
                  </span>
                </S.StyledTd>
                <S.StyledTd style={{ width: '12rem' }}>{row.cpf}</S.StyledTd>
                {row.registration &&
                (row.registration.payment_date || row.registration.due_date) ? (
                  <S.StyledTd
                    style={{
                      color:
                        row.registration.status === 'Pago'
                          ? 'green'
                          : row.registration.status === 'Vencido'
                          ? 'red'
                          : 'black',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '10rem',
                    }}
                  >
                    {row.registration.paid
                      ? convertStringToMoment(
                          row.registration.payment_date,
                          'YYYY-MM-DD',
                        ).format('DD/MM/YYYY')
                      : convertStringToMoment(
                          row.registration.due_date,
                          'YYYY-MM-DD',
                        ).format('DD/MM/YYYY')}
                    <br />
                    {numberToReal(row.registration.value)}
                  </S.StyledTd>
                ) : (
                  <S.StyledTd />
                )}
                <S.StyledTdAction>
                  {row.action.printOut && (
                    <ButtonIcon
                      color="white"
                      onClick={async () => handlePrintOut(row.action.printOut)}
                    >
                      <PrintIcon />
                    </ButtonIcon>
                  )}
                </S.StyledTdAction>
                <S.StyledTdAction>
                  {row.action.edit && (
                    <ButtonIcon
                      color="blue"
                      onClick={() => {
                        setInfoEdit({
                          id:
                            row.action.edit !== undefined ? row.action.edit : 0,
                          cpf: row.cpf !== undefined ? row.cpf : '',
                        });
                        setModalEditOpen(!modalEditOpen);
                      }}
                    >
                      <EditIcon />
                    </ButtonIcon>
                  )}
                </S.StyledTdAction>
              </tr>
            ))}
          </tbody>
        </S.StyledTable>
        <S.StyledTable>
          <thead>
            <tr>
              <S.StyledTh style={{ color: 'green', textAlign: 'center' }}>
                Valor matrículas pagas
              </S.StyledTh>
              <S.StyledTh style={{ color: 'red', textAlign: 'center' }}>
                Valor matrículas em aberto
              </S.StyledTh>
              <S.StyledTh style={{ textAlign: 'center' }}>
                Total de Vendas
              </S.StyledTh>
            </tr>
          </thead>
          <tbody>
            <tr>
              <S.StyledTd style={{ textAlign: 'center' }}>
                {paidRegistration}
              </S.StyledTd>
              <S.StyledTd style={{ textAlign: 'center' }}>
                {notPaidRegistrationTotal}
              </S.StyledTd>
              <S.StyledTd style={{ textAlign: 'center' }}>
                {list.length}
              </S.StyledTd>
            </tr>
          </tbody>
        </S.StyledTable>
        <ModalEdit
          isOpen={modalEditOpen}
          setIsOpen={setModalEditOpen}
          infoEdit={infoEdit}
          setInfoEdit={setInfoEdit}
        />
        <ModalStudentInfo
          isOpen={!!isOpen}
          setIsOpen={setIsOpen}
          dataStudent={search}
        />
      </S.ContainerTable>
    </S.Container>
  );
}
