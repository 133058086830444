import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 30vw;
  padding: 0 3rem;
  height: 100%;
  min-height: 25vh;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 2rem 0 1rem 0;
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  border-bottom: 1px solid #e5e5e5;
`;

export const Title = styled.h4`
  padding: 1rem;
`;

export const Text = styled.h6``;

export const Name = styled.h6`
  font-weight: bold;
  color: #000;
  padding-top: 1rem;
`;
