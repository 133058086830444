import React from 'react';

import { Loader } from 'semantic-ui-react';

import { Container } from './styles';

interface Props {
  text?: string;
}

//Loader simples sem fundo no centro
export default function LoaderSimpleCenter({ text }: Props) {
  return (
    <Container>
      <Loader active inline="centered">
        {text}
      </Loader>
    </Container>
  );
}
