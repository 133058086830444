import FormData from 'form-data';

import * as apiService from '../../api/newStudent/apiStudent.service';
import { defaultReturn } from '../../../util/newStudent';

interface IDocumentRequestBody {
  id?: number;
  documentType?: number;
  universityDegreeId?: number;
  file?: File;
}

type IData = {
  id: number;
  name: string;
  status: string;
  observation: null | string;
  url: string;
};

interface IDocumentApiResponse {
  data: IData;
  message: string[];
  result: boolean;
}

export const documentServiceNewStudent = {
  async getAll() {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/documents/show',
        'GET',
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async documentApi({
    body,
    action,
  }: {
    body: IDocumentRequestBody;
    action?: 'delete' | 'update';
  }) {
    let formData;
    let requestType: 'file' | 'object';
    if (action === 'update') {
      formData = new FormData();
      formData.append('id', body.id && body.id.toString());
      formData.append(
        'type',
        body.documentType && body.documentType.toString(),
      );
      if (body.file) {
        const fileBlob = new Blob([body.file], { type: body.file.type });
        formData.append('file', fileBlob, body.file.name);
      }
      requestType = 'file';
    } else {
      formData = { type: body.documentType, id: body.id };
      requestType = 'object';
    }
    return { formData, requestType };
  },

  async documentRequest({
    body,
    action = 'update',
  }: {
    body: IDocumentRequestBody;
    action?: 'delete' | 'update';
  }): Promise<IDocumentApiResponse> {
    const { formData, requestType } = await this.documentApi({ body, action });
    try {
      const response = await apiService.authenticatedRequest(
        '/student/documents/upload',
        'POST',
        formData,
        requestType,
      );

      return defaultReturn(response);
    } catch (error) {
      throw new Error(error);
    }
  },
};

interface CertificateApiBody {
  id?: string | number;
  registrationId: number;
  universityDegreeId: number;
  initialDate?: string;
  url?: string;
  finalDate?: string;
  workload?: number;
  type: number | string;
  file?: File;
}

export const documentCertificate = {
  async uploadCertificate({ body }: { body: CertificateApiBody }) {
    try {
      const formData = new FormData();
      formData.append('id', body.id ? body.id : '');
      formData.append('type', body.type.toString());
      formData.append(
        'registrationId',
        body.registrationId ? body.registrationId.toString() : null,
      );
      formData.append(
        'universityDegreeId',
        body.universityDegreeId ? body.universityDegreeId.toString() : '',
      );
      formData.append('url', body.url);
      formData.append('file', body.file);
      formData.append('finalDate', body.finalDate);
      formData.append('initialDate', body.initialDate);
      formData.append('workload', body.workload && body.workload.toString());

      const response = await apiService.authenticatedRequest(
        '/student/documents/degree-data',
        'POST',
        formData,
        'file',
      );

      return defaultReturn(response);
    } catch (error) {
      throw new Error(error);
    }
  },
};
