import React, { ReactNode } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { CustomToggle } from './components';
import { CustomAccordion } from './styles';

interface Props {
  color: string;
  icon: ReactNode;
  title: string;
  children: ReactNode;
  titleBox: string;
  message: string;
  iconBar: ReactNode;
}

export default function AccordionCustom({
  color,
  icon,
  title,
  children,
  titleBox,
  message,
  iconBar,
}: Props) {
  return (
    <CustomAccordion>
      <Card>
        <Card.Header>
          <CustomToggle
            iconBar={iconBar}
            eventKey="1"
            message={message}
            titleBox={titleBox}
            color={color}
          >
            {icon}
            {title}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </CustomAccordion>
  );
}
