import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from '../styles';

import OutboxIcon from '@mui/icons-material/Outbox';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { ISubjectContentData } from '../../../..';
import { useTheme } from 'styled-components';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../ProviderNewStudent';
import {
  IContentSubject,
  IDataContentSubject,
  myCoursesServiceNewStudent,
} from '../../../../../../../../services/newStudent/myCourses/myCourses.service';
import { IClassInfo } from '../../../../../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';

interface IIdShouldDisplay {
  video?: number;
  archive?: number;
  audiobook?: number;
  customized?: number;
  tcc?: number;
  internship?: number;
}
interface Props {
  setSubjectContent?: Dispatch<SetStateAction<ISubjectContentData | undefined>>;
  classInfo: IClassInfo;
  contentIdSelected: number;
  setBlockedContent: Dispatch<SetStateAction<boolean>>;
}
export default function TabLesson({
  setSubjectContent,
  classInfo,
  contentIdSelected,
  setBlockedContent,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const theme = useTheme();
  const [content, setContent] = useState<IContentSubject>();
  const [contentShouldDisplay, setContentShouldDisplay] =
    useState<IIdShouldDisplay>();
  const [contentOpen, setContentOpen] = useState<number>();

  const courseId = classInfo.courseId;
  const disciplineId = classInfo.id;
  const contentId = contentIdSelected;

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);

        const { result, data } = await myCoursesServiceNewStudent.getContent(
          courseId,
          disciplineId,
          contentId,
        );

        if (result) {
          setContent(data);
          setBlockedContent(!!data.examInProgress);

          const displayFiltered: IIdShouldDisplay = {};
          let count = 0;

          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              count = count + 1;
              if (data[key].length > 0) {
                displayFiltered[key] = count;
              }
            }
          }

          setContentShouldDisplay(displayFiltered);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [courseId, disciplineId, contentId]);

  const itemInfoCollapse = (
    item: IDataContentSubject,
    index: number,
    type:
      | 'video'
      | 'archive'
      | 'audiobook'
      | 'customized'
      | 'internship'
      | 'tcc',
    icon: ReactNode,
  ) => {
    return (
      <S.DivSubtitle key={index}>
        <S.DivSubtitleSeparated>
          {icon}
          <S.Subtitle
            onClick={() =>
              setSubjectContent!({
                type: type,
                url: item.url ?? '',
                customized: item.customized,
                title: item.title,
                contentId: contentIdSelected,
                resourceId: item.resourceId,
              })
            }
          >
            {item.title}
          </S.Subtitle>
        </S.DivSubtitleSeparated>
      </S.DivSubtitle>
    );
  };

  const itemTitleCollapse = (contentShouldDisplay, title) => {
    return (
      <S.DitTitle>
        {contentOpen !== contentShouldDisplay ? (
          <ArrowDropDownIcon fontSize="large" />
        ) : (
          <ArrowDropUpIcon fontSize="large" />
        )}
        <S.TitleName
          onClick={() => {
            contentOpen !== contentShouldDisplay
              ? setContentOpen(contentShouldDisplay)
              : setContentOpen(undefined);
          }}
        >
          {title}
        </S.TitleName>
      </S.DitTitle>
    );
  };

  return (
    <S.Container>
      {contentShouldDisplay?.video && (
        <S.ContentDiv>
          {itemTitleCollapse(contentShouldDisplay.video, 'Videos')}
          {contentOpen === contentShouldDisplay?.video &&
            content?.video?.map((item: IDataContentSubject, index: number) =>
              itemInfoCollapse(
                item,
                index,
                'video',
                <OndemandVideoOutlinedIcon
                  fontSize="large"
                  style={{
                    color: theme.colors?.text.danger,
                  }}
                />,
              ),
            )}
        </S.ContentDiv>
      )}
      {contentShouldDisplay?.archive && (
        <S.ContentDiv>
          {itemTitleCollapse(contentShouldDisplay.archive, 'Apostila')}
          {contentOpen === contentShouldDisplay?.archive &&
            content?.archive?.map((item: IDataContentSubject, index: number) =>
              itemInfoCollapse(
                item,
                index,
                'archive',
                <DescriptionOutlinedIcon
                  fontSize="large"
                  style={{
                    color: theme.colors?.text.danger,
                  }}
                />,
              ),
            )}
        </S.ContentDiv>
      )}
      {contentShouldDisplay?.audiobook && (
        <S.ContentDiv>
          {itemTitleCollapse(contentShouldDisplay.audiobook, 'AudioBook')}
          {contentOpen === contentShouldDisplay?.audiobook &&
            content?.audiobook?.map(
              (item: IDataContentSubject, index: number) =>
                itemInfoCollapse(
                  item,
                  index,
                  'audiobook',
                  <GraphicEqIcon
                    fontSize="large"
                    style={{
                      color: theme.colors?.text.danger,
                    }}
                  />,
                ),
            )}
        </S.ContentDiv>
      )}
      {contentShouldDisplay?.customized && (
        <S.ContentDiv>
          {itemTitleCollapse(contentShouldDisplay.customized, 'Conteúdo')}
          {contentOpen === contentShouldDisplay?.customized &&
            content?.customized?.map(
              (item: IDataContentSubject, index: number) =>
                itemInfoCollapse(
                  item,
                  index,
                  'customized',
                  <GraphicEqIcon
                    fontSize="large"
                    style={{
                      color: theme.colors?.text.danger,
                    }}
                  />,
                ),
            )}
        </S.ContentDiv>
      )}
      {contentShouldDisplay?.internship && (
        <S.ContentDiv>
          {itemTitleCollapse(contentShouldDisplay.internship, 'Estágio')}
          {contentOpen === contentShouldDisplay?.internship &&
            content?.internship?.map(
              (item: IDataContentSubject, index: number) =>
                itemInfoCollapse(
                  item,
                  index,
                  'internship',
                  <OutboxIcon
                    fontSize="large"
                    style={{
                      color: theme.colors?.text.danger,
                    }}
                  />,
                ),
            )}
        </S.ContentDiv>
      )}
      {contentShouldDisplay?.tcc && (
        <S.ContentDiv>
          {itemTitleCollapse(contentShouldDisplay.tcc, 'TCC')}
          {contentOpen === contentShouldDisplay?.tcc &&
            content?.tcc?.map((item: IDataContentSubject, index: number) =>
              itemInfoCollapse(
                item,
                index,
                'tcc',
                <OutboxIcon
                  fontSize="large"
                  style={{
                    color: theme.colors?.text.danger,
                  }}
                />,
              ),
            )}
        </S.ContentDiv>
      )}
    </S.Container>
  );
}
