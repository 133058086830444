import styled from 'styled-components';

interface Props {
  isNegotiation?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'success'
    | 'danger'
    | 'attention'
    | 'aqua'
    | 'gray03';
}

export const Container = styled.div`
  margin: 2rem;
`;

export const TitlePage = styled.h5<Props>`
  color: ${(props) =>
    props.isNegotiation
      ? props.theme.colors?.danger
      : props.theme.colors?.aqua};
`;

export const DivTitleSelect = styled.div`
  display: flex;

  flex-direction: column;
  gap: 1rem;
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors?.primary};
  padding-top: 2rem;
  gap: 1rem;
`;

export const TermButton = styled.a`
  font-weight: bold;
  width: 13rem;

  cursor: pointer;
`;

export const DivSend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;

  justify-content: space-between;
`;

export const Card = styled.div`
  padding: 1rem 0;
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.p<Props>`
  color: ${(props) => props.theme.colors?.[props.color!]};
  text-align: center;
`;

export const KeyValue = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;

  justify-content: space-between;
`;

export const CardContent = styled.div`
  padding: 1rem 0;

  width: 90%;
`;

export const DivCard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.div`
  align-self: end;
`;
