import React, { useContext, useEffect, useState } from 'react';
import * as S from './styles';
import studentDataService from '../../../../../services/student/student.data.service';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../ProviderAgent';
import { convertStringToMoment } from '../../../../../util/pageUtil';
import { IStudent } from '../../../../../services/agent/student/interface';

export default function TabData({ data, setStudentName }) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  const [student, setStudent] = useState<IStudent>();
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await studentDataService.data({ login: data });
      if (response.data) {
        setStudent(response.data);
        setStudentName(response.data.name);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {student && (
        <S.StyledTable>
          <S.StyledTBody>
            <tr>
              <td>
                <b>Data de Cadastro: </b>
                {student.registerDate &&
                  convertStringToMoment(
                    student.registerDate,
                    'YYYY-MM-DD',
                  ).format('DD/MM/YYYY')}
              </td>
              <td>
                <strong>Nº da Matrícula:</strong> {student.registration}
              </td>
            </tr>
            <tr>
              <td>
                <b>Nome:</b> {student.name}
              </td>
              <td>
                <b>RG:</b> {student.rgId}
              </td>
            </tr>
            <tr>
              <td>
                <b>Nome do Pai:</b> {student.fatherName}
              </td>
              <td>
                <b>CPF:</b> {student.cpf}
              </td>
            </tr>
            <tr>
              <td>
                <b>Nome da Mãe:</b> {student.motherName}
              </td>
              <td>
                <b>Sexo:</b> {student.gender}
              </td>
            </tr>
            <tr>
              <td>
                <b>Data Nascimento: </b>
                {student.bornDate &&
                  student.bornDate !== '0000-00-00' &&
                  convertStringToMoment(student.bornDate, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY',
                  )}
              </td>
              <td>
                <b>Naturalidade:</b> {student.naturalness}
              </td>
            </tr>
            <tr>
              <td>
                <b>Cor/Raça: </b>
                {student.colorRace}
              </td>
              <td>
                <b>Nacionalidade:</b> {student.nationality}
              </td>
            </tr>
            <tr>
              <td>
                <b>Situação financeira: </b>
                {student.financialStatus}
              </td>
              <td>
                <b>Pcd: </b>
                {student.pcd}
              </td>
            </tr>
            <S.StyledTr>
              <td colSpan={1} className="titulo-secao">
                Formação
              </td>
              <td colSpan={1} className="titulo-secao">
                Informações Contato
              </td>
            </S.StyledTr>
            <tr>
              <td>
                <b>Nome da Instituição: </b>
                {student.university}
              </td>
              <td>
                <b>Telefone:</b> {student.homePhone}
              </td>
            </tr>
            <tr>
              <td>
                <b>Curso de Graduação:</b> {student.degree}
              </td>
              <td>
                <b>Celular:</b> {student.cellPhone}
              </td>
            </tr>
            <tr>
              <td>
                <b>Ano de Conclusão:</b> {student.graduationYear}
              </td>
              <td>
                <b>Tel Comercial:</b> {student.businessPhone}
              </td>
            </tr>
            <tr>
              <td>
                <b>Colação de Grau: </b>
                {student.ceremony &&
                  student.ceremony !== '0000-00-00' &&
                  convertStringToMoment(student.ceremony, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY',
                  )}
              </td>
              <td>
                <b>Email:</b> {student.email}
              </td>
            </tr>
            <S.StyledTr>
              <td colSpan={1} className="titulo-secao">
                Endereço
              </td>
              <td colSpan={1} className="titulo-secao"></td>
            </S.StyledTr>
            <tr>
              <td>
                <b>Logradouro:</b> {student.street}
              </td>
              <td>
                <b>Nº:</b> {student.number}
              </td>
            </tr>
            <tr>
              <td>
                <b>Cep:</b> {student.cep}
              </td>
              <td>
                <b>Complemento:</b> {student.complement}
              </td>
            </tr>
            <tr>
              <td>
                <b>Bairro:</b> {student.neighborhood}
              </td>
              <td>
                <b>Município:</b> {student.city}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <b>Estado:</b> {student.state}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <b>Data de Expiração :</b> {student.expirationData}
              </td>
            </tr>
          </S.StyledTBody>
        </S.StyledTable>
      )}
    </>
  );
}
