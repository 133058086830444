import React from 'react';
import * as S from './styles';
import { IItem, TableItem } from '../../../../interfaces/common/Table';

interface Props {
  data: {
    title?: string[] | IItem[] | undefined;
    content?: TableItem[];
  };
  noBorder?: boolean;
  titleNotFound?: string;
  titleLoading?: boolean;
  isLoading?: boolean;
  exception?: string[] | [];
}

export default function BasicTable({
  data,
  noBorder,
  titleNotFound,
  titleLoading,
  exception = [],
}: Props) {
  const { title, content } = data;

  if (!content || content?.length <= 0) {
    return (
      <>
        <S.NotFound>
          <h6>
            {titleLoading
              ? 'Carregando...'
              : titleNotFound
              ? titleNotFound
              : 'Nenhum dado encontrado...'}
          </h6>
        </S.NotFound>
      </>
    );
  }

  return (
    <>
      <S.StyledTable style={{ border: noBorder ? 'none' : '' }}>
        {title && title.length > 0 && (
          <thead>
            <tr>
              {title.map((item, index) => (
                <>
                  {typeof item === 'string' ? (
                    <S.StyledTh
                      key={index}
                      style={{ border: noBorder ? 'none' : '' }}
                    >
                      {item}
                    </S.StyledTh>
                  ) : (
                    <S.StyledTh
                      key={index}
                      style={{
                        border: noBorder ? 'none' : '',
                        ...(item?.style || {}),
                      }}
                    >
                      {item.item}
                    </S.StyledTh>
                  )}
                </>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {content?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).map(
                (key, colIndex) =>
                  exception.every((out) => key !== out) && (
                    <S.StyledTd
                      key={colIndex}
                      style={{
                        ...row[key]?.style,
                        border: noBorder ? 'none' : '',
                      }}
                    >
                      {row[key]?.item}
                    </S.StyledTd>
                  ),
              )}
            </tr>
          ))}
        </tbody>
      </S.StyledTable>
    </>
  );
}
