import React, { ReactNode, useEffect, useState } from 'react';

import * as S from './styles';

import LogoutIcon from '@mui/icons-material/Logout';
import { ButtonSimple } from '../../Buttons';
import Feedback from '../../Modal/FeedBackModal';

interface Props {
  title: string;
  subtitle?: ReactNode | string;
  titlePage?: string;
}
export function TitlePage({ title, subtitle, titlePage }: Props) {
  const [isModalExitOpen, setIsModalExitOpen] = useState(false);

  const renderSubtitle = (data: ReactNode | string) => {
    if (typeof data === 'string')
      return (
        <S.Subtitle isString>
          <h6>{data}</h6>
        </S.Subtitle>
      );
    return <S.Subtitle>{data}</S.Subtitle>;
  };

  useEffect(() => {
    document.title = titlePage ? titlePage : 'Portal do Aluno';
  }, []);

  return (
    <S.Container>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.ButtonDiv className="button_header">
          <ButtonSimple
            color="primary"
            icon={<LogoutIcon fontSize="large" />}
            onClick={() => setIsModalExitOpen(true)}
            justify="space-between"
            heightSize="medium"
            widthSize="medium"
          >
            Sair
          </ButtonSimple>
        </S.ButtonDiv>
      </S.Content>
      <Feedback isOpen={isModalExitOpen} setIsOpen={setIsModalExitOpen} />

      {subtitle && renderSubtitle(subtitle)}
    </S.Container>
  );
}
