import React from 'react';

import BasicModal from '../../../Modal';
import * as S from './styles';
import { ButtonSimple } from '../../../Buttons';

export default function ModalConfirm({ isOpen, setIsOpen, data }) {
  return (
    <BasicModal isFloat={true} isOpen={isOpen} setIsOpen={setIsOpen}>
      <S.Container>
        <S.ContainerTitle>
          <S.Title>{data.title}</S.Title>
        </S.ContainerTitle>
        <S.Name>{data.name}</S.Name>
        <S.Text>{data.text}</S.Text>
        <S.ContainerButton>
          <div style={{ paddingRight: '1rem' }}>
            <ButtonSimple size="medium" onClick={data?.button1?.action}>
              {data?.button1?.name}
            </ButtonSimple>
          </div>
          <ButtonSimple
            size="medium"
            color="green"
            onClick={data?.button2?.action}
          >
            {data?.button2?.name}
          </ButtonSimple>
        </S.ContainerButton>
      </S.Container>
    </BasicModal>
  );
}
