import styled from 'styled-components';

interface Props {
  isPaymentOpen?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'success'
    | 'danger'
    | 'attention'
    | 'gray03';
}
export const Container = styled.div``;

export const Content = styled.div`
  margin: 2rem;
`;

export const Paginate = styled.div`
  display: flex;

  justify-content: center;
  width: 100%;
`;

export const TitlePage = styled.h5<Props>`
  color: ${(props) =>
    props.isPaymentOpen
      ? props.theme.colors?.text.danger
      : props.theme.colors?.text.info02};
`;

export const Card = styled.div`
  padding: 1rem 0;
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.p<Props>`
  color: ${(props) => props.theme.colors?.[props.color!]};
  text-align: center;
`;

export const KeyValue = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;

  justify-content: space-between;
`;

export const CardContent = styled.div`
  padding: 1rem 0 0 0;
`;

export const ContentObservation = styled.div`
  padding: 0 0 1rem 0;
`;
