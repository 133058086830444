import React from 'react';

import { ButtonSimple } from '../Buttons';
import * as S from './styles';

interface Props {
  cancelClick?: () => void | Promise<void>;
  saveClick?: () => void | Promise<void>;
}
export default function FooterModal({ cancelClick, saveClick }: Props) {
  return (
    <S.Footer>
      {cancelClick && (
        <S.Button>
          <ButtonSimple size="medium" onClick={cancelClick}>
            Cancelar
          </ButtonSimple>
        </S.Button>
      )}
      {saveClick && (
        <S.Button>
          <ButtonSimple size="medium" color="blue" onClick={saveClick}>
            Salvar
          </ButtonSimple>
        </S.Button>
      )}
    </S.Footer>
  );
}
