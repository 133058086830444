import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { Reorder } from '@mui/icons-material';

import { IDocuments } from '../../../../../interfaces/student/dashboard.interfaces';
import {
  IStudentContextProps,
  StudentProviderContext,
} from '../../../StudentProvider';
import { ButtonText } from '../../../Buttons';
import '../../../../../styles/aluno.css';
import * as apiService from '../../../../../services/api/student/api.service';
import { warningToast } from '../../../../common/Toast';

export default function Menu() {
  const [menuHidden, setMenuHidden] = useState<boolean>(true);
  const [documentosRejeitados, setDocumentosRejeitados] = useState<
    IDocuments[]
  >([]);

  const context = useContext(StudentProviderContext);
  const { aluno, documents, setIsLoading } = context as IStudentContextProps;

  function checkStatus(item: number) {
    const status = item;
    return status !== 5 && status !== 9 && status !== 2;
  }

  const redirectTo = async (url: string) => {
    setIsLoading(true);
    const response = await apiService.authenticatedRequest(url);
    if (response.result) {
      window.location.href = response.data;
    } else {
      setIsLoading(false);
      response.message.forEach((text) => {
        text && warningToast(text);
      });
    }
    return;
  };

  const hideMenu = () => {
    setMenuHidden(!menuHidden);
  };

  const closeMenu = () => {
    setMenuHidden(true);
  };

  useEffect(() => {
    const element = document.getElementById('menu');
    if (menuHidden) {
      element?.classList.add('hidden-xs', 'hidden-sm');
    } else {
      element?.classList.remove('hidden-xs', 'hidden-sm');
    }
  }, [menuHidden]);

  useEffect(() => {
    setDocumentosRejeitados(
      documents.length > 0
        ? documents.filter((doc) => doc.status === 'Rejeitado')
        : [],
    );
  }, []);

  return (
    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
      <div>
        <div className="pull-left margin-bottom-10">
          <span
            id="hide-menu"
            className="pull-left visible-xs visible-sm margin-right-5"
          >
            <span className="btn btn-default" onClick={hideMenu} title="Menu">
              <Reorder /> Menu
            </span>
          </span>
        </div>
        <table id="menu" className="table table-bordered hidden-xs hidden-sm">
          <thead>
            <tr>
              <th className="text-center">ALUNO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ButtonText
                  title={'Meus Dados'}
                  link={async () =>
                    await redirectTo('/student/my-data/redirect')
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <ButtonText
                  title={'Alterar Senha de Acesso'}
                  link={async () => await redirectTo('/student/user/redirect')}
                />
              </td>
            </tr>
            <tr>
              <td>
                <NavLink to="/aluno/documentos" onClick={closeMenu}>
                  Documentos
                </NavLink>
                {documentosRejeitados.length > 0 ? (
                  <span
                    className="badge pull-right bounceIn margin-right-5"
                    style={{ backgroundColor: 'red' }}
                  >
                    {documentosRejeitados.length}
                  </span>
                ) : null}
              </td>
            </tr>
            <tr>
              <td>
                <ButtonText
                  title={'Matrículas/Contratos'}
                  link={async () =>
                    await redirectTo('/student/contract/redirect')
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <ButtonText
                  title={'Declarações'}
                  link={async () =>
                    await redirectTo('/student/statements/redirect')
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <ButtonText
                  title={'Certificados'}
                  link={async () =>
                    await redirectTo('/student/certificate/redirect')
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <ButtonText
                  title={'Mural de Tickets'}
                  link={async () =>
                    await redirectTo('/student/ticket/redirect')
                  }
                />
              </td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th className="text-center">FINANCEIRO</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <NavLink to="/aluno/historico-financeiro" onClick={closeMenu}>
                  Histórico Financeiro
                </NavLink>
              </td>
            </tr>
            {aluno?.status !== 5 && (
              <tr>
                <td>
                  <ButtonText
                    title={'Informe - Imposto de Renda'}
                    link={async () =>
                      await redirectTo('/student/financial/redirect')
                    }
                  />
                </td>
              </tr>
            )}
          </tbody>
          <thead>
            <tr>
              <th className="text-center">CURSO</th>
            </tr>
          </thead>

          <tbody>
            {(checkStatus(aluno?.status) ||
              aluno?.aluno_universidade_corporativa) && (
              <tr>
                <td>
                  <ButtonText
                    title={'Meus Cursos'}
                    link={async () =>
                      await redirectTo('/student/my-courses/redirect')
                    }
                  />
                </td>
              </tr>
            )}
            <tr>
              <td>
                <ButtonText
                  title={'Minhas Notas'}
                  link={async () =>
                    await redirectTo('/student/my-grades/redirect')
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <ButtonText
                  title={'Atendimento ao Aluno'}
                  link={async () =>
                    await redirectTo('/student/support/redirect')
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
