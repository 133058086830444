import React, { useContext, useEffect } from 'react';

import * as S from './styles';

import HeaderMinimal from '../../../components/newStudent/HeaderMinimal';
import Divider from '../../../components/common/Divider';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import CardUsignedContract from '../../../components/newStudent/Card/CardUsignedContract';
import { ButtonSimple } from '../../../components/newStudent/Buttons';
import {
  AlertWarning,
  AletNotfoundLoading,
} from '../../../components/newStudent/Alert';
import { useNavigate } from 'react-router-dom';
import { contractServiceNewStudent } from '../../../services/newStudent';

export default function DataConferenceNewStudent() {
  const { unsignedContracts, setUnsignedContracts, setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const navigate = useNavigate();

  useEffect(() => {
    if (unsignedContracts && unsignedContracts.length > 0) return;
    const load = async () => {
      try {
        setIsLoading(true);
        const { result, data } =
          await contractServiceNewStudent.unsignedContracts();
        if (result) {
          setUnsignedContracts(data);
          data.length > 0
            ? localStorage.setItem('unsignedContracts', 'true')
            : localStorage.removeItem('unsignedContracts');
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  return (
    <S.Container>
      <HeaderMinimal
        title="Assinatura de Contratos"
        titlePage="Assinatura de Contratos"
        subtitle="Muito bem! Estamos quase lá. Para iniciar os seus estudos, você deve ler e assinar os contratos."
      />
      <Divider padding />
      <S.Cards>
        {unsignedContracts?.length > 0 &&
          unsignedContracts.map((item, index) => (
            <CardUsignedContract contract={item} key={index} index={index} />
          ))}
      </S.Cards>
      {unsignedContracts?.length <= 0 ? (
        <AletNotfoundLoading
          toRender
          notFoundText="Você já assinou todos os contratos"
        />
      ) : (
        <AlertWarning title="Necessário ler os contratos e confirmar para prosseguir." />
      )}
      <S.Button>
        <ButtonSimple
          color="primary"
          widthSize="large"
          disabled={unsignedContracts?.length > 0}
          onClick={() => navigate('/new/aluno/inicio')}
        >
          Finalizar
        </ButtonSimple>
      </S.Button>
    </S.Container>
  );
}
