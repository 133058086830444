import styled from 'styled-components';

interface Props {
  color?:
    | 'primary'
    | 'secondary'
    | 'disable01'
    | 'success'
    | 'aqua'
    | 'disable02'
    | 'danger'
    | 'danger02'
    | 'disable03'
    | 'warning'
    | 'tertiary'
    | 'attention'
    | 'detail'
    | 'gray01'
    | 'gray02'
    | 'gray03'
    | 'gray04'
    | 'disable04'
    | 'backgroundColor';
  padding?: boolean;
}

export const HorizontalDivider = styled.div<Props>`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors?.[props.color ?? 'gray03']};

  padding: ${(props) => (props.padding ? '2rem 0' : '0.5rem 0')};

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid
      ${(props) => props.theme.colors?.[props.color ?? 'gray03']};
  }

  &:not(:empty)::before {
    margin-right: 0.5rem;
  }

  &:not(:empty)::after {
    margin-left: 0.5rem;
  }
`;

export const VerticalDivider = styled.div<Props>`
  border-left: 2px solid
    ${(props) => props.theme.colors?.[props.color ?? 'gray03']};
`;
