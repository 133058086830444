import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as S from './styles';

import HeaderModal from '../../../../../components/agent/HeaderModal';
import BasicTab from '../../../../../components/agent/BasicTab';
import BasicModal from '../../../../../components/agent/Modal';
import {
  TabDataStudent,
  TabListCourses,
  TabSale,
  TabPreviousSale,
} from '../../../../../components/agent/Tabs/TabNegotiation';
import FooterModal from '../../../../../components/agent/FooterModal';
import ModalSearchNegotiation from '../../../../../components/agent/ModalSearchNegotiation';
import {
  ButtonOpenTable,
  ButtonSimple,
} from '../../../../../components/agent/Buttons';
import {
  ICoursesSale,
  IDataStudent,
  IDiscountSale,
  IItem,
  IFormDataStudent,
  IPayment,
  IPaymentSale,
  IRegistrationSale,
} from '../../../../../services/agent/student/interface';

import SearchIcon from '@mui/icons-material/Search';

import { ICoupon } from '../../../../../components/agent/Tabs/TabNegotiation/TabSale/components/SaleInformation';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../../../components/agent/ProviderAgent';
import {
  coursesWithoutDiscounts,
  courseTypeFordoc,
  saleItemTypeCourse,
  saleItemTypeService,
  teamFarmer,
  typeServiceCancel,
  typeServiceRegistrationRenewal,
} from '../../../../../variables';
import { errorToast } from '../../../../../components/common/Toast';
import { saleNegotiationService } from '../../../../../services/agent';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface IFeesNegotiation {
  interest: { value: number };
  penalty: { value: number };
}
export interface INewNegotiationSale {
  dataStudent: IDataStudent;
  items: ICoursesSale[];

  discounts: IDiscountSale;
  registration: IRegistrationSale;
  studentPlan: IPaymentSale;
  sales_id?: number | string;
  observation?: string;
  fees: IFeesNegotiation;
  entranceExamination?: number;
}

export interface ICard {
  discountId: number;
  type: string;
  discountDescription: string;
  percentage: number;
  maxDiscount: number;
}

export const defaultFormNegotiation = {
  percentStudentDiscount: undefined,
  dataStudent: {
    isStudent: false,
    studentId: undefined,
    name: undefined,
    isForeign: undefined,
    cpf: undefined,
    email: undefined,
    rgId: undefined,
    bornDate: undefined,
    gender: undefined,
    pcd: 10,
    colorRace: 0,
    fatherName: undefined,
    motherName: undefined,
    nationality: undefined,
    nativeness: undefined,

    degree: undefined,
    graduationYear: undefined,
    university: undefined,
    ceremony: undefined,
    highSchoolGraduation: undefined,

    cep: undefined,
    address: undefined,
    addressNumber: undefined,
    addressComplement: undefined,
    neighborhood: undefined,
    city: undefined,
    uf: undefined,
    municipalCode: undefined,
    homePhone: undefined,
    cellPhone: undefined,
    businessPhone: undefined,

    companyName: undefined,
    cnpj: undefined,

    knowled: undefined,
  },
  courses: {
    title: ['Tipo', 'Curso'],
    content: [],
  },
  previousSaledata: [],
  dataLocal: '',
};

export const defaultNegotiation = {
  dataStudent: defaultFormNegotiation.dataStudent,
  items: [
    {
      id: undefined,
      discount: undefined,
      value: undefined,
      itemType: undefined,
      type: undefined,
    },
  ],
  discounts: {
    studentDiscount: { value: '0', maxValue: '0' },
    combo: { value: '0', maxValue: '0', id: undefined },
    coupon: { valueDiscount: 0, value: 0, text: '' },
    paymentType: { value: '0', maxValue: '0' },
  },
  registration: {
    paymentType: undefined,
    dueDate: undefined,
    value: undefined,
    unified: false,
    registrationFree: false,
  },
  studentPlan: {
    paymentType: undefined,
    dueDate: undefined,
    numberParcels: 0,
    total: 0,
    studentPlanFree: false,
  },
  sales_id: undefined,
  observation: '',
  fees: {
    interest: { value: 0 },
    penalty: { value: 0 },
  },
};

export default function ModalNewStudentData({ isOpen, setIsOpen }: Props) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  /** Criação das variáveis para usar nos componentes a seguir */
  const couponDefault = {
    valueDiscount: 0,
    value: 0,
    text: undefined,
  };
  const [coupon, setCoupon] = useState<ICoupon | null>(couponDefault);
  const [verifyFields, setVerifyFields] = useState<IFormDataStudent>(
    defaultFormNegotiation,
  );

  const [form, setForm] = useState<IFormDataStudent>(defaultFormNegotiation);
  const [searchOpen, setSearchOpen] = useState(true);

  const [negotiation, setNegotiation] =
    useState<INewNegotiationSale>(defaultNegotiation);

  const [valueTotal, setValueTotal] = useState(0);

  const defaultPayment = {
    registration: {
      value: undefined,
      paymentType: undefined,
      dueDate: undefined,
      unified: false,
      registrationFree: false,
    },
    studentPlan: {
      studentPlanFree: false,
      paymentType: undefined,
      numberParcels: 0,
      dueDate: undefined,
      total: 0,
    },
  };

  const [payment, setPayment] = useState<IPayment>(defaultPayment);
  const [discount, setDiscount] = useState<IDiscountSale>({
    studentDiscount: { value: '0', maxValue: '0.001' },
    combo: { value: '0', maxValue: '0.001', id: undefined },
    coupon: { value: 0, text: '', valueDiscount: 0 },
    paymentType: {
      value: '0',
      maxValue: '0.001',
    },
  });

  const [valueItems, setValueItems] = useState(0);

  const [send, setSend] = useState(false);

  const [items, setItems] = useState<IItem[] | []>([]);
  const [idCardDiscount, setIdCardDiscount] = useState(0);
  const [cardDiscount, setCardDiscount] = useState<ICard[]>([]);

  const [paymentTypeValue, setPaymentTypeValue] = useState([
    {
      id: 0,
      parcels: [{ quantity: 0, discount: 0, interest: 0, penalty: 0 }],
      description: '',
    },
  ]);

  /** Variável para saber quanto de desconto pode dar em cima da forma de pagamento */
  const maxPaymentTypeValue = useMemo(() => {
    if (paymentTypeValue) {
      const filter = paymentTypeValue.find((item) => {
        return item.id === +negotiation.studentPlan.paymentType!;
      });
      if (filter) {
        const selectedParcel = filter.parcels.find(
          (item) => +item.quantity! === +negotiation.studentPlan.numberParcels!,
        );

        if (selectedParcel) {
          const studentDiscountValue =
            negotiation.discounts.studentDiscount?.value ?? 0;
          const comboValue = negotiation.discounts.combo?.value ?? 0;

          const discountsCalc = +studentDiscountValue + +comboValue;

          if (selectedParcel.interest || selectedParcel.penalty) {
            setNegotiation((old) => ({
              ...old,
              discounts: {
                ...old.discounts,
                paymentType: {
                  value: '0',
                  maxValue: '0',
                },
              },
              fees: {
                interest: {
                  value: selectedParcel.interest
                    ? ((valueItems - discountsCalc) * selectedParcel.interest) /
                      100
                    : 0,
                },
                penalty: {
                  value: selectedParcel.penalty
                    ? ((valueItems - discountsCalc) * selectedParcel.penalty) /
                      100
                    : 0,
                },
              },
            }));

            return 0.001;
          }

          const maxValue = (
            (selectedParcel!.discount! / 100) *
            (valueItems - discountsCalc)
          ).toFixed(2);
          setNegotiation((old) => ({
            ...old,
            discounts: {
              ...old.discounts,
              paymentType: {
                ...old.discounts.paymentType,
                maxValue: maxValue,
              },
            },
            fees: {
              interest: { value: 0 },
              penalty: { value: 0 },
            },
          }));
          return +maxValue > 0 ? +maxValue : 0.001;
        }
      }
    }
    setNegotiation((old) => ({
      ...old,
      discounts: {
        ...old.discounts,
        paymentType: {
          value: '0',
          maxValue: '0',
        },
      },
      fees: {
        interest: { value: 0 },
        penalty: { value: 0 },
      },
    }));
    return 0.001;
  }, [
    negotiation.studentPlan,
    valueItems,
    negotiation.discounts.combo?.value,
    negotiation.discounts.studentDiscount?.value,
  ]);

  const maxStudentValue = useMemo(() => {
    if (!!form.percentStudentDiscount && items.length > 0) {
      let valueDecrease = 0;
      if (form.dataLocal.toUpperCase() === 'IPB') {
        for (const item of items) {
          valueDecrease =
            +item.type.value === +courseTypeFordoc!
              ? valueDecrease + +item.value.value!
              : valueDecrease;
        }
      }

      const engineering = coursesWithoutDiscounts!.includes(',')
        ? coursesWithoutDiscounts!.split(',')
        : [coursesWithoutDiscounts];
      if (engineering!.length > 0 && engineering![0] !== '') {
        for (const item of items) {
          for (const eng of engineering!) {
            valueDecrease =
              +item.description.value === +eng!
                ? valueDecrease + (+item.value.value! - +item.discount.value!)
                : valueDecrease;
          }
        }
      }

      const maxValue = (
        (form.percentStudentDiscount! / 100) *
        (valueItems - valueDecrease)
      ).toFixed(2);

      setNegotiation((old) => ({
        ...old,
        discounts: {
          ...old.discounts,
          studentDiscount: {
            ...old.discounts.studentDiscount,
            maxValue: +maxValue > 1 ? maxValue : '0.001',
          },
        },
      }));

      return +maxValue > 1 ? +maxValue : 0.001;
    }
    return 0.001;
  }, [form.percentStudentDiscount, items, valueItems]);

  const maxComboValue = useMemo(() => {
    if (cardDiscount && cardDiscount.length > 0 && idCardDiscount) {
      const selected = cardDiscount.filter((item) => {
        return item?.discountId === idCardDiscount;
      });
      setNegotiation((old) => ({
        ...old,
        discounts: {
          ...old.discounts,
          combo: {
            ...old.discounts.combo,
            id: selected[0]?.discountId,
            maxValue: selected[0]?.maxDiscount.toString(),
          },
        },
      }));
      setDiscount((old) => ({
        ...old,
        combo: { ...old.combo, id: selected[0]?.discountId },
      }));

      return selected[0]?.maxDiscount!.toFixed(2);
    }
    return 0.001;
  }, [idCardDiscount, items, cardDiscount]);

  useEffect(() => {
    setNegotiation((old) => ({
      ...old,
      dataStudent: form.dataStudent,
    }));
  }, [form.dataStudent]);

  const [itemsVerify, setItemsVerify] = useState<IItem[] | []>([]);
  useEffect(() => {
    setIsLoading(true);
    if (items.length <= 0) {
      setItemsVerify([]);
      setIsLoading(false);
      setPaymentTypeValue([]);
      return setCardDiscount([]);
    }
    if (JSON.stringify(itemsVerify) === JSON.stringify(items)) {
      setIsLoading(false);
      return;
    }

    const fetch = async () => {
      setIsLoading(true);
      const list = items
        .filter((item: IItem) => +item.item.value === 1)
        .map((item: IItem) => {
          return {
            id: +item.description.value,
            discount: +item.discount.value,
          };
        });

      const { data, result } =
        await saleNegotiationService.consultDiscount(list);

      if (result) {
        setCardDiscount(() => {
          return data.map((item) => ({
            discountId: item.discountId!,
            type: item.type!,
            discountDescription: item.discountDescription!,
            percentage: item.percentage!,
            maxDiscount: item.maxDiscount!,
          }));
        });
      } else {
        setCardDiscount([]);
      }
      setIsLoading(false);
    };

    const fetchData = async () => {
      const { result, data } =
        await saleNegotiationService.consultPaymentType();
      return result && setPaymentTypeValue(data);
    };

    setItemsVerify(items);
    fetch();

    let value = 0;
    items.map((i) => {
      if (+i.item.value === +saleItemTypeService!) {
        return;
      }
      value = value + (i.value.value - i.discount.value);
    });
    setValueItems(value);

    if (!paymentTypeValue[0]) {
      fetchData();
    }

    setIsLoading(false);
  }, [items]);

  /** Abre o Modal de pesquisa  */
  useEffect(() => {
    setSearchOpen(true);

    if (!isOpen) {
      setCardDiscount([]);
      setForm(defaultFormNegotiation);
    }
  }, [isOpen]);

  /** Estrutura para a as vendas anteriores */
  const previousSaledata = {
    title: ['Data da Venda', 'Vendedor', 'Valor Total', ''],
    content: form.previousSaledata?.map((sale) => ({
      'Data da Venda': { item: sale.date },
      Vendedor: { item: sale.agent },
      'Valor Total': { item: sale.amount },
      '': {
        item: sale.items && (
          <ButtonOpenTable
            data={{
              title: [
                'Item',
                'Descrição',
                'Valor Unitário',
                'Desconto',
                'Total',
                'Status',
              ],
              content: sale.items?.map((item) => ({
                Item: { item: item.item },
                Descrição: { item: item.description },
                'Valor Unitário': { item: item.unitaryValue },
                Desconto: { item: item.discount },
                Total: { item: item.totalItemValue },
                Status: { item: item.status },
              })),
            }}
          />
        ),
      },
    })),
  };

  /** Variável juntando todos os dados necessarios para o componente "TabSale" */
  const newSale = {
    paymentTypeValue,
    form,
    send,
    items,
    setItems,
    negotiation,
    setNegotiation,
    valueTotal,
    setValueTotal,
    payment,
    setPayment,
    defaultPayment,
    discount,
    setDiscount,
    maxStudentValue,
    maxPaymentTypeValue,
    maxComboValue,
    idCardDiscount,
    setIdCardDiscount,
    cardDiscount,
    coupon,
    setCoupon,
  };

  /** Dados para a tabela */
  const [fetchCpfVerify, setFetchCpfVerify] = useState<number | undefined>();
  const data = [
    {
      title: 'Nova Venda',
      children: <TabSale data={newSale} />,
    },
    {
      title: 'Dados',
      children: (
        <TabDataStudent
          form={form}
          setForm={setForm}
          verifyFields={verifyFields}
          send={send}
          cpfVerify={fetchCpfVerify}
          setCpfVerify={setFetchCpfVerify}
        />
      ),
    },
    {
      title: 'Cursos do Aluno',
      children: (
        <TabListCourses
          data={form.courses}
          exception={['id', 'courseTypeId']}
        />
      ),
    },
    {
      title: 'Vendas Anteriores',
      children: <TabPreviousSale data={previousSaledata} />,
    },
  ];

  /** Salva a negociação */
  const handleSave = async () => {
    setSend(true);
    setIsLoading(true);
    const negotiationTmp = { ...negotiation };

    const organize: string[] = [];
    for (const key in negotiationTmp.items) {
      if (
        +negotiation.items[key].itemType! === +saleItemTypeCourse! ||
        (+negotiation.items[key].itemType! === +saleItemTypeService! &&
          (+negotiation.items[key].type! === +typeServiceRegistrationRenewal! ||
            +negotiation.items[key].type! === +typeServiceCancel!))
      ) {
        organize.push('1');
      }
    }

    if (organize.length <= 0) {
      setSend(false);
      setIsLoading(false);

      const agent = JSON.parse(localStorage.getItem('agent')!);
      if (agent && +agent.sales_agent.equipe_id! !== +teamFarmer!) {
        return errorToast(
          'Para realizar a venda, é necessário inserir um curso',
        );
      }
    }
    for (const key in negotiationTmp.dataStudent) {
      if (negotiationTmp.dataStudent[key] === undefined) {
        negotiationTmp.dataStudent[key] = null;
      }
    }
    for (const key in negotiation.registration) {
      if (negotiation.registration[key] === undefined) {
        negotiationTmp.registration[key] = null;
      }
    }
    for (const key in negotiation.studentPlan) {
      if (negotiation.studentPlan[key] === undefined) {
        negotiationTmp.studentPlan[key] = null;
      }
    }

    const { result } =
      await saleNegotiationService.createNegotiation(negotiationTmp);

    if (result) {
      setIsOpen(false);
      handleClean();
    }
    setIsLoading(false);
  };

  /** Limpa tudo ao fechar o modal */
  const handleClean = () => {
    setForm(defaultFormNegotiation);
    setNegotiation(defaultNegotiation);
    setItems([]);
    setSend(false);
    setDiscount({
      studentDiscount: undefined,
      combo: undefined,
      coupon: { value: 0, text: '' },
      paymentType: undefined,
    });
    setCoupon(couponDefault);
    setPayment(defaultPayment);
  };

  return (
    <BasicModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClose={handleClean}
    >
      <S.Container>
        <HeaderModal
          handleClose={handleClean}
          subtitle={true}
          setOpen={setIsOpen}
          title="Novo Negócio"
          search={
            <ButtonSimple
              size="medium"
              icon={<SearchIcon />}
              variant="outlined"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              Pesquisar
            </ButtonSimple>
          }
          itemTitle={
            <h6 style={{ color: form.dataStudent.name ? 'green' : 'red' }}>
              {(form.dataStudent.name
                ? form.dataStudent.name
                : 'Novo aluno'
              ).toUpperCase()}
            </h6>
          }
        />
        <BasicTab data={data} />
        <FooterModal
          cancelClick={() => {
            setIsOpen(false);
            setForm(defaultFormNegotiation);
          }}
          saveClick={handleSave}
        />
        <ModalSearchNegotiation
          form={form}
          defaultForm={defaultFormNegotiation}
          isOpen={searchOpen}
          setVerifyFields={setVerifyFields}
          setIsOpen={setSearchOpen}
          setIsOpenModal={setIsOpen}
          setForm={setForm}
          newNegotiation={items}
          setNewNegotiation={setItems}
          handleClose={() => {
            if (
              !negotiation.dataStudent.cpf &&
              !negotiation.dataStudent.email
            ) {
              handleClean();
              setIsOpen(false);
            }
          }}
        />
      </S.Container>
    </BasicModal>
  );
}
