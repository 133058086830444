import React, { Dispatch, SetStateAction } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as S from './styles';

interface Props {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  setCountChar?: Dispatch<SetStateAction<number>>;
  heightSize?: 'medium';
  disabled?: boolean;
}

export default function QuillEditor({
  text,
  setText,
  heightSize = 'medium',
  disabled,
  setCountChar,
}: Props) {
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'align',
    'size',
    'font',
  ];

  const handleProcedureContentChange = (content, _, __, func) => {
    setCountChar && setCountChar(func.getText().replace(/\s/g, '').length);
    setText(content);
  };

  const sizeHeight = {
    medium: '20rem',
  };

  return (
    <S.Container>
      <div id="toolbarCustom" style={{ display: disabled ? 'none' : 'flex' }}>
        <select
          className="ql-header"
          defaultValue={''}
          onChange={(e) => e.persist()}
        >
          <option value="1">Título 1</option>
          <option value="2">Título 2</option>
          <option value="3">Título 3</option>
          <option value="4">Título 4</option>
          <option value="5">Título 5</option>
          <option value="6">Título 6</option>
          <option value="">Normal</option>
        </select>

        <button className="ql-bold">Negrito</button>
        <button className="ql-italic">Itálico</button>
        <button className="ql-underline">Sublinhado</button>
        <button className="ql-strike">Tachado</button>
        <button className="ql-blockquote">Citação</button>
        <select
          className="ql-align"
          defaultValue={''}
          onChange={(e) => e.persist()}
        >
          <option value="right">Alinhar à direita</option>
          <option value="center">Centralizar</option>
          <option value="justify">Justificar</option>
        </select>
        <button className="ql-list" value="ordered">
          Lista numerada
        </button>
        <button className="ql-list" value="bullet">
          Lista marcada
        </button>
        <button className="ql-link">Link</button>
        <button className="ql-clean">Limpar formatação</button>
      </div>
      <ReactQuill
        modules={{ toolbar: '#toolbarCustom' }}
        formats={formats}
        value={text}
        onChange={handleProcedureContentChange}
        style={{
          height: sizeHeight[heightSize],
        }}
        readOnly={disabled}
      />
    </S.Container>
  );
}
