import React, { ReactNode, useState } from 'react';

import * as S from './styles';

interface ICardLeftColor {
  children: ReactNode;
  color:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'success'
    | 'danger'
    | 'attention'
    | 'gray03'
    | 'aqua';
  heightSize?: 'medium' | 'xmedium' | 'large' | 'fullHeight';
  hover?: boolean;
  onClick?: () => void;
  button?: ReactNode;
  className?: string;
}

export default function CardLeftColor({
  children,
  color,
  heightSize = 'medium',
  button,
  hover,
  onClick,
  className,
}: ICardLeftColor) {
  const [isHovered, setIsHovered] = useState(false);

  const sizeHeight = {
    medium: {
      minHeight: '6rem',
    },
    xmedium: {
      minHeight: '12rem',
    },
    large: {
      minHeight: '15rem',
    },
    fullHeight: {
      minHeight: '100%',
    },
  };

  return (
    <S.Container
      className={className}
      style={{
        ...sizeHeight[heightSize],
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onClick && onClick()}
    >
      <S.IconContainer color={color} hover={hover} isHovered={isHovered}>
        {button && (
          <S.Icon hover={hover} isHovered={isHovered}>
            {button}
          </S.Icon>
        )}
      </S.IconContainer>
      <S.Children hover={hover} isHovered={isHovered}>
        {children}
      </S.Children>
    </S.Container>
  );
}
