import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';

import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import Divider from '../../../components/common/Divider';
import { ticketsServiceNewStudent } from '../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import TicketItem from './components/TicketAlert';
import { AletNotfoundLoading } from '../../../components/newStudent/Alert';
import { emailContract, phoneService, phoneWhatsapp } from '../../../variables';
import { ButtonSimple } from '../../../components/newStudent/Buttons';

export interface ITicket {
  status: string;
  createdDate: string;
  subject: string;
  id: number;
}
export default function TicketsNewStudent() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [allTickets, setAllTickets] = useState<ITicket[] | []>([]);
  const [indexOpen, setIndexOpen] = useState<number | undefined>();
  const [movidesk, setMovidesk] = useState<string>('');

  useEffect(() => {
    setIsLoading(true);
    try {
      const fetch = async () => {
        const { result, data } = await ticketsServiceNewStudent.show();

        if (result) {
          setAllTickets(data);
        }
        setIsLoading(false);
      };

      const load = async () => {
        try {
          const { data, result } = await ticketsServiceNewStudent.urlTicket();
          if (result) {
            return setMovidesk(data);
          }
        } catch (error) {
          throw new Error(error);
        }
      };
      load();
      fetch();
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  }, []);

  return (
    <S.Container>
      <TitlePage
        title="Requerimentos"
        subtitle="Aqui, você tem acesso e acompanha todos os tickets concluídos e em aberto. 
        Basta clicar abaixo em um deles e acompanhar o processo."
        titlePage="Requerimentos"
      />
      <Divider padding />
      <S.ServiceDiv>
        <S.OpenTicket>
          <div>
            <S.KeyValue>
              <S.B>E-mail Atendimento: </S.B>
              <S.P>{emailContract}</S.P>
            </S.KeyValue>
            <S.KeyValue>
              <S.B>Telefone Atendimento: </S.B>
              <S.P>{phoneService}</S.P>
            </S.KeyValue>
            <S.KeyValue>
              <S.B>Whatsapp: </S.B>
              <S.P>{phoneWhatsapp}</S.P>
            </S.KeyValue>
            <S.KeyValue>
              <S.B>Observação: </S.B>
              <S.P>
                O horário de atendimento é de segunda a quinta, das 08:30 às
                17:30, e na sexta, das 08:30 às 16:30. Não temos atendimento aos
                finais de semana.
              </S.P>
            </S.KeyValue>
          </div>
          <S.DivButtonTicket>
            <ButtonSimple
              color="primary"
              onClick={() => window.open(movidesk)}
              widthSize="large"
            >
              Abrir Novo Ticket
            </ButtonSimple>
          </S.DivButtonTicket>
        </S.OpenTicket>
      </S.ServiceDiv>
      <AletNotfoundLoading
        toRender={allTickets.length <= 0}
        notFoundText="Nenhum item encontrado."
      />
      <S.Content>
        {allTickets.map((item: ITicket, index: number) => (
          <TicketItem
            key={index}
            item={item}
            index={index}
            indexOpen={indexOpen}
            setIndexOpen={setIndexOpen}
          />
        ))}
      </S.Content>
    </S.Container>
  );
}
