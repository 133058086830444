import React, { Dispatch, SetStateAction, useContext } from 'react';

import * as S from '../styles';

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import DownloadIcon from '@mui/icons-material/Download';

import { IDocumentFiles } from '../../../..';
import { FileItem, NamedItem } from '../..';
import { documentServiceNewStudent } from '../../../../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../components/newStudent/ProviderNewStudent';
import {
  cleanCache,
  formatDate,
} from '../../../../../../../util/common/appUtil';

interface Props {
  index: number;
  item: IDocumentFiles;
  setSelectedFiles: Dispatch<SetStateAction<FileItem[] | NamedItem[]>>;
  selectedFiles: FileItem[] | NamedItem[];
  setOldFiles: Dispatch<SetStateAction<IDocumentFiles[]>>;
  oldFiles: IDocumentFiles[];
  documentType: number;
  success: boolean;
  setWasChanged: Dispatch<SetStateAction<boolean>>;
}
export default function OldFiles({
  item,
  index,
  setSelectedFiles,
  setOldFiles,
  oldFiles,
  selectedFiles,
  documentType,
  success,
  setWasChanged,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const handleFileChange = async (fileToUpload?: File, toDelete?: boolean) => {
    setIsLoading(true);

    try {
      const body: { documentType: number; file?: File; id: number } = {
        documentType: +documentType,
        file: fileToUpload ? fileToUpload : undefined,
        id: item.id,
      };

      const { result, data } = await documentServiceNewStudent.documentRequest({
        body: body,
        action: toDelete ? 'delete' : 'update',
      });
      if (result) {
        cleanCache('dataDocuments');
        setWasChanged(true);
      }
      setIsLoading(false);
      return { result, data };
    } catch (error) {
      setIsLoading(false);
      throw new Error(error.message);
    }
  };

  const handleRemove = async (index: number) => {
    const send = await handleFileChange(undefined, true);
    if (send.result) {
      const updatedFiles = [...oldFiles];
      updatedFiles.splice(index, 1);
      return setOldFiles(updatedFiles);
    }
  };

  const handleFileReplace = async (indexToReplace: number, newFile: File) => {
    const send = await handleFileChange(newFile);
    if (send.result) {
      if (send.result) {
        const updatedFiles = [...oldFiles];
        updatedFiles.splice(index, 1);
        setOldFiles(updatedFiles);
      }
      const updatedFiles = [...selectedFiles];
      updatedFiles[indexToReplace + selectedFiles.length] = {
        file: newFile,
        id: item.id,
        url: send.data.url,
      };
      setSelectedFiles(updatedFiles);
    }
  };

  return (
    <S.Container>
      <S.DivFile>
        {success ? (
          <div></div>
        ) : (
          <S.InputFile>
            <span>{'Substituir arquivo'}</span>
            <input
              type="file"
              className="input-file"
              onChange={({ target }) => {
                target.files && handleFileReplace(index, target.files[0]);
              }}
            />
          </S.InputFile>
        )}
        <S.DivName>
          <S.H6>{item.name}</S.H6>
          <S.H6>{formatDate(item.date)}</S.H6>
        </S.DivName>
        <S.BtnAction>
          {!success && (
            <S.BtnDanger
              onClick={() => handleRemove(index)}
              style={{
                marginRight: oldFiles[index].url ? '1rem' : '0',
              }}
            >
              <RemoveCircleOutlineOutlinedIcon fontSize="large" />
            </S.BtnDanger>
          )}
          {oldFiles[index].url && (
            <S.BtnDownload onClick={() => window.open(oldFiles[index].url)}>
              <DownloadIcon fontSize="large" />
            </S.BtnDownload>
          )}
        </S.BtnAction>
      </S.DivFile>
    </S.Container>
  );
}
