import React, { useContext, useEffect, useMemo, useState } from 'react';

import * as S from './styles';

import OutboxIcon from '@mui/icons-material/Outbox';
import ListIcon from '@mui/icons-material/List';

import { useTheme } from 'styled-components';
import { ButtonSimple } from '../../../../../../../../Buttons';
import { myCoursesServiceNewStudent } from '../../../../../../../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../../ProviderNewStudent';
import { ISubjectContentData } from '../../../../../..';
import Divider from '../../../../../../../../../common/Divider';
import {
  IPillarTcc,
  IRulesToSendTcc,
  IShowInternship,
} from '../../../../../../../../../../services/newStudent/myCourses/myCourses.service';
import ButtonIcon from '../../../../../../../../Buttons/ButtonIcon';
import TableBasic, { ITableData } from '../../../../../../../../Table';
import { formatDate } from '../../../../../../../../../../util/common/appUtil';
import RegisterAcademicProject from '../RegisterAcademicProject';
import AcademicProjectsHistoric from '../AcademicProjectsHistoric';
import { handleMaxWidth } from '../../../../../../../../../../util/newStudent';
import { IClassInfo } from '../../../../../../../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';
import AcademicProjectPillar from '../AcademicProjectPillar';

interface Props {
  info: ISubjectContentData;
  classInfo: IClassInfo;
  isTcc?: boolean;
}
export default function AcademicProject({ info, classInfo, isTcc }: Props) {
  const { setIsLoading, student } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [loadInternship, setLoadInternship] = useState<boolean>(true);

  const [academicProjectData, setAcademicProjectData] =
    useState<IShowInternship[]>();
  const [whoShow, setWhoShow] = useState<
    '' | 'register' | 'historic' | 'list' | 'pillar'
  >('');
  const [historicIdToShow, setHistoricIdToShow] = useState<number>();
  const [canShowPayment, setCanShowPayment] = useState<boolean | undefined>(
    false,
  );
  const [rulesToSendTcc, setRulesToSendTcc] = useState<IRulesToSendTcc>();
  const [pillar, setPillar] = useState<IPillarTcc[]>();
  const [tccObservationDismissal, setTccObservationDismissal] =
    useState<string[]>();

  const [options, setOptions] = useState<{
    dismissalTcc?: boolean;
    finalCorrectionTcc?: boolean;
    orientationTcc?: boolean;
  }>();

  const theme = useTheme();

  const loadAcademicProject = async () => {
    try {
      const { result, data } =
        await myCoursesServiceNewStudent.showAcademicProject({
          id: classInfo.courseId,
          disciplineId: classInfo.id,
          contentId: info.contentId,
          resourceId: info.resourceId ?? 0,
        });

      setCanShowPayment(data.paymentType);
      setOptions({
        dismissalTcc: data.dismissalTcc,
        finalCorrectionTcc: data.finalCorrectionTcc,
        orientationTcc: data.orientationTcc,
      });
      if (result && data.project) {
        setAcademicProjectData(data.project);

        setWhoShow('list');
      } else {
        setTccObservationDismissal(data.tccObservationDismissal);
        setWhoShow('register');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoadInternship(false);
    }
  };

  const loadTccPillar = async () => {
    const { result, data } = await myCoursesServiceNewStudent.tccPillar({
      id: classInfo.courseId,
      disciplineId: Number(classInfo.id),
      contentId: Number(info.contentId),
      resourceId: Number(info.resourceId ?? 0),
    });
    if (result) {
      if (data.completed) {
        loadAcademicProject();
      } else {
        setWhoShow('pillar');
        setPillar(data.pillars);
        setRulesToSendTcc(data.requirements);
      }
    }
  };

  useEffect(() => {
    if (!loadInternship) return;
    try {
      setIsLoading(true);
      if (isTcc) {
        loadTccPillar();
      } else {
        loadAcademicProject();
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  }, [loadInternship]);

  const changeIdHistoricSelected = useMemo(() => {
    return academicProjectData?.find(
      (item) => item.academicProjectId === historicIdToShow,
    );
  }, [academicProjectData, historicIdToShow]);

  const dataTable: ITableData = useMemo(() => {
    return {
      title: ['Data', 'Tema', 'Status', 'Histórico'],
      content: academicProjectData
        ? academicProjectData.map((item) => ({
            date: { item: <S.H6>{formatDate(item?.date)}</S.H6> },
            subject: { item: <S.H6>{item?.subject}</S.H6> },
            status: { item: <S.H6>{item?.status}</S.H6> },
            historic: {
              item: (
                <S.Div>
                  <ButtonIcon
                    color="primary"
                    icon={<ListIcon fontSize="large" />}
                    onClick={() => {
                      setHistoricIdToShow(Number(item?.academicProjectId));
                      setWhoShow('historic');
                    }}
                  />
                </S.Div>
              ),
            },
          }))
        : [],
    };
  }, [academicProjectData]);

  return (
    <S.Container>
      <S.PdfDiv className="archive_content">
        <S.TitlePdfDiv>
          <S.TitleIconPdfDiv>
            <OutboxIcon
              style={{
                width: '40px',
                height: '40px',
                color: theme.colors?.danger,
              }}
            />
            <S.TitlePdfH5>{info.title}</S.TitlePdfH5>
          </S.TitleIconPdfDiv>

          {academicProjectData &&
            whoShow === 'historic' &&
            changeIdHistoricSelected?.canCreateHistoric &&
            !handleMaxWidth(theme.size?.medium) && (
              <ButtonSimple
                color="success"
                widthSize="xlarge"
                onClick={() => setWhoShow('register')}
              >
                Cadastrar Histórico
              </ButtonSimple>
            )}
        </S.TitlePdfDiv>
      </S.PdfDiv>
      <Divider padding />
      {academicProjectData && whoShow === 'list' && (
        <S.DivContent>
          <S.H6>
            Prezado aluno, para acompanhar todo o processo de seu{' '}
            {isTcc ? 'TCC' : 'Estágio Supervisionado'}, clique no botão
            &quot;Histórico&quot;
          </S.H6>
          {handleMaxWidth(theme.size?.medium) ? (
            academicProjectData.map((item, index) => (
              <S.DivTableMobile key={index}>
                <Divider padding />
                <S.KeyValue>
                  <S.Key>Data: </S.Key>
                  <S.Value>{formatDate(item?.date)}</S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Tema: </S.Key>
                  <S.Value>{item?.subject}</S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Status: </S.Key>
                  <S.Value>{item?.status}</S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Histórico: </S.Key>
                  <S.Value>
                    <ButtonIcon
                      color="primary"
                      icon={<ListIcon fontSize="large" />}
                      onClick={() => {
                        setHistoricIdToShow(Number(item?.academicProjectId));
                        setWhoShow('historic');
                      }}
                    />
                  </S.Value>
                </S.KeyValue>
              </S.DivTableMobile>
            ))
          ) : (
            <S.DivTable>
              <TableBasic data={dataTable} />
            </S.DivTable>
          )}
        </S.DivContent>
      )}
      {whoShow === 'register' && (
        <RegisterAcademicProject
          academicProjectData={academicProjectData?.[0]}
          name={student.dataStudent.name}
          setWhoShow={setWhoShow}
          setLoadAcademicProjectData={setLoadInternship}
          info={info}
          classInfo={classInfo}
          isTcc={isTcc}
          options={options}
          tccObservationDismissal={tccObservationDismissal}
          canShowPayment={canShowPayment}
        />
      )}
      {whoShow === 'historic' && (
        <S.DivHistoricMobile>
          <AcademicProjectsHistoric
            data={changeIdHistoricSelected}
            setWhoShow={setWhoShow}
            resourceId={info?.resourceId}
          />
          {handleMaxWidth(theme.size?.medium) && (
            <S.DivButton>
              <ButtonSimple
                color="primary"
                outline
                onClick={() => setWhoShow('list')}
                widthSize="small"
              >
                Voltar
              </ButtonSimple>
              <ButtonSimple
                color="success"
                widthSize="xlarge"
                onClick={() => setWhoShow('register')}
              >
                Cadastrar Histórico
              </ButtonSimple>
            </S.DivButton>
          )}
        </S.DivHistoricMobile>
      )}
      {whoShow === 'pillar' && (
        <AcademicProjectPillar rules={rulesToSendTcc} pillar={pillar} />
      )}
    </S.Container>
  );
}
