import * as apiService from '../api/student/api.service';
import { IMyGradesRow } from '../../pages/student/MyGrades';
import {
  Grades,
  DataGraduationGrades,
} from '../../pages/student/MyGrades/components/SubjectGrades/components/TableSubjectGraduation';

export interface IMyGradesCore {
  nota?: number;
  situacao: string;
  tentativas: number;
  modulo_id: number;
  modulo_nome: string;
  disciplina_nome: string;
}

export interface IMyGrades {
  nucleo_comum: IMyGradesCore[];
  nucleo_especifico: IMyGradesCore[];
  nucleo_empirico: IMyGradesCore[];
}

const gradeService = {
  async listCourses(): Promise<{
    data: IMyGradesRow[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(`/student/my-grades/list-of-courses`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },

  async myGrades(id: number): Promise<{
    data: IMyGradesCore[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(`/student/my-grades/show/${id}`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },

  async graduationGrades(data: DataGraduationGrades): Promise<{
    data: Grades[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(
        '/student/my-grades/graduation-grades',
        'POST',
        data,
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },
};

export default gradeService;
