import React, { useState } from 'react';

import * as apiService from '../../../../services/api/student/api.service';
import { ContainerCard, ContainerCardContract } from '../styles';
import { convertStringToDate } from '../../../../util/pageUtil';
import { IAlunoContracts } from '../../../../interfaces/student/aluno.interfaces';
import ModalContract from '../../../common/Modal/ModalContract';

interface ContractCardProps {
  contract: IAlunoContracts;
  signContract: (id: number) => void;
}

export default function ContractCard({
  contract,
  signContract,
}: ContractCardProps) {
  const [disabled, setDisabled] = useState(true);
  const [contratoDados, setContratoDados] = useState<string>('');

  const [isOpenModal, setIsOpenModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await apiService.authenticatedRequest(
        `/student/contract/get-contract-content/${contract.id}`,
      );
      setIsOpenModal(true);
      setContratoDados(response.data.content);
    } catch {
      throw new Error('Erro ao assinar o contrato');
    }
  };

  return (
    <>
      <ContainerCard className="col col-xs-12 col-sm-4 mt-5">
        <ContainerCardContract className="panel panel-default">
          <div className="panel-heading">
            <h5 style={{ textAlign: 'center' }}>{contract.curso_tipo_nome}</h5>
          </div>
          <div className="p-2">
            <h4 style={{ textAlign: 'center' }}>
              <b>{contract.curso_nome}</b>
            </h4>
            <p style={{ textAlign: 'center', padding: 10 }}>
              Inscrição em{' '}
              {convertStringToDate(
                contract.matricula_data,
                'YYYY-MM-DD',
                'DD/MM/YYYY',
              )}
            </p>
            <div
              className="bs-info"
              style={{ textAlign: 'center', padding: 10, fontSize: 14 }}
            >
              Ao clicar em <b>Assinar</b> o&nbsp;
              <a onClick={() => fetchData()} href="#">
                Termo de Aceite
              </a>
              {contratoDados && (
                <ModalContract
                  open={isOpenModal}
                  setOpen={setIsOpenModal}
                  title="Termo de aceite"
                >
                  <div dangerouslySetInnerHTML={{ __html: contratoDados }} />
                </ModalContract>
              )}
              , você estará assinando eletronicamente o nosso contrato.
            </div>
            <div
              className="panel-footer"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10 !important',
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  id="contrato_check"
                  type="checkbox"
                  style={{ marginTop: 2, cursor: 'pointer' }}
                  onClick={() => setDisabled(!disabled)}
                />
                <i></i>
                <span
                  style={{ textAlign: 'left', color: 'green', fontSize: 14 }}
                >
                  &nbsp; Li e Concordo.{' '}
                </span>
              </p>
              <p style={{ textAlign: 'right', margin: 0 }}>
                <button
                  type="button"
                  disabled={disabled}
                  className="btn btn-success btn-md m-2"
                  onClick={() => {
                    setDisabled(true);
                    signContract(contract.id);
                  }}
                >
                  Confirmar
                </button>
              </p>
            </div>
          </div>
        </ContainerCardContract>
      </ContainerCard>
    </>
  );
}
