import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const BoxModal = styled.div`
  width: 100vw;
`;

export const Container = styled(Modal)`
  max-height: 75%;
  display: flex;
  justify-content: center;

  width: 80%;
  font-size: 1rem;
`;
