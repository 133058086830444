import styled from 'styled-components';

export const StyledTable = styled.table`
  padding: 10px 5px;
  width: 100%;
  border-collapse: unset !important;
`;

export const StyledTBody = styled.tbody`
  padding-right: 5px;
  align-items: center;

  td {
    max-width: 250px;
    padding-right: 10px;
  }

  .titulo-secao {
    font-weight: bold;
    align-items: center;
    padding: 0 3px 0 3px;
    color: #fff;
    background-color: #3a6588;
  }
`;

export const StyledTr = styled.tr`
  margin-right: 1rem;
`;

export const StyledTh = styled.th`
  text-align: start;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  background-color: #fff !important;
  border: 1px solid #ddd;
  color: #666666;
`;

export const StyledTd = styled.td`
  text-align: start;
  vertical-align: middle;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  background-color: #fff !important;
  border-right: 1px solid #ddd;
  color: #666666;
`;

export const NotFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
