import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding-top: 2rem;
`;

export const Alert = styled.h5``;
