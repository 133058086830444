import React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import * as S from './styles';

export interface INavigates {
  title: string;
}

interface Navigate {
  navigates: INavigates[];
}

export default function Breadcrumb({ navigates }: Navigate) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: '#ccc',
          fontSize: '13px',
          position: 'relative',
          top: '2px',
        },
      }}
    >
      {navigates?.map((navigate, index) => {
        return (
          <S.Title
            key={index}
            style={{
              color: index + 1 === navigates.length ? '#E4E4E4' : '#BBB',
            }}
          >
            {navigate.title}
          </S.Title>
        );
      })}
    </Breadcrumbs>
  );
}
