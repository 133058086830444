import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  background-color: transparent;

  border: 0;
  padding: 0 1.6rem;
  &:disabled {
    opacity: 0.5;
  }
  svg {
    font-size: 2rem;
  }

  span.default {
    color: ${({ theme }) => theme.color.default.main};
  }
  span.primary {
    color: ${({ theme }) => theme.color.primary.main};
  }
  span.success {
    color: ${({ theme }) => theme.color.success.main};
  }
  span.info {
    color: ${({ theme }) => theme.color.info.main};
  }
  span.warning {
    color: ${({ theme }) => theme.color.warning.main};
    border-color: ${({ theme }) => theme.color.warning.main};
  }
  span.danger {
    color: ${({ theme }) => theme.color.danger.main};
  }

  :hover {
    /* background-color: #ccc; */
    opacity: 0.8;
  }
`;
