import styled from 'styled-components';

export const Container = styled.article`
  border-width: 1px 1px 2px;
  border-style: solid;
  border-top: none;
  border-color: #ccc;

  > header {
    display: flex;
    align-items: center;
    border-color: #931313 !important;
    color: #fff;
    text-shadow: none;
    height: 34px;
  }

  header.default {
    background-color: ${({ theme }) => theme.color.default.main};
  }
  header.primary {
    background-color: ${({ theme }) => theme.color.primary.main};
  }
  header.success {
    background-color: ${({ theme }) => theme.color.success.main};
  }
  header.info {
    background-color: ${({ theme }) => theme.color.info.main};
  }
  header.warning {
    background-color: ${({ theme }) => theme.color.warning.main};
  }
  header.danger {
    background-color: ${({ theme }) => theme.color.danger.main};
  }

  .boxTitle {
    font-weight: 700;
    padding-left: 0.7rem;
    font-size: 14px;
  }

  > section {
    display: block;
    border-width: 1px 1px 2px;
    border-style: solid;
    border-color: #ccc;
  }
`;
