import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as S from './styles';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import NegotiationTerm from '../NegotiationTerm';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import FieldFormik from '../../../../../components/newStudent/FieldFormik';
import {
  getInterestByDate,
  handleCalcPercent,
  sumNumbers,
} from '../../../../../util/newStudent/financialHistory';
import { Formik } from 'formik';
import TitleDialogMobile from '../../../../../components/newStudent/Title/TitleDialogMobile';
import Divider from '../../../../../components/common/Divider';
import { CardLeftColor } from '../../../../../components/newStudent/Card';
import {
  cleanCache,
  formatCurrency,
  formatDate,
} from '../../../../../util/common/appUtil';
import {
  financialServiceNewStudent,
  IInfoDebtAndNegotiationBillets,
} from '../../../../../services/newStudent/financial/financial.service';
import { IPaymentType } from '../../../../../services/newStudent/helper/helper.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';

interface Props {
  dataInvoice: IInfoDebtAndNegotiationBillets[];
  paymentType: IPaymentType[];
  isNegotiation?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function AdvanceAndDebtNegotiationMobile({
  dataInvoice,
  paymentType,
  isNegotiation = false,
  isOpen,
  setIsOpen,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [readed, setReaded] = useState<boolean>();
  const [billets, setBillets] = useState<number[]>([]);

  const [selectedPaymentType, setSelectedPaymentType] = useState<
    number | undefined
  >(0);

  const [selectedOptionsParcels, setSelectedOptionsParcels] = useState<
    number | undefined
  >(0);

  /** Criação das opçoes da forma de pagamento */
  const optionsPaymentType = useMemo(() => {
    return [
      { title: '[ Selecione a forma de pagamento ]', value: undefined },
      ...(paymentType.length > 0
        ? paymentType
            .filter((item) => item.description)
            .map((item) => ({
              title: item.description!.split(' ')[0],
              value: item.id,
            }))
        : []),
    ];
  }, [paymentType]);

  /** Opção de pagamento seleceionada */
  const option = useMemo(() => {
    return selectedPaymentType
      ? paymentType.find((item) => item.id === selectedPaymentType)
      : undefined;
  }, [selectedPaymentType]);

  /** Quantidade de parcela selecionada */
  const parcelOptionSelected = useMemo(() => {
    return selectedOptionsParcels
      ? option?.parcels.find((item) => item.quantity === selectedOptionsParcels)
      : undefined;
  }, [option, selectedOptionsParcels]);

  /** Criação das opçoes de parcelas */
  const optionsParcels = useMemo(() => {
    return [
      { title: '[ Número de parcelas ]', value: undefined },
      ...(option?.parcels
        ? option.parcels.map((item) => ({
            title: item.quantity + 'X',
            value: item.quantity,
          }))
        : []),
    ];
  }, [option]);

  /** Estrutura das faturas que seão antecipadas/negociadas */
  const dataFiltered = useMemo(() => {
    if (isNegotiation) return dataInvoice;
    return dataInvoice.filter((item) => billets.includes(item.id));
  }, [billets]);

  useEffect(() => {
    if (!isNegotiation) return;
    setBillets(dataInvoice.map((item) => item.id));
  }, []);

  const validPaymentType = (id?: number) => {
    const validate = optionsPaymentType.find((item) => item.value === +id!);

    if (validate) {
      if (
        validate.title.toLowerCase().includes('pix') ||
        validate.title.toLowerCase().includes('bole')
      ) {
        setSelectedOptionsParcels(1);
        return false;
      }
    }
    return true;
  };

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const body = {
        id: billets,
        paymentType: selectedPaymentType ?? 0,
        parcel: selectedOptionsParcels ?? 0,

        total: sumNumbers(
          dataFiltered?.map(
            (item) =>
              item.value +
              (isNegotiation
                ? handleCalcPercent(
                    item.value,
                    parcelOptionSelected?.penalty ?? 0,
                  ) +
                  getInterestByDate(
                    item.value,
                    parcelOptionSelected?.interest ?? 0,
                    item.daysOfDelay,
                  )
                : 0) -
              handleCalcPercent(
                item.value,
                parcelOptionSelected?.discount ?? 0,
              ),
          ),
        ),
      };
      const { result } = isNegotiation
        ? await financialServiceNewStudent.installmentsNegotiation(body)
        : await financialServiceNewStudent.installmentsAdvance(body);

      if (result) {
        cleanCache('dataContracts');
        localStorage.removeItem('dataStudent');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen} fullScreen scroll="body">
      <TitleDialogMobile setIsOpen={setIsOpen} title={'Histórico Financeiro'} />
      <S.Container>
        <S.TitlePage isNegotiation={isNegotiation}>
          {isNegotiation ? 'Negociação' : 'Antecipação'}
        </S.TitlePage>
        <Divider padding />
        <Formik
          initialValues={{
            selectPaymentType: undefined,
            selectOptionsParcels: undefined,
          }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ handleChange, setFieldValue, values }) => (
            <S.DivTitleSelect>
              <S.H6>Forma de Pagamento: </S.H6>
              <FieldFormik
                name="selectPaymentType"
                type="select"
                value={values.selectPaymentType}
                onChange={(e) => {
                  handleChange(e);
                  setSelectedPaymentType(+e.target.value);
                  setSelectedOptionsParcels(undefined);
                  setReaded(undefined);
                  setFieldValue('selectOptionsParcels', 0);
                }}
                options={optionsPaymentType}
                widthSize="xxlarge"
              />
              {!!selectedPaymentType &&
                validPaymentType(values.selectPaymentType) && (
                  <FieldFormik
                    name="selectOptionsParcels"
                    type="select"
                    value={values.selectOptionsParcels}
                    onChange={(e) => {
                      handleChange(e);
                      setReaded(undefined);
                      setSelectedOptionsParcels(+e.target.value);
                    }}
                    options={optionsParcels}
                    widthSize="large"
                  />
                )}
            </S.DivTitleSelect>
          )}
        </Formik>
        <Divider padding color="gray02" />
        {dataInvoice.map((item, index) => (
          <S.Card key={index}>
            <CardLeftColor
              color={isNegotiation ? 'danger' : 'aqua'}
              heightSize="fullHeight"
              onClick={() => {
                if (!billets.includes(item.id)) {
                  setBillets((old) => [...old, item.id]);
                } else {
                  const updateBillets = [...billets];
                  updateBillets.splice(billets.indexOf(item.id), 1);
                  return setBillets(updateBillets);
                }
              }}
            >
              <S.DivCard>
                <S.CardContent>
                  <S.KeyValue>
                    <S.Key>Referência:</S.Key>
                    <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                      {item.invoiceReference}
                    </S.Value>
                  </S.KeyValue>
                  <S.KeyValue>
                    <S.Key>Valor:</S.Key>
                    <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                      {formatCurrency(item.value)}
                    </S.Value>
                  </S.KeyValue>
                  <S.KeyValue>
                    <S.Key>Vencimento:</S.Key>
                    <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                      {formatDate(item.dueDate)}
                    </S.Value>
                  </S.KeyValue>
                  <S.KeyValue>
                    <S.Key>Desconto:</S.Key>
                    <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                      {formatCurrency(
                        handleCalcPercent(
                          item.value,
                          parcelOptionSelected?.discount ?? 0,
                        ),
                      )}
                    </S.Value>
                  </S.KeyValue>
                  {isNegotiation && (
                    <S.KeyValue>
                      <S.Key>Juros e Multa:</S.Key>
                      <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                        {formatCurrency(
                          handleCalcPercent(
                            item.value,
                            parcelOptionSelected?.penalty ?? 0,
                          ) +
                            getInterestByDate(
                              item.value,
                              parcelOptionSelected?.interest ?? 0,
                              item.daysOfDelay,
                            ),
                        )}
                      </S.Value>
                    </S.KeyValue>
                  )}
                </S.CardContent>
                {!isNegotiation && (
                  <S.KeyValue>
                    <S.Value>
                      <input
                        type="checkbox"
                        checked={billets.includes(item.id)}
                        onChange={({ target }) => {
                          if (target.checked) {
                            setBillets((old) => [...old, item.id]);
                          } else {
                            const updateBillets = [...billets];
                            updateBillets.splice(billets.indexOf(item.id), 1);
                            return setBillets(updateBillets);
                          }
                        }}
                      />
                    </S.Value>
                  </S.KeyValue>
                )}
              </S.DivCard>
            </CardLeftColor>
            <Divider padding color="gray02" />
          </S.Card>
        ))}
        <S.DivCard>
          <S.CardContent>
            <S.KeyValue>
              <S.Key>Valor(R$):</S.Key>
              <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                {formatCurrency(
                  sumNumbers(dataFiltered?.map((item) => +item.value)),
                )}
              </S.Value>
            </S.KeyValue>
            <S.KeyValue>
              <S.Key>Desconto:</S.Key>
              <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                {formatCurrency(
                  sumNumbers(
                    dataFiltered?.map((item) =>
                      handleCalcPercent(
                        item.value,
                        parcelOptionSelected?.discount ?? 0,
                      ),
                    ),
                  ),
                )}
              </S.Value>
            </S.KeyValue>
            {isNegotiation && (
              <S.KeyValue>
                <S.Key>Juros e multa:</S.Key>
                <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                  {formatCurrency(
                    sumNumbers(
                      dataFiltered?.map(
                        (item) =>
                          handleCalcPercent(
                            item.value,
                            parcelOptionSelected?.penalty ?? 0,
                          ) +
                          getInterestByDate(
                            item.value,
                            parcelOptionSelected?.interest ?? 0,
                            item.daysOfDelay,
                          ),
                      ),
                    ),
                  )}
                </S.Value>
              </S.KeyValue>
            )}
            <S.KeyValue>
              <S.Key>Valor Total:</S.Key>
              <S.Value color={isNegotiation ? 'danger' : 'aqua'}>
                {formatCurrency(
                  sumNumbers(
                    dataFiltered?.map(
                      (item) =>
                        item.value +
                        (isNegotiation
                          ? handleCalcPercent(
                              item.value,
                              parcelOptionSelected?.penalty ?? 0,
                            ) +
                            getInterestByDate(
                              item.value,
                              parcelOptionSelected?.interest ?? 0,
                              item.daysOfDelay,
                            )
                          : 0) -
                        handleCalcPercent(
                          item.value,
                          parcelOptionSelected?.discount ?? 0,
                        ),
                    ),
                  ),
                )}
              </S.Value>
            </S.KeyValue>
          </S.CardContent>
        </S.DivCard>
        <S.DivSend>
          <S.CheckboxDiv>
            <S.TermButton onClick={() => setIsModalOpen(true)}>
              Termo de Aceite.
            </S.TermButton>
            <FieldFormik
              name="readed"
              placeholder="Li e confirmo que as informações aqui contidas estão corretas e não precisam de alterações."
              onChange={(e) => {
                setReaded(e.target.checked);
              }}
              disabled={!selectedOptionsParcels}
              checked={readed}
              type="checkbox"
              widthSize="fullWidth"
              notLimitHeight
            />
            <S.Button>
              <ButtonSimple
                color="primary"
                widthSize="xmedium"
                disabled={!readed}
                onClick={() => handleSend()}
              >
                Confirmar
              </ButtonSimple>
            </S.Button>
          </S.CheckboxDiv>
        </S.DivSend>
        <NegotiationTerm
          name="Welson"
          company="Famart"
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          ableBackdropClick
        />
      </S.Container>
    </BasicDialog>
  );
}
