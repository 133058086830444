import styled from 'styled-components';

export const Container = styled.div`
  margin: 2rem;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Request = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  gap: 0.5rem;
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Unavailable = styled.h6`
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const TitlePage = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Button = styled.div`
  display: flex;
  justify-content: end;

  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    justify-content: center;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem 0;
  gap: 1rem;
`;
