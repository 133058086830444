import React from 'react';
import { BasicTable } from '../../../../common/Table';

export default function TabPreviousSale({ data }) {
  return (
    <div style={{ overflow: 'auto' }}>
      <BasicTable data={data} />
    </div>
  );
}
