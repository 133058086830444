import React, { useContext, useState } from 'react';

import {
  AccordionDetails,
  IconButton,
  TableBody,
  TableCell,
  Table,
  TableHead,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DownloadIcon from '@mui/icons-material/Download';

import {
  MyTableCell,
  MyTableRow,
  SubtitleTable,
} from '../../../../../../components/student/Table/components';
import FileInput from '../../../../../../components/student/Files/Upload';

import * as S from './styles';
import {
  typeDocComplementary,
  typeDocInternship,
} from '../../../../../../variables';
import {
  documentCertificate,
  documentStudentChange,
} from '../../../../../../services/documentStudent/document.service';
import { IUniversityDegree } from '../../..';
import {
  errorToast,
  successToast,
} from '../../../../../../components/common/Toast';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  IStudentContextProps,
  StudentProviderContext,
} from '../../../../../../components/student/StudentProvider';

interface Props {
  type: string;
  oldFiles: IUniversityDegree[];
  registrationId: number;
  universityDegreeId: number;
}

interface IObjectFile {
  id?: number;
  file: File;
  url?: string;
  workload?: number;
  startDate?: string;
  endDate?: string;
}

export default function DocInternship({
  type,
  oldFiles,
  registrationId,
  universityDegreeId,
}: Props) {
  const { setIsLoading } = useContext(
    StudentProviderContext,
  ) as IStudentContextProps;
  const [selectedFiles, setSelectedFiles] = useState<IObjectFile[]>([]);

  const [oldFilesState, setOldFilesState] =
    useState<IUniversityDegree[]>(oldFiles);

  const typeSelect = {
    complementary: typeDocComplementary,
    internship: typeDocInternship,
  };

  const handleFileSelect = (file: File[]) => {
    if (file) {
      setSelectedFiles((items) => [
        ...items,
        ...file.map((f) => ({
          ...f,
          file: f,
          startDate: undefined,
          endDate: undefined,
        })),
      ]);
    }
  };

  const deleteFile = async (index: number, old?: boolean, id?: number) => {
    setIsLoading(true);

    if (!id && typeof id !== 'number') {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);

      return setIsLoading(false);
    }
    /** Lógica para excluir o arquivo no banco */
    const body = {
      registrationId: registrationId,
      universityDegreeId: universityDegreeId,
      id: id,
      type: typeSelect[type],
    };
    const request = await documentStudentChange.atvDocApi({
      type: 'POST',
      body: body,
      action: 'delete',
    });
    if (request.result) {
      successToast('Arquivo excluído com sucesso!');

      if (!old) {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
      } else {
        const updatedOldFiles = [...oldFilesState];
        updatedOldFiles.splice(index, 1);
        setOldFilesState(updatedOldFiles);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      request.message.length > 0 &&
        request.message.forEach((text) => {
          return errorToast(text);
        });
    }
  };

  const handleSend = async (item: IObjectFile, indexToInput?) => {
    setIsLoading(true);

    try {
      const body = {
        id: item.id,
        type: typeSelect[type],
        registrationId: registrationId!,
        universityDegreeId: universityDegreeId!,
        url: item.url ? item.url : '',
        initialDate: item.startDate ? item.startDate : '',
        finalDate: item.endDate ? item.endDate : '',
        workload: item.workload ? item.workload : 0,
        file: item.file,
      };
      const request = await documentCertificate.uploadCertificate({ body });

      if (request.result) {
        if (indexToInput || indexToInput === 0) {
          const updatedFiles = [...selectedFiles];
          updatedFiles.splice(indexToInput, 1);
          setSelectedFiles(updatedFiles);

          const newFile: IUniversityDegree = {
            cargaHoraria: String(item.workload!),
            conferido: false,
            dataFim: item.endDate!,
            dataInicio: item.startDate!,
            id: request.data.id,
            tipo: typeSelect[type],
            url: request.data.url,
          };
          const newOldFiles: IUniversityDegree[] = [newFile, ...oldFilesState];
          setOldFilesState(newOldFiles);
        }
        setIsLoading(false);
        return successToast('Arquivo salvo com sucesso!');
      } else {
        setIsLoading(false);
        request.message.forEach((text) => {
          errorToast(text);
        });
      }
    } catch (error) {
      errorToast(error);
      setIsLoading(false);
    }
  };

  function handleDateChange(dateString: string) {
    const [day, month, year] = dateString.split('-');
    const selectedDate = `${day}-${month}-${year}`;
    return selectedDate;
  }

  return (
    <AccordionDetails>
      <S.Container>
        <Table>
          <TableHead sx={{ borderTop: '1px solid #dfdfdf' }}>
            <MyTableRow>
              <MyTableCell align="center">
                <SubtitleTable color="gray">Data inicio</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="gray">Data fim</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="gray">Carga horária</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <FileInput onFileSelect={handleFileSelect}>Adicionar</FileInput>
              </MyTableCell>
            </MyTableRow>
          </TableHead>
          {selectedFiles.map((item, index) => {
            return (
              <TableBody key={index}>
                <TableCell align="center">
                  <S.InputDate
                    type="date"
                    onChange={(e) => {
                      const newSelectedFiles = [...selectedFiles];
                      newSelectedFiles[index].startDate = handleDateChange(
                        e.target.value,
                      );
                      setSelectedFiles(newSelectedFiles);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <S.InputDate
                    type="date"
                    onChange={(e) => {
                      const newSelectedFiles = [...selectedFiles];
                      newSelectedFiles[index].endDate = handleDateChange(
                        e.target.value,
                      );
                      setSelectedFiles(newSelectedFiles);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <S.Style>
                    <S.InputNumber
                      type="number"
                      onChange={({ target }) => {
                        const newSelectedFiles = [...selectedFiles];
                        newSelectedFiles[index].workload = +target.value;
                        setSelectedFiles(newSelectedFiles);
                      }}
                    />
                  </S.Style>
                </TableCell>
                <TableCell align="right">
                  <S.DivButtons>
                    <IconButton component="label">
                      <CloudUploadIcon color="primary" fontSize="large" />
                      <input
                        style={{
                          clip: 'rect(0 0 0 0)',
                          overflow: 'hidden',
                          position: 'absolute',
                          bottom: '0',
                          left: '0',
                          right: '0',
                          top: '0',
                          whiteSpace: 'nowrap',
                        }}
                        onChange={({ target }) => {
                          if (target.files && target.files.length > 0) {
                            const newSelectedFiles = [...selectedFiles];
                            newSelectedFiles[index].file = target!.files[0];
                            setSelectedFiles(newSelectedFiles);
                          }
                        }}
                        type="file"
                        accept=".pdf,.jpg,.png,.doc,.docx"
                      />
                    </IconButton>
                    <IconButton onClick={() => handleSend(item, index)}>
                      <SaveOutlinedIcon
                        fontSize="large"
                        sx={{ color: 'green' }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteFile(index, false, item.id)}
                    >
                      <DeleteForeverIcon
                        fontSize="large"
                        sx={{ color: 'red' }}
                      />
                    </IconButton>
                  </S.DivButtons>
                </TableCell>
              </TableBody>
            );
          })}

          {oldFilesState.map((item, index) => {
            return (
              <TableBody key={item.id}>
                <TableCell align="center">
                  <S.InputDate
                    disabled={item.conferido}
                    type="date"
                    value={item.dataInicio}
                    onChange={({ target }) => {
                      const newSelectedFiles = [...oldFilesState];
                      newSelectedFiles[index].dataInicio = target.value;
                      newSelectedFiles[index].alterado = true;
                      setOldFilesState(newSelectedFiles);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <S.InputDate
                    disabled={item.conferido}
                    type="date"
                    value={item.dataFim}
                    onChange={({ target }) => {
                      const newSelectedFiles = [...oldFilesState];
                      newSelectedFiles[index].dataFim = target.value;
                      newSelectedFiles[index].alterado = true;
                      setOldFilesState(newSelectedFiles);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <S.Style>
                    <S.InputNumber
                      disabled={item.conferido}
                      type="number"
                      value={+item.cargaHoraria.replace(',', '.')}
                      defaultValue={+item.cargaHoraria.replace(',', '.')}
                      onChange={({ target }) => {
                        const newSelectedFiles = [...oldFilesState];
                        newSelectedFiles[index].cargaHoraria = target.value;
                        newSelectedFiles[index].alterado = true;
                        setOldFilesState(newSelectedFiles);
                      }}
                    />
                  </S.Style>
                </TableCell>
                <TableCell align="right">
                  <S.DivButtons>
                    {item.url && (
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <IconButton>
                          <DownloadIcon
                            fontSize="large"
                            sx={{ border: 'none', color: '#1565c0' }}
                          />
                        </IconButton>
                      </a>
                    )}
                    {!item.url && !item.conferido && (
                      <IconButton component="label">
                        <CloudUploadIcon color="primary" fontSize="large" />
                        <input
                          style={{
                            clip: 'rect(0 0 0 0)',
                            overflow: 'hidden',
                            position: 'absolute',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            top: '0',
                            whiteSpace: 'nowrap',
                          }}
                          onChange={({ target }) => {
                            if (target.files && target.files.length > 0) {
                              const newSelectedFiles = [...oldFilesState];
                              newSelectedFiles[index].file = target!.files[0];
                              setOldFilesState(newSelectedFiles);
                            }
                          }}
                          type="file"
                          accept=".pdf,.jpg,.png,.doc,.docx"
                        />
                      </IconButton>
                    )}
                    {!item.conferido && (
                      <>
                        {item.alterado && (
                          <IconButton
                            onClick={() =>
                              handleSend({
                                id: +item.id,
                                url: item.url,
                                file: item.file!,
                                workload: +item.cargaHoraria.replace(',', '.'),
                                startDate: item.dataInicio,
                                endDate: item.dataFim,
                              })
                            }
                          >
                            <SaveOutlinedIcon
                              fontSize="large"
                              sx={{ color: 'green' }}
                            />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => deleteFile(index, true, +item.id)}
                        >
                          <DeleteForeverIcon
                            fontSize="large"
                            sx={{ color: 'red' }}
                          />
                        </IconButton>
                      </>
                    )}
                  </S.DivButtons>
                </TableCell>
              </TableBody>
            );
          })}
        </Table>
      </S.Container>
    </AccordionDetails>
  );
}
