import * as apiService from '../api/student/api.service';

interface IData {
  login: string;
  userType: number;
}

interface IDataPassword {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

const studentService = {
  async firstAccess(data: IData): Promise<{
    data: {
      firstAccess: boolean;
    };
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.unauthenticatedRequest(
      `/student/check-first-login`,
      'POST',
      data,
    );

    return response;
  },

  async changePassword(data: IDataPassword) {
    const response = await apiService.authenticatedRequest(
      '/student/user/change-password',
      'POST',
      data,
    );

    return response;
  },
};

export default studentService;
