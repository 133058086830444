import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-right: 2rem;
  gap: 3rem;
  width: 25%;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const Title = styled.b`
  font-size: medium;
`;

export const TitleRegistration = styled.b`
  font-size: 13px;
`;

export const ContentRegistration = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 3rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Checkbox = styled.div`
  display: flex;
  padding-top: 1rem;
  gap: 1rem;
`;

export const ValueParcels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 1rem;
`;
