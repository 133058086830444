import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as S from './styles';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import TitleDialogMobile from '../../../../../components/newStudent/Title/TitleDialogMobile';
import Divider from '../../../../../components/common/Divider';
import { CardLeftColor } from '../../../../../components/newStudent/Card';
import { formatCurrency, formatDate } from '../../../../../util/common/appUtil';
import { Pagination } from '@mui/material';
import { IInfoBillets } from '../../../../../services/newStudent/financial/financial.service';
import { AletNotfoundLoading } from '../../../../../components/newStudent/Alert';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  expiredPayments?: IInfoBillets[] | [];
  unpaidPayments?: IInfoBillets[] | [];
  settledPayments?: IInfoBillets[] | [];
  isPaymentOpen?: boolean;
  handleBilletPrintOut: (id: number) => void;
}

export default function PaymentsMobile({
  isOpen,
  setIsOpen,
  isPaymentOpen,
  expiredPayments,
  unpaidPayments,
  settledPayments,
  handleBilletPrintOut,
}: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [billets, setBillets] = useState<
    Array<
      IInfoBillets & {
        color: 'primary' | 'danger' | 'gray03';
      }
    >
  >([]);

  const itemsPerPage = 5; // Número de itens por página

  // Calcula o índice inicial e final dos itens para a página atual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtra os itens para a página atual
  const currentItems = billets?.slice(startIndex, endIndex);

  const stylePagination = {
    fontSize: '14px',
  };

  useEffect(() => {
    const tmpExpiredPayments = expiredPayments?.map((item) => ({
      ...item,
      color: 'danger',
    }));
    const tmpUnpaidPayments = unpaidPayments?.map((item) => ({
      ...item,
      color: 'gray03',
    }));
    const tmpSettledPayments = settledPayments?.map((item) => ({
      ...item,
      color: 'primary',
    }));

    const allBillets = [
      ...(tmpExpiredPayments ?? []),
      ...(tmpUnpaidPayments ?? []),
      ...(tmpSettledPayments ?? []),
    ];
    setBillets(allBillets);
  }, [expiredPayments, unpaidPayments, settledPayments]);
  return (
    <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen} fullScreen scroll="body">
      <TitleDialogMobile setIsOpen={setIsOpen} title={'Histórico Financeiro'} />
      <S.Content>
        <S.TitlePage isPaymentOpen={isPaymentOpen}>
          {isPaymentOpen ? 'Pagamentos Abertos' : 'Pagamentos Liquidados'}
        </S.TitlePage>
        <Divider padding />
        <AletNotfoundLoading
          toRender={billets.length <= 0}
          notFoundText="Nenhum pagamento Encontrado..."
        />
        {currentItems?.map((billet, index) => (
          <S.Card key={index}>
            <CardLeftColor
              color={isPaymentOpen ? billet.color : 'primary'}
              heightSize="fullHeight"
              onClick={() => billet.print && handleBilletPrintOut(billet.id)}
            >
              <S.CardContent>
                <S.KeyValue>
                  <S.Key>Referência:</S.Key>
                  <S.Value color={billet.color}>
                    {billet.invoiceReference}
                  </S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Valor:</S.Key>
                  <S.Value color={billet.color}>
                    {formatCurrency(billet.value)}
                  </S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Vencimento:</S.Key>
                  <S.Value color={billet.color}>
                    {formatDate(billet.dueDate)}
                  </S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Status:</S.Key>
                  <S.Value color={billet.color}>{billet.status}</S.Value>
                </S.KeyValue>
              </S.CardContent>
              <S.ContentObservation>
                <S.KeyValue>
                  <S.Value color={billet.color}>{billet.observation}</S.Value>
                </S.KeyValue>
              </S.ContentObservation>
            </CardLeftColor>
            <Divider padding color="gray02" />
          </S.Card>
        ))}
        {billets.length > 0 && (
          <S.Paginate>
            <Pagination
              count={Math.ceil(billets?.length / itemsPerPage)}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              sx={{
                '& .MuiPaginationItem-page.Mui-selected': {
                  ...stylePagination,
                },
                '& .MuiPaginationItem-page': { ...stylePagination },
              }}
            />
          </S.Paginate>
        )}
      </S.Content>
    </BasicDialog>
  );
}
