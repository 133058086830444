import * as api from '../../api/newStudent/apiStudent.service';

interface Banner {
  id: number;
  link: string;
  urlImage: string;
}

export interface IBanners {
  desktop?: Banner[];
  mobile?: Banner[];
}
export const bannerServiceNewStudent = {
  async getAll(): Promise<{
    data: IBanners;
    message: string[];
    result: boolean;
  }> {
    try {
      const response = await api.authenticatedRequest('/new/student/banner');
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
