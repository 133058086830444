import { defaultReturn } from '../../../util/newStudent';
import * as apiService from '../../api/newStudent/apiStudent.service';

export interface IInfoBillets {
  id: number;
  canAnticipate: boolean;
  invoiceReference: string;
  observation: string;
  dueDate: string;
  value: number;
  daysOfDelay: number;
  type: number;
  status: string;
  print: number | null;
  update: number | null;
}
export interface IBillets {
  paidBillet: IInfoBillets[] | [];
  expiredBillet: IInfoBillets[] | [];
  openBillet: IInfoBillets[] | [];
  negotiationBillets?: boolean;
  anticipateBillets?: boolean;
}

interface IIncomeTax {
  data: { years: string[] };
  message: string[];
  result: boolean;
}

interface IIncomeTaxDeclaration {
  data: string;
  message: string[];
  result: boolean;
}

interface IAllBillets {
  data: IBillets;
  message: string[];
  result: boolean;
}

export interface IInfoDebtAndNegotiationBillets {
  id: number;
  invoiceReference: string;
  dueDate: string;
  value: number;
  daysOfDelay: number;
}

export interface IDebtAndNegotiationBillets {
  id: number[];
  paymentType: number;
  parcel: number;
  total: number;
}

export const financialServiceNewStudent = {
  /** Traz todos os boletos do aluno */
  async getAllBillets(): Promise<IAllBillets> {
    const request = await apiService.authenticatedRequest(
      `/new/student/financial/historic`,
    );

    return defaultReturn(request);
  },

  async incomeTax(): Promise<IIncomeTax> {
    try {
      const request = await apiService.authenticatedRequest(
        `/student/financial/income-tax`,
        'GET',
        null,
        'object',
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async incomeTaxDeclaration(data: {
    year: string;
  }): Promise<IIncomeTaxDeclaration> {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/financial/income-tax-declaration',
        'POST',
        data,
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  /** Imprime o boleto da matrícula */
  async handlePrintOutBillet(id: number | undefined) {
    const request = await apiService.authenticatedRequest(
      `/student/bank-billets/view/${id}`,
    );
    if (request.result) {
      window.open(request.data, '_blank');
    }
    return defaultReturn(request);
  },

  /** Função para atualizar o boleto da matrícula */
  async handleUpdateBillet(id: number) {
    const request = await apiService.authenticatedRequest(
      `/student/bank-billets/update/${id}`,
      'PUT',
    );
    if (request.result) {
      await this.handlePrintOutBillet(id);
    }
    return defaultReturn(request);
  },

  /** Negociar os debitos */
  async installmentsNegotiation(
    data: IDebtAndNegotiationBillets,
  ): Promise<{ data: object; result: boolean; message: string | string[] }> {
    try {
      const response = await apiService.authenticatedRequest(
        '/student/installments-negotiation/create',
        'POST',
        data,
      );
      if (response.result) {
        window.open(response.data, '_blank');
        window.location.reload();
      }

      return defaultReturn(response);
    } catch (error) {
      throw new Error(error);
    }
  },

  /** Antecipar as Parcelas */
  async installmentsAdvance(
    data: IDebtAndNegotiationBillets,
  ): Promise<{ data: object; result: boolean; message: string | string[] }> {
    try {
      const response = await apiService.authenticatedRequest(
        '/student/installments-advance/create',
        'POST',
        data,
      );
      if (response.result) {
        window.open(response.data, '_blank');
        window.location.reload();
      }

      return defaultReturn(response);
    } catch (error) {
      throw new Error(error);
    }
  },
};
