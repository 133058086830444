import styled from 'styled-components';

export const Container = styled.div``;

export const Chart = styled.div`
  font-weight: 600;

  .apexcharts-legend-series {
    /* padding: 0.1rem; */
    /* background-color: ${({ theme }) => theme.alert.default.main}; */
  }
  .apexcharts-legend-text {
    font-weight: bolder;
  }
`;
