export function statusStyle(tccStatus) {
  let style;
  if (
    tccStatus === 'Orientação Concluída' ||
    tccStatus === 'Orientação Aprovada' ||
    tccStatus === 'Aprovado' ||
    tccStatus === 'Dispensa Aprovada'
  ) {
    style = { color: 'green', fontWeight: 'bold' };
  } else {
    style = { color: 'red', fontWeight: 'bold' };
  }
  return style;
}
