import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';

import SearchIcon from '@mui/icons-material/Search';

import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import FieldFormik from '../../../components/newStudent/FieldFormik';
import { Formik } from 'formik';
import Divider from '../../../components/common/Divider';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { financialServiceNewStudent } from '../../../services/newStudent';
import { ButtonStartIcon } from '../../../components/newStudent/Buttons';
import ModalScroll from '../../../components/common/Modal/ModalScroll';
import { handleMaxWidth } from '../../../util/newStudent';
import { useTheme } from 'styled-components';

export default function IncomeTaxNew() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [options, setOptions] = useState([
    { value: 0, title: '[ Selecione o ano desejado ]' },
  ]);
  const [incomeTax, setIncomeTax] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = async (value) => {
    setIsLoading(true);
    try {
      const { result, data } =
        await financialServiceNewStudent.incomeTaxDeclaration(value);
      if (result) {
        setIsOpen(true);
        setIncomeTax(data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const handleCloseModal = () => {
    setIncomeTax('');
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      const fetch = async () => {
        const { result, data } = await financialServiceNewStudent.incomeTax();

        if (result) {
          const dataOptions = data.years.map((item) => ({
            value: +item,
            title: `Ano - ${item}`,
          }));
          setOptions((old) => [...old, ...dataOptions]);
        }
        setIsLoading(false);
      };
      fetch();
    } catch (erro) {
      setIsLoading(false);
      throw new Error(erro);
    }
  }, []);
  const theme = useTheme();
  return (
    <S.Container>
      {incomeTax && (
        <ModalScroll
          incomeTax={incomeTax}
          handleClose={handleCloseModal}
          handleOpen={() => setIsOpen(true)}
          open={isOpen}
          title="DECLARAÇÃO DE QUITAÇÃO ANUAL DE DÉBITOS"
        />
      )}
      <TitlePage
        title="Informe - Imposto de Renda"
        titlePage="IR"
        subtitle="Aqui, simplificamos o processo tributário para você. 
        Explore este menu para encontrar todas as informações essenciais 
        relacionadas à sua declaração de imposto de renda."
      />
      <Divider padding />
      <Formik
        initialValues={{ year: '' }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleChange, values }) => (
          <S.Field>
            <FieldFormik
              name="year"
              placeholder="[ Selecione o ano desejado ]"
              widthSize="fullWidth"
              onChange={handleChange}
              value={values.year}
              type="select"
              options={options}
            />
            <ButtonStartIcon
              color="primary"
              font={handleMaxWidth(theme.size?.medium) ? 'h6' : 'h5'}
              startIcon={<SearchIcon fontSize="large" />}
              heightSize="medium"
              widthSize="large"
              type="submit"
            >
              Pesquisar
            </ButtonStartIcon>
          </S.Field>
        )}
      </Formik>
    </S.Container>
  );
}
