import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;

  top: 0;

  min-height: 10vh;
  user-select: none;
  z-index: ${(props) => props.theme.zIndex?.header};

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    right: 0;
  }
`;

export const AccessibilityMobile = styled.div`
  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    position: absolute;
    display: flex;
    right: 0;
    top: 6rem;
  }
`;

export const P = styled.p`
  height: 10px;
  color: ${({ theme }) => theme.colors?.text.tertiary};
`;

export const Accessibility = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 20rem;
  height: 4rem;
  margin-left: 3rem;
  border-radius: 0px 0px 10px 10px;
  background-color: ${({ theme }) => theme.colors?.attention};
  cursor: pointer;

  &&:hover {
    opacity: 0.9;
  }
  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    display: none;
  }
`;

export const FocusDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3rem;
  height: 100%;

  animation: barAccessibility 0.7s;
  @keyframes barAccessibility {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    margin-left: 2rem;
  }
`;

export const AccessibilityFocus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
  width: 20rem;
  height: 4rem;
  border: 1px solid ${({ theme }) => theme.colors?.attention};
  background-color: #fff;
  cursor: pointer;

  &&:hover {
    opacity: 0.9;
  }
`;

export const PFocus = styled.p`
  height: 10px;
  color: ${({ theme }) => theme.colors?.text.attention};
`;

export const Menu = styled.p`
  width: 20rem;
  border-radius: 0px 0px 10px 10px;

  border: 1px solid ${({ theme }) => theme.colors?.attention};
`;

export const HeaderOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 2rem;

  background-color: ${({ theme }) => theme.colors?.attention};
`;

export const TitleOption = styled.div`
  color: #fff;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  gap: 1rem;
  padding: 1rem 0;

  border-radius: 0px 0px 10px 10px;
  background-color: #fff;
`;

export const Opt = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 3rem;
`;

export const OptText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors?.gray04};
`;
