import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import Button from '@mui/material/Button';

import * as S from './styles';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

interface Props {
  noBorder?: boolean;
  children?: ReactNode | string;
  color: 'primary' | 'gray' | 'success' | 'danger' | 'aqua';
  disabled?: boolean;
  font?: 'p' | 'b' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  icon?: ReactNode;
  startIcon?: ReactNode;
  justify?:
    | 'normal'
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end';
  noButton?: boolean;
  onClick?: () => void;
  outline?: boolean;
  selected?: boolean;
  styles?: object;
  type?: 'button' | 'submit' | 'reset';
  upperCase?: boolean;
  boxShadow?: boolean;
  noPaddingIcon?: boolean;
  heightSize?:
    | 'small'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'fullHeight';
  widthSize?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'lsmall'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'fullWidth';
  className?: string;
  toolltip?: string;
}

export default function ButtonSimple({
  icon,
  children,
  justify,
  heightSize = 'medium',
  color,
  outline,
  font = 'h6',
  onClick,
  selected,
  styles,
  widthSize = 'fullWidth',
  type = 'button',
  noBorder = false,
  upperCase,
  noButton,
  disabled,
  startIcon,
  noPaddingIcon,
  boxShadow = false,
  className,
  toolltip,
}: Props) {
  const theme = useTheme();

  /** Objeto para selecionar o tamanho vertical do botão */
  const sizeHeight = {
    small: { height: '3rem' },
    medium: { height: '4rem' },
    xmedium: { height: '4.5rem' },
    large: { height: '5rem' },
    xlarge: { height: '6rem' },
    fullHeight: { height: '100%' },
  };

  /** Objeto para selecionar o tamanho horizontal do botão */
  const sizeWidth = {
    xxsmall: { width: '3rem' },
    xsmall: { width: '4.5rem' },
    small: { width: '5rem' },
    lsmall: { width: '8rem' },
    medium: { width: '10rem' },
    xmedium: { width: '12rem' },
    large: { width: '18.5rem' },
    xlarge: { width: '25rem' },
    fullWidth: { width: '100%' },
  };

  /**
   * Variante de cor do botão
   *
   * Temos algumas variações que são:
   * selected: Para quando botão precisar parecer que está selecionado
   * noButton: Faz o botão não ter ações no componente
   * outline: Para fazer com que o botão tenha uma borda externa
   * noBorder: Para fazer com que o botão fique sem a borda
   */

  const variantPrimaryDefault = {
    backgroundColor: outline
      ? disabled
        ? theme.colors?.backgroundColor
        : selected
        ? theme.colors?.primary
        : theme.colors?.backgroundColor
      : disabled
      ? theme.colors?.disable01
      : selected
      ? theme.colors?.primary
      : theme.colors?.secondary,
    color: outline
      ? selected
        ? theme.colors?.text.tertiary
        : theme.colors?.text.info02
      : theme.colors?.text.tertiary,
  };
  const variantGrayDefault = {
    backgroundColor: outline
      ? disabled
        ? theme.colors?.backgroundColor
        : selected
        ? theme.colors?.gray03
        : theme.colors?.backgroundColor
      : disabled
      ? theme.colors?.disable04
      : selected
      ? theme.colors?.gray03
      : theme.colors?.gray01,
    color: outline
      ? selected
        ? theme.colors?.text.tertiary
        : theme.colors?.text.primary
      : selected
      ? theme.colors?.text.tertiary
      : theme.colors?.text.primary,
  };
  const variantSuccessDefault = {
    backgroundColor: outline
      ? disabled
        ? theme.colors?.backgroundColor
        : selected
        ? theme.colors?.success02
        : theme.colors?.backgroundColor
      : disabled
      ? theme.colors?.disable02
      : selected
      ? theme.colors?.success02
      : theme.colors?.success,
    color: outline
      ? selected
        ? theme.colors?.text.tertiary
        : theme.colors?.text.success
      : theme.colors?.text.tertiary,
  };
  const variantDangerDefault = {
    backgroundColor: outline
      ? disabled
        ? theme.colors?.backgroundColor
        : selected
        ? theme.colors?.danger02
        : theme.colors?.backgroundColor
      : disabled
      ? theme.colors?.disable03
      : selected
      ? theme.colors?.danger02
      : theme.colors?.danger,
    color: outline
      ? selected
        ? theme.colors?.text.tertiary
        : theme.colors?.text.danger
      : theme.colors?.text.tertiary,
  };
  const variantAquaDefault = {
    backgroundColor: outline
      ? selected
        ? theme.colors?.aqua
        : theme.colors?.backgroundColor
      : theme.colors?.aqua,
    color: outline
      ? selected
        ? theme.colors?.backgroundColor
        : theme.colors?.aqua
      : theme.colors?.backgroundColor,
  };

  const variant = {
    primary: {
      ...variantPrimaryDefault,
      '&:hover': !noButton
        ? {
            backgroundColor: theme.colors?.primary,
            color: theme.colors?.backgroundColor,
            cursor: 'pointer',
          }
        : { cursor: 'default', ...variantPrimaryDefault },
      border: outline
        ? disabled
          ? `1px solid ${theme.colors?.disable01}`
          : `1px solid ${theme.colors?.secondary}`
        : noBorder
        ? `1px solid ${theme.colors?.backgroundColor}`
        : '',
      '&:disabled': {
        color: outline
          ? theme.colors?.text.info03
          : theme.colors?.text.tertiary,
      },
    },
    gray: {
      ...variantGrayDefault,
      '&:hover': !noButton
        ? {
            backgroundColor: theme.colors?.gray03,
            color: theme.colors?.backgroundColor,
            cursor: 'pointer',
          }
        : {
            cursor: 'default',
            ...variantGrayDefault,
          },
      border: outline
        ? disabled
          ? `1px solid ${theme.colors?.disable04}`
          : `1px solid ${theme.colors?.gray03}`
        : noBorder
        ? `1px solid ${theme.colors?.backgroundColor}`
        : '',
      '&:disabled': {
        color: outline
          ? theme.colors?.text.gray05
          : theme.colors?.text.tertiary,
      },
    },
    success: {
      ...variantSuccessDefault,
      '&:hover': !noButton
        ? {
            backgroundColor: theme.colors?.success,
            color: theme.colors?.backgroundColor,
            cursor: 'pointer',
          }
        : { cursor: 'default', ...variantSuccessDefault },
      border: outline
        ? disabled
          ? `1px solid ${theme.colors?.disable02}`
          : `1px solid ${theme.colors?.success}`
        : noBorder
        ? `1px solid ${theme.colors?.backgroundColor}`
        : '',
      '&:disabled': {
        color: outline
          ? theme.colors?.text.success02
          : theme.colors?.text.tertiary,
      },
    },
    danger: {
      ...variantDangerDefault,
      '&:hover': !noButton
        ? {
            backgroundColor: theme.colors?.danger,
            color: theme.colors?.backgroundColor,
            cursor: 'pointer',
          }
        : { cursor: 'default', ...variantDangerDefault },
      border: outline
        ? disabled
          ? `1px solid ${theme.colors?.disable03}`
          : `1px solid ${theme.colors?.danger}`
        : noBorder
        ? `1px solid ${theme.colors?.backgroundColor}`
        : '',
      '&:disabled': {
        color: outline
          ? theme.colors?.text.danger03
          : theme.colors?.text.tertiary,
      },
    },
    aqua: {
      ...variantAquaDefault,
      '&:hover': !noButton
        ? {
            backgroundColor: theme.colors?.aqua,
            color: theme.colors?.backgroundColor,
            cursor: 'pointer',
          }
        : { cursor: 'default', ...variantAquaDefault },
      border: outline
        ? `1px solid ${theme.colors?.aqua}`
        : noBorder
        ? `1px solid ${theme.colors?.backgroundColor}`
        : '',
      '&:disabled': {
        color: outline
          ? theme.colors?.text.success02
          : theme.colors?.text.tertiary,
      },
    },
  };

  /** Objeto para selecionar a fonte do botão
   * Estilos aplicados para centralizar o texto
   */
  const selectFont = {
    p: <p>{children}</p>,
    b: <b>{children}</b>,
    h1: <h1 style={{ height: '2.5rem' }}>{children}</h1>,
    h2: <h2 style={{ height: '2.5rem' }}>{children}</h2>,
    h3: <h3 style={{ height: '2rem' }}>{children}</h3>,
    h4: <h4 style={{ height: '2rem' }}>{children}</h4>,
    h5: <h5 style={{ height: '1.7rem' }}>{children}</h5>,
    h6: <h6>{children}</h6>,
  };
  const RedTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: 'white',
      fontSize: '14px',
    },
  }));

  return (
    <RedTooltip title={toolltip} placement="top">
      <Button
        className={className}
        sx={{
          borderRadius: '10px',
          ...variant[color],
          ...(heightSize ? sizeHeight[heightSize] : { height: '5rem' }),
          ...(widthSize ? sizeWidth[widthSize] : {}),
          textTransform: upperCase ? 'uppercase' : 'none',
          boxShadow: boxShadow ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' : 'none',
          ...styles,
        }}
        disableRipple={noButton}
        disabled={disabled}
        onClick={() => {
          !noButton && onClick && onClick();
        }}
        type={type}
      >
        <S.Child
          justify={justify}
          noPaddingIcon={noPaddingIcon}
          style={sizeWidth[widthSize]}
        >
          {startIcon && startIcon}
          <S.Text>
            {typeof children === 'string' ? selectFont[font] : children}
          </S.Text>
          {icon && icon}
        </S.Child>
      </Button>
    </RedTooltip>
  );
}
