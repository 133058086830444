import React, { useContext, useEffect, useState } from 'react';

import { Info, Notice } from '../../../../../components/student/Info';
import { BasicTable } from '../../../../../components/common/Table';

import {
  RowText,
  RowTextBold,
} from '../../../../../components/common/Table/components';
import ButtonPrint from '../../../../../components/agent/Buttons/ButtonPrint';
import {
  IStudentContextProps,
  StudentProviderContext,
} from '../../../../../components/student/StudentProvider';
import { errorToast } from '../../../../../components/common/Toast';
import { sieadUrl } from '../../../../../variables';

interface ITableCourses {
  title: string;
  subtitle: string;
  print: number;
}

interface INoticeRows {
  subtitle: string;
  text: string;
}

export default function Capacitation() {
  const context = useContext(StudentProviderContext);
  const { setIsLoading } = context as IStudentContextProps;

  const [tableCourses, setTableCourses] = useState<ITableCourses[] | []>([]);

  const noticeRows: INoticeRows[] = [
    {
      subtitle: 'Avaliação: ',
      text: 'Aprovação mínima de 70% em nas avaliações',
    },
    {
      subtitle: 'Tempo de Curso: ',
      text: 'Tempo minímo de curso',
    },
    {
      subtitle: 'Documentação: ',
      text: 'CPF e RG ou Carteira de Motorista (Cópia Simples)',
    },
  ];

  const handlePrintOut = async () => {
    try {
      setIsLoading(true);
      // fazer no fetch para trazer os dados
      // const handleFechLink = async () => {
      //   // await authenticatedRequest. dar um fetch e imprimir
      // };

      // handleFechLink();
    } catch (error) {
      errorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTableCourses([
      {
        title: 'Técnico de Informática',
        subtitle: 'CAPACITAÇÃO PROFISSIONAL',
        print: 25,
      },
      {
        title: 'Introdução à Algoritmos',
        subtitle: 'CAPACITAÇÃO PROFISSIONAL',
        print: 25,
      },
      {
        title: 'Fundamentos e Arquitetura de Informática',
        subtitle: 'CAPACITAÇÃO PROFISSIONAL',
        print: 25,
      },
      {
        title: 'Técnico de InformáticRede de Computadoresa',
        subtitle: 'CAPACITAÇÃO PROFISSIONAL',
        print: 25,
      },
    ]);
  }, []);

  const tableInfo = {
    title: [
      { item: 'Curso' },
      { item: 'Certificado', style: { textAlign: 'center', width: '10rem' } },
    ],
    content: [
      ...tableCourses.map((item, index) => ({
        Curso: {
          item: (
            <>
              <RowTextBold key={index} align="left">
                {item.title}
              </RowTextBold>
              <RowText>{item.subtitle}</RowText>
            </>
          ),
        },
        Certificado: {
          item: (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ButtonPrint onClick={() => handlePrintOut()} />
            </div>
          ),
        },
      })),
    ],
  };

  return (
    <>
      <Notice title="Requisitos para emissão:" rows={noticeRows} />
      <BasicTable data={tableInfo} />
      <Info>
        Caso tenha alguma dúvida para emitir o certificado, acesse o menu
        <a
          href={`${sieadUrl}/aluno/requerimento/movidesk`}
          target="_blank"
          rel="noreferrer"
        >
          Requerimentos/Serviços
        </a>
        .
      </Info>
    </>
  );
}
