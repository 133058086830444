import React from 'react';

import { Phone, WhatsApp } from '@mui/icons-material';

import * as S from './styles';
import {
  phoneService,
  phoneWhatsapp,
  sieadImagesFolderUrl,
} from '../../../variables';

const logo = `${sieadImagesFolderUrl}c81e728d9d4c2f636f067f89cc14862c.jpg`;

export default function Maintenance() {
  return (
    <S.Container>
      <S.Content>
        <S.DivImg>
          <S.Img src={'/img/newStudent/warning.png'}></S.Img>
          <S.TitleError>Site em Manutenção!</S.TitleError>
        </S.DivImg>
        <S.Subtitle>
          Desculpe-nos pelo transtorno. <br />
          Por favor, tente novamente mais tarde.
        </S.Subtitle>
        <S.Text>Em caso de dúvidas, conte com nosso atendimento.</S.Text>
        <S.DivMobile>
          <S.DivPhone>
            <Phone fontSize="large" />
            <S.Phone>
              <S.Text>
                Atendimento
                <br />
                <b>{phoneService}</b>
              </S.Text>
            </S.Phone>
          </S.DivPhone>
          <S.DivPhone>
            <WhatsApp fontSize="large" />
            <S.Phone>
              <S.Text>
                Whatsapp
                <br />
                <b>{phoneWhatsapp}</b>
              </S.Text>
            </S.Phone>
          </S.DivPhone>
        </S.DivMobile>
        <S.StyledTable>
          <tbody>
            <S.Row>
              <S.Td>
                <S.DivPhone>
                  <Phone fontSize="large" />
                  <S.Phone>
                    <S.Text>
                      Atendimento
                      <br />
                      <b>{phoneService}</b>
                    </S.Text>
                  </S.Phone>
                </S.DivPhone>
              </S.Td>
              <S.Td>
                <S.DivPhone>
                  <WhatsApp fontSize="large" />
                  <S.Phone>
                    <S.Text>
                      Whatsapp
                      <br />
                      <b>{phoneWhatsapp}</b>
                    </S.Text>
                  </S.Phone>
                </S.DivPhone>
              </S.Td>
            </S.Row>
          </tbody>
        </S.StyledTable>
        <S.DivImgLogo>
          <S.ImgLogo src={logo} alt="logo" />
        </S.DivImgLogo>
      </S.Content>
    </S.Container>
  );
}
