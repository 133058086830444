import { warningToast } from '../../components/common/Toast';
import {
  ICourse,
  IFinancial,
  IStudent,
  IServices,
  ITccInternship,
  IStudentDocuments,
} from '../agent/student/interface';
import * as apiService from '../api/agent/api.agent.service';

interface IData {
  login: string;
  tipo_usuario?: number;
}

const studentDataService = {
  async data(data: IData): Promise<{
    data: IStudent;
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(`/helper/student/data`, 'POST', data)
      .then((data) => {
        if (!data.result) {
          return warningToast(
            data.message.map((text) => {
              warningToast(text);
            }),
          );
        } else {
          return data;
        }
      })
      .catch((error) => {
        return console.error(error);
      });
    return response;
  },

  async grades(data: IData) {
    const response = await apiService.authenticatedRequest(
      `/helper/student/grades`,
      'POST',
      data,
    );
    return response;
  },

  async financial(data: IData): Promise<{
    data: IFinancial[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.authenticatedRequest(
      `/helper/student/financial`,
      'POST',
      data,
    );
    return response;
  },

  async courses(data: IData): Promise<{
    data: ICourse[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.authenticatedRequest(
      `/helper/student/courses`,
      'POST',
      data,
    );
    return response;
  },

  async tccInternship(data: IData): Promise<{
    data: { tcc: ITccInternship[]; internship: ITccInternship[] };
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.authenticatedRequest(
      `/helper/student/tcc`,
      'POST',
      data,
    );
    return response;
  },

  async documents(data: IData): Promise<{
    data: IStudentDocuments[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.authenticatedRequest(
      `/helper/student/documentation`,
      'POST',
      data,
    );
    return response;
  },

  async services(data: IData): Promise<{
    data: IServices[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.authenticatedRequest(
      `/helper/student/services`,
      'POST',
      data,
    );
    return response;
  },
};

export default studentDataService;
