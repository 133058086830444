import styled from 'styled-components';

export const ContainerText = styled.div``;

export const ButtonsText = styled.div`
  border: 1px solid #b6b6b6;
  padding: 0.5rem;
`;

export const Style = styled.div`
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
