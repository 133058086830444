import React from 'react';

import { Container } from './styles';

interface LoadingProps {
  text: string;
}

export default function Loading({ text }: LoadingProps) {
  return (
    <Container>
      <div className="loading">
        <div>
          <div className="c1"></div>
          <div className="c2"></div>
          <div className="c3"></div>
          <div className="c4"></div>
        </div>
        <span>{text}</span>
      </div>
    </Container>
  );
}
