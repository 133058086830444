import React, { ReactNode } from 'react';

import * as S from './styles';

interface Props {
  icon?: ReactNode;
  title: string;
  subtitle?: boolean;
}
export function TitlePage({ icon, title, subtitle }: Props) {
  return (
    <S.Container>
      <div style={{ paddingRight: icon ? '0.5rem' : 0 }}>{icon}</div>
      <p style={{ fontSize: subtitle ? '20px' : '26px' }}>{title}</p>
    </S.Container>
  );
}
