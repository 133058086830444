import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const ContainerTable = styled.div`
  padding-top: 1.5rem;

  &.firstTable {
    padding-top: 0.5rem;
  }
`;

export const CheckCustom = styled.div`
  .checkAll {
    border: '1px solid #fff';
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
      color: #fff;
    }
    > svg {
      color: #fff;
    }
  }
`;
