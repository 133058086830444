import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const ContentMobile = styled.div`
  padding: 2rem;
`;

export const TitlePage = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;
