import React, { useContext, useEffect, useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';

import {
  SubtitleTable,
  TitleTable,
  RowText,
  DivRow,
  RowTextBold,
  MyTableRow,
  MyTableCell,
} from '../../../../common/Table/components';
import * as S from './styles';
import studentDataService from '../../../../../services/student/student.data.service';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../ProviderAgent';
import { IGrades } from '../../../../../services/agent/student/interface';

export default function TabGrades({ data }) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  const [coursesData, setCoursesData] = useState<IGrades[]>();
  const situationMap = {
    'Em Andamento': { situacao: 'Em Andamento', color: '#000' },
    Aprovado: { situacao: 'Aprovado', color: '#008000' },
    'Em Recuperação': { situacao: 'Em Recuperação', color: '#D75413' },
    Reprovado: { situacao: 'Reprovado', color: '#F00' },
    Dispensado: { situacao: 'Dispensado', color: '#008000' },
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchGrades = async () => {
      const response = await studentDataService.grades({ login: data });
      if (response.data) {
        setCoursesData(response.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    fetchGrades();
  }, [data]);

  return (
    <div>
      {coursesData?.map((course) => (
        <>
          {course && (
            <div key={course.courseId}>
              <S.ContainerTitle style={{ backgroundColor: '#c7c7c7' }}>
                <TitleTable>{course.courseName}</TitleTable>
              </S.ContainerTitle>
              <TableContainer>
                <S.Table>
                  <Table component={Paper} size="small">
                    <TableHead style={{ backgroundColor: '#e2e2e2' }}>
                      <MyTableRow>
                        <S.TableCellStyle align="left" className="title">
                          <SubtitleTable color="gray">Disciplina</SubtitleTable>
                        </S.TableCellStyle>
                        <MyTableCell align="center" className="grade">
                          <SubtitleTable color="gray">Nota</SubtitleTable>
                        </MyTableCell>

                        <MyTableCell align="center" className="status">
                          <SubtitleTable color="gray">Situação</SubtitleTable>
                        </MyTableCell>
                      </MyTableRow>
                    </TableHead>
                    <TableBody>
                      {course.modules.map(
                        (module) =>
                          module.disciplines?.map((discipline, index) => (
                            <MyTableRow key={index}>
                              <TableCell scope="row" className="title-row">
                                <RowText align="left">
                                  {discipline.disciplineName}
                                </RowText>
                              </TableCell>
                              <TableCell scope="row" className="grade-row">
                                <DivRow>
                                  <RowText align="center">
                                    {discipline.grade}
                                  </RowText>
                                </DivRow>
                              </TableCell>

                              <TableCell>
                                <RowTextBold
                                  color={
                                    situationMap[discipline.situation]?.color
                                  }
                                  align="center"
                                >
                                  {discipline.situation}
                                </RowTextBold>
                              </TableCell>
                            </MyTableRow>
                          )),
                      )}
                    </TableBody>
                  </Table>
                </S.Table>
              </TableContainer>
            </div>
          )}
        </>
      ))}
    </div>
  );
}
