import styled from 'styled-components';

export const Container = styled.div`
  gap: 3rem;
`;

export const Title = styled.h5``;

export const Tcc = styled.div``;

export const Internship = styled.div``;

export const ContainerTable = styled.div`
  padding: 1rem;
`;

export const DivTable = styled.div`
  overflow: auto;
  max-width: 90vw;
`;
