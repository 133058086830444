import React, { useEffect } from 'react';

import { authStudentService } from '../../../../../services/auth';
import { warningToast } from '../../../../common/Toast';

export default function InactivityTimer() {
  useEffect(() => {
    let inactivityTimeout;

    const handleUserActivity = () => {
      clearTimeout(inactivityTimeout);

      inactivityTimeout = setTimeout(() => {
        warningToast(
          'Você está a mais de 1 hora inativo, seu sistema será desconectado em alguns minutos.',
          0,
          {
            hideProgressBar: false,
          },
        );
        setTimeout(() => {
          authStudentService.cleanLoggerUser(true);
        }, 5400000); // Desconecta o usuário após 90 minutos de inatividade.
      }, 4200000); // Alerta após 70 minutos de inatividade.
      document.addEventListener('mousemove', handleUserActivity);
      document.addEventListener('keydown', handleUserActivity);
    };

    handleUserActivity();

    return () => {
      clearTimeout(inactivityTimeout);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  return <></>;
}
