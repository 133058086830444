import React, { useContext, useMemo, useState } from 'react';

import * as S from './styles';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../ProviderNewStudent';
import { sieadImagesFolderUrl } from '../../../variables';
import { myDataServiceNewStudent } from '../../../services/newStudent';
import { TitlePage } from '../Title/TitlePage';
import { handleMaxWidth } from '../../../util/newStudent';
import { useTheme } from 'styled-components';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import Feedback from '../Modal/FeedBackModal';

const logo = `${sieadImagesFolderUrl}white_logo.png`;
const avatarCircle = '/assets/img/circle.png';
const avatarCircleBlue = '/assets/img/circle_blue.png';
const avatar = '/img/newStudent/avatar.png';
const plus = `${sieadImagesFolderUrl}plus.svg`;

interface Props {
  title: string;
  titlePage?: string;
  subtitle?: string;
}

export default function HeaderMinimal({ title, titlePage, subtitle }: Props) {
  const { student, setIsLoading, setStudent } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const [isModalExitOpen, setIsModalExitOpen] = useState(false);
  const [reloadedImage, setReloadedImage] = useState(0);

  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleFileChange = async (file: File) => {
    try {
      setIsLoading(true);
      const { data, result } = await myDataServiceNewStudent.changePhoto(file);
      if (result) {
        const tmpStudent = {
          ...student,
          dataStudent: { ...student?.dataStudent, photo: data },
        };
        setReloadedImage(reloadedImage + 1);
        localStorage.setItem('dataStudent', JSON.stringify(tmpStudent));
        setStudent(tmpStudent);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error.message);
    }
  };

  const renderImage = useMemo(() => {
    const image = student?.dataStudent.photo
      ? student?.dataStudent.photo
      : avatar;

    return reloadedImage % 2 === 1 ? (
      <S.ImgAvatar src={image} />
    ) : (
      <>
        <S.ImgAvatar src={image} />
      </>
    );
  }, [reloadedImage, student?.dataStudent.photo]);

  return (
    <S.Container>
      <S.ContainerMobile>
        <S.MobileButtons>
          <div style={{ width: '3.5rem' }}></div>
          <div onClick={() => navigate('/new/aluno/inicio')}>
            <S.ImgLogo src={logo} alt="logo" width="100px" />
          </div>
          <IconButton
            className="button_icon"
            onClick={() => setIsModalExitOpen(true)}
            sx={{
              color: theme.colors?.backgroundColor,
            }}
          >
            <LogoutIcon fontSize="large" />
          </IconButton>
        </S.MobileButtons>
      </S.ContainerMobile>
      <S.ContainerDesktop>
        <S.ContainerPerfil>
          <div>
            <S.ImgLogo src={logo} alt="logo" className="image_logo" />
          </div>
          <S.ImageContainer>
            <S.PrefilContent>
              {renderImage}
              <S.ImgCircle
                src={
                  handleMaxWidth(theme.size?.medium)
                    ? avatarCircleBlue
                    : avatarCircle
                }
              />
              {pathname === '/new/aluno/conferencia-dados' && (
                <S.InputFilePlus>
                  <span>
                    <S.ImgPlus src={plus} />
                    <input
                      type="file"
                      className="input-file"
                      onChange={({ target }) => {
                        target.files && handleFileChange(target.files[0]);
                      }}
                    />
                  </span>
                </S.InputFilePlus>
              )}
            </S.PrefilContent>
          </S.ImageContainer>
          {pathname.includes('conferencia-dados') ? (
            <S.InputFile className="button_change_perfil">
              <span>Alterar foto</span>
              <input
                type="file"
                className="input-file"
                onChange={({ target }) => {
                  target.files && handleFileChange(target.files[0]);
                }}
              />
            </S.InputFile>
          ) : (
            <div></div>
          )}
        </S.ContainerPerfil>
        <S.TitlePageDiv>
          <TitlePage title={title} titlePage={titlePage} subtitle={subtitle} />
        </S.TitlePageDiv>
      </S.ContainerDesktop>
      <Feedback isOpen={isModalExitOpen} setIsOpen={setIsModalExitOpen} />
    </S.Container>
  );
}
