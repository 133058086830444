import React, { Dispatch, SetStateAction, useContext } from 'react';

import * as S from '../../styles';

import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  contractServiceNewStudent,
  IDataSignedContractObject,
  IDataContracts,
} from '../../../../../services/newStudent/contract/contract.service';
import BulletPoint from '../../../../../components/newStudent/BulletPoint';
import ButtonIcon from '../../../../../components/newStudent/Buttons/ButtonIcon';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';

interface Props {
  item: IDataContracts;
  index: number;
  type: 'contracts' | 'acceptanceTerm';
  dataApi: IDataSignedContractObject;
  setDataApi: Dispatch<SetStateAction<IDataSignedContractObject>>;
}

export default function ContentContract({
  item,
  index,
  type,
  dataApi,
  setDataApi,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const handleOpenPdf = async (
    url?: string | null,
    registrationId?: number,
    courseDocumentTypeId?: number,
    index?: number,
    type?: 'contracts' | 'acceptanceTerm',
  ) => {
    setIsLoading(true);
    try {
      if (url) {
        window.open(url);
      } else {
        const { result, data } = await contractServiceNewStudent.getContractPdf(
          {
            contractId: registrationId ?? null,
            courseDocumentTypeId: courseDocumentTypeId ?? null,
          },
        );
        if (result) {
          window.open(data);

          const tmpData = dataApi;
          tmpData[type!][index!].url = data;
          setDataApi(tmpData);
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.Content key={index} className={'contract-' + item.registrationId}>
      <BulletPoint
        className={'contract-' + item.registrationId}
        buttons={
          <div>
            <ButtonIcon
              className={`${type + item.registrationId}`}
              icon={
                !item.url ? (
                  <RefreshIcon fontSize="large" />
                ) : (
                  <PrintIcon fontSize="large" />
                )
              }
              color={!item.url ? 'primary' : 'success'}
              onClick={() =>
                handleOpenPdf(
                  item.url,
                  item.registrationId,
                  item.courseDocumentTypeId,
                  index,
                  type,
                )
              }
            />
          </div>
        }
        noPoint
      >
        <S.NameWithChevron>
          <S.Name>
            <S.DivItemName>
              <S.H6>{item.courseName[0]}</S.H6>
            </S.DivItemName>
            <S.H6 className="divider"> | </S.H6>
            <S.H6>{item.courseType}</S.H6>
          </S.Name>
        </S.NameWithChevron>
      </BulletPoint>
    </S.Content>
  );
}
