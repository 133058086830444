import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  gap: 1rem;
`;

export const Content = styled.div`
  width: 100%;
`;

export const VideoDiv = styled.div`
  width: 100%;
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;

  text-align: center;
`;

export const PdfDiv = styled.div`
  width: 100%;
`;

export const TitlePdfDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  margin: 0;
`;

export const TitleIconPdfDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.5rem;

  margin: 0;
`;

export const TitlePdfH5 = styled.h4`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const ContentPdfDiv = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  grid-gap: 2rem;
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const TextDivPdf = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2rem;
`;

export const AuthorsDiv = styled.div``;

export const SummaryDiv = styled.div`
  text-align: justify;
`;

export const Img = styled.div`
  width: 100%;
`;

export const Key = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
  line-height: 2rem;
  text-align: justify;
`;
