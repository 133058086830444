import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonsOpen = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  animation: focus 1s;

  @keyframes focus {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 1;
`;
