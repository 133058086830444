import styled from 'styled-components';

export const Container = styled.div``;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;

  padding: 1rem 3rem;
`;

export const H4Title = styled.h4`
  height: 3rem;
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors?.tertiary};
  color: ${({ theme }) => theme.colors?.text.primary};
  border-radius: 4px 4px 0 0;

  width: 100%;
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
  padding: 3rem;
`;

export const Button = styled.div`
  display: flex;
  justify-content: end;

  width: 100%;
`;
