import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 4.5rem;

  padding: 0 1rem;
  border-radius: 10px;
`;
