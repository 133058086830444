import React, { Dispatch, SetStateAction, useState } from 'react';

import * as S from './styles';
import { useLocation, useNavigate } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonitorIcon from '@mui/icons-material/Monitor';

import { ButtonSimple } from '../../../../../Buttons';
import { ButtonStartIcon } from '../../../../../Buttons';

interface Props {
  setIsSidebarOpen?: Dispatch<SetStateAction<boolean>>;
}

export default function SideMenu({ setIsSidebarOpen }: Props) {
  const [studentOpen, setStudentOpen] = useState<boolean>(false);
  const [financialOpen, setFinancialOpen] = useState<boolean>(false);
  const [courseOpen, setCourseOpen] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  return (
    <S.Container>
      <S.HeaderCollapse>
        <ButtonStartIcon
          color="primary"
          heightSize="medium"
          justify={'space-between'}
          startIcon={<HomeIcon fontSize="large" />}
          font="h5"
          onClick={() => {
            setIsSidebarOpen && setIsSidebarOpen(false);
            navigate('/new/aluno/inicio');
          }}
          boxShadow
        >
          INÍCIO
        </ButtonStartIcon>
      </S.HeaderCollapse>
      <S.HeaderCollapse>
        <ButtonStartIcon
          color="primary"
          heightSize="medium"
          endIcon={
            studentOpen ? (
              <RemoveCircleOutlineIcon fontSize="large" />
            ) : (
              <AddCircleOutlineIcon fontSize="large" />
            )
          }
          justify={'space-between'}
          startIcon={<SchoolIcon fontSize="large" />}
          font="h5"
          selected={studentOpen}
          onClick={() => {
            setFinancialOpen(false);
            setCourseOpen(false);
            setStudentOpen(!studentOpen);
          }}
          boxShadow
        >
          ALUNO
        </ButtonStartIcon>
      </S.HeaderCollapse>
      {studentOpen && (
        <S.Collapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/meus-dados')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/meus-dados');
              }}
            >
              Meus Dados
            </ButtonSimple>
          </S.ChildCollapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/documentos')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/documentos');
              }}
            >
              Documentos
            </ButtonSimple>
          </S.ChildCollapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/matricula')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/matricula');
              }}
            >
              Contratos
            </ButtonSimple>
          </S.ChildCollapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/declaracao')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/declaracao');
              }}
            >
              Declarações
            </ButtonSimple>
          </S.ChildCollapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/tickets')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/tickets');
              }}
            >
              Requerimentos
            </ButtonSimple>
          </S.ChildCollapse>
        </S.Collapse>
      )}
      <S.HeaderCollapse>
        <ButtonStartIcon
          color="primary"
          heightSize="medium"
          endIcon={
            financialOpen ? (
              <RemoveCircleOutlineIcon fontSize="large" />
            ) : (
              <AddCircleOutlineIcon fontSize="large" />
            )
          }
          justify={'space-between'}
          startIcon={<AttachMoneyIcon fontSize="large" />}
          font="h5"
          selected={financialOpen}
          onClick={() => {
            setCourseOpen(false);
            setStudentOpen(false);
            setFinancialOpen(!financialOpen);
          }}
          boxShadow
        >
          FINANCEIRO
        </ButtonStartIcon>
      </S.HeaderCollapse>
      {financialOpen && (
        <S.Collapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/historico-financeiro')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/historico-financeiro');
              }}
            >
              Histórico Financeiro
            </ButtonSimple>
          </S.ChildCollapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/ir')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/ir');
              }}
            >
              Informe - Imposto de Renda
            </ButtonSimple>
          </S.ChildCollapse>
        </S.Collapse>
      )}
      <S.HeaderCollapse>
        <ButtonStartIcon
          color="primary"
          heightSize="medium"
          endIcon={
            courseOpen ? (
              <RemoveCircleOutlineIcon fontSize="large" />
            ) : (
              <AddCircleOutlineIcon fontSize="large" />
            )
          }
          justify={'space-between'}
          startIcon={<MonitorIcon fontSize="large" />}
          font="h5"
          selected={courseOpen}
          onClick={() => {
            setFinancialOpen(false);
            setStudentOpen(false);
            setCourseOpen(!courseOpen);
          }}
          boxShadow
        >
          CURSOS
        </ButtonStartIcon>
      </S.HeaderCollapse>
      {courseOpen && (
        <S.Collapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/cursos')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/cursos');
              }}
            >
              Meus Cursos
            </ButtonSimple>
          </S.ChildCollapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/minhas-notas')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/minhas-notas');
              }}
            >
              Minhas Notas
            </ButtonSimple>
          </S.ChildCollapse>
          <S.ChildCollapse>
            <ButtonSimple
              heightSize="medium"
              color="gray"
              selected={location.pathname.includes('/certificados')}
              onClick={() => {
                setIsSidebarOpen && setIsSidebarOpen(false);
                navigate('/new/aluno/certificados');
              }}
            >
              Certificados
            </ButtonSimple>
          </S.ChildCollapse>
        </S.Collapse>
      )}
    </S.Container>
  );
}
