import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';

import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import Divider from '../../../components/common/Divider';

import { AletNotfoundLoading } from '../../../components/newStudent/Alert';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { addDataCache, getDataCache } from '../../../util/common/appUtil';
import { useLocation } from 'react-router-dom';
import { contractServiceNewStudent } from '../../../services/newStudent';
import { IDataSignedContractObject } from '../../../services/newStudent/contract/contract.service';
import ContentContract from './components/ContentContract';

export default function RegistrationNewStudent() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const { pathname } = useLocation();

  const [dataApi, setDataApi] = useState<IDataSignedContractObject>({
    contracts: [],
    acceptanceTerm: [],
  });

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      try {
        const dataContracts = await getDataCache('dataContracts', pathname);
        if (dataContracts) {
          setDataApi(dataContracts);
        } else {
          const { result, data } =
            await contractServiceNewStudent.signedContracts();
          if (result) {
            addDataCache('dataContracts', data, pathname);
            setDataApi(data);
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  return (
    <S.Container>
      <TitlePage
        title="Contratos"
        titlePage="Contratos"
        subtitle={`
        Aqui você tem acesso aos contratos de sua matrícula de cada curso. Faça o download ou imprima o documento.
      `}
      />
      <Divider padding />
      <AletNotfoundLoading
        toRender={
          dataApi.contracts?.length <= 0 && dataApi.acceptanceTerm?.length <= 0
        }
        notFoundText="Nenhum contrato encontrado."
      />

      {dataApi.contracts.length > 0 && (
        <div>
          <S.Title>Contratos</S.Title>
          {dataApi.contracts.map((item, index) => (
            <ContentContract
              key={index}
              item={item}
              dataApi={dataApi}
              index={index}
              setDataApi={setDataApi}
              type="contracts"
            />
          ))}
        </div>
      )}
      {dataApi.acceptanceTerm.length > 0 && (
        <div>
          <Divider padding />
          <S.Title>Termo de Aceite</S.Title>
          {dataApi.acceptanceTerm.map((item, index) => (
            <ContentContract
              key={index}
              item={item}
              dataApi={dataApi}
              index={index}
              setDataApi={setDataApi}
              type="acceptanceTerm"
            />
          ))}
        </div>
      )}
    </S.Container>
  );
}
