import styled from 'styled-components';

interface IRowTextProps {
  color?: string;
  align?: string;
}

export const RowText = styled.b<IRowTextProps>`
  text-align: ${(props) => props.align || 'center'};
  color: ${(props) => props.color};
  font-weight: normal;
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const RowTextBold = styled.p<IRowTextProps>`
  text-align: ${(props) => props.align || 'center'};
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 14px;
`;
