import React, { ReactNode } from 'react';

import { Container } from './styles';

interface IAlertBar {
  message: string | ReactNode;
  color: string;
}

export default function AlertBar({ message, color }: IAlertBar) {
  return (
    <Container>
      <section className={color}>
        <span className="alertTitle">{message}</span>
      </section>
    </Container>
  );
}
