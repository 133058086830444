import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import BasicModal from '../../../../../components/newStudent/Modal/BasicModal';

interface Props {
  name: string;
  company: string;
  ableBackdropClick: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function NegotiationTerm({
  name,
  company,
  isOpen,
  setIsOpen,
  ableBackdropClick,
}: Props) {
  return (
    <S.Container>
      <BasicModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        isFloat
        ableBackdropClick={ableBackdropClick}
      >
        <S.Content>
          <S.Title>TERMOS E CONDIÇÕES DE NEGOCIAÇÃO FINANCEIRA</S.Title>
          <S.Text>
            Ao clicar no botão <b>{'Li e concordo'}</b>, eu, <b>{name}</b>{' '}
            assumo as obrigações financeiras acordadas, e declaro ter negociado
            com a <b>{company}</b> os meus débitos e estou ciente das condições
            de pagamento, incluindo a forma de pagamento, data de vencimento e o
            valor das parcelas.
          </S.Text>
        </S.Content>
      </BasicModal>
    </S.Container>
  );
}
