import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 2rem;
  width: 95%;
`;

export const Content = styled.div`
  width: 50vw;

  @media screen and (max-width: 767px) {
    width: 90vw;
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Error = styled.h6`
  color: red;
`;

export const Number = styled.div`
  padding-right: 1.5rem;
`;

export const DivCheckbox = styled.div`
  display: flex;
  align-items: center;

  margin: 0 1.5rem;
`;

export const ItemPurchase = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Value = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  padding: 2rem 0;
  width: 100%;
`;

export const Line = styled.div`
  padding-top: 1rem;
  width: 60%;
  border-bottom: 1px solid #a5a5a5;
`;

export const Observation = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  width: 100%;
`;

export const H6 = styled.h6`
  font-weight: bold;
`;

export const DivObservation = styled.div`
  text-align: justify;
  width: 100%;
  padding: 0 3rem;
`;
