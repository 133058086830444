import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';

import {
  faCheck,
  faExclamationCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Typography } from '@mui/material';

import poloService from '../../../services/polo/polo.service';
import * as apiService from '../../../services/api/student/api.service';
import {
  errorToast,
  successToast,
  infoToast,
  warningToast,
} from '../../common/Toast';

import {
  convertIntToCpfCnpj,
  convertIntToCep,
  convertIntToTelefone,
  emailIsValid,
  cellPhoneIsValid,
} from '../../../util/pageUtil';
import { Image } from 'semantic-ui-react';
import FieldInformation from './components';
import { IStudent } from '../../../interfaces/student/interfaces';
import { IPolo } from '../../../interfaces/student/aluno.interfaces';
import moment from 'moment';
import {
  IStudentContextProps,
  StudentProviderContext,
} from '../StudentProvider';
import { getPhone } from '../../../util/common/appUtil';
import * as S from './styles';

interface PersonalInformationProps {
  aluno?: IStudent;
  handlePoloAlunoChange: (value: number) => void;
  poloAluno?: number;
  originalPoloAluno?: number;
  setRaca?: Dispatch<SetStateAction<number>>;
  raca?: number;
  handleEmailConfirmed?: (arg0: boolean) => void;
  handleCellphoneNumberConfirmed?: (arg0: boolean) => void;
}

export default function PersonalInformation(props: PersonalInformationProps) {
  const context = useContext(StudentProviderContext);
  const { shouldCheckPersonalInformation } = context as IStudentContextProps;

  const alunoData = localStorage.getItem('alunoStorage');
  const aluno = alunoData && JSON.parse(alunoData);

  const email = aluno?.usuario?.email;
  const phone = getPhone(aluno?.telefone, 'cellPhone');

  const [
    showConfirmationCodeCellphoneInput,
    setShowConfirmationCodeCellphoneInput,
  ] = useState(false);
  const [showConfirmationCodeEmailInput, setShowConfirmationCodeEmailInput] =
    useState(false);
  const [confirmationCodeCellphone, setConfirmationCodeCellphone] =
    useState('');
  const [confirmationCodeEmail, setConfirmationCodeEmail] = useState('');
  const [newEmail, setNewEmail] = useState<string>(email);

  const [newPhone, setNewPhone] = useState<string>(phone);
  const [cellphoneNumberChanged, setCellphoneNumberChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [phoneInChange, setPhoneInChange] = useState(false);
  const [emailInChange, setEmailInChange] = useState(false);
  const [photoInChange, setPhotoInChange] = useState(false);
  const [photo, setPhoto] = useState<string>(aluno?.aluno_link_imagem_perfil);

  const [polos, setPolos] = useState<IPolo[]>([]);
  const [photoFile, setPhotoFile] = useState<File>();

  const handleAskConfirmationCodeCellphone = () => {
    if (!cellPhoneIsValid(newPhone)) {
      warningToast('Digite um número de celular válido.');
      return;
    }
    setPhoneInChange(true);
    infoToast('Enviando código de confirmação...');
    apiService
      .authenticatedRequest(
        '/student/my-data/generate-confirmation-code',
        'POST',
        {
          telefone: newPhone,
        },
      )
      .then((response) => {
        if (response.result) {
          successToast(
            'Código de confirmação enviado para o celular ' +
              convertIntToTelefone(newPhone) +
              '.',
          );
          setShowConfirmationCodeCellphoneInput(true);
        } else {
          warningToast(response.message, 1);
        }
      })
      .catch((error) => {
        error;
        errorToast(
          'Erro ao gerar código de confirmação. Tente novamente mais tarde.',
        );
        setShowConfirmationCodeCellphoneInput(false);
        setPhoneInChange(false);
      });
  };

  const handleSendConfirmationCodeCellphone = () => {
    apiService
      .authenticatedRequest('/student/my-data/confirm-code', 'POST', {
        telefone: newPhone,
        code: confirmationCodeCellphone,
      })
      .then((response) => {
        if (response.result && response.message !== '') {
          successToast('Número de celular alterado.');
          setCellphoneNumberChanged(false);
          props.handleEmailConfirmed && props.handleEmailConfirmed(true);

          /** Aqui troca o valor do número no localstorage */
          const newStorage = JSON.parse(localStorage.getItem('alunoStorage')!);
          const storage = newStorage.telefone.split(';');
          newStorage.telefone = `${storage[0]};${newPhone?.replace(
            /[^a-zA-Z0-9]/g,
            '',
          )};${storage[2]}`;
          localStorage.setItem('alunoStorage', JSON.stringify(newStorage));
        } else {
          warningToast(response.message[0]);
        }
      })
      .catch((error) => {
        error;
        errorToast(
          'Erro ao confirmar o código informado. Tente novamente mais tarde.',
        );
        setShowConfirmationCodeCellphoneInput(false);
      });
  };

  const handleAskConfirmationCodeEmail = () => {
    setEmailInChange(true);
    if (!emailIsValid(newEmail)) {
      warningToast('Digite um e-mail válido.');
      setEmailInChange(false);
      return;
    }
    infoToast('Enviando código de confirmação...');
    apiService
      .authenticatedRequest(
        '/student/my-data/generate-confirmation-code',
        'POST',
        {
          email: newEmail,
        },
      )
      .then((response) => {
        if (response.result) {
          successToast('Código de confirmação enviado para seu e-mail.');
          setShowConfirmationCodeEmailInput(true);
        } else {
          warningToast(response.message[0], 1);
          setEmailInChange(false);
        }
      })
      .catch((error) => {
        error;
        errorToast(
          'Erro ao gerar código e enviar por e-mail, tente novamente mais tarde.',
          1,
        );
        setShowConfirmationCodeEmailInput(false);
        setEmailInChange(false);
      });
  };

  const handleSendConfirmationCodeEmail = () => {
    apiService
      .authenticatedRequest('/student/my-data/confirm-code', 'POST', {
        email: newEmail,
        code: confirmationCodeEmail,
      })
      .then((response) => {
        if (response.result) {
          successToast('E-mail alterado com sucesso.');
          props.handleCellphoneNumberConfirmed &&
            props.handleCellphoneNumberConfirmed(true);

          /** Aqui troca o valor do Email no localstorage */
          const newStorage = JSON.parse(localStorage.getItem('alunoStorage')!);
          newStorage.usuario = { ...newStorage.usuario, email: newEmail };
          localStorage.setItem('alunoStorage', JSON.stringify(newStorage));
          setEmailInChange(true);
          setShowConfirmationCodeEmailInput(false);
        } else {
          setEmailInChange(false);
          warningToast(response.message || 'Erro ao alterar seu E-mail', 1);
        }
      })
      .catch((error) => {
        error;
        errorToast(
          'Erro ao confirmar código de email. Tente novamente mais tarde.',
        );
        setEmailInChange(false);
        setShowConfirmationCodeEmailInput(false);
      });
    setEmailInChange(false);
  };

  const changeLocalStorageImage = (newLink: string) => {
    const newStorage = JSON.parse(localStorage.getItem('alunoStorage')!);
    newStorage.aluno_link_imagem_perfil = newLink;

    localStorage.setItem('alunoStorage', JSON.stringify(newStorage));
  };

  const handleChangePhoto = async () => {
    setPhotoInChange(true);
    if (photoFile) {
      const formData = new FormData();
      formData.append('file', photoFile);

      apiService
        .authenticatedRequest(
          '/student/my-data/update',
          'POST',
          formData,
          'file',
        )
        .then((response) => {
          setPhoto(response.data);
          changeLocalStorageImage(response.data);

          setPhotoInChange(false);
        });
    }
  };

  const handleChangeField = (
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement),
  ) => {
    if (target.name === 'email') {
      setEmailChanged(true);
      setNewEmail(target.value);
    }
    if (target.name === 'telefone1') {
      setCellphoneNumberChanged(true);
      setNewPhone(target.value);
    }
  };

  useEffect(() => {
    if (aluno) {
      if (shouldCheckPersonalInformation()) {
        infoToast(
          'Verifique se seus dados estão corretos e prossiga para a página inicial.',
          5,
        );
      }

      const fetchPolos = async () => {
        const response = await poloService.getAll();
        if (response.result) {
          setPolos(
            response.data.filter((polo) => {
              return polo.nome_polo_mec;
            }),
          );
        }
      };

      aluno.aluno_graduacao && fetchPolos();
    }
  }, []);

  const personalStructure = [
    {
      title: 'Nome Social',
      value: aluno?.nome,
      name: 'nome',
      type: 'text',
      xs: 7,
      sm: 8,
      lg: 10,
    },
    {
      title: 'RG/Identidade',
      value: aluno?.rg,
      name: 'rg',
      type: 'text',
      xs: 6,
      sm: 3,
      lg: 3,
    },
    {
      title: 'CPF',
      value: convertIntToCpfCnpj(aluno?.cpf),
      name: 'cpf',
      type: 'text',
      xs: 6,
      sm: 3,
      lg: 3,
    },
    {
      title: 'Data de Nasc.',
      value: moment(aluno?.data_nascimento).format('DD/MM/YYYY'),
      name: 'data_nascimento',
      type: 'text',
      xs: 5,
      sm: 4,
      lg: 3,
    },
    {
      title: 'Se declara na cor/raça',
      value: props.raca!,
      name: 'data_nascimento',
      disabled: !!props.aluno?.raca,
      type: 'select',
      handleChange: (e) => props.setRaca!(e.value),
      options: [
        { title: '[ Selecione ]', value: 0 },
        { title: 'Branca', value: 1 },
        { title: 'Preta', value: 2 },
        { title: 'Amarela', value: 3 },
        { title: 'Parda', value: 4 },
        { title: 'Indigena', value: 5 },
        { title: 'Outra', value: 6 },
      ],
      xs: 5,
      sm: 4,
      lg: 3,
    },

    {
      title: 'Nome do Pai',
      value: aluno?.pai,
      name: 'pai',
      type: 'text',
      xs: 7,
      sm: 8,
      lg: 6,
    },
    {
      title: 'Nome da Mãe',
      value: aluno?.mae,
      name: 'mae',
      type: 'text',
      xs: 7,
      sm: 8,
      lg: 6,
    },
    {
      title: 'Nacionalidade',
      value: aluno?.nacionalidade,
      name: 'nacionalidade',
      type: 'text',
      xs: 5,
      sm: 4,
      lg: 3,
    },
    {
      title: 'Naturalidade',
      value: aluno?.naturalidade,
      name: 'naturalidade',
      type: 'text',
      xs: 6,
      sm: 4,
      lg: 3,
    },
  ];

  const addressStructure = [
    {
      title: 'Cep',
      value: convertIntToCep(aluno?.endereco?.cep),
      name: 'cep',
      type: 'text',
      xs: 6,
      sm: 2,
      lg: 2,
    },
    {
      title: 'Logradouro',
      value: aluno?.endereco?.logradouro,
      name: 'logradouro',
      type: 'text',
      xs: 12,
      sm: 6,
      lg: 4,
    },
    {
      title: 'Número',
      value: aluno?.endereco?.numero,
      name: 'numero',
      type: 'text',
      xs: 3,
      sm: 2,
      lg: 1,
    },
    {
      title: 'Complemento',
      value: aluno?.endereco?.complemento,
      name: 'complemento',
      type: 'text',
      xs: 9,
      sm: 3,
      lg: 2,
    },
    {
      title: 'Bairro',
      value: aluno?.endereco?.bairro,
      name: 'bairro',
      type: 'text',
      xs: 5,
      sm: 7,
      lg: 4,
    },
    {
      title: 'Cidade',
      value: aluno?.endereco?.cidade,
      name: 'cidade',
      type: 'text',
      xs: 7,
      sm: 10,
      lg: 5,
    },
    {
      title: 'UF',
      value: aluno?.endereco?.estado,
      name: 'estado',
      type: 'text',
      xs: 12,
      sm: 2,
      lg: 1,
    },
  ];

  return (
    <Form>
      <fieldset style={{ paddingTop: 15 }}>
        <Row>
          <Image src={photo} size="tiny" />
          <Col xs={12} sm={6} lg={6} style={{ marginTop: 10 }}>
            <Form.Group>
              <Form.Label>Foto</Form.Label>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  if (e.target.files) {
                    setPhotoFile(e.target.files[0]);
                  }
                }}
              />
            </Form.Group>
          </Col>
          <S.ContainerButton
            xs={12}
            sm={4}
            lg={4}
            style={{
              marginBottom: 5,
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <button
              className="btn btn-primary mw-100"
              type="button"
              /** Colocar aqui a lógica para mandar a foto do cara */
              onClick={handleChangePhoto}
              disabled={photoInChange}
            >
              Enviar
            </button>
          </S.ContainerButton>
          {personalStructure.map((item, index) => (
            <FieldInformation
              key={index}
              title={item.title}
              value={item.value}
              onChange={(e) => item.handleChange!(e.target)}
              disabled={item.disabled}
              type={item.type}
              name={item.name}
              options={item.options}
              sm={item.sm}
              lg={item.lg}
              xs={item.xs}
            />
          ))}
          <div></div>
          {addressStructure.map((item, index) => (
            <FieldInformation
              key={index}
              title={item.title}
              value={item.value}
              type={item.type}
              name={item.name}
              sm={item.sm}
              lg={item.lg}
              xs={item.xs}
            />
          ))}
          <div></div>
          <Col
            xs={6}
            sm={3}
            lg={2}
            style={{ padding: '1rem 1rem 0 1rem', minWidth: '155px' }}
          >
            <Form.Group>
              <Form.Label>Telefone Celular</Form.Label>
              <Form.Label className="input">
                <InputMask
                  placeholder="(00) 00000-0000"
                  mask="(99) 99999-9999"
                  name="telefone1"
                  disabled={phoneInChange}
                  type="string"
                  onChange={(e) => handleChangeField(e.target)}
                  value={convertIntToTelefone(newPhone)}
                />
              </Form.Label>
            </Form.Group>
          </Col>
          {cellphoneNumberChanged && (
            <>
              {showConfirmationCodeCellphoneInput ? (
                <>
                  <Col
                    xs={6}
                    sm={4}
                    lg={2}
                    style={{
                      marginBottom: 0,
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <label>
                      Confirme o Código{' '}
                      <Tooltip
                        title={
                          <Typography fontSize={12}>
                            Digite aqui o código enviado via SMS para o número
                            informado.
                          </Typography>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      </Tooltip>
                    </label>
                    <label className="input-group">
                      <input
                        className="form-control"
                        name="confirmationCodeCellphone"
                        type="text"
                        maxLength={6}
                        onChange={(e) =>
                          setConfirmationCodeCellphone(e.target.value)
                        }
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={handleSendConfirmationCodeCellphone}
                        >
                          <FontAwesomeIcon icon={faCheck} size="lg" />
                        </button>
                      </div>
                    </label>
                  </Col>
                </>
              ) : (
                <Col
                  xs={6}
                  sm={3}
                  lg={2}
                  style={{
                    marginBottom: 5,
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-success mw-100"
                    onClick={handleAskConfirmationCodeCellphone}
                  >
                    Alterar Celular
                  </button>
                </Col>
              )}
            </>
          )}
          <Col
            xs={6}
            sm={4}
            lg={2}
            style={{ padding: '1rem 1rem 0 1rem', minWidth: '155px' }}
          >
            <Form.Group>
              <Form.Label>Telefone Residencial</Form.Label>
              <Form.Label className="input">
                <Form.Control
                  readOnly
                  disabled
                  style={{ backgroundColor: 'white' }}
                  name="telefone0"
                  type="text"
                  maxLength={15}
                  value={convertIntToTelefone(
                    getPhone(aluno?.telefone, 'homePhone') || '',
                  )}
                />
              </Form.Label>
            </Form.Group>
          </Col>
          <Col
            xs={6}
            sm={4}
            lg={2}
            style={{
              padding: '1rem 1rem 0 1rem',
              minWidth: '155px',
            }}
          >
            <Form.Group>
              <Form.Label>Telefone Comercial</Form.Label>
              <Form.Control
                readOnly
                disabled
                style={{ backgroundColor: 'white' }}
                name="telefone2"
                type="text"
                maxLength={15}
                value={convertIntToTelefone(
                  getPhone(aluno?.telefone, 'businessPhone') || '',
                )}
              />
            </Form.Group>
          </Col>
          <div></div>
          <Col
            xs={6}
            sm={6}
            lg={5}
            style={{ padding: '1rem 1rem 0 1rem', minWidth: '155px' }}
          >
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Label className="input" style={{ minWidth: '100%' }}>
                <Form.Control
                  name="email"
                  type="text"
                  disabled={emailInChange}
                  placeholder="Email"
                  onChange={(e) => handleChangeField(e.target)}
                  value={newEmail}
                />
              </Form.Label>
            </Form.Group>
          </Col>
          {emailChanged && (
            <>
              {showConfirmationCodeEmailInput ? (
                <>
                  <Col
                    xs={6}
                    sm={3}
                    lg={3}
                    style={{
                      marginBottom: 0,
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <label>
                      Confirme o Código{' '}
                      <Tooltip
                        title={
                          <Typography fontSize={12}>
                            Digite aqui o código enviado no seu e-mail.
                          </Typography>
                        }
                      >
                        <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
                      </Tooltip>
                    </label>
                    <label className="input-group">
                      <input
                        className="form-control"
                        name="confirmationCodeEmail"
                        type="text"
                        maxLength={6}
                        onChange={(e) =>
                          setConfirmationCodeEmail(e.target.value)
                        }
                      />
                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ borderBottom: 1, marginBottom: 1 }}
                          onClick={handleSendConfirmationCodeEmail}
                        >
                          <FontAwesomeIcon icon={faCheck} size="lg" />
                        </button>
                      </div>
                    </label>
                  </Col>
                </>
              ) : (
                <Col
                  xs={6}
                  sm={3}
                  lg={3}
                  style={{
                    marginBottom: 5,
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-success mw-100"
                    onClick={handleAskConfirmationCodeEmail}
                  >
                    Confirmar e-mail
                  </button>
                </Col>
              )}
            </>
          )}
          {aluno?.aluno_graduacao && (
            <Col xs={12} sm={6} lg={4} style={{ marginTop: 10 }}>
              <Form.Group>
                <Form.Label>POLO</Form.Label>
                <Form.Control
                  as="select"
                  name="polo"
                  onChange={(e) => {
                    props.handlePoloAlunoChange(+e.target.value);
                  }}
                  value={
                    props.poloAluno ? props.poloAluno : props.originalPoloAluno
                  }
                >
                  <option value="">Selecione um polo</option>
                  {polos.map((polo) => (
                    <option key={polo.id} value={polo.id}>
                      {polo.nome_polo_mec}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          <Col
            xs={12}
            sm={12}
            lg={12}
            style={{ marginTop: 20, paddingBottom: '3rem' }}
          >
            <h5 style={{ color: '#001B4F' }}>
              <FontAwesomeIcon icon={faExclamationCircle} /> Caso algum dado
              esteja incorreto, entre em contato com a gente através do chat de
              atendimento no canto inferior direito.
            </h5>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}
