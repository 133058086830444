import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import { ButtonSimple } from '../../../../../../../components/newStudent/Buttons';
import OldFiles from '../OldFiles';
import Input from '../Input';
import { IDocumentFiles } from '../../../..';
import { FileItem, NamedItem } from '../..';
import Divider from '../../../../../../../components/common/Divider';

interface Props {
  status: 'danger' | 'primary' | 'success';
  statusDescription: string;
  title: string;
  documentType: number;
  oldFiles: IDocumentFiles[] | [];
  setOldFiles: Dispatch<SetStateAction<IDocumentFiles[] | []>>;
  selectedFiles: FileItem[] | NamedItem[];
  setSelectedFiles: Dispatch<SetStateAction<FileItem[] | NamedItem[]>>;
  textToOpen: number | undefined;
  setWasChanged: Dispatch<SetStateAction<boolean>>;
  setTextToOpen: Dispatch<SetStateAction<number | undefined>>;
}
export default function DocumentsLayoutMobile({
  status,
  statusDescription,
  title,
  documentType,
  oldFiles,
  setOldFiles,
  selectedFiles,
  setSelectedFiles,
  textToOpen,
  setTextToOpen,
  setWasChanged,
}: Props) {
  return (
    <S.ContentFocus>
      <S.DivTitle>
        <S.H5>{title}</S.H5>
        <ButtonSimple color={status} noButton outline>
          {statusDescription}
        </ButtonSimple>
        <Divider padding />
      </S.DivTitle>
      <S.StyledForm>
        {status !== 'success' &&
          selectedFiles.map((item, index) => (
            <S.InputFocus key={index}>
              <div style={{ width: '100%' }}>
                <Input
                  index={index}
                  item={item}
                  documentType={documentType}
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                  setWasChanged={setWasChanged}
                />
              </div>
              <S.Divider />
            </S.InputFocus>
          ))}
        {oldFiles &&
          oldFiles.map((item, index) => (
            <S.InputFocus key={index}>
              <div style={{ width: '100%' }}>
                <OldFiles
                  item={item}
                  index={index}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  documentType={documentType}
                  oldFiles={oldFiles}
                  setOldFiles={setOldFiles}
                  setWasChanged={setWasChanged}
                  success={status === 'success'}
                />
              </div>
              {item.observation && (
                <S.ObservationDiv
                  className={textToOpen === item.id ? 'isOpen' : ''}
                >
                  <S.ObservationInfo>
                    <S.ObservationText>{item.observation}</S.ObservationText>
                    <ButtonSimple
                      color="primary"
                      heightSize="small"
                      widthSize="xxsmall"
                      onClick={() =>
                        setTextToOpen(
                          textToOpen === item.id ? undefined : item.id,
                        )
                      }
                    >
                      {textToOpen !== item.id ? 'Ler' : 'Fechar'}
                    </ButtonSimple>
                  </S.ObservationInfo>
                </S.ObservationDiv>
              )}
              <S.Divider />
            </S.InputFocus>
          ))}
      </S.StyledForm>
    </S.ContentFocus>
  );
}
