import moment, { Moment } from 'moment';

moment.locale('pt-br');
moment.updateLocale('pt-br', {
  relativeTime: {
    future: 'em %s',
    past: '%s atrás',
    s: 'segundos',
    ss: '%d segundos',
    m: 'um minuto',
    mm: '%d minutos',
    h: 'uma hora',
    hh: '%d horas',
    d: 'um dia',
    dd: '%d dias',
    M: 'um mês',
    MM: '%d meses',
    y: 'um ano',
    yy: '%d anos',
  },
});

export function isNullOrEmpty(string: string | null | undefined) {
  return string === null || string === '' || string === undefined;
}

export function expiredTicket(string: string, format = 'YYYY-MM-DD') {
  return calculateDifferenceDays(convertStringToMoment(string, format)) > 0;
}

export function calculateDaysToAccess(expirationDate) {
  const daysUntilExpiration = calculateDifferenceDays(
    convertStringToMoment(expirationDate),
  );

  if (daysUntilExpiration === -1) {
    return 'amanhã.';
  } else if (daysUntilExpiration === 0) {
    return 'hoje.';
  } else {
    return `no dia ${expirationDate}`;
  }
}

export function convertStringToDate(
  dateString: string,
  initialFormat = 'DD/MM/YYYY',
  finalFormat = 'YYYY-MM-DD HH:mm:ss',
) {
  return moment(dateString, initialFormat).format(finalFormat);
}

export function convertStringToDateTime(
  dateTimeString: string,
  initialFormat = 'DD/MM/YYYY HH:mm:ss',
  finalFormat = 'YYYY-MM-DD HH:mm:ss',
) {
  return moment(dateTimeString, initialFormat).format(finalFormat);
}

export function convertDateTimeToMoment(
  dateTimeString: string,
  initialFormat = 'DD/MM/YYYY HH:mm:ss',
) {
  return moment(dateTimeString, initialFormat);
}

export function convertStringToMoment(
  dateString: string | Moment,
  initialFormat = 'DD/MM/YYYY',
) {
  return moment(dateString, initialFormat);
}

export function convertStringToDays(
  dateString: string,
  initialFormat = 'DD/MM/YYYY',
) {
  return moment(dateString, initialFormat).day();
}

export function differenceInDays(
  dateString1: string | Moment,
  dateString2: string | Moment,
  initialFormat = 'DD/MM/YYYY',
) {
  return moment(dateString1, initialFormat)
    .startOf('day')
    .diff(moment(dateString2, initialFormat).startOf('day'), 'days');
}

export function calculateDifferenceDays(
  dateString: string | Moment,
  dateType: 'day' | 'week' | 'month' = 'day',
) {
  return moment().startOf('day').diff(dateString, dateType);
}

export function convertIntToCpfCnpj(cpfCnpj: string | undefined) {
  if (cpfCnpj?.length === 11)
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  else if (cpfCnpj?.length === 14)
    return cpfCnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );

  return cpfCnpj;
}

export function convertIntToCep(cep: string | undefined) {
  return cep?.replace(/(\d{5})(\d{3})/, '$1-$2');
}

export function convertIntToTelefone(telefone: string | undefined) {
  return telefone?.length === 11
    ? telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    : telefone?.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
}

export function toLocaleString(value: number | undefined) {
  if (value === undefined || value === null) {
    return '0,00';
  }
  return parseFloat(value.toString()).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

export function emailIsValid(email: string | null | undefined) {
  if (email) {
    return (
      email !== '' &&
      email !== null &&
      /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,25}([.][a-z]{2,5})?[.][a-z]{2,5}/g.test(
        email,
      )
    );
  }
}

export function cpfIsValid(cpf: string) {
  const cpfClean = cpf.replace(/[^\d]/g, '');

  if (cpfClean.length !== 11 || /^(\d)\1{10}$/.test(cpfClean)) {
    return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpfClean.substring(i - 1, i)) * (11 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpfClean.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpfClean.substring(i - 1, i)) * (12 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpfClean.substring(10, 11))) {
    return false;
  }

  return true;
}

export function cellPhoneIsValid(cellphoneNumber: string | null | undefined) {
  if (cellphoneNumber) {
    return (
      cellphoneNumber !== '' &&
      cellphoneNumber !== null &&
      /\((\d{2})\) (\d{5})-(\d{4})/.test(cellphoneNumber)
    );
  }
}

export const loadImageAsBase64 = (imageUrl: string) => {
  return fetch(imageUrl)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return new Promise<string | undefined>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result as string;
          resolve(base64Image);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const getEtag = (linkUrl: string) => {
  const storageUrl = localStorage.getItem(linkUrl);
  if (storageUrl) {
    const storage = JSON.parse(storageUrl);
    const currentHour = new Date().getTime();

    const expirationCalc = currentHour > storage.expirationTime;
    if (expirationCalc) {
      localStorage.removeItem(linkUrl);
    } else {
      return storage.etag;
    }
  }
  return null;
};
