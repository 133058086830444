import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';

import { IDocumentsDegree } from '../../../../';
import DegreeInput from './components/DegreeInput';

interface Props {
  open: number | undefined;
  courseId: number | undefined;
  newData: IDocumentsDegree[] | [];
  setNewData: Dispatch<SetStateAction<IDocumentsDegree[] | []>>;
  setOldData: Dispatch<SetStateAction<IDocumentsDegree[] | []>>;
  oldData: IDocumentsDegree[] | [];
  documentDegreeType: number;
  registrationId: number;
  universityDegreeId: number;
}
export default function DocumentDegree({
  oldData,
  open,
  courseId,
  setNewData,
  newData,
  documentDegreeType,
  registrationId,
  universityDegreeId,
  setOldData,
}: Props) {
  const dataTitle = [
    <h5 style={{ fontWeight: 'bold' }} key={1}>
      Data Início
    </h5>,

    <h5 style={{ fontWeight: 'bold' }} key={2}>
      Data Fim
    </h5>,

    <h5 style={{ fontWeight: 'bold' }} key={3}>
      Carga Horária
    </h5>,

    <h5 style={{ fontWeight: 'bold' }} key={4}>
      Nome do Arquivo
    </h5>,
  ];

  return (
    <S.Container>
      {open === courseId && (
        <S.ContainerFocus>
          <S.StyledTable>
            <thead>
              <S.Row>
                {dataTitle.map((item, index) => (
                  <S.Th key={index} align="center">
                    {item}
                  </S.Th>
                ))}
              </S.Row>
            </thead>
            <tbody>
              {newData.map((item: IDocumentsDegree, index: number) => (
                <DegreeInput
                  key={index}
                  index={index}
                  item={item}
                  registrationId={registrationId}
                  universityDegreeId={universityDegreeId}
                  setNewData={setNewData}
                  newData={newData}
                  documentDegreeType={documentDegreeType}
                />
              ))}
              {oldData.map((item: IDocumentsDegree, index: number) => (
                <DegreeInput
                  key={index}
                  index={index}
                  item={item}
                  registrationId={registrationId}
                  universityDegreeId={universityDegreeId}
                  isOld
                  setOldData={setOldData}
                  oldData={oldData}
                  documentDegreeType={documentDegreeType}
                />
              ))}
            </tbody>
          </S.StyledTable>
        </S.ContainerFocus>
      )}
    </S.Container>
  );
}
