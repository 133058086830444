import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface Props {
  color?: string;
  value?: string | number;
}
export default function ChartRadialBar({ color, value }: Props) {
  const options = {
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: '22px',
            fontWeight: 'bold',
            color: color,
            offsetY: 10,
          },
        },
      },
    },
    labels: ['Progress'],
    colors: [color],
  };

  const series = [Number(value)];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height={200}
      width={150}
    />
  );
}
