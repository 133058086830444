import React, { useContext, useEffect, useState } from 'react';

import HeaderPages from '../../../components/student/HeaderPages';

import financialService from '../../../services/financial/financial.service';
import { AccordionDeclaration } from '../../../components/student/Accordions';
import * as S from './styles';

import {
  IStudentContextProps,
  StudentProviderContext,
} from '../../../components/student/StudentProvider';
import { AlertBar } from '../../../components/student/Alerts';
import ModalScroll from '../../../components/common/Modal/ModalScroll';

export default function IncomeTax() {
  const [open, setOpen] = useState(false);
  const [yearsReleases, setYearsReleases] = useState<string[]>([]);
  const [incomeTax, setDeclaration] = useState<string[]>([]);

  const context = useContext(StudentProviderContext);
  const { setIsLoading } = context as IStudentContextProps;

  async function getIncomeTax(year: string) {
    setIsLoading(false);
    setDeclaration([]);
    const data = {
      ano: year,
    };

    if (data) {
      const response = await financialService.incomeTaxDeclaration(data);
      if (response.data) {
        setDeclaration(response.data);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await financialService.incomeTax();
      if (response.result) {
        return setYearsReleases(response.data);
      }
    };

    fetchData();
  }, []);

  const handleYearClick = (year: string) => {
    setOpen(true);
    getIncomeTax(year);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <HeaderPages title="Informe - Imposto de Renda" isNotMain={true} />

      <S.Alert>
        <AlertBar
          color={'default aviso'}
          message={
            <p>
              Declarações apresentadas apenas para os anos que possuem
              pagamentos.
            </p>
          }
        />
      </S.Alert>
      {yearsReleases.length > 0 && (
        <S.Container>
          <AccordionDeclaration
            title={'Selecione o ano desejado'}
            year={yearsReleases.map((year) => (
              <ModalScroll
                key={year}
                title={'DECLARAÇÃO DE QUITAÇÃO ANUAL DE DÉBITOS'}
                titleSelect={year}
                incomeTax={incomeTax}
                handleOpen={() => handleYearClick(year)}
                handleClose={() => handleClose()}
                open={open}
              />
            ))}
          />
        </S.Container>
      )}
    </>
  );
}
