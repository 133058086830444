import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 2rem 4rem 3rem;

  box-shadow: 0px 3px 6px #00000029;

  border-radius: 1rem;
  gap: 1.5rem;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    padding: 2rem;
  }
`;
