import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  padding: 1rem 2rem;
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    padding: 1rem;
  }
`;

export const H4Title = styled.h4`
  height: 3rem;
`;

export const H6Title = styled.h6``;

export const H4 = styled.h4`
  height: 3rem;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

interface IHeader {
  completed?: boolean;
}
export const Header = styled.div<IHeader>`
  background-color: ${(props) =>
    props.theme.colors?.[props.completed ? 'aqua' : 'danger']};
  color: ${({ theme }) => theme.colors?.backgroundColor};
  border-radius: 4px 4px 0 0;
  height: 10rem;
  width: 100%;
`;

export const DivIconContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 22rem;
  overflow: auto;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
  }
`;
export const Chart = styled.div`
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: flex;
    justify-content: center;
  }
`;

export const Key = styled.h6`
  color: ${(props) => props.theme.colors?.text.primary};
  font-weight: bold;
`;

export const Value = styled.h6`
  max-width: 23rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors?.text.secondary};
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 1rem;
  gap: 0.5rem;
`;

export const KeyValue = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 2.5rem;
`;

export const KeyValueCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: center;
`;

export const Card = styled.div`
  flex: 1;
  min-width: 40rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    min-width: 90vw;
    width: 90vw;
  }
`;

interface IContentChart {
  completed: boolean;
}
export const ContentChart = styled.div<IContentChart>`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  color: ${(props) =>
    props.theme.colors?.[props.completed ? 'aqua' : 'danger']};
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
