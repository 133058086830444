import React, { ReactNode } from 'react';

import { Paper } from '@mui/material';

import * as S from './styles';

export interface INoticeRows {
  subtitle?: string;
  text?: string | string[] | ReactNode;
}

export interface INoticeProps {
  title?: string;
  rows?: INoticeRows[];
  noBorder?: boolean;
  split?: number;
}

export default function Notice({
  rows,
  title,
  noBorder = false,
  split = 1,
}: INoticeProps) {
  const columnCount = Math.max(1, split);

  const columns = Array.from({ length: columnCount }, (_, columnIndex) => {
    if (!rows) {
      return [];
    }
    const columnSize = Math.ceil(rows.length / columnCount);
    const startIndex = columnIndex * columnSize;
    const endIndex = startIndex + columnSize;
    return rows.slice(startIndex, endIndex);
  });

  return (
    <>
      <Paper
        elevation={noBorder ? 0 : 1}
        sx={{ margin: noBorder ? '10px 0 0' : '10px 0' }}
      >
        <S.ContentNotice style={{ padding: noBorder ? '0' : '2rem' }}>
          {title && <S.TitleNotice>{title ? title : ''}</S.TitleNotice>}
          <div
            className="columns"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            {columns.map((column, columnIndex) => (
              <div
                key={columnIndex}
                className="column"
                style={{ marginRight: '2rem' }}
              >
                {column.map((row, key) => (
                  <div key={key}>
                    <S.TextNotice>
                      <b>{row.subtitle}</b>
                      {row.text}
                    </S.TextNotice>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </S.ContentNotice>
      </Paper>
    </>
  );
}
