import styled from 'styled-components';

export const Container = styled.div`
  user-select: none;
`;

export const ContentDiv = styled.div``;

export const TitleName = styled.h6`
  font-weight: bold;
  cursor: pointer;
`;

export const DitTitle = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const DivSubtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 1rem;

  animation: open 1s;

  @keyframes open {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 1;
  height: 100%;
`;

export const DivSubtitleSeparated = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

interface ISubtitle {
  completed?: boolean;
  isApproved?: boolean;
}
export const Subtitle = styled.h6<ISubtitle>`
  cursor: ${(props) => (props.isApproved ? 'default' : 'pointer')};
  color: ${(props) =>
    props.completed
      ? props.theme.colors?.text.secondary
      : props.theme.colors?.text.primary};
`;

export const Separated = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};

  @media screen and (min-width: ${(props) => props.theme.size?.large}) {
    &:before {
      content: '|  ';
    }
  }
`;
