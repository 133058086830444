import * as apiService from '../api/student/api.service';

const statementService = {
  async statementShow() {
    const response = await apiService.authenticatedRequest(
      `/student/statements/show`,
      'GET',
    );

    return response;
  },
};

export default statementService;
