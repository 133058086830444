import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './styles';

import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import Divider from '../../../components/common/Divider';
import UniversityDegree from './components/UniversityDegree';
import InputFile from './components/InputFile';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { addDataCache, getDataCache } from '../../../util/common/appUtil';

import { documentServiceNewStudent } from '../../../services/newStudent';
import { AlertBar } from '../../../components/newStudent/Alert';

export interface IDocumentFiles {
  id: number;
  name: string;
  date: string;
  status: string;
  observation: string;
  url: string;
}
export interface IDocuments {
  id: number;
  name: string;
  status: string;
  analysis: IDocumentFiles[] | [];
}

export interface IDocumentsDegree {
  id: string | number;
  name: string;
  startDate: string;
  endDate: string;
  workload: string | number;
  url: string;
  checked?: boolean;
  file?: File;
}
export interface IUniversityDegree {
  courseId: number;
  courseName: string;
  registrationId: number;
  universityDegreeId: number;
  internship: IDocumentsDegree[] | [];
  complementaryActivity: IDocumentsDegree[] | [];
}
interface IData {
  documents: IDocuments[] | [];
  universityDegree: IUniversityDegree[] | [];
}

export default function DocumentsNewStudent() {
  const [data, setData] = useState<IData>();
  const [documentOpen, setDocumentOpen] = useState();

  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const location = useLocation();

  const subtitle = () => {
    return (
      <div>
        <S.H6b>Documentos Rejeitados: </S.H6b>
        <S.H6>
          {`
          Caso algum documento esteja com o status 
          'Rejeitado', para visualizar basta clicar em 'Abrir' e verificar o motivo.
        `}
        </S.H6>
        <S.H6b>Importante: </S.H6b>
        <S.H6>
          {`
        Certidão de Nascimento/Casamento deverá ser a atualizada,
        pois será o documento de maior relevância para a emissão do Certificado.
        `}
        </S.H6>
        <S.H6b>Observação: </S.H6b>
        <S.H6>
          {`
         Os documentos enviados deverão estar no formato pdf.
         `}
        </S.H6>
        <S.H6b>Lembrando que: </S.H6b>
        <S.H6>
          {`
         O Certificado será emitido conforme a documentação
        enviada. Em caso de quaisquer alterações de dados após a emissão, será
        cobrada uma taxa para a correção do certificado.
         `}
        </S.H6>
      </div>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      const dataDocuments = await getDataCache(
        'dataDocuments',
        `${location.pathname}`,
      );
      if (dataDocuments) {
        setData(dataDocuments);
      } else {
        const { data, result } = await documentServiceNewStudent.getAll();

        if (result) {
          addDataCache('dataDocuments', data, location.pathname);
          setData(data);
        }
      }
      setIsLoading(false);
    };
    fetch();
  }, []);

  return (
    <S.Container>
      <TitlePage
        titlePage="Documentos"
        title="Documentos"
        subtitle={subtitle()}
      />
      <Divider padding />
      <AlertBar
        color="tertiary"
        text="O prazo de análise é de 7 a 20 dias úteis"
      />
      {data &&
        data.documents!.map((item: IDocuments, index: number) => (
          <InputFile
            documentType={item.id}
            index={index}
            key={index}
            data={item}
            open={documentOpen}
            setOpen={setDocumentOpen}
          />
        ))}
      {data?.universityDegree &&
        data?.universityDegree.map((item: IUniversityDegree, index: number) => (
          <UniversityDegree key={index} data={item} />
        ))}
    </S.Container>
  );
}
