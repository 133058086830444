import styled from 'styled-components';

interface ICards {
  hasOnlyOne: boolean;
}
export const Container = styled.div<ICards>`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: ${(props) => (props.hasOnlyOne ? '1fr' : '1fr 1fr')};
  gap: 2rem;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const P = styled.p`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Content = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    padding: 2rem 0;
  }
`;

export const ButtonContent = styled.div`
  width: 12rem;
`;
