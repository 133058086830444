import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div``;

export const Field = styled(Form)`
  display: flex;
  gap: 2rem;
  width: 100%;
`;
