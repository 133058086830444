import React, { ReactNode } from 'react';

import * as S from './styles';
import { useTheme } from 'styled-components';

interface Props {
  color: 'danger' | 'warning' | 'tertiary' | 'white' | 'aqua';
  children: ReactNode;
  style?: object;
  onClick?: () => void;
}

export default function AlertBarSimple({
  color,
  children,
  style,
  onClick,
}: Props) {
  const theme = useTheme();

  const colorSelect = {
    danger: {
      backgroundColor: theme.colors?.danger,
      color: theme.colors?.backgroundColor,
    },
    aqua: {
      backgroundColor: theme.colors?.aqua,
      color: theme.colors?.backgroundColor,
    },
    warning: {
      backgroundColor: theme.colors?.warning,
      color: theme.colors?.backgroundColor,
    },
    tertiary: {
      backgroundColor: theme.colors?.tertiary,
      color: theme.colors?.text.primary,
    },
    white: {
      backgroundColor: theme.colors?.gray01,
      color: theme.colors?.text.primary,
    },
  };
  return (
    <S.Container
      style={{ ...colorSelect[color], ...style }}
      onClick={onClick && onClick}
    >
      {children}
    </S.Container>
  );
}
