import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div > span {
    letter-spacing: -1px;
    font-size: 24px;
    font-weight: 300;
    padding: 12px 0 0;
  }

  .back {
    display: flex;
    color: #3276b1;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
  }
  svg {
    font-size: 1.5rem;
    padding-bottom: 0.2rem;
  }
  .divider {
    padding-top: 15px;
  }
`;
