import { TableCell } from '@mui/material';
import styled from 'styled-components';

export const SubtitleTable = styled.b`
  color: gray;
  font-size: 14px;
`;

export const TableCellStyle = styled(TableCell)`
  width: 100%;
  max-width: 80%;
  border: 1px solid;
`;

export const ContainerTitle = styled.div`
  margin-top: 4rem;
  padding: 1rem 1rem 1rem 16px;
`;

export const ContainerSubTitle = styled.div``;

export const Table = styled.div`
  border-bottom: 1px solid #fff;
  border-radius: 4px;
`;

export const SituationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 2.5rem;
  width: 120px;
`;
