import styled from 'styled-components';

export const Container = styled.div``;

export const Fieldset = styled.fieldset`
  padding: 0;
  margin: 2rem;
`;

export const Contents = styled.div`
  border: 1px solid #e5e5e5;
`;
