import styled from 'styled-components';

export const Container = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: 4px;
  color: black;
  font-weight: 700;

  svg {
    font-size: 2.5rem !important;
    margin-right: 0.4rem;
  }

  span.default {
    color: ${({ theme }) => theme.color.default.main};
  }
  span.primary {
    color: ${({ theme }) => theme.color.primary.main};
  }
  span.success {
    color: ${({ theme }) => theme.color.success.main};
  }
  span.info {
    color: ${({ theme }) => theme.color.info.main};
  }
  span.warning {
    color: ${({ theme }) => theme.color.warning.main};
    border-color: ${({ theme }) => theme.color.warning.main};
  }
  span.danger {
    color: ${({ theme }) => theme.color.danger.main};
  }

  :hover:not([disabled]) {
    background-color: #ccc;
    opacity: 0.8;
  }

  :disabled {
    opacity: 0.7;
    color: ${({ theme }) => theme.color.default.main};
  }
`;
