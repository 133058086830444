import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr;

  gap: 2rem;
  @media screen and (max-width: ${(props) => props.theme.size?.xsmall}) {
    display: flex;
    flex-direction: column;

    gap: 0;
  }
`;

export const ImageContainer = styled.div`
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    .button_change_perfil {
      display: none;
    }
  }
`;

export const PrefilContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 17rem;

  @media screen and (max-width: ${(props) => props.theme.size?.xsmall}) {
    height: 10rem;
  }
`;

export const ImgAvatar = styled.img`
  position: absolute;

  border-radius: 50%;
  z-index: 1;
  width: 14rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 8rem;
  }
`;

export const ImgCircle = styled.img`
  position: absolute;
  z-index: 0;
  width: 15rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 9rem;
  }
`;

export const ImgPlus = styled.img`
  display: none;
  position: relative;
  z-index: 1;
  width: 20px;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: flex;
    bottom: -3rem;
    right: -35px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;

  padding-top: 5rem;

  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.xsmall}) {
    padding-top: 1rem;
  }
`;

export const Password = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  gap: 3rem;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;

  gap: 2rem;
`;

export const P = styled.p`
  font-size: 16px;
`;

export const B = styled.b`
  font-size: 14px;
`;

export const H3 = styled.h3``;

export const Polo = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 1rem;
`;

export const InputFilePlus = styled.label`
  input[type='file'] {
    display: none;
  }
`;

export const InputFile = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors?.primary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors?.backgroundColor};
  cursor: pointer;
  margin-top: 0.5rem;

  max-width: 20rem;
  width: 100%;
  height: 3rem;

  input[type='file'] {
    display: none;
  }
`;
