import React, {
  useState,
  useEffect,
  SetStateAction,
  createContext,
  Dispatch,
} from 'react';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { IBanner, IStudent } from '../../../interfaces/student/interfaces';
import { errorToast, successToast } from '../../common/Toast';
import { authStudentService } from '../../../services/auth';
import {
  IAlunoContracts,
  IDocuments,
} from '../../../interfaces/student/aluno.interfaces';

import * as apiService from '../../../services/api/student/api.service';
import { calculateDifferenceDays } from '../../../util/pageUtil';
import ErrorBoundary from '../../common/ErrorBoundary';

export interface IStudentContextProps {
  aluno: IStudent;
  banners: IBanner[];
  documents: IDocuments[];
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loginAs: boolean;
  redirectTo: string;
  setRedirectTo: Dispatch<SetStateAction<string>>;
  empresaConfiguracao?: number;
  handleAlunoChange: (el: IStudent) => void;
  shouldCheckPersonalInformation: () => boolean;
  cellphoneNumberConfirmed: boolean;
  setCellphoneNumberConfirmed: (bool: boolean) => void;
  emailConfirmed: boolean;
  setEmailConfirmed: (bool: boolean) => void;
  setRegistrationWithoutContracts: Dispatch<SetStateAction<IAlunoContracts[]>>;
  signContract: (id: number) => void;
  registrationWithoutContracts: IAlunoContracts[];
  setActiveStep: (step: number) => void;
  activeStep: number;
}

export const StudentProviderContext = createContext<IStudentContextProps>(
  {} as IStudentContextProps,
);
export default function StudentProvider({ children }) {
  const navigate = useNavigate();
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [documents, setDocuments] = useState<IDocuments[]>([]);
  const [empresaConfiguracao, setEmpresaConfiguracao] = useState<number>();
  const [loginAs, setLoginAs] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [aluno, setAluno] = useState<IStudent>({
    id: 0,
    matricula: 0,
    nome: '',
    rg: '',
    orgao_emissor_rg: '',
    cpf: '',
    data_nascimento: '',
    sexo: 0,
    pcd: 0,
    pai: '',
    mae: '',
    naturalidade: '',
    nacionalidade: '',
    graduacao_curso: '',
    area_conhecimento: '',
    graduacao_instituicao: '',
    graduacao_ano: 0,
    graduacao_colacao: '',
    ano_conclusao_ensino_medio: 0,
    documentos: '',
    telefone: '',
    curso_vencimento_dia: '',
    curso_numero_parcelas: '',
    curso_valor_parcela: 0,
    data_cadastro: '',
    local_de_trabalho: '',
    empresa_contribuinte_nome: '',
    empresa_contribuinte_cnpj: '',
    estrangeiro: '',
    lista_negra: '',
    status: 0,
    sistema: 0,
    usuario_id: 0,
    polo_id: 0,
    endereco_id: 0,
    agenciador_id: 0,
    cliente_id: 0,
    empresa_id: 0,
    exportado: '',
    data_ultima_conferencia: '',
    aluno_graduacao: false,
    data_expiracao_login: '',
    aluno_link_imagem_perfil: '',
    videos: [],
    aluno_universidade_corporativa: false,
    endereco: {
      id: 0,
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      codigo_municipio: '',
    },
    usuario: {
      id: 0,
      email: '',
      username: '',
      primeiro_acesso: '',
      ultimo_acesso: '',
      status: 0,
      usuario_grupo_id: 0,
      usuario_perfil_id: 0,
      empresa_id: 0,
      avatar_url: '',
    },
  });

  const [cellphoneNumberConfirmed, setCellphoneNumberConfirmed] =
    useState<boolean>(true);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(true);
  const [registrationWithoutContracts, setRegistrationWithoutContracts] =
    useState<IAlunoContracts[]>([]);

  const shouldCheckPersonalInformation = () => {
    const alunoStorage = localStorage.getItem('alunoStorage') || '';

    if (typeof alunoStorage === 'string' && alunoStorage?.length === 0) {
      return false;
    }

    const tmpAluno = JSON.parse(alunoStorage);

    if (tmpAluno !== JSON.parse('{}')) {
      if (!moment(tmpAluno.data_ultima_conferencia, 'YYYY/MM/DD').isValid()) {
        return true;
      }
      return (
        calculateDifferenceDays(
          moment(tmpAluno.data_ultima_conferencia, 'YYYY/MM/DD'),
          'month',
        ) >= 3 ||
        !tmpAluno.data_ultima_conferencia ||
        !cellphoneNumberConfirmed ||
        !emailConfirmed
      );
    }

    return false;
  };

  const handleAlunoChange = (el: IStudent) => {
    if (typeof el === 'object' && el.data_ultima_conferencia) {
      setAluno(el);
      setCellphoneNumberConfirmed(true);
      setEmailConfirmed(true);
      localStorage.setItem('alunoStorage', JSON.stringify(el));
      setRedirectTo('/aluno/dashboard');
      return;
    }
    setAluno(aluno);
    localStorage.setItem('alunoStorage', JSON.stringify(aluno));
  };

  const handleActiveStep = (step: number) => {
    setActiveStep(step);
  };

  const handleCellphoneNumberConfirmed = (bool: boolean) => {
    setCellphoneNumberConfirmed(bool);
  };

  const handleEmailConfirmed = (bool: boolean) => {
    setEmailConfirmed(bool);
  };

  useEffect(() => {
    shouldCheckPersonalInformation();

    const student = JSON.parse(
      localStorage.getItem('alunoStorage') || '{"nome": ""}',
    );
    if (student.nome) {
      if (shouldCheckPersonalInformation()) {
        return navigate('/aluno/conferencia-dados');
      } else {
        location.pathname === '/aluno/conferencia-dados' &&
          navigate('/aluno/dashboard');
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const loggedUser = authStudentService.getLoggedUser();
    const dataAluno =
      localStorage.getItem('alunoStorage') &&
      JSON.parse(localStorage.getItem('alunoStorage') as string);
    const dataDoc =
      localStorage.getItem('docStorage') &&
      JSON.parse(localStorage.getItem('docStorage') as string);
    const dataInfo =
      localStorage.getItem('dataStorage') &&
      JSON.parse(localStorage.getItem('dataStorage') as string);

    if (!loggedUser) {
      setIsLoading(false);
      setRedirectTo('/new/aluno');
      navigate(redirectTo);
      return;
    }

    apiService
      .authenticatedRequest('/student/contract/unsigned-contracts')
      .then((response) => {
        response.result && setRegistrationWithoutContracts(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        errorToast(error.message);
        setRegistrationWithoutContracts([]);
        setIsLoading(false);
      });

    if (!dataAluno) {
      apiService
        .authenticatedRequest('/student/dashboard/show')
        .then((response) => {
          if (!response.status && response.status !== 'Token is Expired') {
            setAluno(response.data);
            localStorage.setItem('alunoStorage', JSON.stringify(response.data));
            setIsLoading(false);
            if (
              !response.data.preferences.isOldLayout ||
              !response.data.preferences.canYouAccessOld
            ) {
              localStorage.removeItem('alunoStorage');
              window.location.href = '/new/aluno/inicio';
            }
          }
        })
        .catch((error) => {
          console.error(error.name + ':' + error.message);
          errorToast('Instabilidade temporária. Tente novamente mais tarde.');
          setIsLoading(false);
          localStorage.removeItem('alunoStorage');
        });
    } else {
      setAluno(dataAluno);
      if (
        dataAluno.preferences &&
        (!dataAluno.preferences.isOldLayout ||
          !dataAluno.preferences.canYouAccessOld)
      ) {
        window.location.href = '/new/aluno/inicio';
      }
    }

    if (!dataDoc) {
      apiService
        .authenticatedRequest('/student/documents/show')
        .then((response) => {
          setDocuments(response.data);
          localStorage.setItem('docStorage', JSON.stringify(response.data));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error.name + ':' + error.message);
          errorToast('Instabilidade temporária. Tente novamente mais tarde.');
          setIsLoading(false);
          localStorage.removeItem('docStorage');
        });
    } else {
      setDocuments(dataDoc);
      setIsLoading(false);
    }

    if (!dataInfo) {
      apiService
        .authenticatedRequest('/student/dashboard/banner')
        .then((response) => {
          const { banners, empresa_configuracao } = response.data;
          const bannerData = {
            banners: banners,
            empresaConfiguracao:
              empresa_configuracao.area_aluno_prazos_orientacoes,
          };
          setBanners(banners);
          setEmpresaConfiguracao(
            empresa_configuracao.area_aluno_prazos_orientacoes,
          );
          setIsLoading(false);
          localStorage.setItem('dataStorage', JSON.stringify(bannerData));
        })
        .catch((error) => {
          console.error(error.name + ':' + error.message);
          errorToast('Instabilidade temporária. Tente novamente mais tarde.');
          localStorage.removeItem('dataStorage');
          setIsLoading(false);
        });
    }
    setLoginAs(localStorage.getItem('acessadoComo') === 'true' && true);
  }, [redirectTo]);

  const signContract = async (id: number) => {
    try {
      const request = await apiService.authenticatedRequest(
        `/student/contract/sign-contract/${id}`,
        'PUT',
      );
      if (request.result) {
        successToast('Contrato assinado com sucesso!');
        setRegistrationWithoutContracts(
          registrationWithoutContracts.filter((item) => item.id !== id),
        );
        return true;
      } else {
        errorToast(
          'Desculpe! Ocorreu um erro ao assinar o contrato.\n' +
            request.message,
        );
        return false;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ErrorBoundary>
      <StudentProviderContext.Provider
        value={{
          aluno: aluno,
          banners: banners,
          documents: documents,
          loginAs: loginAs,
          isLoading: isLoading,
          redirectTo: redirectTo,
          setRedirectTo: setRedirectTo,
          setIsLoading: setIsLoading,
          empresaConfiguracao: empresaConfiguracao,
          handleAlunoChange: handleAlunoChange,
          activeStep: activeStep,
          setActiveStep: handleActiveStep,
          cellphoneNumberConfirmed: cellphoneNumberConfirmed,
          setCellphoneNumberConfirmed: handleCellphoneNumberConfirmed,
          emailConfirmed: emailConfirmed,
          setEmailConfirmed: handleEmailConfirmed,
          setRegistrationWithoutContracts: setRegistrationWithoutContracts,
          registrationWithoutContracts: registrationWithoutContracts,
          shouldCheckPersonalInformation: shouldCheckPersonalInformation,
          signContract: signContract,
        }}
      >
        {children}
      </StudentProviderContext.Provider>
    </ErrorBoundary>
  );
}
