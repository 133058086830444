import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.color.primary.main};

  .ui.loader:after {
    border-color: ${({ theme }) => theme.color.primary.main} transparent
      transparent;
  }
`;
