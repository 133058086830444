const CustomTheme = {
  color: {
    default: {
      main: '#999999',
    },
    primary: {
      main: '#3276B1',
    },
    success: {
      main: '#739E73',
    },
    info: {
      main: '#57889C',
    },
    warning: {
      main: '#C79121',
    },
    danger: {
      main: '#A90329',
    },
  },

  alert: {
    default: {
      main: '#f3f1f5',
    },
    success: {
      main: '#cde0c4',
    },
    info: {
      main: '#d6dde7',
    },
    warning: {
      main: '#efe1b3',
    },
    danger: {
      main: '#c26565',
    },
  },

  fontColor: {
    default: {
      main: '#696969',
    },
    white: {
      main: '#fff',
    },
    black: {
      main: '#000',
    },
    success: {
      main: '#356635',
    },
    info: {
      main: '#305d8c',
    },
    warning: {
      main: '#826430',
    },
    danger: {
      main: '#953b39',
    },
  },

  institutionalColor: {
    primary: {
      main: '#034694',
      light: '#4097e1',
    },
    secundary: {
      main: '#ffdf3a',
      main2: '#FD98D6',
    },
  },
};

export default CustomTheme;
