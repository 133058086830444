import styled from 'styled-components';

export const Container = styled.div``;

export const DivTdObservation = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 3rem;
  color: ${({ theme }) => theme.colors?.text.info01};
`;

export const DivTd = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  min-height: 3rem;
  color: ${({ theme }) => theme.colors?.text.info01};
`;

export const ExpiredDivTdObservation = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 3rem;
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const ExpiredDivTd = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
  min-height: 3rem;
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const Td = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  min-height: 3rem;
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const UnpaidDivTdObservation = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 3rem;
  color: ${({ theme }) => theme.colors?.text.secondary};
`;
export const UnpaidDivTd = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  text-align: center;

  min-height: 3rem;
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Alert = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

export const AlertPs = styled.div`
  max-width: 30rem;
`;

export const ContainerTable = styled.div`
  padding-bottom: 1rem;
`;

export const TitleTable = styled.h5`
  color: ${({ theme }) => theme.colors?.text.info01};
  padding: 1rem 0;
`;

export const ExpiredTitleTable = styled.h5`
  color: ${({ theme }) => theme.colors?.text.danger};

  padding: 1rem 0;
`;

interface IExpiredTitleTableMobile {
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'attention'
    | 'danger'
    | 'success'
    | 'info01'
    | 'info02';
}
export const ExpiredTitleTableMobile = styled.h6<IExpiredTitleTableMobile>`
  color: ${(props) =>
    props.color
      ? props.theme.colors?.text[props.color]
      : props.theme.colors?.text.danger};

  padding: 1rem 0;
`;

export const ContainerButtonOpen = styled.div`
  display: flex;

  justify-content: space-between;

  padding-top: 2rem;
`;

export const ContainerTableMobile = styled.div`
  padding-bottom: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    width: 10rem;
  }
`;
