import React from 'react';

import { Header } from './styles';

interface LoginAsProps {
  text?: string;
  name?: string;
  warning?: string;
  color: string;
}

export default function LoginAs({ text, name, warning, color }: LoginAsProps) {
  return (
    <>
      <Header className="aviso">
        <section className={`aviso ${color}`} style={{ margin: 0 }}>
          <header>
            <h2>
              <strong>
                {text}
                {name}
              </strong>
            </h2>
            {warning && <p>{warning}</p>}
          </header>
        </section>
      </Header>
      <Header style={{ paddingTop: 74 }}></Header>
    </>
  );
}
