import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import * as S from './styles';

import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  IShowInternship,
  myCoursesServiceNewStudent,
} from '../../../../../../../../../../services/newStudent/myCourses/myCourses.service';
import TableBasic, { ITableData } from '../../../../../../../../Table';
import { formatDateHour } from '../../../../../../../../../../util/common/appUtil';
import { handleMaxWidth } from '../../../../../../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import Divider from '../../../../../../../../../common/Divider';
import ButtonIcon from '../../../../../../../../Buttons/ButtonIcon';
import { ButtonSimple } from '../../../../../../../../Buttons';
import { BasicDialog } from '../../../../../../../../Dialog';
import { IconButton } from '@mui/material';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../../ProviderNewStudent';

interface Props {
  data?: IShowInternship;
  setWhoShow: Dispatch<SetStateAction<'register' | 'historic' | 'list'>>;
  resourceId?: number | string;
}
export default function AcademicProjectsHistoric({
  data: info,
  setWhoShow,
  resourceId,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [attachments, setAttachments] =
    useState<{ name: string; url: string }[]>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();

  const loadAttachments = async (academicProjectHistoricId) => {
    try {
      setIsLoading(true);
      const { data, result } =
        await myCoursesServiceNewStudent.resourceHistoricAttachment({
          resourceId: resourceId,
          academicProjectId: info?.academicProjectId,
          academicProjectHistoricId: academicProjectHistoricId,
        });
      if (result) {
        setAttachments(data);
        setIsModalOpen(true);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dataTable: ITableData = useMemo(() => {
    return {
      title: ['Cadastro', 'Observação', 'Status', 'Anexos'],
      content:
        info?.historic?.map((item) => ({
          date: { item: <S.H6>{formatDateHour(item?.date)}</S.H6> },
          observation: {
            item: (
              <S.Observation
                dangerouslySetInnerHTML={{ __html: item?.observation }}
              ></S.Observation>
            ),
          },
          status: {
            item: (
              <S.DivStatus>
                <S.H6>{item?.status}</S.H6>
              </S.DivStatus>
            ),
          },
          anexo: {
            item: (
              <S.DivAttachment>
                <S.H6>
                  <ButtonIcon
                    icon={<OpenInNewIcon fontSize="large" />}
                    color="primary"
                    onClick={() =>
                      loadAttachments(item.academicProjectHistoricId)
                    }
                  />
                </S.H6>
              </S.DivAttachment>
            ),
          },
        })) ?? [],
    };
  }, [info]);

  return (
    <S.Container>
      <S.ButtonBackContent>
        <S.ContentInfo>
          <S.KeyValue>
            <S.Key>Tema: </S.Key>
            <S.Value>{info?.subject}</S.Value>
          </S.KeyValue>
          <S.KeyValue>
            <S.Key>Data de Cadastro: </S.Key>
            <S.Value>{formatDateHour(info?.date)}</S.Value>
          </S.KeyValue>
          <S.KeyValue>
            <S.Key>Status Atual: </S.Key>
            <S.Value>{info?.status}</S.Value>
          </S.KeyValue>
        </S.ContentInfo>
        <ButtonSimple
          color="primary"
          outline
          onClick={() => setWhoShow('list')}
          widthSize="small"
        >
          Voltar
        </ButtonSimple>
      </S.ButtonBackContent>
      {handleMaxWidth(theme.size?.medium) ? (
        <S.DivListMobile>
          {info?.historic.map((item, index) => (
            <S.ContentInfo key={index}>
              {index !== 0 && <Divider padding />}
              <S.KeyValueMobile>
                <S.Key>Cadastro: </S.Key>
                <S.Value>{formatDateHour(item?.date)}</S.Value>
              </S.KeyValueMobile>
              <S.KeyValueMobile>
                <S.Key>Status Atual: </S.Key>
                <S.Value>{item?.status}</S.Value>
              </S.KeyValueMobile>
              <S.KeyValueMobile>
                <S.Key>Anexos: </S.Key>
                <S.Value>
                  <ButtonIcon
                    icon={<OpenInNewIcon fontSize="large" />}
                    color="primary"
                    onClick={() =>
                      loadAttachments(item.academicProjectHistoricId)
                    }
                  />
                </S.Value>
              </S.KeyValueMobile>
              <S.KeyValue>
                <S.Key>Observação: </S.Key>
                <div
                  style={{ wordBreak: 'break-word' }}
                  dangerouslySetInnerHTML={{ __html: item?.observation }}
                ></div>
              </S.KeyValue>
              {info?.historic?.length === index + 1 && <Divider padding />}
            </S.ContentInfo>
          ))}
        </S.DivListMobile>
      ) : (
        <TableBasic data={dataTable} />
      )}
      <BasicDialog isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <S.ContainerDialog>
          <S.DialogTitleDiv>
            <S.DialogTitle>Anexos</S.DialogTitle>
            <IconButton onClick={() => setIsModalOpen(false)}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </S.DialogTitleDiv>
          <S.ContentDialog>
            {attachments?.map((item, index) => (
              <S.KeyValueDialog key={index}>
                <S.Key>Anexo {index + 1}: </S.Key>
                <S.Buttons>
                  <S.DialogValue>{item.name}</S.DialogValue>
                  <ButtonIcon
                    icon={<DownloadIcon fontSize="large" />}
                    onClick={() => window.open(item.url)}
                    widthSize="xxsmall"
                    disabled={!item.url}
                    color="success"
                  />
                </S.Buttons>
              </S.KeyValueDialog>
            ))}
          </S.ContentDialog>
        </S.ContainerDialog>
      </BasicDialog>
    </S.Container>
  );
}
