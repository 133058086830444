import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0071bd;

  height: 100%;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;

  align-items: center;
  gap: 2rem;

  padding: 2rem;
  @media screen and (max-width: 426px) {
    flex-direction: column;
  }
`;

export const ContentText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
`;

export const Error = styled.div`
  padding: 1.5rem 0;
  width: 40vw;
`;

export const Img = styled.img`
  max-width: 17rem;

  @media screen and (max-width: 426px) {
    max-width: 15rem;
  }
`;

export const ImgLogo = styled.img`
  max-width: 22rem;
`;

export const Title = styled.h1`
  margin: auto;
  font-size: 3.3rem;
  text-align: center;
  color: #fff;
  padding-top: 2rem;
`;

export const Text = styled.p`
  font-size: 1.8rem;
  color: #fff;
  padding: 0.5rem;

  @media screen and (max-width: 426px) {
    text-align: center;
  }
`;

export const Bold = styled.b`
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  padding: 0.5rem;
`;

export const Li = styled.li`
  font-size: 1.4rem;
  color: #fff;
  padding: 0.5rem;
`;

export const ErrorText = styled.p`
  font-size: 1.4rem;
  color: #fff;
  padding: 0.5rem;
`;

export const DivImg = styled.div`
  width: 100%;

  display: flex;
  justify-content: end;

  @media screen and (max-width: 426px) {
    justify-content: center;
  }
`;
