import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const ServiceDiv = styled.div`
  padding-bottom: 2rem;
  align-items: start;
`;

export const OpenTicket = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    flex-direction: column;
    align-items: start;
  }
`;

export const KeyValue = styled.div`
  display: flex;
  gap: 0.5rem;
  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    flex-direction: column;
    gap: 0;
  }
`;

export const DivButtonTicket = styled.div`
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    justify-content: center;
    width: 100%;
    padding-top: 1rem;
  }
`;

export const P = styled.h6`
  height: 1rem;
`;

export const B = styled.h6`
  height: 1rem;
  font-weight: bold;
`;

export const A = styled.a``;
