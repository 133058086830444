import styled from 'styled-components';
import { Collapse, ListItem } from '@mui/material';

interface IListItemMain {
  open: boolean;
}

export const ListItemMain = styled(ListItem)<IListItemMain>`
  border-bottom: ${(props) => (!props.open ? '1px solid #1A1817' : '')};
  border-top: ${(props) => (!props.open ? '1px solid #525151' : '')};
`;

export const NameImage = styled.div`
  display: flex;
  justify-content: start;
  padding: 0 0 0 10px;

  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextList = styled.p`
  line-height: normal;
  white-space: break-spaces;

  font-size: 14px;
  display: block;
  font-weight: 400;
`;

export const TextName = styled.p`
  line-height: normal;
  font-size: 14px;
  display: block;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #a8a8a8;
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TitleDiv = styled.div`
  padding-left: 1rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    left: 23px;
    top: 24px;
    border-top: 1px solid #7a7a7a;
    z-index: 1;
  }
`;

export const CustomListItem = styled(ListItem)`
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 23px;
    top: 0;
    bottom: 0;
    border-left: 1px solid #7a7a7a;
  }
`;

export const CustomCollapse = styled(Collapse)`
  background-color: rgba(69, 69, 69, 0.6);
`;

export const containerCollapse = styled.div``;
