import { errorToast } from '../../components/common/Toast';
import * as apiService from '../api/student/api.service';

interface IDataBillets {
  id: number | number[];
  numero_parcels?: number;
  forma_pagamento?: number;
}

interface IDataIncome {
  ano?: string;
}

const financialService = {
  async historic(): Promise<{
    data: string[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(`/student/financial/historic`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },

  async viewBillet(id: number) {
    const response = await apiService.authenticatedRequest(
      `/student/bank-billets/view/${id}`,
    );

    if (!response.result) {
      return response;
    } else {
      const win = window.open();
      if (win) {
        win.location.href = response.data;
        win.focus();
        window.location.reload();
      } else {
        errorToast('A janela pop-up não pôde ser aberta.');
      }
    }
  },

  async updateBillet(id: number) {
    const response = await apiService.authenticatedRequest(
      `/student/bank-billets/update/${id}`,
      'PUT',
    );
    if (response.message) {
      return response;
    } else {
      await this.viewBillet(id);
      window.location.reload();
    }
  },

  // NEGOCIAR PARECELAS
  async installmentsNegotiation(data: IDataBillets) {
    const response = await apiService.authenticatedRequest(
      '/student/installments-negotiation/create',
      'POST',
      data,
    );

    return response ? response.data[0] : '';
  },

  // ANTECIPAR PARECELAS
  async installmentsAdvance(data: IDataBillets) {
    try {
      const response = await apiService.authenticatedRequest(
        '/student/installments-advance/create',
        'POST',
        data,
      );

      return response.data ? response.data : '';
    } catch {
      errorToast('Não foi possivel gerar o boleto');
    }
  },

  async incomeTax(): Promise<{
    data: string[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(
        `/student/financial/income-tax`,
        'GET',
        null,
        'object',
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },

  async incomeTaxDeclaration(data: IDataIncome): Promise<{
    data: string[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(
        '/student/financial/income-tax-declaration',
        'POST',
        data,
      )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },
};

export default financialService;
