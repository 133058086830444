import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    padding-top: 5rem;
  }
`;

export const ContainerDesktop = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const ContainerPerfil = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  min-width: 25rem;
  height: 32rem;
  border-radius: 0px 0px 15px 15px;

  background-color: ${({ theme }) => theme.colors?.primary};
  z-index: ${(props) => props.theme.zIndex?.sideBar};
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    background-color: ${({ theme }) => theme.colors?.backgroundColor};
    height: 20rem;
    padding-bottom: 4rem;

    .image_logo {
      display: none;
    }
  }
`;

export const PrefilContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgAvatar = styled.img`
  position: absolute;

  width: 12rem;
  border-radius: 50%;
  z-index: 1;
`;

export const ImgCircle = styled.img`
  position: absolute;
  width: 13rem;
  z-index: 0;
`;

export const ImgLogo = styled.img`
  max-width: 12rem;
`;

export const InfoText = styled.div``;

export const Name = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.colors?.text.tertiary};
`;

export const Registration = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors?.text.tertiary};
`;

export const ImageContainer = styled.div``;

export const InputFilePlus = styled.label`
  input[type='file'] {
    display: none;
  }
`;

export const InputFile = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors?.secondary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors?.backgroundColor};
  cursor: pointer;
  margin-top: 0.5rem;

  max-width: 15rem;
  width: 100%;
  height: 4rem;

  input[type='file'] {
    display: none;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: none;
  }
`;

export const TitlePageDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.size?.medium}) {
    padding-left: 2rem;
  }
`;

export const ImgPlus = styled.img`
  display: none;
  position: relative;
  z-index: 1;
  width: 20px;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: flex;
    bottom: -4rem;
    right: -50px;
  }
`;

export const MobileButtons = styled.div`
  display: flex;

  width: 100%;
  height: 6rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

export const ContainerMobile = styled.div`
  display: none;

  background-color: ${({ theme }) => theme.colors?.primary};

  width: 100vw;
  position: absolute;

  top: 0;
  left: 0;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: flex;
  }
`;
