import styled from 'styled-components';

export const Text = styled.div``;
interface IChild {
  justify?:
    | 'normal'
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end';
  noPaddingIcon?: boolean;
}
export const Child = styled.div<IChild>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: ${(props) => props.justify || 'center'};

  height: 3rem;
  gap: ${(props) => (props.noPaddingIcon ? '0' : '1rem')};
`;
