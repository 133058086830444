import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import DataStudent from '../../../CertificateNewStudent/components/CoursesCommon/components/DataStudent';
import {
  addDataCache,
  cleanCache,
  getDataCache,
} from '../../../../../util/common/appUtil';
import { IUserData } from '../../../MyDataNewStudent';
import { myDataServiceNewStudent } from '../../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import FieldFormik, {
  IFormOptions,
} from '../../../../../components/newStudent/FieldFormik';
import { Formik } from 'formik';
import MinimumRequirements from '../../../../../components/newStudent/MinimumRequirements';
import { AlertWarning } from '../../../../../components/newStudent/Alert';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import { handleMaxWidth } from '../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import TitleDialogMobile from '../../../../../components/newStudent/Title/TitleDialogMobile';
import Divider from '../../../../../components/common/Divider';
import {
  ICourseCategory,
  IDataCourseStudent,
  statementsServiceNewStudent,
} from '../../../../../services/newStudent/statements/statements.service';
import { courseTypeCapacitation } from '../../../../../variables';

interface Props {
  isOpen: boolean;
  rowsCorses?: ICourseCategory[];
}
export default function CourseCompletion({ isOpen, rowsCorses }: Props) {
  const { student, setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [courseSelected, setCourseSelected] = useState<number>(0);
  const [initialValues, setInitialValues] = useState({ courseSelected: 0 });

  const [dataStudent, setDataStudent] = useState<IUserData>();
  const [courses, setCourses] = useState<IFormOptions[] | []>([
    { value: 0, title: '[ Selecione o curso ]' },
  ]);

  const [isOpenBasicDialog, setIsOpenBasicDialog] = useState(false);

  const [dataStudentCourse, setDataStudentCourse] =
    useState<IDataCourseStudent>();

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);
        const tmpCourses =
          rowsCorses
            ?.filter(
              (filtered) =>
                filtered.courseTypeId !== Number(courseTypeCapacitation),
            )
            .map((item) => ({
              value: item.id,
              title: item.courseName + ' | ' + item.type,
            })) ?? [];
        setCourses([
          { value: 0, title: '[ Selecione o curso ]' },
          ...tmpCourses,
        ]);

        const dataStudent = await getDataCache(
          'dataStudent',
          '/new/aluno/dados',
        );
        if (dataStudent && dataStudent.name) {
          setDataStudent(dataStudent);
        } else {
          const { data, result } = await myDataServiceNewStudent.studentData({
            login: student?.dataStudent.cpf
              ? student?.dataStudent.cpf
              : student?.dataStudent.email,
          });
          if (result) {
            const tmpData = {
              name: data.name,
              email: data.email,
              cellPhone: data.cellPhone,
              businessPhone: data.businessPhone,
              homePhone: data.homePhone,
              fatherName: data.fatherName,
              motherName: data.motherName,
              bornDate: data.bornDate,
              rgId: data.rgId,
              cpf: data.cpf,
              colorRace: data.colorRace,
              studentGraduation: data.studentGraduation,
              degree: data.degree,
              graduationYear: data.graduationYear,
              nationality: data.nationality,
              naturalness: data.naturalness,
              university: data.university,
              complement: data.complement,
              city: data.city,
              state: data.state,
              street: data.street,
              cep: data.cep,
              number: data.number,
              neighborhood: data.neighborhood,
              addressCep: data.cep,
              poleId: data.poleId,
            };

            addDataCache('dataStudent', tmpData, '/new/aluno/dados');
            setDataStudent(tmpData);
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [rowsCorses]);

  useEffect(() => {
    if (courseSelected <= 0) return;

    const load = async () => {
      setIsLoading(true);
      try {
        if (courseSelected) {
          const { result, data } =
            await statementsServiceNewStudent.getStatementsRequiriments(
              courseSelected,
              2,
            );
          /** Faz a requisição dos curso Selecionado */

          if (result) {
            return setDataStudentCourse(data);
          }
        }
        setDataStudentCourse(undefined);
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [courseSelected]);

  const handleChangeSelect = (value: number) => {
    setCourseSelected(+value);
    !isOpenBasicDialog && setIsOpenBasicDialog(true);
  };

  useEffect(() => {
    setInitialValues({ courseSelected: +courseSelected! });
  }, [courseSelected]);

  const requestStatements = async () => {
    try {
      setIsLoading(true);

      const { result, data } = await statementsServiceNewStudent.getDocument(
        courseSelected,
        2,
      );
      if (result) {
        window.open(data);
        setCourseSelected(0);
        setDataStudentCourse(undefined);
        cleanCache('registrationData');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const theme = useTheme();
  return (
    <S.Container>
      {isOpen && (
        <S.Content>
          {courses.length > 0 && (
            <Formik
              initialValues={initialValues}
              onSubmit={() => {}}
              enableReinitialize
            >
              {({ handleChange, values }) => (
                <FieldFormik
                  name="courseSelected"
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeSelect(e.target.value);
                  }}
                  type="select"
                  options={courses}
                  widthSize="fullWidth"
                  value={values.courseSelected}
                />
              )}
            </Formik>
          )}
          <AlertWarning title="Caso tenha alguma dúvida para emitir a declaração, nos chame pelo chat online." />
          <DataStudent dataStudent={dataStudent!} />

          {dataStudentCourse &&
            (handleMaxWidth(theme.size?.medium) ? (
              <div>
                <BasicDialog
                  isOpen={isOpenBasicDialog}
                  setIsOpen={setIsOpenBasicDialog}
                  fullScreen
                  scroll="body"
                >
                  <TitleDialogMobile
                    title={'Declarações - Digital'}
                    setIsOpen={setIsOpenBasicDialog}
                    onClose={() => {
                      setDataStudentCourse(undefined);
                      setCourseSelected(0);
                    }}
                  />

                  <S.ContentMobile>
                    <S.TitlePage>Conclusão de Curso</S.TitlePage>

                    <Divider padding />
                    <MinimumRequirements
                      dataStudentCourse={dataStudentCourse!}
                    />
                    {dataStudentCourse.statementCanBeRequested && (
                      <ButtonSimple
                        color="success"
                        heightSize="medium"
                        widthSize="large"
                        onClick={() => requestStatements()}
                      >
                        Emitir declaração
                      </ButtonSimple>
                    )}
                  </S.ContentMobile>
                </BasicDialog>
              </div>
            ) : (
              <div>
                <MinimumRequirements dataStudentCourse={dataStudentCourse!} />
                {dataStudentCourse.statementCanBeRequested && (
                  <ButtonSimple
                    color="success"
                    heightSize="medium"
                    widthSize="large"
                    onClick={() => requestStatements()}
                  >
                    Emitir declaração
                  </ButtonSimple>
                )}
              </div>
            ))}
        </S.Content>
      )}
    </S.Container>
  );
}
