import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Navigate } from 'react-router';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Checkbox from '@mui/material/Checkbox';
import { Block } from '@mui/icons-material';

import {
  AlertNegotiation,
  AlertBar,
} from '../../../../../components/student/Alerts';
import { AccordionCustom } from '../../../../../components/student/Accordions';
import { LoaderComponent } from '../../../../../components/student/Loaders';
import { NegotiationTerm } from '../../../../../components/student/Terms';
import { BasicButton } from '../../../../../components/student/Buttons';
import { BasicSelect } from '../../../../../components/student/Select';
import { toLocaleString } from '../../../../../util/pageUtil';
import { errorToast } from '../../../../../components/common/Toast';
import { authStudentService } from '../../../../../services/auth';
import { Container } from '../../styles';
import { Value } from '../';

import { payment as payments } from '../../../../../util/financialUtil';

import {
  HeaderTableNegotiation,
  BodyTableNegotiation,
} from '../../../../../components/student/Table/NegotiationTable';

import {
  company,
  invoiceIugu,
  creditCardIugu,
  pixIugu,
} from '../../../../../variables';

import {
  getDiscountValue,
  getFeeValuePenality,
} from '../../../../../util/calculationUtil';
import {
  IBankAccount,
  IBillet,
  IPayment,
} from '../../../../../interfaces/student/installments.interface';
import { financialService } from '../../../../../services';

interface InstallmentsNegotiationProps {
  billetsForNegotiationProp: IBillet[];
  activeBilletsProp: (billets: IBillet[]) => void;
  bankAccountProp: IBankAccount[];
  numberOfParcel: number;
}

const aluno = JSON.parse(localStorage.getItem('alunoStorage') || 'null');

export default function InstallmentsNegotiation({
  activeBilletsProp,
  billetsForNegotiationProp,
  bankAccountProp,
  numberOfParcel,
}: InstallmentsNegotiationProps) {
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [payment, setPayment] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<IPayment>();
  const [parcels, setParcels] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loggedUser = authStudentService.getLoggedUser();
    if (!loggedUser) {
      setRedirectTo('/new/aluno');
    }
  }, []);

  async function installmentsNegotiation() {
    setLoading(true);
    const data = {
      id: billetsForNegotiationProp.map((ticket) => {
        return ticket.id;
      }),
      numero_parcelas: parcels,
      forma_pagamento: payment,
    };
    setChecked(false);

    try {
      const response = await financialService.installmentsNegotiation(data);
      if (response) {
        activeBilletsProp(response);
        return setLoading(false);
      }

      setLoading(false);
      return errorToast('Houve um erro ao gerar a negociação');
    } catch {
      setLoading(false);
      return errorToast('Houve um erro ao gerar a negociação');
    }
  }

  function handleParcelsChange(selectedOption) {
    setParcels(selectedOption.value);
  }

  function handleChange(e) {
    setPaymentType(e);
    setChecked(false);

    if (paymentType?.id !== e.value) {
      setPayment(e.value);
      setParcels(1);
    } else if (!e.value) {
      setPayment(0);
      setParcels(null);
    }
  }

  const paymentOptions: IPayment[] = payments.selectPayment(bankAccountProp);

  const valueFeeAndPenality = billetsForNegotiationProp.reduce(
    (total, ticket) => {
      const penalityFee = getFeeValuePenality(
        ticket.vencimento_data,
        paymentType?.multa,
        paymentType?.juros,
        ticket.vencimento_valor,
      );
      return total + penalityFee;
    },
    0,
  );

  const ticketWithDiscount = billetsForNegotiationProp.map((ticket) => {
    const discount = getDiscountValue(
      paymentType?.desconto,
      ticket.vencimento_valor,
    );

    const ticketTotalValue =
      parseFloat(ticket.vencimento_valor.replace(',', '.')) - discount;

    return {
      ...ticket,
      ticketTotalValue: ticketTotalValue,
    };
  });

  const totalValueDiscount = ticketWithDiscount.reduce(
    (total, ticket) =>
      total +
      (parseFloat(ticket.vencimento_valor.replace(',', '.')) -
        ticket.ticketTotalValue),
    0,
  );

  const originalInstallmentsValue = billetsForNegotiationProp.reduce(
    (total, ticket) => {
      return total + parseFloat(ticket.vencimento_valor.replace(',', '.'));
    },
    0,
  );

  const maxParcels = 10;
  const parcelsOptions = Array.from(
    {
      length:
        payment.toString() === creditCardIugu ? maxParcels : numberOfParcel,
    },
    (_, i) => ({
      value: i + 1,
      label: `${i + 1}x`,
    }),
  );

  const acceptParcels =
    payment.toString() === creditCardIugu || payment.toString() === invoiceIugu;

  const getDiscount =
    payment.toString() === creditCardIugu || payment.toString() === pixIugu;

  return redirectTo ? (
    <Navigate to={redirectTo} />
  ) : (
    <>
      <AccordionCustom
        color={'danger'}
        icon={<MonetizationOnIcon />}
        iconBar={<Block />}
        title={'Negociar'}
        titleBox={`Você possui ${billetsForNegotiationProp.length} mensalidades vencidas!`}
        message={'Considere quitar os seus débitos.'}
      >
        {loading ? (
          <LoaderComponent text={'Carregando'} />
        ) : (
          <Form>
            <div>
              <AlertBar
                color={'default'}
                message={
                  <>
                    Pagamentos feitos por <b>Pix ou Cartão</b> são isentos de
                    juros/multa e recebem desconto no valor final.
                  </>
                }
              />
            </div>
            <Container>
              <BasicSelect
                label={'Forma de Pagamento'}
                title={'Selecione a forma de pagamento'}
                payment={paymentOptions}
                onChange={handleChange}
                value={paymentType}
              />
              {acceptParcels && (
                <BasicSelect
                  label={'Número de Parcelas'}
                  title={'Selecione o número de parcelas'}
                  payment={parcelsOptions}
                  onChange={handleParcelsChange}
                  defaultValue={parcelsOptions[0]}
                  value={{ value: parcels || 1, label: `${parcels}X` }}
                />
              )}
            </Container>
            <HeaderTableNegotiation showMulta={true}>
              {billetsForNegotiationProp.map((ticket) => {
                return (
                  <BodyTableNegotiation
                    id={ticket.id}
                    key={ticket.id}
                    accountName={ticket.plano_contas_nome}
                    dueDate={
                      ticket.vencimento_data_atualizada
                        ? ticket.vencimento_data_atualizada
                        : ticket.vencimento_data
                    }
                    dueDateValue={`R$ ${ticket.vencimento_valor}`}
                    currentValue={
                      getDiscount
                        ? toLocaleString(
                            getDiscountValue(
                              paymentType?.desconto,
                              ticket.vencimento_valor,
                            ),
                          )
                        : 'R$ 0,00'
                    }
                    currentPenalityValue={
                      paymentType?.id && paymentType?.id > 0 && !getDiscount
                        ? toLocaleString(
                            getFeeValuePenality(
                              ticket.vencimento_data,
                              paymentType?.multa,
                              paymentType?.juros,
                              ticket.vencimento_valor,
                            ),
                          )
                        : 'R$ 0,00'
                    }
                    showMulta={true}
                  />
                );
              })}
            </HeaderTableNegotiation>
            <Value
              data={[
                {
                  title: 'Valor',
                  value: toLocaleString(originalInstallmentsValue),
                },
                {
                  title: 'Desconto',
                  value: getDiscount
                    ? toLocaleString(totalValueDiscount)
                    : 'R$ 0,00',
                },
                {
                  title: 'Juros e Multa',
                  value:
                    paymentType?.id && !getDiscount
                      ? toLocaleString(valueFeeAndPenality)
                      : 'R$ 0,00',
                },
                {
                  title: 'Valor Total',
                  value: paymentType?.id
                    ? getDiscount
                      ? toLocaleString(
                          originalInstallmentsValue - totalValueDiscount,
                        )
                      : toLocaleString(
                          originalInstallmentsValue + valueFeeAndPenality,
                        )
                    : 'R$ 0,00',
                },
              ]}
            />

            <AlertNegotiation
              term={
                <NegotiationTerm
                  studentName={aluno && aluno.nome}
                  companyName={aluno && aluno.empresa_id === 2 && company}
                />
              }
              checkbox={
                <span
                  style={{
                    pointerEvents: payment ? 'auto' : 'none',
                  }}
                >
                  <Checkbox
                    checked={checked}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                    onChange={({ target }) => setChecked(target.checked)}
                  />
                </span>
              }
              color={'success'}
              button={
                <BasicButton
                  type="submit"
                  title={'Li e concordo'}
                  color={'success'}
                  link={installmentsNegotiation}
                  disabled={checked === true ? false : true}
                />
              }
            />
          </Form>
        )}
      </AccordionCustom>
    </>
  );
}
