import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import { BasicDialog } from '../../../../../../../Dialog';
import { ButtonSimple } from '../../../../../../../Buttons';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
}
export default function ModalWarning({
  isOpen,
  setIsOpen,
  handleConfirm,
}: Props) {
  return (
    <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <S.Container>
        <S.Title>ATENÇÃO!</S.Title>
        <S.Subtitle>
          Ao entrar na avaliação, não será possível acessar as abas dos
          conteúdos da Disciplina e Auxiliar.
        </S.Subtitle>
        <S.Buttons>
          <ButtonSimple color="gray" onClick={() => setIsOpen(false)}>
            Cancelar
          </ButtonSimple>
          <ButtonSimple
            color="primary"
            onClick={() => {
              setIsOpen(false);
              handleConfirm();
            }}
          >
            Prosseguir
          </ButtonSimple>
        </S.Buttons>
      </S.Container>
    </BasicDialog>
  );
}
