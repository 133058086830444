import React, { useState } from 'react';

import * as S from './styles';
import { IGradeInterfaceMobile } from '../CollapseGrades';
import Divider from '../../../../../components/common/Divider';
import { CardLeftColor } from '../../../../../components/newStudent/Card';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface Props {
  data: IGradeInterfaceMobile;
}
export default function TableCollapseTitle({ data }: Props) {
  const [whoIsOpen, setWhoIsOpen] = useState<number>(-1);

  return (
    <S.Container>
      <S.Content>
        <S.Title>{data.title}</S.Title>
        <Divider padding />
        {data.grades.map((item, index) => (
          <div key={index}>
            <S.DivExpand
              onClick={() => {
                whoIsOpen === index ? setWhoIsOpen(-1) : setWhoIsOpen(index);
              }}
            >
              <S.Subtitle>{item.discipline}</S.Subtitle>
              {whoIsOpen === index ? (
                <ExpandLess fontSize="large" />
              ) : (
                <ExpandMore fontSize="large" />
              )}
            </S.DivExpand>
            <Divider padding />
            {whoIsOpen === index && (
              <S.Focus>
                <CardLeftColor color={item.color} heightSize="fullHeight">
                  <S.CardContent>
                    <S.KeyValue>
                      <S.Key>Nota:</S.Key>
                      <S.Value color={item.color}>{item.grade}</S.Value>
                    </S.KeyValue>
                    <S.KeyValue>
                      <S.Key>Situação:</S.Key>
                      <S.Value color={item.color}> {item.status}</S.Value>
                    </S.KeyValue>
                  </S.CardContent>
                </CardLeftColor>
                <Divider padding />
              </S.Focus>
            )}
          </div>
        ))}
      </S.Content>
    </S.Container>
  );
}
