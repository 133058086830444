import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import studentDataService from '../../../../../services/student/student.data.service';
import { convertStringToMoment } from '../../../../../util/pageUtil';

import { Form } from 'react-bootstrap';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../ProviderAgent';
import { BasicTable } from '../../../../common/Table';
import { IServices } from '../../../../../services/agent/student/interface';

export default function TabServices({ data }) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  const [servicesData, setServicesData] = useState<IServices[]>();
  const [occurrenceTypes, setOccurrenceTypes] = useState<string[]>([
    'Selecione',
  ]);

  const [selectedType, setSelectedType] = useState<string>(
    '[ Selecione o tipo ]',
  );

  const handleSelectType = (selected) => {
    setSelectedType(selected);
  };

  const filteredServicesData =
    selectedType !== '[ Selecione o tipo ]'
      ? servicesData?.filter((item) => item.type === selectedType)
      : servicesData;

  const formatServicesData = {
    title: ['Cadastro', 'Descrição', 'Status', 'Responsável'],
    content:
      filteredServicesData?.map((item) => ({
        Cadastro: {
          item: (
            <div>
              {convertStringToMoment(
                item.registered,
                'YYYY-MM-DD HH:mm:ss',
              ).format('DD/MM/YYYY HH:mm')}
            </div>
          ),
        },
        Descrição: {
          item: <div dangerouslySetInnerHTML={{ __html: item.description }} />,
        },
        Status: {
          item: item.status,
        },

        Responsável: {
          item: item.responsible,
        },
      })) || [],
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchServices = async () => {
      const response = await studentDataService.services({ login: data });
      if (response.data) {
        setServicesData(response.data);
        const typesMap = response.data.reduce((acc: string[], item) => {
          if (item.type && !acc.includes(item.type!)) {
            acc.push(item.type);
          }
          return acc;
        }, []);
        const sortedTypes = typesMap.sort();
        setOccurrenceTypes(sortedTypes);
      }
      setIsLoading(false);
    };

    Promise.all([fetchServices()]).then(() => {
      setIsLoading(false);
    });
  }, [data]);

  return (
    <>
      <S.Form>
        <Form.Group>
          <Form.Select
            size="lg"
            id="typeStatement"
            onChange={(e) => handleSelectType(e.target.value)}
          >
            <option>[ Selecione o tipo ]</option>
            {Array.isArray(servicesData) &&
              occurrenceTypes.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </S.Form>
      <BasicTable data={formatServicesData} />
    </>
  );
}
