import React, { useEffect } from 'react';

import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  isBackgroundSolid?: boolean;
}
export default function Loading({ isBackgroundSolid }: Props) {
  const ModalContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: ${isBackgroundSolid ? '#c3c3c3' : 'rgba(0, 0, 0, 0.5)'};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
  `;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <ModalContainer>
      <CircularProgress />
    </ModalContainer>
  );
}
