import { Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 6rem;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding: 2rem 0 0 0;

  @media screen and (max-width: 424px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const ContainerDocument = styled.div`
  @media screen and (max-width: 424px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const ContainerFile = styled.div`
  width: 25rem;
  min-height: 2rem;
  word-wrap: break-word;

  @media screen and (max-width: 424px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-wrap: break-word;
    word-break: break-all;
  }
`;

export const BoxStyled = styled(Box)`
  width: 10rem;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }

  @media screen and (max-width: 424px) {
    padding-top: 2rem;
  }
`;

export const ContainerInfo = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';

  @media screen and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
`;
