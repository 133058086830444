import React, { useEffect, useState } from 'react';

import * as S from './styles';
import HeaderPages from '../../../components/student/HeaderPages';
import Breadcrumb from '../../../components/student/Breadcrumbs';
import TableGrades from './components/TableGrades';
import gradeService from '../../../services/grade/grade.service';
import { warningToast } from '../../../components/common/Toast';
import { LoaderSimpleCenter } from '../../../components/student/Loaders';

export interface IMyGradesRow {
  aluno_curso_id: number;
  nome: string;
  status: number;
}

export default function MyGrades() {
  const [load, setLoad] = useState<boolean>(false);
  const [course, setCourse] = useState<IMyGradesRow[]>([]);
  const bradcrumbs = [
    {
      title: 'Início',
      link: '/aluno/dashboard',
    },
    {
      title: 'Minhas Notas',
      link: '/aluno/minhas-notas',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      const response = await gradeService.listCourses();
      if (response.data) {
        setCourse(response.data);
      } else {
        response.message?.forEach((text) => {
          warningToast(text);
        });
      }
      setLoad(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumb navigates={bradcrumbs} />
      <HeaderPages title={'Minhas Notas'} isNotMain={true} />
      {load ? (
        <S.Load>
          <LoaderSimpleCenter text="Carregando" />
        </S.Load>
      ) : (
        <TableGrades rows={course} />
      )}
    </div>
  );
}
