import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;

  @media screen and (max-width: 650px) {
    font-size: 10px !important;
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 1.5rem;

  @media screen and (max-width: 650px) {
    font-size: 10px !important;
    width: 75% !important;
  }
`;

export const Container = styled.div`
  min-width: 80%;
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ComponentTitle = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  gap: 1rem;
  font-size: 10px;

  @media screen and (max-width: 426px) {
    align-items: start;
    flex-direction: column;
    gap: 0;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
`;
