import React, { ReactNode } from 'react';

import ButtonSimple from '../ButtonSimple';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface Props {
  children: ReactNode;
  widthSize?: 'small' | 'medium' | 'xmedium' | 'large' | 'xlarge' | 'fullWidth';
  heightSize?: 'small' | 'medium' | 'large' | 'xlarge';
  font?: 'p' | 'b' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color: 'primary' | 'gray' | 'success' | 'danger';
  outline?: boolean;
  border?: boolean;
  upperCase?: boolean;
  style?: object;
  onClick?: () => void;
}

export default function ButtonNext({
  children,
  widthSize = 'xlarge',
  heightSize = 'small',
  font = 'h6',
  color,
  outline,
  border = false,
  upperCase,
  style,
  onClick,
}: Props) {
  return (
    <ButtonSimple
      color={color}
      icon={<ChevronRightIcon fontSize="large" />}
      widthSize={widthSize}
      heightSize={heightSize}
      font={font}
      outline={outline}
      noBorder={border}
      upperCase={upperCase}
      styles={style}
      onClick={onClick}
    >
      {children}
    </ButtonSimple>
  );
}
