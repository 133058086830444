import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  flex-direction: row;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
  }
`;
