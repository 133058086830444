import React, { useEffect, useState } from 'react';

import * as S from '../styles';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FieldFormik from '../../../../../../../../components/newStudent/FieldFormik';
import { IDocumentsDegree } from '../../../../..';

interface Props {
  item: IDocumentsDegree;
  handleSave: (item: IDocumentsDegree, isOld?: boolean) => void;
  handleDelete: (id: number | string, isOld?: boolean) => void;
  isOld?: boolean;
}
export default function ItemDegree({
  item,
  handleDelete,
  handleSave,
  isOld,
}: Props) {
  const [dataItem, setDataItem] = useState<IDocumentsDegree>(item);

  useEffect(() => {
    setDataItem(item);
  }, [item]);

  return (
    <S.CardContent>
      <S.KeyValue>
        <S.H5
          style={{
            textAlign: 'center',
            width: '100%',
            paddingBottom: '1rem',
          }}
        >
          {item.name}
        </S.H5>
        <S.Div>
          {JSON.stringify(dataItem) !== JSON.stringify(item) && (
            <S.ButtonSave onClick={() => handleSave(dataItem, isOld)}>
              <UploadFileIcon fontSize="large" />
            </S.ButtonSave>
          )}
          <S.BtnDanger onClick={() => handleDelete(dataItem.id, isOld)}>
            <DeleteForeverIcon fontSize="large" color="error" />
          </S.BtnDanger>
        </S.Div>
      </S.KeyValue>
      <S.KeyValue>
        <S.Key>Data Início: </S.Key>
        <FieldFormik
          name="startDate"
          onChange={({ target }) =>
            setDataItem((old) => ({ ...old, startDate: target.value }))
          }
          type="date"
          value={dataItem.startDate}
          disabled={item.checked}
        />
      </S.KeyValue>
      <S.KeyValue>
        <S.Key>Data fim: </S.Key>
        <FieldFormik
          name="endDate"
          onChange={({ target }) =>
            setDataItem((old) => ({ ...old, endDate: target.value }))
          }
          type="date"
          value={dataItem.endDate}
          disabled={item.checked}
        />
      </S.KeyValue>
      <S.KeyValue>
        <S.Key>Carga Horária: </S.Key>
        <FieldFormik
          name="workload"
          onChange={({ target }) =>
            setDataItem((old) => ({ ...old, workload: target.value }))
          }
          type="number"
          value={dataItem.workload}
          disabled={item.checked}
        />
      </S.KeyValue>
    </S.CardContent>
  );
}
