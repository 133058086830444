import styled from 'styled-components';

export const Container = styled.div``;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;

  padding: 1rem 2rem;
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    padding: 1rem;
  }
`;

export const H4Title = styled.h4`
  height: 3rem;

  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    height: 5rem;
  }
`;

export const H4 = styled.h4`
  height: 3rem;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    min-width: 35rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    min-width: 27rem;
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors?.danger};
  color: ${({ theme }) => theme.colors?.backgroundColor};
  border-radius: 4px 4px 0 0;

  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
  padding: 3rem 2rem;

  .contact {
    display: flex;
    flex-direction: row;

    gap: 0.5rem;
    @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
      gap: 0;
      flex-direction: column;
    }
  }

  .divider {
    display: none;

    @media screen and (min-width: ${(props) => props.theme.size?.medium}) {
      display: flex;
    }
  }

  .button_close {
    @media screen and (min-width: ${(props) => props.theme.size?.large}) {
      display: none;
    }
  }
`;
