import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 3rem;
  width: 25%;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const Title = styled.b`
  font-size: medium;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const ItemDiv = styled.div`
  width: 12rem;
`;

export const H6 = styled.h6`
  text-align: right;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Cupom = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  gap: 2rem;
`;
