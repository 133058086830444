import { Button } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  icon?: ReactNode;
  children?: string | ReactNode;
  variant?: 'outlined' | 'text' | 'contained';
  iconLocation?: 'start' | 'end';
  color?: 'white' | 'blue' | 'green';
  disabled?: boolean;
  size?: 'larger' | 'medium';
  type?: 'button' | 'submit';
  textTransform?: 'capitalize' | 'uppercase';
  onClick: (e) => void | Promise<void>;
}

export default function ButtonSimple({
  icon,
  children,
  variant = 'contained',
  iconLocation = 'start',
  color = 'white',
  size = 'larger',
  disabled,
  type = 'button',
  textTransform: textTransformProps = 'capitalize',
  onClick,
}: Props) {
  const propColors = {
    blue: {
      backgroundColor: '#275b89',
      borderColor: '#1f496d',
      color: '#fff',
    },
    green: {
      backgroundColor: '#5b835b',
      borderColor: '#4c6e4c',
      color: '#fff',
    },
    white: {
      backgroundColor: '#f9f9f9',
      borderColor: '#ddd',
      color: '#2c2c2c',
    },
  };

  const propSize = {
    larger: { height: '44px', fontSize: '17px' },
    medium: { height: '30px', fontSize: '15px' },
  };
  return (
    <Button
      disableRipple
      disabled={disabled}
      startIcon={iconLocation === 'start' && icon}
      endIcon={iconLocation === 'end' && icon}
      variant={variant}
      type={type}
      sx={{
        ...propSize[size],
        textTransform: textTransformProps,
        backgroundColor: propColors[color].backgroundColor,
        borderColor: propColors[color].borderColor,
        color: propColors[color].color,
        '&:hover': {
          opacity: 0.8,
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
