import styled from 'styled-components';
import { Link } from '@mui/material';

export const Container = styled(Link)`
  color: #3276b1;
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  :focus,
  :hover {
    color: #214e75;
    text-decoration: underline;
  }

  /* Estilos para o tooltip */
  &::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -45px;
    left: 100%;
    padding: 8px;
    background-color: ${({ theme }) => theme.fontColor.info.main};
    border-left: solid 2px ${({ theme }) => theme.fontColor.white.main};
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s,
      visibility 0.3s;
    z-index: 999;
    display: ${(props) => (props['data-tooltip'] ? 'block' : 'none')};
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;
