import styled from 'styled-components';
import { Form } from 'formik';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 3rem 5rem;
  gap: 1rem;
`;

export const FieldForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  gap: 1rem;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TitleInputImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Subtitle = styled.p`
  text-align: center;

  width: 22rem;
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const FaceContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 1rem;
  padding: 1rem 0;
`;

export const Img = styled.img`
  cursor: pointer;
`;
