import React, { ReactNode, useState } from 'react';

import { ErrorMessage } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import * as S from './styles';

export interface IFormOptions {
  title: string;
  value?: number;
}

interface Props {
  name: string;
  type:
    | 'textarea'
    | 'password'
    | 'select'
    | 'string'
    | 'email'
    | 'number'
    | 'date'
    | 'checkbox';
  align?: 'center' | 'start' | 'end';
  error?: boolean;
  component?: string;
  label?: ReactNode | string;
  placeholder?: string;
  leftIcon?: ReactNode;
  widthSize?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'fullWidth';

  heightSize?: 'small' | 'medium' | 'large' | 'textSmall' | 'textLarge';
  onChange: (e) => void;
  options?: IFormOptions[];
  disabled?: boolean;
  checked?: boolean;
  notLimitHeight?: boolean;
  value?: string | number;
  mask?: string;
  cols?: number;
  className?: string;
}

export default function FieldFormik({
  name,
  type,
  error,
  align = 'start',
  component,
  label,
  placeholder,
  widthSize = 'xlarge',
  heightSize = 'medium',
  leftIcon,
  onChange,
  options,
  disabled,
  value,
  mask,
  checked,
  className,
  notLimitHeight = false,
}: Props) {
  const sizeWidth = {
    xxsmall: { width: '6rem' },
    xsmall: { width: '10rem' },
    small: { width: '15rem' },
    medium: { width: '18rem' },
    large: { width: '20rem' },
    xlarge: { width: '25rem' },
    xxlarge: { width: '27rem' },
    fullWidth: { width: '100%' },
  };

  const sizeHeight = {
    small: '3rem',
    medium: '4rem',
    large: '5.5rem',
    textSmall: '8rem',
    textLarge: '30rem',
  };

  const [visible, setVisible] = useState(false);

  function renderPassword() {
    return (
      <>
        <S.StyledField
          placeholder={placeholder}
          align={align}
          id={name}
          name={name}
          type={visible ? 'text' : 'password'}
          leftIcon={!!leftIcon}
          onChange={onChange}
          disabled={disabled}
          value={value && value}
        />
        <S.EyeIcon onClick={() => setVisible(!visible)}>
          {visible ? (
            <VisibilityOffIcon fontSize={'large'} />
          ) : (
            <VisibilityIcon fontSize={'large'} />
          )}
        </S.EyeIcon>
      </>
    );
  }

  function renderString() {
    return (
      <>
        <S.MaskedField
          placeholder={placeholder}
          align={align}
          id={name}
          name={name}
          type={type}
          leftIcon={!!leftIcon}
          onChange={onChange}
          disabled={disabled}
          value={value && value}
          mask={mask && mask}
        />
      </>
    );
  }

  function renderText() {
    const handleChange = (e) => {
      onChange && onChange(e);
    };
    return (
      <>
        <S.StyledFieldText
          placeholder={placeholder}
          sizeHeight={sizeHeight[heightSize]}
          id={name}
          name={name}
          form={name}
          onChange={(e) => handleChange(e)}
          disabled={disabled}
        />
      </>
    );
  }

  function renderNumber() {
    return (
      <>
        <S.MaskedField
          placeholder={placeholder}
          align={align}
          id={name}
          name={name}
          type={type}
          leftIcon={!!leftIcon}
          onChange={onChange}
          disabled={disabled}
          value={value && value}
          mask={mask && mask}
        />
      </>
    );
  }

  function renderCheckbox() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          gap: '1rem',
          ...sizeWidth[widthSize],
        }}
      >
        <input
          id={name}
          name={name}
          type={type}
          onChange={onChange}
          disabled={disabled}
          checked={checked!}
          value={value}
        />
        <b style={{ height: notLimitHeight ? '' : '1.5rem' }}>{placeholder}</b>
      </div>
    );
  }

  function renderDate() {
    return (
      <>
        <S.StyledField
          align={align}
          placeholder={placeholder}
          id={name}
          name={name}
          type={type}
          leftIcon={!!leftIcon}
          onChange={onChange}
          disabled={disabled}
          value={value && value}
        />
      </>
    );
  }

  function renderSelect() {
    return (
      <S.DivSelect>
        <S.StyledSelect
          className="dropdown-select"
          align={align}
          id={name}
          name={name}
          leftIcon={!!leftIcon}
          onChange={onChange}
          disabled={disabled}
          value={value && value}
        >
          {options &&
            options.map((item, i) => {
              return (
                <option key={i} value={item.value}>
                  {item.title}
                </option>
              );
            })}
        </S.StyledSelect>
      </S.DivSelect>
    );
  }

  const typeRender = {
    date: renderDate(),
    textarea: renderText(),
    email: renderString(),
    string: renderString(),
    number: renderNumber(),
    select: renderSelect(),
    checkbox: renderCheckbox(),
    password: renderPassword(),
  };

  return (
    <S.Container style={{ ...sizeWidth[widthSize] }} className={className}>
      {label && <label htmlFor={name}>{label}</label>}

      {type === 'textarea' || type === 'checkbox' ? (
        typeRender[type]
      ) : (
        <S.InputContainer
          sizeHeight={sizeHeight[heightSize]}
          disabled={disabled}
        >
          {/** Renderiza o espaço lateral a esquerda */}
          {leftIcon && <S.LeftBackground>{leftIcon}</S.LeftBackground>}
          {typeRender[type]}
        </S.InputContainer>
      )}
      {error && (
        <ErrorMessage name={name} component={component}>
          {(msg) => <S.StyledError>{msg}</S.StyledError>}
        </ErrorMessage>
      )}
    </S.Container>
  );
}
