import styled from 'styled-components';

export const LinkCourse = styled.b`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding-left: 1rem;
`;

export const ContainerLinkCourse = styled.div`
  display: flex;
  align-items: center;

  .buttonText {
    color: #3276b1;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  padding: 1rem 0 2rem;

  .cell {
    padding: 1rem;
  }
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
`;

export const B = styled.b`
  padding-left: 1rem;
`;
