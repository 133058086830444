import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h5``;

export const Name = styled.div`
  display: flex;
  flex-direction: row;

  align-items: start;
  gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
    gap: 0rem;

    .divider {
      display: none;
    }
  }
`;

export const NameWithChevron = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 1rem;
`;

interface Props {
  isOpen?: boolean;
}
export const DivItemName = styled.div<Props>`
  display: flex;
  flex-direction: column;

  white-space: ${(props) => (props.isOpen ? 'normal' : 'nowrap')};
  overflow: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
  text-overflow: ${(props) => (props.isOpen ? 'unset' : 'ellipsis')};

  max-width: 30rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    max-width: 22rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.xsmall}) {
    max-width: 15rem;
  }
`;

export const H6 = styled.h6`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  height: ${(props) => (props.isOpen ? '100%' : '4.5rem')};
  min-height: ${(props) => (props.isOpen ? '100%' : '4.5rem')};

  margin-top: 3rem;
  gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    height: 100%;
    margin-top: 1rem;
  }
`;
