/* eslint-disable react/display-name */
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from './styles';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import FieldFormik, {
  IFormOptions,
} from '../../../../../components/newStudent/FieldFormik';
import { Formik } from 'formik';
import DataStudent from './components/DataStudent';
import MinimumRequirements, {
  IDataCourseStudent,
} from '../../../../../components/newStudent/MinimumRequirements';
import { addDataCache, getDataCache } from '../../../../../util/common/appUtil';
import {
  certificateServiceNewStudent,
  myDataServiceNewStudent,
} from '../../../../../services/newStudent';
import { IUserData } from '../../../MyDataNewStudent';
import CertificateRequest from './components/CertificateRequest';

interface Props {
  courses: IFormOptions[];
  setReload?: Dispatch<SetStateAction<number>>;
  reload: number;
  courseToScroll?: number;
  scrollToBotton?: boolean;
  setScrollToBotton?: Dispatch<SetStateAction<boolean>>;
  scrollToCertificateButton?: () => void;
}

export default function CoursesCommon({
  courses,
  courseToScroll,
  setReload,
  scrollToBotton,
  scrollToCertificateButton,
  reload,
}: Props) {
  const { student, setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [courseSelected, setCourseSelected] = useState<number>();
  const [readed, setReaded] = useState<boolean>();
  const [initialValues, setInitialValues] = useState({
    courseSelected: 0,
    readed: false,
  });

  const [dataStudent, setDataStudent] = useState<IUserData>();
  const [dataStudentCourse, setDataStudentCourse] =
    useState<IDataCourseStudent>();

  useEffect(() => {
    setIsLoading(true);
    const load = async () => {
      try {
        const dataStudent = await getDataCache(
          'dataStudent',
          '/new/aluno/dados',
        );
        if (dataStudent && dataStudent.name) {
          setDataStudent(dataStudent);
        } else {
          const { data, result } = await myDataServiceNewStudent.studentData({
            login: student?.dataStudent.cpf
              ? student?.dataStudent.cpf
              : student?.dataStudent.email,
          });
          if (result) {
            const tmpData = {
              name: data.name,
              email: data.email,
              cellPhone: data.cellPhone,
              businessPhone: data.businessPhone,
              homePhone: data.homePhone,
              fatherName: data.fatherName,
              motherName: data.motherName,
              bornDate: data.bornDate,
              rgId: data.rgId,
              cpf: data.cpf,
              colorRace: data.colorRace,
              studentGraduation: data.studentGraduation,
              degree: data.degree,
              graduationYear: data.graduationYear,
              nationality: data.nationality,
              naturalness: data.naturalness,
              university: data.university,
              complement: data.complement,
              city: data.city,
              state: data.state,
              street: data.street,
              cep: data.cep,
              number: data.number,
              neighborhood: data.neighborhood,
              addressCep: data.cep,
              poleId: data.poleId,
            };

            addDataCache('dataStudent', tmpData, '/new/aluno/dados');
            setDataStudent(tmpData);
          }
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw new Error(error);
      }
    };
    load();
  }, []);

  const handleChangeSelect = async (value: number) => {
    if (value <= 0) return;

    setIsLoading(true);
    setCourseSelected(value);
    try {
      if (value) {
        const { result, data } =
          await certificateServiceNewStudent.requirements(+value);
        if (result) {
          setDataStudentCourse(data);
          setTimeout(() => {
            scrollToBotton &&
              scrollToCertificateButton &&
              scrollToCertificateButton();
          }, 100);
        }
      } else setDataStudentCourse(undefined);
    } catch (error) {
      throw new Error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (courseToScroll && courseToScroll !== 0) {
      handleChangeSelect(courseToScroll);
    }
  }, [courseToScroll]);

  useEffect(() => {
    setInitialValues({
      courseSelected: +courseSelected!,
      readed: courseToScroll !== 0 ? true : false,
    });
    setReaded(courseToScroll !== 0 ? true : false);
  }, [courseSelected]);

  useEffect(() => {
    if (reload !== 1) {
      setReaded(false);
      setInitialValues({
        courseSelected: 0,
        readed: false,
      });
    }
  }, [reload]);
  return (
    <S.Container>
      <S.TitleDiv>
        <S.Title>Selecione o curso para emissão do certificado:</S.Title>
      </S.TitleDiv>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({ handleChange, setFieldValue, values }) => (
          <>
            <FieldFormik
              name="courseSelected"
              onChange={(e) => {
                handleChange(e);
                handleChangeSelect(e.target.value);
                setFieldValue('readed', false);
                setReaded(undefined);
              }}
              type="select"
              options={courses}
              value={values.courseSelected}
              widthSize="fullWidth"
            />
            <DataStudent dataStudent={dataStudent!} />
            {+courseSelected! > 0 && (
              <S.CheckboxDiv>
                <FieldFormik
                  name="readed"
                  placeholder="Li e confirmo que as informações aqui contidas estão corretas e não precisam de alterações."
                  onChange={(e) => {
                    handleChange(e);
                    setReaded(e.target.checked);
                  }}
                  type="checkbox"
                  checked={readed}
                  widthSize="fullWidth"
                />
              </S.CheckboxDiv>
            )}
          </>
        )}
      </Formik>
      {((+courseSelected! > 0 && readed && dataStudentCourse) ||
        (+courseSelected! > 0 &&
          readed &&
          scrollToBotton &&
          dataStudentCourse)) && (
        <div>
          <MinimumRequirements dataStudentCourse={dataStudentCourse} />
          <CertificateRequest
            setReload={setReload}
            dataStudentCourse={dataStudentCourse}
            courseId={+courseSelected!}
            setCourseId={setCourseSelected}
          />
        </div>
      )}
    </S.Container>
  );
}
