import React, { useRef, useEffect, ReactNode } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

import * as S from './styles';
import { DeclarationDischarge } from '../../DeclarationDischarge';

interface IModalScroll {
  open: boolean;
  title: string;
  titleSelect?: string;
  incomeTax: ReactNode;
  handleOpen: () => void;
  handleClose: () => void;
}

export default function ModalScroll({
  open,
  title,
  incomeTax,
  titleSelect,
  handleOpen,
  handleClose,
}: IModalScroll) {
  const descriptionElementRef = useRef<HTMLElement | null>(null);

  const handlePrint = () => {
    const modalContent = descriptionElementRef.current?.innerHTML;
    if (modalContent) {
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(
          '<html><head><title>Impressão</title></head><body>',
        );
        printWindow.document.write(modalContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
      }
    }
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <S.Container>
      <Typography className="textSelect" onClick={handleOpen}>
        {titleSelect}
      </Typography>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <DeclarationDischarge incomeTax={incomeTax} />
          </DialogContentText>
        </DialogContent>

        <S.Button>
          <DialogActions>
            <Button onClick={handlePrint}>Imprimir</Button>
          </DialogActions>
        </S.Button>
      </Dialog>
    </S.Container>
  );
}
