import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import * as S from './styles';

import { Col } from 'react-bootstrap';

import { InactivityTimer, Footer, LoginAs, Header, Menu } from '../index';
import {
  IStudentContextProps,
  StudentProviderContext,
} from '../../StudentProvider';
import { Loading } from '../../Loaders';
import { Blip } from '..';
import { getPhone } from '../../../../util/common/appUtil';
import { calculateDaysToAccess } from '../../../../util/pageUtil';
import { warningToast } from '../../../common/Toast';
import { myDataServiceNewStudent } from '../../../../services/newStudent';

export default function LayoutStudent() {
  const context = useContext(StudentProviderContext);
  const [redirectTo, setRedirectTo] = useState<string>('');
  const {
    aluno,
    isLoading,
    loginAs,
    registrationWithoutContracts,
    setIsLoading,
  } = context as IStudentContextProps;
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      registrationWithoutContracts.length > 0 &&
      pathname !== '/aluno/dashboard'
    ) {
      warningToast(
        'Você possui contratos pendentes de assinatura! Favor assiná-los antes de prosseguir.',
        1,
      );
      return setRedirectTo('/aluno/conferencia-dados');
    }
  }, [context]);

  const changeLayout = async () => {
    try {
      setIsLoading(true);
      const { result } = await myDataServiceNewStudent.updatePreferences({
        isOldLayout: false,
      });
      if (result) {
        localStorage.removeItem('alunoStorage');
        localStorage.removeItem('dataStudent');

        window.location.href = '/new/aluno/dashboard';
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return redirectTo ? (
    <Navigate to={redirectTo} />
  ) : (
    <>
      <InactivityTimer />
      {(isLoading || aluno.id === 0) && (
        <>
          <Loading text={'Carregando'} />
        </>
      )}
      <>
        {aluno.data_expiracao_login && !loginAs && (
          <LoginAs
            text={`Seu acesso ao Aluno Explorer termina ${calculateDaysToAccess(
              aluno?.data_expiracao_login,
            )}`}
            color={'info'}
          />
        )}
        {loginAs && (
          <LoginAs text={`ACESSADO COMO: ${aluno.nome}`} color={'danger'} />
        )}
        <>
          <div className="animated fadeInDown">
            <S.AnnouncementNewLayout>
              <S.H6>
                Criamos um novo Portal do Aluno para melhorar sua experiência
                conosco, o atual Portal será descontinuado nas próximas
                atualizações.{' '}
              </S.H6>
              <S.H6>
                Clique <S.A onClick={changeLayout}> aqui</S.A> para testar o
                novo portal
              </S.H6>
            </S.AnnouncementNewLayout>
            <Header />
            <div id="main" role="main">
              <div id="content" className="container">
                <div className="row">
                  <Menu />
                  <Col id="outlet" lg={9}>
                    <Outlet />
                  </Col>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>

        {aluno && (
          <Blip
            nome={aluno?.nome}
            email={aluno?.usuario.email}
            telefone={aluno.telefone && getPhone(aluno?.telefone, 'cellPhone')}
            onClose={true}
          />
        )}
      </>
    </>
  );
}
