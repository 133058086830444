import React, { ReactNode } from 'react';

import * as S from './styles';
import { useTheme } from 'styled-components';

interface Props {
  color?: 'gray01' | 'gray02' | 'background';
  className?: string;
  children: ReactNode;
}

export default function CardBasic({
  color = 'gray01',
  children,
  className,
}: Props) {
  const theme = useTheme();
  const colorSelect = {
    gray01: { backgroundColor: theme.colors?.gray01 },
    gray02: { backgroundColor: theme.colors?.gray02 },
    background: { backgroundColor: theme.colors?.backgroundColor },
  };
  return (
    <S.Container className={className} style={{ ...colorSelect[color] }}>
      {children}
    </S.Container>
  );
}
