import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  padding: 2rem;
  gap: 1rem;
  width: 50%;

  @media screen and (max-width: 985px) {
    width: 100%;
  }
`;

export const Title = styled.b`
  font-size: medium;
`;

export const Content = styled.b`
  padding: 2rem 0 0 1rem;
`;

export const Mark0 = styled.b`
  font-size: 10px;
  padding-left: 2rem;
`;

export const Mark = styled.b`
  font-size: 10px;
  padding-right: 2rem;
`;

export const ContainerCard = styled.div`
  margin: 1rem 3rem 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

interface CardProp {
  checked?: boolean;
}
export const Card = styled.div<CardProp>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 27rem;

  height: 100%;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid ${(props) => (props.checked ? '#61b1ed' : '#ccc')};
  background-color: ${(props) => (props.checked ? '#e6f0f7' : '#fff')};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

export const CardImg = styled.div`
  width: 5rem;
  height: 5rem;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 180px;
  margin: 1rem;
`;

export const Img = styled.img`
  width: 5rem;
  height: 5rem;
`;

export const CardTitle = styled.b`
  font-size: 14px;
`;

export const CardInfo = styled.div`
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Checkbox = styled.div`
  display: flex;
  justify-content: end;
`;

export const ItemTitle = styled.p`
  font-weight: normal;
`;

export const ItemSubtitle = styled.p`
  font-weight: normal;
`;
