import 'react-toastify/dist/ReactToastify.css';

import { errorToast } from '../../../components/common/Toast';
import { authAgentService } from '../../auth';
import { apiUrl } from '../../../variables';

export const authenticatedRequest = async (
  endpoint: string,
  method?: string,
  body?: object | FormData,
  type?: 'file' | 'object',
) => {
  const bearer = authAgentService.getLoggedUser();
  if (!bearer || bearer === 'null') return;

  const headers = new Headers({
    Authorization: `Bearer ${bearer}`,
  });

  let bodyRequest;

  if (type === 'file') {
    bodyRequest = body;
  } else {
    bodyRequest = body ? JSON.stringify(body) : null;
    headers.set('Content-Type', 'application/json');
  }

  const requestOptions: RequestInit = {
    method: method,
    mode: 'cors',
    headers: headers,
    body: bodyRequest,
  };

  const lastRefresh = localStorage.getItem('lastRefreshToken');

  /** > 1 ¹/² Hora  desloga */
  if (lastRefresh && +lastRefresh + 1000 * 5400 < new Date().getTime())
    return authAgentService.cleanLoggerUser(true);

  async function repeatedRequest(newToken: { access_token: string }, body) {
    const refreshedRequestOptions: RequestInit = {
      method: method,
      mode: 'cors',
      headers: new Headers({
        Authorization: `Bearer ${newToken.access_token}`,
      }),
      body: body,
    };

    const refreshedResponse = await fetch(
      apiUrl + endpoint,
      refreshedRequestOptions,
    );
    return refreshedResponse.json();
  }

  // > 45 min && < 1 ¹/² hora refreshToken
  if (
    lastRefresh &&
    +lastRefresh + 1000 * 2700 < new Date().getTime() &&
    +lastRefresh + 1000 * 5400 > new Date().getTime()
  ) {
    const newToken = await authAgentService.refreshToken();
    return await repeatedRequest(newToken, bodyRequest);
  }

  const response = await fetch(apiUrl + endpoint, requestOptions);

  if (response.status === 401 || response.status === 441)
    authAgentService.cleanLoggerUser();

  if (response.status === 442) {
    const newToken = await authAgentService.refreshToken();
    return await repeatedRequest(newToken, bodyRequest);
  }

  if (response.status === 404 || response.status === 500)
    return JSON.stringify({ data: [], result: false, message: [''] });

  return response.json();
};

export const unauthenticatedRequest = async (
  endpoint: string,
  method?: string,
  body?: string | object,
) => {
  const response = await fetch(apiUrl + endpoint, {
    method: method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },

    body: body ? JSON.stringify(body) : null,
  })
    .then(async (res) => {
      if (res.status === 404 || res.status === 500)
        return JSON.stringify({ data: [] });

      return res.json();
    })
    .catch((error) => {
      errorToast(error, 1);
    });

  return response;
};
