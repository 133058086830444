import { IUserData } from '../../../pages/newStudent/MyDataNewStudent';
import { defaultReturn } from '../../../util/newStudent';
import * as api from '../../api/newStudent/apiStudent.service';

interface IChangePassword {
  body: {
    password: string;
    newPassword: string;
    passwordConfirmation: string;
  };
}

interface IDataPoloRace {
  poleId?: number | null;
  raceColor?: number | null;
}
export const myDataServiceNewStudent = {
  async changePoleOrRace(data: IDataPoloRace) {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-data/update',
        'POST',
        data,
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async changeMyData(data: IUserData) {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-data/update',
        'POST',
        data,
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async changePhoto(photo: File) {
    try {
      const formData = new FormData();
      formData.append('file', photo);
      const request = await api.authenticatedRequest(
        '/student/my-data/update',
        'POST',
        formData,
        'file',
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async changePassword({ body }: IChangePassword) {
    try {
      const request = await api.authenticatedRequest(
        '/student/user/change-password',
        'POST',
        body,
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async studentData(body: { login: string }) {
    try {
      const request = await api.authenticatedRequest(
        '/helper/student/data',
        'POST',
        body,
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updatePreferences(data: { theme?: string; isOldLayout?: boolean }) {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-data/update-preferences',
        'POST',
        data,
      );

      return request;
    } catch (error) {
      throw new Error(error);
    }
  },
};

interface IGenerate {
  email?: string;
  phone?: string | number;
  code?: string | number;
}
export const confirmation = {
  async generateCode(data: IGenerate) {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-data/generate-confirmation-code',
        'POST',
        data,
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async confirmCode(data: IGenerate) {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-data/confirm-code',
        'POST',
        data,
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },
};
