import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import * as S from './styles';
import { Notice } from '../../../../student/Info';
import { INoticeRows } from '../../../../student/Info/Notice';
import { ChartInfoCourse } from '../../../../../components/student/Charts';
import studentDataService from '../../../../../services/student/student.data.service';
import { convertStringToMoment } from '../../../../../util/pageUtil';
import { statusStyle } from '../../../../../util/agent/searchInfoUtill';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../ProviderAgent';
import { warningToast } from '../../../../common/Toast';
import {
  ICourse,
  IPillar,
} from '../../../../../services/agent/student/interface';

export default function TabCourses({ data }) {
  const context = useContext(ProviderAgentContext);
  const { setIsLoading } = context as IAgentContextProps;

  const [courses, setCourses] = useState<ICourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<ICourse>();
  const [pillar, setPillar] = useState<IPillar>();
  const [rowsNotice, setRowsNotice] = useState<INoticeRows[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchCourses = async () => {
      const response = await studentDataService.courses({ login: data });

      if (response.result) {
        setCourses(response.data);
      } else {
        response?.message?.length > 0 &&
          response.message.forEach((text) => {
            warningToast(text);
          });
      }
      setIsLoading(false);
    };
    fetchCourses();
  }, [data]);

  const handleSelectCourse = (courseId) => {
    const selected = courses.find(
      (course) => course.courseId === Number(courseId),
    );
    if (selected) {
      setSelectedCourse(selected);
      setPillar(selected.pillar);

      const tccStatusStyle = statusStyle(selected.tccStatus);

      setRowsNotice([
        {
          subtitle: 'Assinatura do Contrato: ',
          text: (
            <span>
              {convertStringToMoment(
                selected.contractSignDate,
                'YYYY-MM-DD',
              ).format('DD/MM/YYYY')}
            </span>
          ),
        },
        {
          subtitle: 'Tempo Mínimo de Curso: ',
          text: String(selected.minimumCourseTime),
        },
        {
          subtitle: 'Status do TCC: ',
          text: <span style={tccStatusStyle}>{selected.tccStatus}</span>,
        },
        {
          subtitle: 'Data da Colação de Grau: ',
          text: (
            <span>
              {selected.ceremony
                ? convertStringToMoment(selected.ceremony, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY',
                  )
                : 'Data não informa'}
            </span>
          ),
        },
      ]);
    }
  };

  return (
    <S.Container>
      <S.Form>
        <Form.Group>
          <Form.Select
            size="lg"
            id="typeStatement"
            onChange={(e) => handleSelectCourse(e.target.value)}
          >
            <option>[ Selecione o curso ]</option>
            {courses.map((course) => (
              <option key={course.courseId} value={course.courseId}>
                {course.courseName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </S.Form>

      {selectedCourse && (
        <>
          {rowsNotice.length > 0 && <Notice rows={rowsNotice} />}
          <ChartInfoCourse pillar={pillar} />
          {selectedCourse.observation && (
            <S.Observation>
              <S.DivObservation>
                <S.H6>Observação do curso:</S.H6>

                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.observation,
                  }}
                ></div>
              </S.DivObservation>
            </S.Observation>
          )}
        </>
      )}
    </S.Container>
  );
}
