import styled from 'styled-components';

export const Container = styled.div``;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.5rem;
  color: ${({ theme }) => theme.colors?.text.danger};
`;
