import React from 'react';

import * as S from './styles';

import DownloadIcon from '@mui/icons-material/Download';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import YoutubeVideo from '../../../../../../../common/YoutubeVideo';
import { ISubjectContentData } from '../../../..';
import ButtonIcon from '../../../../../../Buttons/ButtonIcon';
import { useTheme } from 'styled-components';
import { AcademicProjects } from './components';
import { IClassInfo } from '../../../../../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';

interface Props {
  data?: ISubjectContentData;
  classInfo: IClassInfo;
}

const contendNotFound = () => {
  return (
    <S.Div>
      <h5>
        Conteúdo não encontrado.
        <br /> Favor entrar em contato com o NAE
      </h5>
    </S.Div>
  );
};
export default function LessonContent({ data, classInfo }: Props) {
  const theme = useTheme();
  return (
    <S.Container>
      <S.Content>
        {data?.type === 'video' &&
          (data.url ? (
            <S.VideoDiv className="video_content">
              <YoutubeVideo key={data?.url} url={data?.url} />
            </S.VideoDiv>
          ) : (
            contendNotFound()
          ))}
        {data?.type === 'archive' &&
          (data.url ? (
            <S.PdfDiv className="archive_content">
              <S.TitlePdfDiv>
                <S.TitleIconPdfDiv>
                  <DescriptionOutlinedIcon
                    style={{
                      width: '40px',
                      height: '40px',
                      color: theme.colors?.danger,
                    }}
                  />
                  <S.TitlePdfH5>{data.title}</S.TitlePdfH5>
                </S.TitleIconPdfDiv>
                <ButtonIcon
                  color="success"
                  icon={<DownloadIcon fontSize="large" />}
                  onClick={() => window.open(data.url)}
                />
              </S.TitlePdfDiv>
            </S.PdfDiv>
          ) : (
            contendNotFound()
          ))}

        {data?.type === 'audiobook' &&
          (data.url ? (
            <div>
              <iframe
                width="100%"
                height="450"
                allow="autoplay"
                src={data.url}
              ></iframe>
            </div>
          ) : (
            contendNotFound()
          ))}
        {data?.type === 'customized' &&
          (data.customized ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data.customized!,
              }}
            ></div>
          ) : (
            contendNotFound()
          ))}
        {data?.type === 'internship' &&
          (data.title ? (
            <AcademicProjects info={data} classInfo={classInfo} />
          ) : (
            contendNotFound()
          ))}
        {data?.type === 'tcc' &&
          (data.title ? (
            <AcademicProjects isTcc info={data} classInfo={classInfo} />
          ) : (
            contendNotFound()
          ))}
      </S.Content>
    </S.Container>
  );
}
