import { TableCell } from '@mui/material';
import styled from 'styled-components';

export const SubtitleTable = styled.b`
  color: gray;
  font-size: 14px;
`;

export const TableCellStyle = styled(TableCell)`
  width: 20px;
`;

export const ContainerTitle = styled.div`
  padding: 5rem 1rem 1rem 16px;
`;

export const SituationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 2.5rem;
  width: 120px;
`;
