import React, { useState } from 'react';

import * as S from './styles';
import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import Divider from '../../../components/common/Divider';
import CollapseCertificate from './components/CollapseCertificate';
import ContentCapacitation from './components/ContentCapacitation';
import {
  courseTypeGraduation,
  courseTypePosGraduation,
  courseTypeCapacitation,
} from '../../../variables';
import Graduation from './components/Graduation';
import PosGraduation from './components/PosGraduation';
import ContentCapacitationMobile from './components/ContentCapacitationMobile';
import { handleMaxWidth } from '../../../util/newStudent';
import { useTheme } from 'styled-components';
import CoursesCommonMobile from './components/CoursesCommonMobile';

export default function CertificateNewStudent() {
  const [whoIsOpen, setWhoIsOpen] = useState<number>();

  const [isOpenCapacitation, setIsOpenCapacitation] = useState<boolean>(false);
  const [isOpenGraduation, setIsOpenGraduation] = useState<boolean>(false);
  const [isOpenPosGraduation, setIsOpenPosGraduation] =
    useState<boolean>(false);

  const theme = useTheme();

  return (
    <S.Container>
      <TitlePage
        title="Meus Certificados"
        titlePage="Meus Certificados"
        subtitle="Aqui, você encontrará um registro detalhado de todas as suas conquistas acadêmicas e profissionais. 
        Navegue por este espaço para acessar e baixar seus certificados, validando suas habilidades e realizações. 
        Este menu oferece uma visão abrangente de suas certificações, permitindo que você gerencie seu portfólio de forma fácil e eficiente."
      />
      <Divider padding />
      <S.ButtonsOpen>
        <CollapseCertificate
          item={{ id: +courseTypeCapacitation!, name: 'Capacitação' }}
          isOpen={whoIsOpen}
          onClick={() => {
            if (handleMaxWidth(theme.size?.medium)) {
              setIsOpenCapacitation(!isOpenCapacitation);
              setWhoIsOpen(undefined);
            } else {
              whoIsOpen !== +courseTypeCapacitation!
                ? setWhoIsOpen(+courseTypeCapacitation!)
                : setWhoIsOpen(undefined);
            }
          }}
        />
        <CollapseCertificate
          item={{ id: +courseTypeGraduation!, name: 'Graduação' }}
          isOpen={whoIsOpen}
          onClick={() => {
            if (handleMaxWidth(theme.size?.medium)) {
              setIsOpenGraduation(!isOpenGraduation);
              setWhoIsOpen(undefined);
            } else {
              whoIsOpen !== +courseTypeGraduation!
                ? setWhoIsOpen(+courseTypeGraduation!)
                : setWhoIsOpen(undefined);
            }
          }}
        />
        <CollapseCertificate
          item={{ id: +courseTypePosGraduation!, name: 'Pós-Graduação' }}
          isOpen={whoIsOpen}
          onClick={() => {
            if (handleMaxWidth(theme.size?.medium)) {
              setIsOpenPosGraduation(!isOpenPosGraduation);
              setWhoIsOpen(undefined);
            } else {
              whoIsOpen !== +courseTypePosGraduation!
                ? setWhoIsOpen(+courseTypePosGraduation!)
                : setWhoIsOpen(undefined);
            }
          }}
        />
      </S.ButtonsOpen>

      {
        /** Tela de capacitação */
        isOpenCapacitation && (
          <ContentCapacitationMobile
            isOpen={isOpenCapacitation}
            setIsOpen={setIsOpenCapacitation}
          />
        )
      }
      {whoIsOpen === +courseTypeCapacitation! && (
        <S.Content>
          <ContentCapacitation />
        </S.Content>
      )}

      {
        /** Tela de Graduação */
        isOpenGraduation && (
          <CoursesCommonMobile
            isOpen={isOpenGraduation}
            setIsOpen={setIsOpenGraduation}
            isGraduation
          />
        )
      }
      {whoIsOpen === +courseTypeGraduation! && (
        <S.Content>
          <Graduation />
        </S.Content>
      )}

      {
        /** Tela de Pós-Graduação */
        isOpenPosGraduation && (
          <CoursesCommonMobile
            isOpen={isOpenPosGraduation}
            setIsOpen={setIsOpenPosGraduation}
          />
        )
      }
      {whoIsOpen === +courseTypePosGraduation! && (
        <S.Content>
          <PosGraduation />
        </S.Content>
      )}
    </S.Container>
  );
}
