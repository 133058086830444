import React, { Dispatch, SetStateAction, useContext } from 'react';

import * as S from '../styles';

import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { documentServiceNewStudent } from '../../../../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../components/newStudent/ProviderNewStudent';
import { FileItem, NamedItem } from '../..';
import { cleanCache } from '../../../../../../../util/common/appUtil';

interface Props {
  index: number;
  documentType: number;
  item: FileItem | NamedItem;
  setSelectedFiles: Dispatch<SetStateAction<FileItem[] | NamedItem[]>>;
  selectedFiles: FileItem[] | NamedItem[];
  setWasChanged: Dispatch<SetStateAction<boolean>>;
}
export default function Input({
  item,
  index,
  documentType,
  setSelectedFiles,
  selectedFiles,
  setWasChanged,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const handleFileSelect = (file: File, fileId: number, url: string) => {
    setSelectedFiles((items) => [
      ...items,
      { file: file, id: fileId, url: url },
    ]);
  };

  const handleFileChange = async (
    fileToUpload?: File,
    idToReplace?: number,
    toDelete?: boolean,
  ) => {
    setIsLoading(true);
    try {
      const body: { documentType: number; file?: File; id?: number } = {
        documentType: +documentType,
        file: fileToUpload ? fileToUpload : undefined,
      };

      if (idToReplace) body.id = +idToReplace;
      console.log(body, idToReplace);

      const { data, result } = await documentServiceNewStudent.documentRequest({
        body: body,
        action: toDelete ? 'delete' : 'update',
      });

      if (result) {
        cleanCache('dataDocuments');
        setWasChanged(true);
        if (!toDelete && fileToUpload) {
          handleFileSelect(fileToUpload, data.id, data.url);
          idToReplace && handleFileSelect(fileToUpload, idToReplace, data.url);
        }
      }
      setIsLoading(false);
      return result;
    } catch (error) {
      setIsLoading(false);
      throw new Error(error.message);
    }
  };

  const handleFileReplace = async (
    indexToReplace: number,
    newFile?: File | null,
  ) => {
    const sendFile = await handleFileChange(
      newFile!,
      selectedFiles[indexToReplace].id,
    );
    if (newFile && sendFile) {
      const updatedFiles = [...selectedFiles];
      updatedFiles[indexToReplace] = {
        file: newFile,
      };
      setSelectedFiles(updatedFiles);
    }
  };

  const handleRemove = async (index: number) => {
    const send = await handleFileChange(
      undefined,
      selectedFiles[index].id,
      true,
    );
    if (send) {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      return setSelectedFiles(updatedFiles);
    }
  };

  return (
    <S.Container>
      <S.DivFile>
        <S.InputFile index={index}>
          <span>{index === 0 ? 'Escolher arquivo' : 'Substituir arquivo'}</span>
          <input
            type="file"
            className="input-file"
            onChange={({ target }) => {
              target.files &&
                (index === 0
                  ? handleFileChange(target.files![0])
                  : handleFileReplace(index, target.files[0]));
            }}
          />
        </S.InputFile>
        <S.DivName>
          <S.H6>
            {item.file.name ? item.file.name : 'Nenhum arquivo escolhido'}
          </S.H6>
        </S.DivName>
        <S.BtnAction>
          {index === 0 ? (
            <>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={({ target }) => handleFileChange(target.files![0])}
              />
              <label htmlFor="fileInput">
                <S.BtnSuccess>
                  <AddCircleOutlineOutlinedIcon fontSize="large" />
                </S.BtnSuccess>
              </label>
            </>
          ) : (
            <>
              <S.BtnDanger
                onClick={() => handleRemove(index)}
                style={{
                  marginRight: selectedFiles[index].url ? '1rem' : '0',
                }}
              >
                <RemoveCircleOutlineOutlinedIcon
                  fontSize="large"
                  color="error"
                />
              </S.BtnDanger>
              {selectedFiles[index].url && (
                <S.BtnDownload
                  onClick={() => window.open(selectedFiles[index].url)}
                >
                  <DownloadIcon fontSize="large" />
                </S.BtnDownload>
              )}
            </>
          )}
        </S.BtnAction>
      </S.DivFile>
    </S.Container>
  );
}
