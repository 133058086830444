import React from 'react';

import * as S from './styles';
import FormField from '../../../../../FormField';

export default function SaleObservation({ negotiation, setNegotiation }) {
  return (
    <S.Container>
      <S.Title>Observação</S.Title>
      <S.Content>
        <FormField
          item={{
            handleChange: (e) =>
              setNegotiation((old) => ({
                ...old,
                observation: e.target.value,
              })),
            name: 'observation',
            type: 'text',
            value: negotiation.observation,
            rows: 2,
            style: { padding: '2rem' },
          }}
        />
      </S.Content>
    </S.Container>
  );
}
