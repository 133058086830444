import React, { useContext } from 'react';
import { Divider, Link, List, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { IOutletContext } from '../../../../../interfaces/student/dashboard.interfaces';
import { studentArea, company } from '../../../../../variables';
import { StudentProviderContext } from '../../../../../components/student/StudentProvider';

function DashboardInfo() {
  const context = useContext(StudentProviderContext);
  const { aluno } = context as IOutletContext;

  return (
    <div className="well">
      <Typography variant="h6" component="h2" paragraph={true}>
        <strong>{aluno && aluno.nome}</strong>, seja bem vindo(a) à área de
        alunos {aluno && aluno.empresa_id === 2 ? company : ''}.
      </Typography>
      <Box>
        <Typography variant="h6" component="h2" paragraph={true}>
          Neste portal você tem acesso à toda a sua jornada acadêmica.
        </Typography>
        Aqui você poderá:
        <List>
          <ul>
            <li>Atualizar seu cadastro</li>
            <li>Enviar documentos</li>
            <li>Solicitar serviços</li>
            <li>Receber notícias exclusivas e novidades</li>
            <li>Acessar seus cursos</li>
            <li>Acompanhar seu histórico financeiro</li>
            <li>Acompanhar seu TCC</li>
            <li>Falar com o professor</li>
          </ul>
        </List>
      </Box>

      <Typography
        variant="h6"
        component="h2"
        paragraph={true}
        sx={{ fontStyle: 'italic' }}
      >
        Para navegar em nosso sistema, clique em uma opção no menu lateral
        esquerdo e as informações serão exibidas logo em seguida.
      </Typography>
      <Typography variant="h6" component="h2" paragraph={true}>
        Você também pode acessar todas as informações sobre sua jornada
        acadêmica no{' '}
        <a href={studentArea} target="_blank" rel="noreferrer">
          Manual do Aluno
        </a>
      </Typography>

      <span>
        <Divider style={{ background: 'black' }} sx={{ height: 2 }} />
      </span>

      <Typography paragraph={true}></Typography>

      <Typography variant="h5" paragraph={true}>
        <strong>Novidades!</strong>
      </Typography>

      <Typography variant="h6" paragraph={true}>
        Agora teremos a opção de emissão dos certificados digitais.
      </Typography>
      <Typography variant="h6" paragraph={true}>
        Você poderá emitir seu certificado no formato digital de forma segura e
        simples, em sua área do aluno.
      </Typography>
      <Box>
        <Typography variant="h6" paragraph={true}>
          {`O certificado digital poderá ser solicitado manualmente após a
          conclusão do seu curso de pós-graduação e após cumprimento de todos os
          pilares do curso. Basta acessar o menu "Certificados" no canto
          esquerdo e seguir o passo a passo a seguir:`}
        </Typography>
        <Box>
          <List>
            <ul>
              <li>{`Acesse o menu "Certificados";`}</li>
              <li>Escolha o seu curso na lista suspensa;</li>
              <li>Confirme seu dados pessoais;</li>
              <li>
                {`Se todos os dados, estiverem corretos, marque a opção: "Li e confirmo que as informações aqui contidas
                estão corretas e não precisam de alterações";`}
              </li>
              <li>{`Clique no botão: "Solicitar Certificado digital";`}</li>
              <li>
                Basta aguardar a emissão do seu certificado, ele ficará
                disponível em sua área do aluno para download.
              </li>
            </ul>
          </List>
          <Typography variant="h6" component="h2" paragraph={true}>
            Lembrando que esse processo leva 7 dias úteis para pós-graduação.
          </Typography>
          <Typography variant="h4" paragraph={true}></Typography>
        </Box>

        <span>
          <Divider style={{ background: 'black' }} sx={{ height: 2 }} />
        </span>
        <Typography variant="h4" paragraph={true}></Typography>
        <Typography variant="h4" paragraph={true}>
          <strong>Você já ouviu falar do Aulão da Pós?</strong>
        </Typography>

        <Typography variant="h6" paragraph={true}>
          É um evento mensal, que acontece ao vivo em nosso canal do YouTube,
          com temas voltados para a sua área do aluno, TCC, estágio e muito
          mais!
        </Typography>
        <Typography variant="h6" paragraph={true}>
          Durante cada aulão, você terá a oportunidade de esclarecer todas as
          suas dúvidas com nossos professores e também de se conectar com outros
          alunos da pós-graduação que estão na mesma jornada que você.
        </Typography>
        <Typography variant="h6" paragraph={true}>
          O evento é gratuito e exclusivo para alunos da pós-graduação.
        </Typography>
        <Typography variant="h6" paragraph={true}>
          Não esqueça de ficar atento ao seu e-mail, pois antes de cada aulão,
          enviaremos um formulário para você preencher com suas principais
          dúvidas sobre o tema do mês. E se você não puder assistir ao vivo, não
          se preocupe, todos os vídeos gravados estarão disponíveis na{' '}
          <Link
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLy17lcQqr7Xz7qXhuFxfBf-6u4qY_Swcl"
            rel="noreferrer"
            underline="none"
          >
            Playlist do Aulão da Pós
          </Link>
          .
        </Typography>
        <Typography variant="h6" paragraph={true}>
          Esperamos por você no próximo Aulão da Pós!
        </Typography>

        <span>
          <Divider style={{ background: 'black' }} sx={{ height: 2 }} />
        </span>
        <Typography variant="h4" paragraph={true}></Typography>
        <Typography variant="h4" paragraph={true}>
          <strong>Queremos te conhecer melhor! </strong>
        </Typography>
        <div>
          <span>
            Responda nossa{' '}
            <Link
              target="_blank"
              href="https://forms.gle/a7yNoyjkgxtYXWiV9"
              rel="noreferrer"
              underline="none"
            >
              Pesquisa.
            </Link>
          </span>
        </div>
      </Box>
    </div>
  );
}

export default DashboardInfo;
