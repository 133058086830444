import { invoiceIugu, creditCardIugu, pixIugu } from '../variables';
import { Payment } from '../models/Financial';

export const payment = {
  selectPayment(contaBancaria) {
    const tiposPagamento = [
      new Payment(creditCardIugu, creditCardIugu, 'Cartão de Crédito'),
      new Payment(invoiceIugu, invoiceIugu, 'Boleto'),
      new Payment(pixIugu, pixIugu, 'Pix'),
    ];

    const contasPagamento = [creditCardIugu, invoiceIugu, pixIugu];

    const contasSelecionada = contaBancaria.filter((conta) =>
      contasPagamento.includes(conta.id.toString()),
    );

    const paymentOptions = tiposPagamento.map((payment) => {
      const contaSelecionada = contasSelecionada.find(
        (conta) => conta.id.toString() === payment.id,
      );
      if (contaSelecionada) {
        return {
          ...payment,
          multa: contaSelecionada.multa,
          juros: contaSelecionada.juros,
          desconto: contaSelecionada.desconto,
        };
      }
      return payment;
    });

    return paymentOptions;
  },
};
