import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import BasicModal from '../BasicModal';
import { ButtonSimple } from '../../Buttons';
import { useNavigate } from 'react-router-dom';

interface Props {
  canUpdate: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
  message?: string;
}

export default function UpdateBillet({
  isOpen,
  setIsOpen,
  handleSubmit,
  message,
  canUpdate,
}: Props) {
  const navigate = useNavigate();
  return (
    <S.Container>
      <BasicModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isFloat
        ableBackdropClick
      >
        <S.Content>
          <S.Text>
            <S.H4>
              {canUpdate
                ? 'Você deseja atualizar seu boleto?'
                : 'Boleto não pode ser atualizado!'}
            </S.H4>
            <S.H6>{message}</S.H6>
          </S.Text>
          <S.Buttons>
            <ButtonSimple
              onClick={() => setIsOpen(false)}
              color="danger"
              outline
            >
              Cancelar
            </ButtonSimple>

            <ButtonSimple
              onClick={() =>
                canUpdate
                  ? handleSubmit()
                  : navigate('/new/aluno/historico-financeiro')
              }
              color="primary"
            >
              <h6>{canUpdate ? 'Atualizar' : 'Redirecionar'}</h6>
            </ButtonSimple>
          </S.Buttons>
        </S.Content>
      </BasicModal>
    </S.Container>
  );
}
