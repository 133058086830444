import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 3rem;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const Subtitle = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Buttons = styled.div`
  display: flex;

  gap: 1rem;
  width: 30rem;
  padding-top: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
    width: 20rem;
  }
`;
