import { defaultReturn } from '../../../util/newStudent';
import * as apiService from '../../api/newStudent/apiStudent.service';

export const ticketsServiceNewStudent = {
  async show() {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/ticket/show',
        'GET',
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async find(ticket: number) {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/ticket/find-ticket',
        'POST',
        { ticket: ticket },
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async urlTicket() {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/ticket/new-ticket',
      );

      return request;
    } catch (error) {
      throw new Error(error);
    }
  },
};
