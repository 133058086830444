import React from 'react';

import * as S from '../../styles';

import {
  formatCpf,
  formatDate,
} from '../../../../../../../util/common/appUtil';
import { IUserData } from '../../../../../MyDataNewStudent';

interface Props {
  dataStudent: IUserData;
  noBorder?: boolean;
}
export default function DataStudent({ dataStudent, noBorder }: Props) {
  return (
    <S.DivData noBorder={noBorder}>
      <S.Title>Antes de continuar, confira seus dados abaixo:</S.Title>
      <S.DivInformaiton>
        <S.KeyValue>
          <S.Key>Nome: </S.Key>
          <S.Value>{dataStudent?.name}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>CPF: </S.Key>
          <S.Value>{formatCpf(dataStudent?.cpf)}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>RG: </S.Key>
          <S.Value>{dataStudent?.rgId}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Data de Nascimento: </S.Key>
          <S.Value>{formatDate(dataStudent?.bornDate)}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Naturalidade: </S.Key>
          <S.Value>{dataStudent?.naturalness}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Nacionalidade: </S.Key>
          <S.Value>{dataStudent?.nationality}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Nome do Pai: </S.Key>
          <S.Value>{dataStudent?.fatherName}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Nome da Mãe: </S.Key>
          <S.Value>{dataStudent?.motherName}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Logradouro: </S.Key>
          <S.Value>{dataStudent?.street}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Número: </S.Key>
          <S.Value>{dataStudent?.number}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Complemento: </S.Key>
          <S.Value>{dataStudent?.complement}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Bairro: </S.Key>
          <S.Value>{dataStudent?.neighborhood}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Cidade: </S.Key>
          <S.Value>{dataStudent?.city}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>Estado: </S.Key>
          <S.Value>{dataStudent?.state}</S.Value>
        </S.KeyValue>
        <S.KeyValue>
          <S.Key>CEP: </S.Key>
          <S.Value>{dataStudent?.cep}</S.Value>
        </S.KeyValue>
      </S.DivInformaiton>
    </S.DivData>
  );
}
