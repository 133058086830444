import React from 'react';

import * as S from './styles';
import { useLocation } from 'react-router-dom';
import {
  phoneService,
  phoneWhatsapp,
  sieadImagesFolderUrl,
} from '../../../variables';
import { Phone, WhatsApp } from '@mui/icons-material';

export default function NotFoundNewStudent() {
  const { pathname } = useLocation();
  const errorImage = `${sieadImagesFolderUrl}page_error.svg`;
  const logo = `${sieadImagesFolderUrl}c81e728d9d4c2f636f067f89cc14862c.jpg`;

  return (
    <S.Container>
      <S.Content>
        <S.DivImg>
          <S.Img src={errorImage}></S.Img>
          <S.TitleError>Erro 404</S.TitleError>
        </S.DivImg>
        <S.Subtitle>
          Alguma coisa deu errada. A página que você está procurando não foi
          encontrada.
        </S.Subtitle>
        <S.Text>
          O que pode ter acontecido?
          <br />
          <br />
          O conteúdo que você procura pode ter sido removido ou estar
          temporáriamente indisponível.
          <br />
          Verifique se você digitou corretamente o endereço desejado ou, se
          preferir, acesse a{' '}
          <a
            href={
              pathname.startsWith('/agenciador')
                ? '/agenciador'
                : pathname.startsWith('/new')
                ? '/new/aluno/inicio'
                : '/aluno/dashboard'
            }
            style={{
              color: '#47AAEB',
            }}
          >
            página inicial{' '}
          </a>
          para encontrar o que precisa.
        </S.Text>
        <S.DivMobile>
          <S.DivPhone>
            <Phone fontSize="large" />
            <S.Phone>
              <S.Text>
                Atendimento
                <br />
                <b>{phoneService}</b>
              </S.Text>
            </S.Phone>
          </S.DivPhone>
          <S.DivPhone>
            <WhatsApp fontSize="large" />
            <S.Phone>
              <S.Text>
                Whatsapp
                <br />
                <b>{phoneWhatsapp}</b>
              </S.Text>
            </S.Phone>
          </S.DivPhone>
          <S.DivImgLogo>
            <S.ImgLogo src={logo} alt="logo" />
          </S.DivImgLogo>
        </S.DivMobile>
        <S.StyledTable>
          <tbody>
            <S.Row>
              <S.Td>
                <S.DivPhone>
                  <Phone fontSize="large" />
                  <S.Phone>
                    <S.Text>
                      Atendimento
                      <br />
                      <b>{phoneService}</b>
                    </S.Text>
                  </S.Phone>
                </S.DivPhone>
              </S.Td>
              <S.Td>
                <S.DivPhone>
                  <WhatsApp fontSize="large" />
                  <S.Phone>
                    <S.Text>
                      Whatsapp
                      <br />
                      <b>{phoneWhatsapp}</b>
                    </S.Text>
                  </S.Phone>
                </S.DivPhone>
              </S.Td>
              <S.Td>
                <S.DivImgLogo>
                  <S.ImgLogo src={logo} alt="logo" />
                </S.DivImgLogo>
              </S.Td>
            </S.Row>
          </tbody>
        </S.StyledTable>
      </S.Content>
    </S.Container>
  );
}
