import React from 'react';

import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as S from './style';

import { RowTextBold } from '../../common/Table/components';
import { FileDocumentList } from '../../../pages/student/Documents/components';
import {
  StyledAccordion,
  IDocumentData,
} from '../../../pages/student/Documents';

export default function AccordionDocuments({
  dataType,
}: {
  dataType: IDocumentData[] | [];
}) {
  const structure = {
    Pendente: '#ffa500 ',
    Rejeitado: '#F00',
    'Em Análise': '#0000ff ',
    Concluído: '#008000',
    Aprovado: '#008000',
  };

  return (
    <div style={{ marginBottom: '4rem' }}>
      {dataType &&
        dataType.map((item, index) => (
          <div key={item.id}>
            <StyledAccordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="accordion-content"
                id="accordion-header"
              >
                <S.AccordionSummary>
                  <S.AccordionSummary>
                    <RowTextBold>{item.name}</RowTextBold>
                    <RowTextBold color={structure[item.status]}>
                      {item.status}
                    </RowTextBold>
                  </S.AccordionSummary>
                  <div>
                    <RowTextBold>Abrir</RowTextBold>
                  </div>
                </S.AccordionSummary>
              </AccordionSummary>
              <AccordionDetails>
                <FileDocumentList
                  documentTypeId={dataType[index].id}
                  oldFiles={item.analysis}
                  canEdit={
                    item.status !== 'Aprovado' && item.status !== 'Concluído'
                  }
                />
              </AccordionDetails>
            </StyledAccordion>
          </div>
        ))}
    </div>
  );
}
