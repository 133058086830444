import React from 'react';
import { Form, Col } from 'react-bootstrap';
export interface IFieldOptions {
  title?: string;
  value?: boolean | string | number;
}

interface Props {
  title?: string;
  value?: string;
  type?: string;
  name?: string;
  maxLength?: number;
  sm?: number;
  lg?: number;
  xs?: number;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (e) => void;
  options?: IFieldOptions[];
}

export default function FieldInformation({
  title,
  value,
  type,
  name,
  maxLength,
  disabled = true,
  onChange,
  options,
  xs,
  sm,
  lg,
}: Props) {
  const textStyle = {
    backgroundColor: 'white',
    height: '3.2rem',
    color: disabled ? '#b6b6b6' : '#1b1b1b',
    borderColor: disabled ? '#dadada' : '#a5a4a4',
    fontSize: '13px',
  };
  return (
    <Col xs={xs} sm={sm} lg={lg} style={{ marginTop: 10 }}>
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        {type === 'select' ? (
          <>
            <Form.Select
              name={name}
              style={textStyle}
              disabled={disabled}
              onChange={(e) => onChange && onChange(e)}
              value={
                value !== undefined && value !== null ? value.toString() : ''
              }
            >
              {options?.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={
                      item.value !== undefined ? item.value.toString() : ''
                    }
                  >
                    {item.title}
                  </option>
                );
              })}
            </Form.Select>
          </>
        ) : (
          <Form.Control
            disabled={disabled}
            maxLength={maxLength}
            style={{ backgroundColor: 'white' }}
            name={name}
            type={type}
            value={value}
          />
        )}
      </Form.Group>
    </Col>
  );
}
