import React from 'react';
import PrintIcon from '@mui/icons-material/Print';

import ButtonIcon from '../ButtonIcon';

interface Props {
  color?: 'blue' | 'green' | 'white' | 'icon';
  onClick?: () => void | Promise<void>;
}

export default function ButtonPrint({
  onClick = () => {},
  color = 'white',
}: Props) {
  return (
    <ButtonIcon color={color} onClick={onClick}>
      <PrintIcon />
    </ButtonIcon>
  );
}
