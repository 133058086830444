import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import * as S from './styles';
import { Modal } from '@mui/material';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  handleOnClose?: () => void;
  isFloat?: boolean;
  zIndex?: number;
  disableEsc?: boolean;
  disableWidth?: boolean;
}

export default function BasicModal({
  isOpen,
  setIsOpen,
  handleOnClose,
  children,
  isFloat,
  zIndex,
  disableEsc,
  disableWidth = false,
}: Props) {
  return (
    <Modal
      open={!!isOpen}
      disableEscapeKeyDown={disableEsc}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        setIsOpen(false);
        handleOnClose && handleOnClose();
      }}
      aria-labelledby="modal-basic"
      aria-describedby="modal-basic-description"
      sx={{
        backgroundColor: 'rgba(0, 0, 0, .6)',
        overflow: 'auto',
        zIndex: zIndex,
      }}
    >
      <S.BoxStyle
        sx={{
          p: 4,
          ...(isFloat && {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }),
        }}
      >
        <S.PaperStyled disableWidth={disableWidth}>{children}</S.PaperStyled>
      </S.BoxStyle>
    </Modal>
  );
}
