import React from 'react';

import * as S from './styles';
import ButtonOpen from '../../../../../components/newStudent/Buttons/ButtonOpen';
interface IItem {
  id: number;
  name: string;
}
interface Props {
  item: IItem;
  isOpen: number | undefined;
  onClick?: () => void;
}
export default function CollapseCertificate({ item, isOpen, onClick }: Props) {
  return (
    <S.Container>
      <S.ButtonDiv>
        <ButtonOpen
          title={item.name}
          widthSize="fullWidth"
          font="h4"
          styles={{ height: isOpen !== item.id ? '8rem' : '12rem' }}
          onClick={() => {
            onClick && onClick();
          }}
        />
      </S.ButtonDiv>
    </S.Container>
  );
}
