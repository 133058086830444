import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import * as S from './styles';
import { RowText, RowTextBold, TitleTable } from '../components';

interface IProps {
  columns: IRegistrationColumns;
  rows: IRegistrationRows[];
}

interface IRegistrationColumns {
  title: string;
  titleAction: string;
}

interface IRegistrationRows {
  title: string;
  subtitle: string;
  action: string;
  link: string;
  html?: string | null;
}

export default function DocumentsTable({ columns, rows }: IProps) {
  return (
    <S.TableContainerStyle>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TitleTable>{columns.title}</TitleTable>
            </TableCell>
            <S.CellRow align="right">
              <S.Cell>
                <TitleTable>{columns.titleAction}</TitleTable>
              </S.Cell>
            </S.CellRow>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => {
            return (
              <TableRow key={row.title}>
                <S.TableCellStyle scope="row">
                  <div>
                    <RowTextBold align="left">{row.title}</RowTextBold>
                  </div>
                  <RowText align="left">{row.subtitle}</RowText>
                </S.TableCellStyle>
                <TableCell component="th" scope="row" align="right">
                  <S.ContainerLine>
                    <a href={row.link} target="_blank" rel="noreferrer">
                      <DownloadIcon />
                      {/* Criar ação de baixar o arquivo*/}
                    </a>
                  </S.ContainerLine>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </S.TableContainerStyle>
  );
}
