import { defaultReturn } from '../../../util/newStudent';
import * as api from '../../api/newStudent/apiStudent.service';

export interface IStatementsRequirements {
  title: string;
  structure: {
    title: string;
    status: string | string[];
  }[];
}

export interface ICourseCategory {
  type: string;
  id: number;
  courseName: string;
  courseTypeId: number;
}

interface Pillar {
  minimumTime: number;
  documentation: number;
  financial: number;
  exam: number;
}
export interface IDataCourseStudent {
  title: string;
  structure: {
    title: string;
    value:
      | number
      | string
      | {
          completed: boolean;
          obrigatory: boolean;
          status: string;
          title: string;
          value: string;
        }[];
  }[];
  statementCanBeRequested?: boolean;
  pillar: Pillar;
}

export const statementsServiceNewStudent = {
  async show(): Promise<{
    data: {
      statements: ICourseCategory[];
      requirements: IStatementsRequirements[];
    };
    message: string[];
    result: boolean;
  }> {
    const request = await api.authenticatedRequest(
      '/student/statements/show',
      'GET',
    );

    return defaultReturn(request);
  },

  async getDocument(id: number, type: 1 | 2) {
    const request = await api.authenticatedRequest(
      '/student/statements/request-statement/' + id,
      'POST',
      {
        type,
      },
    );
    return defaultReturn(request);
  },

  async getStatementsRequiriments(id: number, type: 1 | 2) {
    const request = await api.authenticatedRequest(
      '/student/statements/statements-requirements/' + id,
      'POST',
      {
        type,
      },
    );
    return defaultReturn(request);
  },
};
