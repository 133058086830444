import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import SliderComponent from '../../../../../SliderComponent';
import { numberToReal } from '../../../../../../../util/agent/money';
import { IIsItemsEmpty } from '../..';
import { INewNegotiationSale } from '../../../../../../../pages/agent/MyBusiness/components/ModalNewStudentData';

import * as S from './styles';

interface Props {
  negotiation: INewNegotiationSale;
  setNegotiation: Dispatch<SetStateAction<INewNegotiationSale>>;
  maxStudentValue: number;
  maxPaymentTypeValue: number;
  maxComboValue: number;
  isItemsEmpty: IIsItemsEmpty;
  idCardDiscount?: number;
  setIdCardDiscount?: Dispatch<SetStateAction<number>>;
  cardDiscount?: {
    discountId: number;
    type: string;
    discountDescription: string;
    percentage: number;
    maxDiscount: number;
  }[];
}

export default function SaleDiscount({
  maxStudentValue,
  maxPaymentTypeValue,
  maxComboValue,
  isItemsEmpty,
  negotiation,
  setNegotiation,
  idCardDiscount,
  setIdCardDiscount,
  cardDiscount,
}: Props) {
  const setMarks = (num) => {
    return [
      {
        value: 0,
        label: <S.Mark0>R$ 0,00</S.Mark0>,
      },
      {
        value: num,
        label: <S.Mark>{num > 0 ? numberToReal(num) : null}</S.Mark>,
      },
    ];
  };

  useEffect(() => {
    const setClean = cardDiscount?.filter(
      (item) => item.discountId === idCardDiscount,
    );

    setClean!.length <= 0 && setIdCardDiscount!(0);
  }, [cardDiscount]);

  const [tmpCombo, setTmpCombo] = useState(negotiation.discounts.combo?.value);
  const [tmpStudent, setTmpStudent] = useState(
    negotiation.discounts.studentDiscount?.value,
  );
  const [tmpPayment, setTmpPayment] = useState(
    negotiation.discounts.paymentType?.value,
  );

  useEffect(() => {
    setNegotiation((old) => ({
      ...old,
      discounts: {
        ...old.discounts,
        studentDiscount: {
          ...old.discounts.studentDiscount,
          value: tmpStudent,
        },
        combo: { ...old.discounts.combo, value: tmpCombo },
        paymentType: { ...old.discounts.paymentType, value: tmpPayment },
      },
    }));
  }, [tmpStudent, tmpCombo, tmpPayment]);

  const student = useMemo(() => {
    if (tmpStudent == '0') {
      setTmpStudent(negotiation.discounts.studentDiscount?.value);
    }
    return negotiation.discounts.studentDiscount?.value;
  }, [negotiation.discounts.studentDiscount?.value]);

  const combo = useMemo(() => {
    if (tmpCombo == '0') {
      setTmpCombo(negotiation.discounts.combo?.value);
    }
    return negotiation.discounts.combo?.value;
  }, [negotiation.discounts.combo?.value]);

  const payment = useMemo(() => {
    setTmpPayment(negotiation.discounts.paymentType?.value);

    return negotiation.discounts.paymentType?.value;
  }, [negotiation.discounts.paymentType?.value]);

  const orderCardDiscount = cardDiscount!.sort((a, b) => {
    return a.discountDescription.length - b.discountDescription.length;
  });

  return (
    <S.Container>
      <S.Title>Desconto</S.Title>
      <S.Content>
        <SliderComponent
          maxValue={maxStudentValue}
          disabled={isItemsEmpty.discount.student}
          title="Aluno"
          value={student!}
          setValue={setTmpStudent}
          input={true}
          marks={setMarks(maxStudentValue)}
        />
        <SliderComponent
          maxValue={maxComboValue}
          disabled={isItemsEmpty.discount.combo}
          title="Combo / Campanha"
          value={combo!}
          setValue={setTmpCombo}
          input={true}
          marks={setMarks(maxComboValue)}
        />
        <SliderComponent
          maxValue={maxPaymentTypeValue}
          disabled={isItemsEmpty.discount.courses}
          title="Forma Pgto"
          value={payment!}
          setValue={setTmpPayment}
          input={true}
          marks={setMarks(maxPaymentTypeValue)}
        />
        <S.ContainerCard>
          {orderCardDiscount!.map((item) => (
            <S.Card
              key={item.discountId}
              checked={idCardDiscount === item.discountId}
              onClick={() => {
                if (idCardDiscount === item.discountId) {
                  return setIdCardDiscount!(0);
                }
                setIdCardDiscount!(item.discountId);
              }}
            >
              <S.CardImg>
                {item.type === 'Campanha' ? (
                  <S.Img src={'/img/agent/card_img/campaign.svg'} />
                ) : (
                  <S.Img src={'/img/agent/card_img/discount.svg'} />
                )}
              </S.CardImg>
              <S.CardContent>
                <S.Checkbox>
                  <input
                    type="checkbox"
                    checked={idCardDiscount === item.discountId}
                    onChange={() => {
                      if (idCardDiscount === item.discountId) {
                        return setIdCardDiscount!(0);
                      }
                      setIdCardDiscount!(item.discountId);
                    }}
                  />
                </S.Checkbox>
                <S.CardTitle>{item.discountDescription}</S.CardTitle>
                <S.CardInfo>
                  <S.Info>
                    <div>
                      <S.ItemTitle>Porcentagem</S.ItemTitle>
                    </div>
                    <div>
                      <S.ItemSubtitle>{item.percentage}%</S.ItemSubtitle>
                    </div>
                  </S.Info>
                  <S.Info>
                    <div>
                      <S.ItemTitle>Desconto</S.ItemTitle>
                    </div>
                    <div>
                      <S.ItemSubtitle>
                        {numberToReal(item.maxDiscount)}
                      </S.ItemSubtitle>
                    </div>
                  </S.Info>
                </S.CardInfo>
              </S.CardContent>
            </S.Card>
          ))}
        </S.ContainerCard>
      </S.Content>
    </S.Container>
  );
}
