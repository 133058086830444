import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerSaleInfo = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;

  @media screen and (max-width: 985px) {
    flex-direction: column;
  }
`;

export const ContainerPayment = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 769px) {
    gap: 1rem;
    flex-direction: column;
  }
`;

export const SelectionInfo = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;
