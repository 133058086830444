import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

export default function NotFound() {
  return (
    <S.Container>
      <S.H1>Página Não Encontrada!</S.H1>
      <Link to="/aluno/dashboard">
        <S.Button>Voltar</S.Button>
      </Link>
    </S.Container>
  );
}
