import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form } from 'react-router-dom';

import FormField from '../../../../../../../FormField';
import BasicModal from '../../../../../../../Modal';
import HeaderModal from '../../../../../../../HeaderModal';

import * as S from './styles';
import FooterModal from '../../../../../../../FooterModal';
import { numberToReal } from '../../../../../../../../../util/agent/money';
import {
  IFormDataStudent,
  IItem,
  IOptionDescription,
  IdontSearchItem,
} from '../../../../../../../../../services/agent/student/interface';
import { defaultItemSale } from '../..';
import { warningToast } from '../../../../../../../../common/Toast';
import {
  typeServiceExam,
  saleItemTypeService,
  typeServiceRegistrationRenewal,
  typeServiceCancel,
} from '../../../../../../../../../variables';
import { INewNegotiationSale } from '../../../../../../../../../pages/agent/MyBusiness/components/ModalNewStudentData';
import { saleNegotiationService } from '../../../../../../../../../services/agent';

interface IEditItem {
  index?: number;
  edit?: IItem;
}
interface Props {
  openNewItemModal: boolean;
  setOpenNewItemModal: Dispatch<SetStateAction<boolean>>;
  handleAddItem: (e) => void;
  editItem: IEditItem;
  setNegotiation: Dispatch<SetStateAction<INewNegotiationSale>>;
  negotiation: INewNegotiationSale;
  setEditItem: Dispatch<SetStateAction<IEditItem>>;
  setItems: Dispatch<SetStateAction<IItem[]>>;
  dontSearch: IdontSearchItem[];
  form: IFormDataStudent;
}

export default function SaleModalItem({
  openNewItemModal,
  setOpenNewItemModal,
  setNegotiation,
  negotiation,
  handleAddItem,
  editItem,
  setEditItem,
  setItems,
  form,
  dontSearch,
}: Props) {
  const [item, setItem] = useState<IItem>(defaultItemSale);
  const [error, setError] = useState('');

  /** Estrutura de input dos selects */
  const data = [
    {
      title: 'Item',
      name: 'item',
      type: 'select',
      value: item?.item.value,
      disabled: !!editItem.edit?.description.title,
      isInvalid: !!error,
      handleChange: (e) => {
        setError('');

        const selectElement = e.currentTarget;
        const selectedOption =
          selectElement.options[selectElement.selectedIndex];
        setItem((oldItems: IItem) => ({
          ...oldItems,
          item: {
            ...oldItems.item,
            value: e.target.value,
            title: selectedOption.text,
          },
          type: {
            ...oldItems.type,
            options: [{ title: '[ SELECIONE ]', value: 0 }],
            value: '',
            title: '',
          },
          value: { value: 0 },
          discount: { value: 0 },
          description: defaultItemSale.description,
        }));
      },
      options: item?.item?.options,
      lg: 3,
    },
    {
      title: 'Tipo',
      name: 'type',
      type: 'select',
      value: item?.type.value,
      isInvalid: !!error,
      disabled: !!editItem.edit?.description.title,
      handleChange: (e) => {
        setError('');
        const selectElement = e.currentTarget;
        const selectedOption =
          selectElement.options[selectElement.selectedIndex];
        setItem((oldItems) => ({
          ...oldItems,
          type: {
            ...oldItems.type,
            value: e.target.value,
            title: selectedOption.text,
          },
          value: { value: 0 },
          discount: { value: 0 },
          description: defaultItemSale.description,
        }));
      },
      options: item?.type?.options,
      lg: 9,
    },
    {
      title: 'Descrição',
      name: 'description',
      type: 'select',
      value: +item?.description.value,
      isInvalid: !!error,
      disabled: !!editItem.edit?.description.title,
      handleChange: (e) => {
        setError('');
        const selectElement = e.currentTarget;
        const selectedOption =
          selectElement.options[selectElement.selectedIndex];

        const observationItem = item.description.options.find(
          (items: IOptionDescription) => items.value == selectedOption.value,
        );
        setItem((oldItems) => ({
          ...oldItems,
          description: {
            ...oldItems.description,
            value: e.target.value,
            title: selectedOption.text,
            observation: observationItem?.observation,
          },
        }));
      },
      options: item?.description?.options,
      lg: 12,
    },
  ];

  /** Coloca o maximo de desconto ao incluir um curso */
  const maxDiscount = useMemo(() => {
    const tmp = item.description.options.filter(
      (opt: IOptionDescription) =>
        opt.value === (item.description.value && +item.description.value),
    );
    tmp &&
      setItem((old) => ({
        ...old,
        value: { value: tmp[0]?.courseValue || 0 },
      }));
    return tmp[0]?.maxDiscount || 0;
  }, [item.description.value]);

  /** Estrutura de input do desconto */
  const inputDiscount = {
    name: 'discount',
    placeholder: '0,00',
    type: 'number',
    disabled: maxDiscount <= 0,
    value: item?.discount.value || undefined,
    isInvalid: !!error,
    handleChange: (e) => {
      setError('');
      setItem((oldItems) => ({
        ...oldItems,
        discount: { value: e.target.value > 0 ? e.target.value : 0 },
      }));
    },
    lg: 4,
    style: {
      boxShadow: 'none',
      textAlign: 'end',
    },
  };

  /** Inclui o item  */
  const handleSave = () => {
    let hasUndefinedOrEmptyValue = false;
    for (const key in item) {
      if (
        key !== 'discount' &&
        (item[key].value === undefined || item[key].value === '')
      ) {
        hasUndefinedOrEmptyValue = true;
        break;
      }
    }
    if (item?.discount?.value && item?.discount?.value > maxDiscount) {
      return setError('Valor do desconto superior ao "Maximo de desconto"');
    }
    if (hasUndefinedOrEmptyValue) {
      return setError('Favor preencher todos os dados!');
    }
    if (
      +item.value.value <= 0 &&
      (+item.item.value !== +saleItemTypeService! ||
        (+item.item.value === +saleItemTypeService! &&
          !(
            +item.type.value === +typeServiceExam! ||
            +item.type.value === +typeServiceRegistrationRenewal! ||
            +item.type.value === +typeServiceCancel!
          )))
    ) {
      return setError('Valor deve ser diferente de Zero!');
    }
    if (typeof editItem.index !== 'number') {
      setItem(defaultItemSale);
      handleAddItem(item);

      return setOpenNewItemModal(false);
    } else {
      setItems((oldItems) => {
        const updatedItems = [...oldItems];
        if (editItem.index! >= 0 && editItem.index! < updatedItems.length) {
          updatedItems[editItem.index! || 0] = item;
        } else {
          item ? updatedItems.push(item) : null;
        }
        return updatedItems;
      });
      setOpenNewItemModal(false);
    }
  };

  /** Cancela a inclusão do item e limpa tudo */
  const handleCancel = () => {
    setOpenNewItemModal(false);
    setItem(defaultItemSale);
    setError('');
    setEditItem({
      index: undefined,
      edit: undefined,
    });
  };

  /** Coloca o dado de edição para ser editado */
  useEffect(() => {
    if (editItem.edit) {
      setItem(editItem.edit);
    }
  }, [openNewItemModal]);

  /** Pesquisa quais tipos de curso pode selecionar */
  useEffect(() => {
    if (editItem.edit?.description.title) {
      return;
    }
    const load = async () => {
      if (item?.item.value) {
        const response = await saleNegotiationService.consultType(
          item.item.value,
        );
        if (!response.data) {
          return;
        }

        const { data } = response;
        const itemMap = data.map((e) => {
          return {
            title: e.nome,
            value: e.id,
          };
        });
        return setItem((old) => ({
          ...old,
          type: {
            ...old.type,
            options: [{ title: '[ SELECIONE ]', value: undefined }, ...itemMap],
          },
        }));
      }
      return setItem((old) => ({
        ...old,
        type: {
          value: '',
          title: '',
          options: [{ title: '[ SELECIONE ]', value: 0 }],
        },
        description: {
          value: '',
          title: '',
          options: [
            {
              title: '[ SELECIONE ]',
              value: 0,
              maxDiscount: 0,
              courseValue: 0,
            },
          ],
        },
      }));
    };

    load();
  }, [item.item.value]);

  /** Pesquisa quais cursos pode selecionar */
  useEffect(() => {
    if (editItem.edit?.description.title) {
      return;
    }
    const load = async () => {
      if (item.type.value) {
        const { data, result, message } =
          await saleNegotiationService.consultByType(
            item.item.value,
            item.type.value,
            editItem.edit ? dontSearch : null,
            form.dataStudent.cpf
              ? form.dataStudent.cpf
              : form.dataStudent.email,
          );
        if (!result) {
          message.forEach((text: string) => {
            warningToast(text);
          });
        }
        if (data) {
          const itemMap = data.map((e) => {
            return {
              title: e.name,
              value: e.id,
              maxDiscount: e.discount,
              courseValue: e.value,
              observation: e.observation,
            };
          });
          return setItem((old) => ({
            ...old,
            description: {
              ...old.description,
              options: [
                {
                  title: '[ SELECIONE ]',
                  value: undefined,
                  maxDiscount: undefined,
                  courseValue: undefined,
                  observation: undefined,
                },
                ...itemMap,
              ],
            },
          }));
        }
      }
      setItem((old) => ({
        ...old,
        description: {
          ...old.description,
          options: [
            {
              title: '[ SELECIONE ]',
              value: 0,
              maxDiscount: 0,
              courseValue: 0,
            },
          ],
        },
      }));
    };

    load();
  }, [item.type.value]);

  return (
    <BasicModal
      disableWidth
      isOpen={openNewItemModal}
      setIsOpen={setOpenNewItemModal}
      handleOnClose={handleCancel}
    >
      <S.Content>
        <HeaderModal
          handleClose={handleCancel}
          setOpen={setOpenNewItemModal}
          title={editItem.edit?.description.title ? 'Editar Item' : 'Novo Item'}
          subtitle
        />
        <Form>
          <S.Contents>
            <S.Fieldset>
              <div>
                {data.map((item, index) => {
                  return <FormField item={item} key={index} />;
                })}
              </div>
              {item.item.value === saleItemTypeService &&
                item.type.value === typeServiceExam &&
                item.description.value && (
                  <S.DivCheckbox>
                    <b>Solicitar Isenção do Vestibular</b>
                    <input
                      style={{ margin: '1rem 1rem' }}
                      checked={!!negotiation?.entranceExamination}
                      disabled={!!editItem.edit?.description.title}
                      onChange={(e) => {
                        item.item.value === saleItemTypeService &&
                          item.type.value === typeServiceExam &&
                          item.description.value &&
                          setNegotiation((old) => ({
                            ...old,
                            entranceExamination:
                              e.target.checked === true ? 1 : 0,
                          }));
                      }}
                      type="checkbox"
                    />
                  </S.DivCheckbox>
                )}
            </S.Fieldset>
            <S.Value>
              <S.ItemPurchase>
                <h6>Valor: </h6>
                <S.Number>
                  <h6>{numberToReal(item?.value?.value)}</h6>
                </S.Number>
              </S.ItemPurchase>
              <S.ItemPurchase>
                <h6>Desconto Max: </h6>
                <S.Number>
                  <h6>{numberToReal(maxDiscount)}</h6>
                </S.Number>
              </S.ItemPurchase>
              <S.ItemPurchase>
                <h6>Desconto: </h6>
                {<FormField item={inputDiscount} />}
              </S.ItemPurchase>
              <S.Line />
              <S.ItemPurchase>
                <h6>Total: </h6>
                <S.Number>
                  <h6>
                    {numberToReal(
                      item?.value?.value - (item?.discount?.value || 0),
                    )}
                  </h6>
                </S.Number>
              </S.ItemPurchase>
            </S.Value>
            {error && <S.Error>{error}</S.Error>}
          </S.Contents>
        </Form>
        {item.description.observation && (
          <S.Observation>
            <S.DivObservation>
              <S.H6>Observação do curso:</S.H6>

              <div
                dangerouslySetInnerHTML={{
                  __html: item.description.observation,
                }}
              ></div>
            </S.DivObservation>
          </S.Observation>
        )}
        <FooterModal cancelClick={handleCancel} saveClick={handleSave} />
      </S.Content>
    </BasicModal>
  );
}
