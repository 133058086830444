import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 100vw;
`;

export const ButtonDiv = styled.div``;

export const TitleSubtitleDiv = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 90%;
  gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    flex-direction: column;
    gap: 0;
    align-items: start;
    justify-content: start;
  }
`;

export const DivName = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  max-width: 50%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    align-items: start;
    justify-content: start;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const ButtonClose = styled.div`
  cursor: pointer;
`;

export const H4 = styled.h4`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const H6 = styled.h6`
  width: 100%;

  color: ${({ theme }) => theme.colors?.text.secondary};

  @media screen and (min-width: ${(props) => props.theme.size?.large}) {
    &:before {
      content: '| ';
    }
  }
`;
