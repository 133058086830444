import styled from 'styled-components';

export const Container = styled.div``;

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`;

export const ContentFocus = styled.div`
  display: flex;
  flex-direction: column;

  opacity: 0;

  animation: focus 1s;
  @keyframes focus {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 1;
`;

export const Table = styled.div`
  padding-top: 1rem;
`;

export const DivNote = styled.div`
  width: 4rem;
`;

export const DivSituation = styled.div`
  width: 18rem;
  display: flex;
  justify-content: center;

  gap: 1rem;
`;

export const Name = styled.h6``;

export const Grade = styled.h6`
  width: 4rem;
`;

export const H6 = styled.h6`
  width: 9rem;
`;

export const Title = styled.h6`
  width: 55rem;
  font-weight: bold;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    width: 30rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    width: 45rem;
  }
`;

export const DivDiscipline = styled.div`
  width: 50rem;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    width: 30rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    width: 45rem;
  }
`;

export const TitleSmall = styled.h6`
  font-weight: bold;
`;
