import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  max-width: 95vw;
`;

export const TitleText = styled.b`
  font-size: 13px;
  color: '#555555';
  text-transform: capitalize;
  font-weight: 700;
`;
