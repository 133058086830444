import React from 'react';

import { Container } from './styles';

export default function Deadlines({ data }) {
  return (
    <Container>
      <section>
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        ></div>
      </section>
    </Container>
  );
}
