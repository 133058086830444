import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  align-items: start;

  width: 100%;
  gap: 2rem;
`;

export const ContentBullet = styled.div`
  width: 100%;
  margin: 2rem 0 0 0;
`;

export const ContentFocus = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  margin-top: 1rem;

  opacity: 100%;
`;

export const InputFocus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  width: 100%;

  gap: 1rem;

  animation: height 1s;
  @keyframes height {
    from {
      opacity: 0;
      height: 0px;
    }
    to {
      opacity: 100%;
      height: 4rem;
    }
  }
  /* height: 4rem; */
  display: flex;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  gap: 1rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;

  input[type='file'] {
    display: none;
  }
`;

export const ObservationDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 0.5rem;
  padding-right: 2rem;

  width: 100%;
  max-width: 30rem;
  height: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &&.isOpen {
    animation: isOpen 1s;
    @keyframes isOpen {
      from {
        white-space: nowrap;
      }
      to {
        white-space: normal;
      }
    }
    white-space: normal;
  }
`;

export const ObservationText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
`;

export const DocumentName = styled.h6`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    width: 25rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    width: 40rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    text-align: center;
    width: 31rem;
  }
  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    text-align: center;
    width: 25rem;
  }
  @media screen and (max-width: 343px) {
    text-align: center;
    width: 20rem;
  }
`;
