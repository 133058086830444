import React, { ReactNode } from 'react';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

import { MyTableCell, SubtitleTable, TableTitleRow } from '../../../components';
import { TableBody } from '@mui/material';

interface Props {
  selectAll?: ReactNode;
  showMulta?: boolean;
  children: ReactNode;
}

export default function HeaderTableNegotiation({
  selectAll,
  showMulta,
  children,
}: Props) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableTitleRow props={{ backgroundColor: '#001b4f' }}>
              <MyTableCell>
                <SubtitleTable color="white">Referência</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="white">Vencimento</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="white">Valor</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="white">Desconto</SubtitleTable>
              </MyTableCell>
              {showMulta && (
                <MyTableCell align="center">
                  <SubtitleTable color="white">Juros e multa</SubtitleTable>
                </MyTableCell>
              )}
              <MyTableCell align="center">{selectAll}</MyTableCell>
            </TableTitleRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
