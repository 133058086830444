import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ThemeProvider } from 'styled-components';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import maintenanceService from './services/maintenance/maintenance.service';
import theme from './styles/theme';
import Loading from './components/common/Loading';
import {
  LoadingProvider,
  useLoading,
} from './components/agent/Context/LoadingContext';
import { routes, routesMaintenance } from './routes';

export function App() {
  const [maintenanceMode, setMaintenanceMode] = useState<boolean>(false);
  const { isLoading, setIsLoading } = useLoading();

  const googleTag: string = process.env.REACT_APP_GOOGLE_TAG || '';
  ReactGA.initialize(googleTag);

  useEffect(() => {
    setIsLoading(true);
    maintenanceService
      .checkMaintenance()
      .then((data) => {
        if (data.result) {
          if (data.data.server_under_maintenance) {
            setMaintenanceMode(true);
          }
        }
      })
      .catch((error: Error) => {
        console.error(error.name + ':' + error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Loading />
      </ThemeProvider>
    );
  }

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        {maintenanceMode ? (
          <RouterProvider router={routesMaintenance} />
        ) : (
          <RouterProvider router={routes} />
        )}
      </LoadingProvider>
    </ThemeProvider>
  );
}
