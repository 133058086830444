import styled from 'styled-components';

export const Container = styled.div`
  z-index: ${(props) => props.theme.zIndex?.header};
`;

export const ContainerMobile = styled.div`
  display: none;

  background-color: ${({ theme }) => theme.colors?.primary};

  width: 100vw;
  position: absolute;

  top: 0;
  left: 0;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    display: flex;
  }
`;

export const MobileButtons = styled.div`
  display: flex;

  width: 100%;
  height: 6rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

interface IMobileSidebar {
  isOpen: boolean;
}
export const MobileSidebar = styled.div<IMobileSidebar>`
  display: none;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: auto;
  z-index: ${(props) => props.theme.zIndex?.sideBar};
  background-color: ${({ theme }) => theme.colors?.primary};

  padding-bottom: 3rem;
  .button_icon {
    color: ${({ theme }) => theme.colors?.backgroundColor};
  }

  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  width: ${(props) => (props.isOpen ? '100vw' : '0')};

  transition:
    opacity 0.5s ease,
    width 0.5s ease;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    display: flex;
  }
`;

export const ContainerDesktop = styled.aside`
  top: 0;
  width: 25rem;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    display: none;
  }
`;

export const ContainerPerfil = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 43rem;
  border-radius: 0px 0px 15px 15px;

  background-color: ${({ theme }) => theme.colors?.primary};
  z-index: ${(props) => props.theme.zIndex?.sideBar};
`;

export const PerfilDiv = styled.div`
  height: 35rem;
  width: 90vw;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .button_close {
    position: absolute;
    top: 1rem;
    left: 1rem;

    z-index: ${(props) => props.theme.zIndex?.sideBar};
  }
`;

export const TemporarySwitchToChangeLayout = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${(props) => props.theme.colors?.backgroundColor};
`;

export const SideMenuDiv = styled.div`
  margin: 0 3rem;
`;

export const PrefilContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ImgAvatar = styled.img`
  position: absolute;

  border-radius: 50%;
  z-index: 1;
`;

export const ImgCircle = styled.img`
  position: absolute;
  z-index: 0;
`;

export const Perfil = styled.div`
  background-color: ${({ theme }) => theme.colors?.backgroundColor};
  border-radius: 100%;

  border: 2px solid #000;

  border-spacing: 10px;

  width: 10rem;
  height: 10rem;
`;

export const ImgLogo = styled.img`
  max-width: 22rem;
`;

export const InfoText = styled.div``;

export const Name = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.colors?.text.tertiary};
`;

export const Registration = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors?.text.tertiary};
`;
