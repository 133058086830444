import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;

  :hover {
    opacity: 50%;
  }
  p {
    font-size: 1.3rem;
    margin-left: 2rem;
    font-weight: 600;
  }

  .textSelect {
    cursor: 'pointer';
  }

  .css-hlj6pa-MuiDialogActions-root {
    display: flex;
    justify-content: center;
  }
  > .button {
    color: red;
    font-size: 18px;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-size: 12px;
  }
`;
