import React, { useEffect } from 'react';

import { Navigate, createBrowserRouter } from 'react-router-dom';

import {
  Maintenance,
  SubjectGrades,
  MyGrades,
  MyData,
  NotFound,
  Dashboard,
  FinancialHistoric,
  RegistrationsContracts,
  IncomeTax,
  StudentService,
  Documents,
  Logout,
  Statements,
  ChangePassword,
  MyCertificate,
} from '../pages/student';
import { ContractUnsigned } from '../components/student/Contract';
import LayoutStudent from '../components/student/Layout/LayoutStudent';
import { MyBusiness } from '../pages/agent';
import LayoutAgent from '../components/agent/Layout';
import StudentProvider from '../components/student/StudentProvider';
import ProviderAgent from '../components/agent/ProviderAgent';
import { urlSite } from '../variables';
import LayoutMainNewStudent from '../components/newStudent/Layout/LayoutMain';
import {
  NotFoundNewStudent,
  LoginNewStudent,
  MyDataNewStudent,
  FinancialHistory,
  TicketsNewStudent,
  MyGradesNewStudent,
  IncomeTaxNewStudent,
  HomeNewStudent,
  DocumentsNewStudent,
  StatementsNewStudent,
  CertificateNewStudent,
  DataConferenceNewStudent,
  RegistrationNewStudent,
  MyCoursesNewStudent,
  ContractUnsignedNewStudent,
} from '../pages/newStudent';
import NewStudentProvider from '../components/newStudent/ProviderNewStudent';
import LayoutMinimum from '../components/newStudent/Layout/LayoutMinimum';

const institutionSite = urlSite;

const RedirectTo = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

const routesStudent = [
  {
    path: '/',
    element: <RedirectTo to={institutionSite} />,
  },
  {
    path: '/aluno',
    element: (
      <NewStudentProvider>
        <LoginNewStudent />
      </NewStudentProvider>
    ),
  },
  {
    path: '/aluno/conferencia-dados',
    element: (
      <StudentProvider>
        <ContractUnsigned />
      </StudentProvider>
    ),
  },
  {
    path: '/aluno',
    element: (
      <StudentProvider>
        <LayoutStudent />
      </StudentProvider>
    ),
    children: [
      {
        path: '/aluno/dashboard',
        element: <Dashboard />,
        index: true,
      },
      {
        path: '/aluno/meus-dados',
        element: <MyData />,
      },
      {
        path: '/aluno/alterar-senha',
        element: <ChangePassword />,
      },
      {
        path: '/aluno/declaracao',
        element: <Statements />,
      },
      {
        path: '/aluno/historico-financeiro',
        element: <FinancialHistoric />,
      },
      {
        path: '/aluno/financeiro/ir',
        element: <IncomeTax />,
      },
      {
        path: '/aluno/minhas-notas',
        element: <MyGrades />,
      },
      {
        path: '/aluno/minhas-notas/:disciplina?/:curso',
        element: <SubjectGrades />,
      },
      {
        path: '/aluno/matriculas-contratos',
        element: <RegistrationsContracts />,
      },
      {
        path: '/aluno/atendimento',
        element: <StudentService />,
      },
      {
        path: '/aluno/documentos',
        element: <Documents />,
      },
      {
        path: '/aluno/certificado',
        element: <MyCertificate />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: '/aluno/logout',
        element: <Logout />,
      },
    ],
  },

  /** Daqui pra baixo é o novo layout */
  {
    path: '/new/aluno',
    element: (
      <NewStudentProvider>
        <LoginNewStudent />
      </NewStudentProvider>
    ),
  },
  {
    path: '*',
    element: (
      <NewStudentProvider>
        <NotFoundNewStudent />
      </NewStudentProvider>
    ),
  },
  {
    path: '/new/',
    element: (
      <NewStudentProvider>
        <LayoutMainNewStudent />
      </NewStudentProvider>
    ),
    children: [
      {
        path: '/new/aluno/dashboard',
        element: <Navigate to="/new/aluno/inicio" replace />,
      },
      {
        path: '/new/aluno/inicio',
        element: <HomeNewStudent />,
        index: true,
      },
      {
        path: '/new/aluno/meus-dados',
        element: <MyDataNewStudent />,
      },
      {
        path: '/new/aluno/documentos',
        element: <DocumentsNewStudent />,
      },
      {
        path: '/new/aluno/matricula',
        element: <RegistrationNewStudent />,
      },
      {
        path: '/new/aluno/declaracao',
        element: <StatementsNewStudent />,
      },
      {
        path: '/new/aluno/tickets',
        element: <TicketsNewStudent />,
      },
      {
        path: '/new/aluno/historico-financeiro',
        element: <FinancialHistory />,
      },
      {
        path: '/new/aluno/ir',
        element: <IncomeTaxNewStudent />,
      },
      {
        path: '/new/aluno/cursos',
        element: <MyCoursesNewStudent />,
      },
      {
        path: '/new/aluno/minhas-notas',
        element: <MyGradesNewStudent />,
      },
      {
        path: '/new/aluno/certificados',
        element: <CertificateNewStudent />,
      },
    ],
  },
  {
    path: '/new/',
    element: (
      <NewStudentProvider>
        <LayoutMinimum />
      </NewStudentProvider>
    ),
    children: [
      {
        path: '/new/aluno/conferencia-dados',
        element: <DataConferenceNewStudent />,
      },
      {
        path: '/new/aluno/contratos',
        element: <ContractUnsignedNewStudent />,
      },
    ],
  },
];

const routesAgent = [
  {
    path: '/agenciador',
    element: (
      <ProviderAgent>
        <Navigate to="/agenciador/negocio" />,
      </ProviderAgent>
    ),
  },
  {
    path: '/agenciador',
    element: (
      <ProviderAgent>
        <LayoutAgent />
      </ProviderAgent>
    ),
    children: [
      {
        path: '/agenciador/negocio',
        element: <MyBusiness />,
        index: true,
      },
    ],
  },
];

export const routes = createBrowserRouter([...routesAgent, ...routesStudent]);

export const routesMaintenance = createBrowserRouter([
  {
    path: '*',
    element: (
      <NewStudentProvider>
        <Maintenance />
      </NewStudentProvider>
    ),
  },
]);
