import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from '../styles';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { useTheme } from 'styled-components';
import ModalWarning from './ModalWarning';
import { myCoursesServiceNewStudent } from '../../../../../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../ProviderNewStudent';
import {
  IExam,
  IListOfExams,
} from '../../../../../../../../services/newStudent/myCourses/myCourses.service';
import { IClassInfo } from '../../../../../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';

interface Props {
  setSubjectExam: Dispatch<SetStateAction<IExam>>;
  setBlockedContent: Dispatch<SetStateAction<boolean>>;
  blockedContent: boolean;
  classInfo: IClassInfo;
  contentIdSelected: number;
}
export default function TabExam({
  setSubjectExam,
  setBlockedContent,
  classInfo,
  contentIdSelected,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [examInfo, setExamInfo] = useState<IListOfExams>();
  const [modalWarningIsOpen, setModalWarningIsOpen] = useState<boolean>(false);
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);

  const theme = useTheme();

  const courseId = classInfo.courseId;
  const disciplineId = classInfo.id;
  const contentId = contentIdSelected;

  useEffect(() => {
    /** Fazer o fetch que traga apenas as informações  das avaliações que já fez e a aberta */
    setIsLoading(true);
    const fetch = async () => {
      try {
        const { result, data } = await myCoursesServiceNewStudent.listExams(
          +courseId,
          +disciplineId,
          +contentId,
        );
        if (result) {
          setHasGenerated(false);
          setExamInfo(data);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  const handleGenerateExam = async (title: string) => {
    try {
      setIsLoading(true);
      const { result, data } = await myCoursesServiceNewStudent.getExam(
        +courseId,
        +disciplineId,
        +contentId,
      );
      if (result) {
        setSubjectExam({ ...data, title });
        setHasGenerated(true);
        setBlockedContent(true);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenExam = (id: number, title: string, response?: boolean) => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        if (response) {
          const { result, data } =
            await myCoursesServiceNewStudent.getAnswerSheet(+id);

          if (result) {
            setSubjectExam({ ...data, title });
          }
        } else {
          const { result, data } = await myCoursesServiceNewStudent.getExam(
            +courseId,
            +disciplineId,
            +contentId,
            +id,
          );

          if (result) {
            setSubjectExam({ ...data, title });
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  };

  const isApproved = (title: string) => {
    return title.trim().toLowerCase().includes('aprovado');
  };
  return (
    <S.Container className="tab_exam">
      {examInfo?.exams?.map((item, index) => (
        <S.DivSubtitle key={index}>
          <S.DivSubtitleSeparated>
            <DescriptionOutlinedIcon
              fontSize="large"
              style={{
                color: theme.colors?.danger,
              }}
            />
            <S.Subtitle
              isApproved={isApproved(item.title)}
              onClick={() =>
                !isApproved(item.title) &&
                handleOpenExam(item.id, item.title, item.response)
              }
            >
              {item.title}
            </S.Subtitle>
            {!isApproved(item.title) && (
              <S.Separated> {item.attempt}ª Tentativa</S.Separated>
            )}
          </S.DivSubtitleSeparated>
        </S.DivSubtitle>
      ))}
      {(!!examInfo?.generateClosedExam ||
        !!examInfo?.generateDescriptiveExam) && (
        <S.DivSubtitle>
          <S.DivSubtitleSeparated>
            <DescriptionOutlinedIcon
              fontSize="large"
              style={{
                color: theme.colors?.danger,
              }}
            />
            <S.Subtitle
              onClick={() => !hasGenerated && setModalWarningIsOpen(true)}
            >
              {examInfo.generateClosedExam ?? examInfo.generateDescriptiveExam}
            </S.Subtitle>
          </S.DivSubtitleSeparated>
        </S.DivSubtitle>
      )}
      <ModalWarning
        isOpen={modalWarningIsOpen}
        setIsOpen={setModalWarningIsOpen}
        handleConfirm={() => {
          setBlockedContent(true);
          setModalWarningIsOpen(false);
          return handleGenerateExam(
            String(
              examInfo?.generateClosedExam ?? examInfo?.generateDescriptiveExam,
            ),
          );
        }}
      />
    </S.Container>
  );
}
