import React, { useContext, useEffect, useMemo, useState } from 'react';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Box, IconButton, Toolbar, List } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiDrawer from '@mui/material/Drawer';

import MenuIcon from '@mui/icons-material/Menu';
import Groups2Icon from '@mui/icons-material/Groups2';
import HomeIcon from '@mui/icons-material/Home';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import InsertChartOutlinedSharpIcon from '@mui/icons-material/InsertChartOutlinedSharp';

import { MenuList } from './components';
import * as S from './styles';
import * as apiAgentService from '../../../../../services/api/agent/api.agent.service';

import Breadcrumb from '../../../Breadcrumb';
import { useLocation } from 'react-router-dom';
import { validatePermissions } from '../../../../../util/common/permission';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../ProviderAgent';

const drawerWidth = 221;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  overflow: 'hidden',
  zIndex: theme.zIndex.drawer + 1,

  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  [`@media screen and (max-width: 1023px)`]: {
    display: open ? 'flex' : 'none',
    width: '100vw',
  },

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      height: '100%',
      overflowX: 'hidden',
      position: 'relative',
      zIndex: '1300',
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      height: '100%',
      overflowX: 'hidden',
      position: 'relative',
    },
  }),
}));

export default function Menu({ children }) {
  const location = useLocation();
  const currentPath = location.pathname;

  const context = useContext(ProviderAgentContext);
  const { agentLogged, setIsLoading } = context as IAgentContextProps;

  const [open, setOpen] = useState(false);
  const [whoSelected, setWhoSelected] = useState<number | undefined>();
  const [selectedChild, setSelectedChild] = useState<number | undefined>();
  const [selectedItem, setSelectedItem] = useState<number | undefined>();

  const agent = localStorage.getItem('agent');
  const agentParse = agent ? JSON.parse(agent) : null;
  const permission = agentParse?.permissions.agenciador;

  const redirectTo = async (url: string, blank?: boolean) => {
    setIsLoading(true);
    const response = await apiAgentService.authenticatedRequest(url);

    if (response.result) {
      blank
        ? window.open(response.data)
        : (window.location.href = response.data);
    } else {
      return response.message;
    }
    return setIsLoading(false);
  };

  const menuItems = [
    {
      title: 'Dashboard',
      link: async () => await redirectTo('/sales-agent/dashboard'),
      icon: <HomeIcon fontSize="large" />,
      permission: 'dashboard',
    },
    {
      title: 'Agenciadores',
      link: async () => await redirectTo('/sales-agent'),
      icon: <Groups2Icon fontSize="large" />,
      permission: 'agenciador',
    },
    {
      title: 'Fechamento Bonificação',
      link: async () => await redirectTo('/sales-agent/commission'),
      icon: <EmojiEventsIcon fontSize="large" />,
      permission: 'fechamento-comissao',
    },
    {
      title: 'Meus Negócios',
      link: '/agenciador/negocio',
      icon: <BusinessCenterIcon fontSize="large" />,
      permission: 'negocio',
    },
    {
      title: 'Negócios Ganhos',
      link: async () => await redirectTo('/sales-agent/report/won-business'),
      icon: <DescriptionOutlinedIcon fontSize="large" />,
      permission: 'negocios-ganhos',
    },
    {
      title: 'Negócios Inadimplentes',
      link: async () => await redirectTo('/sales-agent/report/late-business'),
      icon: <DescriptionOutlinedIcon fontSize="large" />,
      permission: 'negocios-inadimplentes',
    },
    {
      title: 'Requerimentos',
      link: async () => await redirectTo('/sales-agent/application', true),
      icon: <RssFeedOutlinedIcon fontSize="large" />,
      permission: 'requerimento',
    },
    {
      title: 'Dashboards',
      icon: <InsertChartOutlinedSharpIcon fontSize="large" />,
      permission: 'dashboards',
      children: [
        {
          title: 'Dashboard Supervisor - Brayan',
          link: '/agenciador/',
        },
      ],
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuStyle = {
    backgroundColor: '#3a3633',
  };

  const [nav, setNav] = useState<{ title: string }[]>([
    {
      title: 'Home',
    },
    {
      title: 'Meus Negócios',
    },
  ]);

  useEffect(() => {
    const foundIndex = menuItems.findIndex((item) => item.link === currentPath);
    if (foundIndex !== -1) {
      setSelectedItem(foundIndex);
    } else {
      setSelectedItem(undefined);
    }
  }, [currentPath]);

  useEffect(() => {
    if (whoSelected !== undefined) {
      setNav([
        {
          title: 'Home',
        },
        { title: menuItems[whoSelected].title },
      ]);
      if (
        selectedChild !== undefined &&
        menuItems !== undefined &&
        menuItems[whoSelected].children !== undefined
      ) {
        setNav((n) => [
          ...n,
          { title: menuItems[whoSelected].children![selectedChild].title },
        ]);
      }
    }
  }, [whoSelected, selectedChild, agentLogged]);

  // Assim que fizer o context pro agent, colocar la
  let agentVerify = 0;
  window.addEventListener('storage', () => {
    agentVerify = agentVerify + 1;
  });
  const { agentLink, agentName } = useMemo(() => {
    if (localStorage.getItem('agent')) {
      const tmp = JSON.parse(localStorage.getItem('agent')!);
      return {
        agentName: tmp!.sales_agent!.nome,
        agentLink: tmp!.sales_agent!.agenciador_link_imagem_perfil,
      };
    }
    return { agentLink: null, agentName: null };
  }, [agentVerify, agentLogged]);

  /** Alterando o nome do titulo da pagina */
  useEffect(() => {
    document.title = 'Meus Negócios';
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        open={open}
        sx={menuStyle}
        style={{
          top: 100,
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          height: '40px',
        }}
      >
        <Toolbar style={{ minHeight: 40, backgroundColor: '#474544' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Breadcrumb navigates={nav} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          '& .MuiDrawer-paper': {
            borderRight: 'none',
            backgroundColor: '#3a3633',
          },
        }}
      >
        <DrawerHeader
          style={{
            ...menuStyle,
            minHeight: 40,
            borderBottom: '1px solid #1A1817',
          }}
        >
          <S.NameImage>
            <img src={agentLink} alt="user" width={'25px'} height={'25px'} />
            <S.TextName>{agentName}</S.TextName>
          </S.NameImage>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon sx={{ color: 'white' }} fontSize="large" />
          </IconButton>
        </DrawerHeader>
        <List
          style={{
            ...menuStyle,
            padding: 0,
            borderTop: open ? '1px solid #525151' : '',
            width: '100%',
            minHeight: '100vh',
          }}
          sx={{
            color: '#a8a8a8',
            '& .MuiListItemButton-root:hover': {
              '&, & .MuiListItemIcon-root': {
                color: 'white',
              },
            },
          }}
        >
          {permission &&
            menuItems.map((item, index) => {
              if (validatePermissions(permission, item.permission)) {
                return (
                  <MenuList
                    key={index}
                    index={index}
                    list={item}
                    open={open}
                    setOpen={setOpen}
                    whoSelected={whoSelected}
                    setSelect={setWhoSelected}
                    setSelectedChild={setSelectedChild}
                    selected={index === selectedItem}
                    onLinkClick={redirectTo}
                  />
                );
              }
            })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flex: 1, overflow: 'auto' }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
