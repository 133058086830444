import React, { useContext, useEffect, useMemo, useState } from 'react';

import * as S from './styles';
import { ChevronRight, Print, Refresh } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import Divider from '../../../components/common/Divider';
import { AlertBar } from '../../../components/newStudent/Alert';
import { ButtonSimple } from '../../../components/newStudent/Buttons';

import TableBasic from '../../../components/newStudent/Table';
import {
  cleanCache,
  formatCurrency,
  formatDate,
} from '../../../util/common/appUtil';
import ButtonIcon from '../../../components/newStudent/Buttons/ButtonIcon';
import {
  financialServiceNewStudent,
  helperServiceNewStudent,
} from '../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import AdvanceAndDebtNegotiation from './components/AdvanceAndDebtNegotiation';
import { useTheme } from 'styled-components';
import { handleMaxWidth } from '../../../util/newStudent';
import { handleMinWidth } from '../../../util/newStudent/windowWidthSize';
import PaymentsMobile from './components/PaymentsMobile';
import AdvanceAndDebtNegotiationMobile from './components/AdvanceAndDebtNegotiationMobile';
import {
  IBillets,
  IInfoBillets,
  IInfoDebtAndNegotiationBillets,
} from '../../../services/newStudent/financial/financial.service';
import { IPaymentType } from '../../../services/newStudent/helper/helper.service';
import {
  typeFinancialAdvance,
  typeFinancialNegotiation,
} from '../../../variables';

export default function FinancialHistory() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [isOpenExpiredPaymentsMobile, setIsOpenExpiredPayments] =
    useState<boolean>(false);
  const [isOpenSettledPayments, setIsOpenSettledPayments] =
    useState<boolean>(false);

  const [billets, setBillets] = useState<IBillets>();
  const [invoiceDebt, setInvoiceDebt] =
    useState<IInfoDebtAndNegotiationBillets[]>();
  const [invoiceNegotiation, setInvoiceNegotiation] =
    useState<IInfoDebtAndNegotiationBillets[]>();
  const theme = useTheme();

  const [paymentType, setPaymentType] = useState<IPaymentType[] | []>([]);
  const [isOpenAdvanceAndDebtNegotiation, setIsOpenAdvanceAndDebtNegotiation] =
    useState<boolean>(false);
  const [
    isOpenAdvanceAndDebtNegotiationMobile,
    setIsOpenAdvanceAndDebtNegotiationMobile,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const load = async () => {
      try {
        const { result, data } =
          await financialServiceNewStudent.getAllBillets();
        if (result) {
          setBillets(data);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  /** Chama a api de infomações de boletos para negociação ou antecipação */
  const handleOpenAdvanceAndDebtNegotiation = async (
    isNegotiation?: boolean,
  ) => {
    setIsLoading(true);
    if (handleMaxWidth(theme.size?.medium)) {
      setIsOpenAdvanceAndDebtNegotiationMobile(
        !isOpenAdvanceAndDebtNegotiationMobile,
      );
    } else {
      setIsOpenAdvanceAndDebtNegotiation(!isOpenAdvanceAndDebtNegotiation);
    }
    try {
      if (isNegotiation) {
        const expiredBillet: IInfoDebtAndNegotiationBillets[] =
          billets?.expiredBillet
            .filter((filtered: IInfoBillets) => filtered.type === 1)
            .map((item: IInfoBillets) => ({
              id: item.id,
              invoiceReference: item.invoiceReference,
              dueDate: item.dueDate,
              daysOfDelay: item.daysOfDelay,
              value: item.value,
            })) ?? [];
        setInvoiceNegotiation([...expiredBillet]);
      } else {
        const openBillet: IInfoDebtAndNegotiationBillets[] =
          billets?.openBillet
            .filter((filtered: IInfoBillets) => filtered.canAnticipate)
            .map((item: IInfoBillets) => ({
              id: item.id,
              invoiceReference: item.invoiceReference,
              dueDate: item.dueDate,
              daysOfDelay: item.daysOfDelay,
              value: item.value,
            })) ?? [];

        setInvoiceDebt([...openBillet]);
      }

      const queryString = isNegotiation
        ? '?type=' + typeFinancialNegotiation! + '&parcel=' + expired.length
        : '?type=' + typeFinancialAdvance!;

      if (paymentType.length > 0) return;
      const { data, result } =
        await helperServiceNewStudent.paymentType(queryString);
      if (result) {
        setPaymentType(data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  /** Faz o download do boleto */
  const handleBilletPrintOut = async (id: number) => {
    setIsLoading(true);
    await financialServiceNewStudent.handlePrintOutBillet(id);
    setIsLoading(false);
  };

  /** Atualiza o boleto vencido */
  const handleBilletUpdate = async (id: number) => {
    setIsLoading(true);
    const { result } = await financialServiceNewStudent.handleUpdateBillet(id);
    if (result) {
      cleanCache('dataContracts');
      localStorage.removeItem('dataStudent');
      window.location.reload();
    }
    setIsLoading(false);
  };

  /** informação da tabela de boletos liquidados */
  const settledPaymentsTable = {
    title: ['Referência', 'Observação', 'Vencimento', 'Valor', 'Status'],
    content: billets?.paidBillet?.map((item) => ({
      invoiceReference: { item: <S.DivTd> {item.invoiceReference}</S.DivTd> },
      observation: {
        item: (
          <S.DivTdObservation>
            {item.observation.replace('Referente', ' Referente')}
          </S.DivTdObservation>
        ),
      },
      dueDate: { item: <S.DivTd>{formatDate(item.dueDate)} </S.DivTd> },
      value: {
        item: <S.DivTd> {formatCurrency(item.value)}</S.DivTd>,
      },
      status: { item: <S.DivTd>{item.status} </S.DivTd> },
    })),
  };

  /** informação da tabela de boletos atrasados */
  const expired = billets?.expiredBillet
    ? billets.expiredBillet.map((item: IInfoBillets) => ({
        invoiceReference: {
          item: <S.ExpiredDivTd> {item.invoiceReference}</S.ExpiredDivTd>,
        },
        observation: {
          item: (
            <S.ExpiredDivTdObservation>
              {item.observation.replace('Referente', ' Referente')}
            </S.ExpiredDivTdObservation>
          ),
        },
        dueDate: {
          item: <S.ExpiredDivTd>{formatDate(item.dueDate)} </S.ExpiredDivTd>,
        },
        value: {
          item: <S.ExpiredDivTd> {formatCurrency(item.value)}</S.ExpiredDivTd>,
        },
        status: {
          item: (
            <S.Td>
              {item.status}
              {item.update && (
                <ButtonIcon
                  color="danger"
                  heightSize="small"
                  onClick={() => item.update && handleBilletUpdate(item.id)}
                  icon={<Refresh fontSize="large" />}
                />
              )}
            </S.Td>
          ),
        },
      }))
    : [];

  /** informação da tabela de boletos não pagos */
  const unpaid = billets?.openBillet
    ? billets.openBillet.map((item: IInfoBillets) => ({
        invoiceReference: {
          item: <S.UnpaidDivTd> {item.invoiceReference}</S.UnpaidDivTd>,
        },
        observation: {
          item: (
            <S.UnpaidDivTdObservation>
              {item.observation.replace('Referente', ' Referente')}
            </S.UnpaidDivTdObservation>
          ),
        },
        dueDate: {
          item: <S.UnpaidDivTd>{formatDate(item.dueDate)} </S.UnpaidDivTd>,
        },
        value: {
          item: <S.UnpaidDivTd> {formatCurrency(item.value)}</S.UnpaidDivTd>,
        },
        status: {
          item: (
            <S.UnpaidDivTd>
              {item.status}
              {item.print && (
                <ButtonIcon
                  color="success"
                  heightSize="small"
                  onClick={() => item.print && handleBilletPrintOut(item.id)}
                  icon={<Print fontSize="large" />}
                />
              )}
            </S.UnpaidDivTd>
          ),
        },
      }))
    : [];

  const unpaidPaymentsTable = {
    title: ['Referência', 'Observação', 'Vencimento', 'Valor', 'Status'],
    content: [...expired, ...unpaid],
  };

  const openBilletsFiltered: IInfoBillets[] = useMemo(() => {
    return billets?.openBillet
      ? billets.openBillet.filter(
          (filtered: IInfoBillets) => filtered.canAnticipate,
        )
      : [];
  }, [billets]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpenAdvanceAndDebtNegotiationMobile]);

  return (
    <S.Container>
      <TitlePage
        title="Histórico Financeiro"
        titlePage="Histórico Financeiro"
        subtitle="Aqui você tem uma visão clara e organizada das suas movimentações financeiras. 
        Navegue para explorar seus registros financeiros de forma simples e intuitiva. 
        Acompanhe seus pagamentos, pendências e histórico mês a mês em um só lugar."
      />
      <Divider padding />
      {/** Validação se Pode ou não mostrar o Alerta para a negociação */}
      {billets?.negotiationBillets && (
        <>
          <AlertBar
            noWarning
            color="danger"
            text={
              <S.Alert>
                <h6>
                  Você possui{' '}
                  {billets?.expiredBillet && billets!.expiredBillet!.length}{' '}
                  mensalidades vencidas!
                </h6>
                {!handleMaxWidth(theme.size?.medium) && (
                  <S.AlertPs>
                    <p>Considere quitar os seus débitos.</p>
                  </S.AlertPs>
                )}
              </S.Alert>
            }
            button={
              <ButtonSimple
                color="danger"
                outline
                heightSize="small"
                widthSize="xmedium"
                startIcon={<AttachMoneyIcon fontSize="large" />}
                onClick={() => handleOpenAdvanceAndDebtNegotiation(true)}
                noPaddingIcon
              >
                Negociar
              </ButtonSimple>
            }
          />

          {/* Abre a nogociação da divida */}
          {invoiceNegotiation && (
            <>
              {isOpenAdvanceAndDebtNegotiation && (
                <AdvanceAndDebtNegotiation
                  isNegotiation
                  paymentType={paymentType}
                  dataInvoice={invoiceNegotiation}
                />
              )}
              {isOpenAdvanceAndDebtNegotiationMobile && (
                <AdvanceAndDebtNegotiationMobile
                  isNegotiation
                  paymentType={paymentType}
                  dataInvoice={invoiceNegotiation}
                  isOpen={isOpenAdvanceAndDebtNegotiationMobile}
                  setIsOpen={setIsOpenAdvanceAndDebtNegotiationMobile}
                />
              )}
            </>
          )}
        </>
      )}

      {/** Validação se Pode ou não mostrar o Alerta para a negociação */}
      {billets?.anticipateBillets && (
        <>
          <AlertBar
            noWarning
            color="aqua"
            text={
              <S.Alert>
                <h6>
                  Você possui {openBilletsFiltered.length} faturas que podem ser
                  adiantadas!
                </h6>
                {!handleMaxWidth(theme.size?.medium) && (
                  <S.AlertPs>
                    <p>
                      Considere adiantar suas parcelas ou quitar o seu plano
                      estudantil com desconto
                    </p>
                  </S.AlertPs>
                )}
              </S.Alert>
            }
            button={
              <ButtonSimple
                color="aqua"
                outline
                heightSize="small"
                widthSize="xmedium"
                startIcon={<AttachMoneyIcon fontSize="large" />}
                onClick={handleOpenAdvanceAndDebtNegotiation}
                noPaddingIcon
              >
                Antecipar
              </ButtonSimple>
            }
          />
          {/* Abre o adiantamento das faturas */}
          {invoiceDebt && (
            <>
              {isOpenAdvanceAndDebtNegotiation && (
                <AdvanceAndDebtNegotiation
                  isNegotiation={false}
                  paymentType={paymentType}
                  dataInvoice={invoiceDebt}
                />
              )}
              {isOpenAdvanceAndDebtNegotiationMobile && (
                <AdvanceAndDebtNegotiationMobile
                  paymentType={paymentType}
                  dataInvoice={invoiceDebt}
                  isOpen={isOpenAdvanceAndDebtNegotiationMobile}
                  setIsOpen={setIsOpenAdvanceAndDebtNegotiationMobile}
                />
              )}
            </>
          )}
        </>
      )}
      {/** Mobile */}
      {handleMaxWidth(theme.size?.medium) ? (
        <S.ContainerButtonOpen>
          <S.ContainerTableMobile>
            <S.ExpiredTitleTableMobile
              onClick={() => setIsOpenExpiredPayments(true)}
            >
              Pagamentos Abertos <ChevronRight fontSize="large" />
            </S.ExpiredTitleTableMobile>

            <PaymentsMobile
              isOpen={isOpenExpiredPaymentsMobile}
              setIsOpen={setIsOpenExpiredPayments}
              expiredPayments={billets?.expiredBillet}
              unpaidPayments={billets?.openBillet}
              isPaymentOpen
              handleBilletPrintOut={handleBilletPrintOut}
            />
          </S.ContainerTableMobile>
          <S.ContainerTableMobile>
            <S.ExpiredTitleTableMobile
              color="info01"
              onClick={() => setIsOpenSettledPayments(true)}
            >
              Pagamentos Liquidados <ChevronRight fontSize="large" />
            </S.ExpiredTitleTableMobile>
            <PaymentsMobile
              isOpen={isOpenSettledPayments}
              setIsOpen={setIsOpenSettledPayments}
              settledPayments={billets?.paidBillet}
              handleBilletPrintOut={handleBilletPrintOut}
            />
          </S.ContainerTableMobile>
        </S.ContainerButtonOpen>
      ) : (
        <>
          {/** Tabela de Pagamentos Abertos */}
          <S.ContainerTable>
            <S.ExpiredTitleTable>Pagamentos Abertos:</S.ExpiredTitleTable>
            <TableBasic data={unpaidPaymentsTable} />
          </S.ContainerTable>

          {/** Tabela de Pagamentos Liquidados */}
          <S.ContainerTable>
            <S.TitleTable>Pagamentos Liquidados:</S.TitleTable>
            <TableBasic data={settledPaymentsTable} />
          </S.ContainerTable>
        </>
      )}
      <AlertBar
        noWarning
        color="tertiary"
        text={
          <div>
            <p>
              Após a emissão, o boleto estará disponível para pagamento em até 1
              hora.
              {handleMinWidth(theme.size?.medium) && <br></br>}
              Após o pagamento, o prazo de compensação da fatura é de até 3 dias
              úteis.
            </p>
          </div>
        }
      />
    </S.Container>
  );
}
