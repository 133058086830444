import React, { Dispatch, SetStateAction } from 'react';

import * as S from '../styles';
import BasicModal from '../../BasicModal';
import { Formik } from 'formik';
import FieldFormik from '../../../FieldFormik';
import { ButtonSimple } from '../../../Buttons';
import SignOutConfirmModal from '../../SignOutConfirmModal';
import { helperServiceNewStudent } from '../../../../../services/newStudent';

interface Props {
  imageFeedback: string;
  isOpen: boolean;
  isSignOutOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsSignOutOpen: Dispatch<SetStateAction<boolean>>;
  setFeedbackIsOpen: Dispatch<SetStateAction<boolean>>;
  rating?: number;
}
export default function InputFeedBack({
  imageFeedback,
  isOpen,
  setIsOpen,
  setFeedbackIsOpen,
  isSignOutOpen,
  setIsSignOutOpen,
  rating,
}: Props) {
  const handleSubmit = async (value) => {
    try {
      await helperServiceNewStudent.feedback({
        feedbackCategoryId: 1,
        content: value.text,
        rating: rating,
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsSignOutOpen(true);
    }
  };

  const handleGoBack = () => {
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsSignOutOpen(false);
    setFeedbackIsOpen(false);
  };

  return (
    <S.Container>
      <BasicModal isOpen={isOpen} setIsOpen={setIsOpen} isFloat>
        <S.Content>
          <S.TitleInputImg>
            <S.Img src={imageFeedback} alt="feedback" width="45px" />
            <S.DivTitle>
              <S.Title>Obrigado por Avaliar!</S.Title>
              <S.Subtitle>
                Deixe sua opinião ou sugestão no campo abaixo, ou clique em sair
              </S.Subtitle>
            </S.DivTitle>
          </S.TitleInputImg>
          <Formik
            initialValues={{ text: '' }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ handleChange }) => (
              <S.FieldForm>
                <FieldFormik
                  name="text"
                  placeholder="Digite Aqui..."
                  onChange={handleChange}
                  type="textarea"
                  heightSize="textSmall"
                />
                <S.Buttons>
                  <ButtonSimple
                    color="gray"
                    outline
                    heightSize="small"
                    onClick={() => handleGoBack()}
                  >
                    Voltar
                  </ButtonSimple>
                  <ButtonSimple
                    color="primary"
                    heightSize="small"
                    type="submit"
                  >
                    Enviar
                  </ButtonSimple>
                </S.Buttons>
              </S.FieldForm>
            )}
          </Formik>
        </S.Content>
      </BasicModal>
      <SignOutConfirmModal
        isOpen={isSignOutOpen}
        setIsOpen={setIsSignOutOpen}
        handleClose={handleClose}
      />
    </S.Container>
  );
}
