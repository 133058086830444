import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;

  z-index: ${(props) => props.theme.zIndex?.sideBar};
`;

export const Collapse = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const HeaderCollapse = styled.div`
  margin: 0.5rem 0;
  width: 100%;
`;

interface IChildCollapse {
  isOpen?: boolean;
}

export const ChildCollapse = styled.div<IChildCollapse>`
  width: 100%;

  animation: barAccessibility 0.7s;
  @keyframes barAccessibility {
    from {
      opacity: 0;
      height: 0;
    }
    to {
      opacity: 100%;
      height: 4rem;
    }
  }
`;
