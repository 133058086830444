import React, { ReactNode } from 'react';

import { Container } from './styles';

interface IAlertMessage {
  title?: string;
  message?: string | ReactNode;
  color?: string;
  icon?: ReactNode;
}

export default function AlertMessage({
  title,
  message,
  color,
  icon,
}: IAlertMessage) {
  return (
    <Container>
      <section className={color}>
        <div>
          {title && <h4>{title}</h4>}
          <div>{message}</div>
        </div>
        {icon}
      </section>
    </Container>
  );
}
