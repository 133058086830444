import * as api from '../../api/newStudent/apiStudent.service';

interface IParcels {
  quantity: number;
  discount: number;
  penalty: number;
  interest: number;
}
export interface IPaymentType {
  id: number;
  parcels: IParcels[];
  description: string;
}

export const helperServiceNewStudent = {
  async paymentType(queryString: string): Promise<{
    data: IPaymentType[];
    result: boolean;
    message: string | string[];
  }> {
    const request = await api.authenticatedRequest(
      '/helper/financial/consult-payment-type' + queryString ?? '',
    );

    return request;
  },

  async feedback(body) {
    const request = await api.authenticatedRequest(
      '/feedback/create',
      'POST',
      body,
    );
    return request;
  },
};
