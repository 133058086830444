import React, { ReactNode, useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

interface Props {
  title?: string;
  year?: ReactNode | number;
}

export default function AccordionDeclaration({ title, year }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (_, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion fluid styled>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={(_, titleProps) => handleClick(_, titleProps)}
      >
        <Icon name="dropdown" />
        {title}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>{year}</Accordion.Content>
    </Accordion>
  );
}
