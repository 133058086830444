import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors?.gray01};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 2rem 0;
`;

interface IChildrenProps {
  isHovered?: boolean;
  hover?: boolean;
}

export const Children = styled.div<IChildrenProps>`
  height: 100%;
  padding: 0 1.7rem 0 2.5rem;

  ${(props) =>
    props.isHovered &&
    props.hover &&
    css`
      cursor: pointer;
      animation: pushContent 1s;
      @keyframes pushContent {
        from {
          padding: 0 2rem 0 3rem;
        }
        to {
          padding: 0 2rem 0 6rem;
        }
      }
      padding: 0 2rem 0 6rem;
    `};
`;

interface IIconProps {
  color:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'success'
    | 'danger'
    | 'attention'
    | 'gray03'
    | 'aqua';
  isHovered?: boolean;
  hover?: boolean;
}

export const IconContainer = styled.div<IIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1rem;

  background-color: ${(props) => props.theme.colors?.[props.color]};

  border-radius: 10px 0 0 10px;

  ${(props) =>
    props.isHovered &&
    props.hover &&
    css`
      cursor: pointer;
      animation: pushAction 1s;
      @keyframes pushAction {
        from {
          width: 1rem;
        }
        to {
          width: 4rem;
        }
      }
      width: 4rem;
    `};
`;

interface IIcon {
  isHovered?: boolean;
  hover?: boolean;
}
export const Icon = styled.div<IIcon>`
  color: ${({ theme }) => theme.colors?.backgroundColor};
  display: none;

  ${(props) =>
    props.isHovered &&
    props.hover &&
    css`
      cursor: pointer;
      animation: sizeAnimation 1s;
      @keyframes sizeAnimation {
        from {
          opacity: 0;
          display: none;
        }
        to {
          opacity: 100%;
          display: flex;
        }
      }
      opacity: 100%;
      display: flex;
    `};
`;
