import React, { ReactNode } from 'react';

import { Container } from './styles';

interface Props {
  link: () => void;
  icon?: ReactNode;
  title: string;
  color?: string;
  textTooltip?: string;
}

export default function ButtonText({
  link,
  icon,
  title,
  color,
  textTooltip,
}: Props) {
  return (
    <Container
      type="text"
      onClick={link}
      underline="none"
      data-tooltip={textTooltip ? textTooltip : undefined}
    >
      <>
        <span className={color}>
          {icon}
          <span>{title}</span>
        </span>
      </>
    </Container>
  );
}
