import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div``;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
`;

export const DivLiPhone = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: end;
  }
`;

export const DivLi = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  gap: 1.5rem;

  width: 100%;
`;

export const DivLiRgCpf = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  gap: 1.5rem;

  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: grid;
    grid-template-columns: 1fr;
    align-content: end;
  }
`;

export const DivAddress = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 1.5rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: grid;
    grid-template-columns: 1fr;
    align-content: end;
  }
`;

export const DivAddressInfo = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  gap: 1.5rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: grid;
    grid-template-columns: 1fr;
    align-content: end;
  }
`;

export const DivGraduation = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: end;

  gap: 1.5rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const Button = styled.div`
  display: flex;

  justify-content: end;
  width: 100%;
`;
