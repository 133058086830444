import React, { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';

import RegistrationStatement from './components/RegistrationStatement';
import ConclusionStatement from './components/ConclusionStatement';
import HeaderPages from '../../../components/student/HeaderPages';
import { statementService } from '../../../services';
import * as S from './styles';

export default function Statements() {
  const [conclusion, setConclusion] = useState<boolean>(false);
  const [registration, setRegistration] = useState<boolean>(false);

  const handleStatementTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedOption = e.target.value;
    if (selectedOption === 'Declaração de Conclusão de Curso') {
      setConclusion(true);
      setRegistration(false);
    } else if (selectedOption === 'Declaração de Matrícula/Cursando') {
      setConclusion(false);
      setRegistration(true);
    } else {
      setConclusion(false);
      setRegistration(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await statementService.statementShow();
    };

    fetchData();
  }, []);

  return (
    <S.Container>
      <HeaderPages title="Declarações (Formato Digital)" />

      <S.Form>
        <Form.Group>
          <label htmlFor="typeStatement">Selecione o tipo de declaração</label>
          <Form.Select
            size="lg"
            id="typeStatement"
            onChange={handleStatementTypeChange}
          >
            <option selected>Selecione o tipo de declaração</option>
            <option>Declaração de Matrícula/Cursando</option>
            <option>Declaração de Conclusão de Curso</option>
          </Form.Select>
        </Form.Group>
      </S.Form>

      {conclusion && <ConclusionStatement />}
      {registration && <RegistrationStatement />}
    </S.Container>
  );
}
