import { Box, Paper, BoxProps, SxProps } from '@mui/material';

import styled from 'styled-components';

interface BoxStyleProps extends BoxProps {
  isFloat?: boolean;
}

export const BoxStyle: React.ComponentType<BoxStyleProps & SxProps> = styled(
  Box,
)<BoxStyleProps>`
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  display: flex;
`;

interface IPaperStyled {
  disableWidth?: boolean;
}
export const PaperStyled = styled(Paper)<IPaperStyled>`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;

  width: ${(props) => (!props.disableWidth ? '100%' : '')};
  height: 100%;

  @media screen and (max-width: 485px) {
    width: 130%;
  }
`;
