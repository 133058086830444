import styled from 'styled-components';

export const ContentFocus = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  padding: 2rem;

  opacity: 100%;
`;

export const DivTitle = styled.div``;

export const H5 = styled.h5``;

export const InputFocus = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;

  gap: 1rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;

  input[type='file'] {
    display: none;
  }
`;

export const ObservationDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  height: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &&.isOpen {
    animation: isOpen 1s;
    @keyframes isOpen {
      from {
        white-space: nowrap;
      }
      to {
        white-space: normal;
      }
    }
    white-space: normal;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors?.gray02};
  margin-bottom: 0.5rem;

  width: 100%;
`;

export const ObservationInfo = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  width: 100%;
`;

export const ObservationText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;
