import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';

import WarningIcon from '@mui/icons-material/Warning';

import { ButtonSimple } from '../../Buttons';
import { BasicDialog } from '../../Dialog';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setInReview: Dispatch<SetStateAction<boolean>>;
}
export default function AccessExamReview({
  isOpen,
  setIsOpen,
  setInReview,
}: Props) {
  return (
    <S.Container>
      <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <S.Header>
          <S.ContentHeader>
            <WarningIcon fontSize="large" />
            <S.H4Title>Revisão da avaliação</S.H4Title>
          </S.ContentHeader>
        </S.Header>
        <S.Content>
          <S.H6>
            Após a revisão das respostas selecionadas, clique no botão
            &quot;FINALIZAR&quot; no rodapé da prova, caso você deseje alterar a
            resposta de alguma questão, clique no botão &quot;CORRIGIR&quot;
          </S.H6>
          <S.Button>
            <ButtonSimple
              color="primary"
              widthSize="large"
              onClick={() => {
                setIsOpen(false);
                setInReview(true);
              }}
            >
              Revisar
            </ButtonSimple>
          </S.Button>
        </S.Content>
      </BasicDialog>
    </S.Container>
  );
}
