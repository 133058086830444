import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 2rem;
  gap: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`;

export const DocumentName = styled.h6`
  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    width: 25rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    width: 40rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    text-align: center;
    width: 31rem;
  }
  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    text-align: center;
    width: 25rem;
  }
  @media screen and (max-width: 343px) {
    text-align: center;
    width: 20rem;
  }
`;
