import React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import * as S from './styles';

export interface INavigates {
  title: string;
  link?: string;
}

interface Navigate {
  navigates: INavigates[];
}

export default function Breadcrumb({ navigates }: Navigate) {
  return (
    <S.Container>
      <Breadcrumbs aria-label="breadcrumb">
        {navigates?.map((navigate, index) => {
          return (
            <Link
              key={index}
              underline="hover"
              color={
                index + 1 === navigates.length ? 'text-primary' : 'inherit'
              }
              href={navigate.link}
            >
              <S.Title>{navigate.title}</S.Title>
            </Link>
          );
        })}
      </Breadcrumbs>
    </S.Container>
  );
}
