import React from 'react';

import * as S from './styles';

interface Props {
  type: 'email' | 'phone';
}
export default function ConfirmMyData({ type }: Props) {
  const typeOption = {
    email: 'E-mail',
    phone: 'SMS',
  };
  return (
    <S.Container>
      <S.H5>Confirmação de {typeOption[type]}</S.H5>
      <S.P>Prezado aluno.</S.P>
      <S.P>
        Para a confirmação e troca do seu e-mail solicitamos que insira o código
        encaminhado por E-mail no campo ao lado.
      </S.P>
    </S.Container>
  );
}
