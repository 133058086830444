import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import * as S from './styles';

import { FormControlLabel, Switch } from '@mui/material';
import { handleMaxWidth } from '../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import { myDataServiceNewStudent } from '../../../../../services/newStudent';

interface Props {
  setThemeSelected: Dispatch<
    SetStateAction<'contrast' | 'gray' | 'dark' | 'light'>
  >;
  themeSelected: 'contrast' | 'gray' | 'dark' | 'light';
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Header({
  setThemeSelected,
  themeSelected,
  open,
  setOpen,
}: Props) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  const changeLayout = async (
    value: 'contrast' | 'gray' | 'dark' | 'light',
  ) => {
    try {
      setThemeSelected(value);
      await myDataServiceNewStudent.updatePreferences({
        theme: value,
      });
    } catch (error) {
      throw new Error(error);
    }
  };
  const handleOpenAccessibility = () => {
    return (
      <S.FocusDiv ref={ref}>
        <S.AccessibilityFocus onClick={() => setOpen(!open)}>
          <S.PFocus>Acessibilidade</S.PFocus>
          <img
            src={
              themeSelected === 'contrast'
                ? '/assets/img/accessibility_green.svg'
                : '/assets/img/accessibility_purple.png'
            }
            width="20px"
            height="20px"
          />
        </S.AccessibilityFocus>
        <S.Menu>
          <S.HeaderOption>
            <S.TitleOption>Cores e contrastes</S.TitleOption>
          </S.HeaderOption>
          <S.Option>
            <S.Opt>
              <FormControlLabel
                control={
                  <Switch
                    checked={themeSelected === 'contrast'}
                    onChange={(e) => {
                      e.target.checked
                        ? changeLayout('contrast')
                        : changeLayout('light');
                    }}
                  />
                }
                label={<S.OptText>Alto contraste</S.OptText>}
                labelPlacement="end"
              />
            </S.Opt>
            <S.Opt>
              <FormControlLabel
                control={
                  <Switch
                    checked={themeSelected === 'gray'}
                    onChange={(e) => {
                      e.target.checked
                        ? changeLayout('gray')
                        : changeLayout('light');
                    }}
                  />
                }
                label={<S.OptText>Tons de cinza</S.OptText>}
                labelPlacement="end"
              />
            </S.Opt>
            <S.Opt>
              <FormControlLabel
                control={
                  <Switch
                    checked={themeSelected === 'dark'}
                    onChange={(e) => {
                      e.target.checked
                        ? changeLayout('dark')
                        : changeLayout('light');
                    }}
                  />
                }
                label={<S.OptText>Claro/Escuro</S.OptText>}
                labelPlacement="end"
              />
            </S.Opt>
          </S.Option>
        </S.Menu>
      </S.FocusDiv>
    );
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <S.Container>
      {!handleMaxWidth(theme.size?.large) ? (
        !open ? (
          <S.Accessibility onClick={() => setOpen(!open)}>
            <S.P>Acessibilidade</S.P>
            <img
              src="/assets/img/accessibility_white.png"
              width="20px"
              height="20px"
            />
          </S.Accessibility>
        ) : (
          handleOpenAccessibility()
        )
      ) : !open ? (
        <></>
      ) : (
        <S.AccessibilityMobile>
          {handleOpenAccessibility()}
        </S.AccessibilityMobile>
      )}
    </S.Container>
  );
}
