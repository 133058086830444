import React, { useContext, useEffect, useMemo, useState } from 'react';

import * as S from './styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import studentDataService from '../../../../../services/student/student.data.service';
import { errorToast } from '../../../../common/Toast';
import {
  ProviderAgentContext,
  IAgentContextProps,
} from '../../../ProviderAgent';

import { ButtonIcon } from '../../../Buttons';
import BasicModal from '../../../Modal';
import HeaderModal from '../../../HeaderModal';
import TableBasic from '../../../../newStudent/Table';
import { ITccInternship } from '../../../../../services/agent/student/interface';

export default function TabTCC({ data: cpf }: { data: string }) {
  const context = useContext(ProviderAgentContext);
  const { isLoading, setIsLoading } = context as IAgentContextProps;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [historicData, setHistoricData] =
    useState<{ date: string; observation: string; status: string }[]>();

  const [tcc, setTcc] = useState<ITccInternship[] | []>([]);
  const [internship, setInternship] = useState<ITccInternship[] | []>([]);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const { data, message, result } = await studentDataService.tccInternship({
        login: cpf,
      });
      if (result) {
        setTcc(data.tcc);
        setInternship(data.internship);
      } else {
        message &&
          message.forEach((text) => {
            errorToast(text);
          });
      }
      setIsLoading(false);
    };
    load();
  }, [cpf]);

  const tccData = {
    title: ['Cadastro', 'Curso', 'Tipo', 'Situação', 'Histórico'],
    content: tcc.map((item) => ({
      register: {
        item: new Date(item.date).toLocaleDateString(),
      },
      course: {
        item: item.courseName,
      },
      type: {
        item: item.type,
      },
      status: {
        item: item.status,
      },
      historic: {
        item: item.historic.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonIcon
              color="white"
              onClick={() => {
                setOpenModal(true);
                setHistoricData(item.historic);
              }}
            >
              <VisibilityIcon />
            </ButtonIcon>
          </div>
        ),
      },
    })),
  };

  const internshipData = {
    title: ['Cadastro', 'Curso', 'Situação'],
    content: internship.map((item) => ({
      Cadastro: {
        item: new Date(item.date).toLocaleDateString(),
      },
      Curso: {
        item: item.name,
      },
      Situação: {
        item: item.status,
      },
    })),
  };

  const historicDataTable = useMemo(() => {
    return {
      title: ['Data', 'Observação', 'Status'],
      content: historicData?.map((item) => ({
        date: {
          item: new Date(item.date).toLocaleDateString(),
        },
        observation: {
          item: (
            <div
              dangerouslySetInnerHTML={{ __html: item.observation }}
              style={{ minWidth: '20rem' }}
            ></div>
          ),
        },
        status: {
          item: item.status,
        },
      })),
    };
  }, [historicData]);
  return (
    <S.Container>
      <S.Tcc>
        {tcc.length > 0 && <S.Title>TCC</S.Title>}
        <S.DivTable>
          <TableBasic
            data={tccData}
            titleNotFound={
              isLoading
                ? ''
                : tcc.length <= 0
                ? 'Este aluno não possui TCC cadastrado'
                : ''
            }
            titleLoading={isLoading}
          />
        </S.DivTable>
      </S.Tcc>
      <S.Internship>
        {internship.length > 0 && <S.Title>Estágio</S.Title>}
        <S.DivTable>
          <TableBasic
            data={internshipData}
            titleNotFound={
              isLoading
                ? ''
                : internship.length <= 0
                ? 'Este aluno não possui Estágio cadastrado'
                : ''
            }
            titleLoading={isLoading}
          />
        </S.DivTable>
      </S.Internship>
      <BasicModal isOpen={openModal} setIsOpen={setOpenModal} isFloat>
        <S.ContainerTable>
          <HeaderModal
            setOpen={setOpenModal}
            title="Histórico do TCC"
            subtitle
          />
          <S.DivTable>
            <TableBasic data={historicDataTable} />
          </S.DivTable>
        </S.ContainerTable>
      </BasicModal>
    </S.Container>
  );
}
