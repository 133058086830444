import React, { useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { Confirm } from 'semantic-ui-react';

import { ExitToAppOutlined } from '@mui/icons-material';

import * as apiService from '../../../../../services/api/student/api.service';
import { authStudentService } from '../../../../../services/auth';
import { sieadImagesFolderUrl } from '../../../../../variables';
import { ButtonQuit } from '../../../Buttons';

import * as S from './styles';

const logo = `${sieadImagesFolderUrl}c81e728d9d4c2f636f067f89cc14862c.jpg`;

export default function Header() {
  const [redirectTo, setRedirectTo] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    apiService
      .authenticatedRequest('/logout', 'POST')
      .then(() => {
        setRedirectTo(true);
        authStudentService.cleanLoggerUser();
      })
      .catch((error) => {
        error;
      });
  };

  return redirectTo ? (
    <Navigate to="/new/aluno" />
  ) : (
    <S.ContainerHead>
      <S.Div>
        <span>
          <NavLink title="Dashboard" to={'/aluno/dashboard'}>
            <img src={logo} alt="Logomarca" />
          </NavLink>
        </span>
      </S.Div>
      <S.ButtonContainer>
        <S.Quit>
          <ButtonQuit icon={<ExitToAppOutlined />} link={handleOpen} />
          <Confirm
            content="Deseja sair?"
            open={open}
            onCancel={handleClose}
            onConfirm={logout}
            style={{
              maxHeight: 100,
              width: '25%',
              position: 'fixed',
              display: 'flex',
              margin: 'auto',
              fontSize: '1.5rem',
            }}
          />
        </S.Quit>
      </S.ButtonContainer>
    </S.ContainerHead>
  );
}
