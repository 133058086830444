import React, { ReactNode } from 'react';

import * as S from './styles';

import ButtonSimple from '../ButtonSimple';

interface Props {
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  children: ReactNode;
  justify?:
    | 'normal'
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end';
  color: 'primary' | 'gray' | 'success' | 'danger';
  outline?: boolean;
  font: 'p' | 'b' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  onClick?: () => void;
  selected?: boolean;
  boxShadow?: boolean;
  styles?: object;
  heightSize?: 'small' | 'medium' | 'large' | 'xlarge';
  widthSize?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'fullWidth';
  type?: 'button' | 'submit';
}

export default function ButtonStartIcon({
  endIcon,
  startIcon,
  children,
  justify,
  heightSize,
  color,
  outline,
  font,
  onClick,
  selected,
  styles,
  widthSize,
  type = 'button',
  boxShadow,
}: Props) {
  const selectFont = {
    p: <p>{children}</p>,
    b: <b>{children}</b>,
    h1: <h1 style={{ height: '4.5rem' }}>{children}</h1>,
    h2: <h2 style={{ height: '4.5rem' }}>{children}</h2>,
    h3: <h3 style={{ height: '4rem' }}>{children}</h3>,
    h4: <h4 style={{ height: '3rem' }}>{children}</h4>,
    h5: <h5 style={{ height: '2.7rem' }}>{children}</h5>,
    h6: <h6>{children}</h6>,
  };

  return (
    <ButtonSimple
      icon={endIcon}
      justify={justify}
      heightSize={heightSize}
      color={color}
      outline={outline}
      onClick={onClick && onClick}
      selected={selected}
      styles={styles}
      widthSize={widthSize}
      type={type}
      boxShadow={boxShadow}
    >
      <S.TextIcon>
        {startIcon && startIcon}
        {selectFont[font]}
      </S.TextIcon>
    </ButtonSimple>
  );
}
