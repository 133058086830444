import React from 'react';

import * as S from './styles';
import { Paper, Table, TableBody, TableHead } from '@mui/material';
import {
  MyTableCell,
  MyTableRow,
  RowText,
  SubtitleTable,
  TableTitleRow,
} from '../../../../../components/student/Table/components';

interface IInfo {
  title: string;
  value: string;
}

interface Props {
  data: IInfo[];
}

export default function Value({ data }: Props) {
  return (
    <S.Container>
      <Table component={Paper}>
        <TableHead>
          <TableTitleRow>
            {data.map((cell) => {
              return (
                <MyTableCell
                  align="center"
                  key={cell.title.toLocaleLowerCase()}
                >
                  <SubtitleTable>{cell.title}</SubtitleTable>
                </MyTableCell>
              );
            })}
          </TableTitleRow>
        </TableHead>
        <TableBody>
          <MyTableRow>
            {data.map((cell) => {
              return (
                <MyTableCell
                  align="center"
                  className={cell.title.toLocaleLowerCase()}
                  key={cell.title.toLocaleLowerCase()}
                >
                  <RowText>{cell.value}</RowText>
                </MyTableCell>
              );
            })}
          </MyTableRow>
        </TableBody>
      </Table>
    </S.Container>
  );
}
