import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const element = document.getElementById('root');
const root = ReactDOM.createRoot(element!);

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover={false}
      theme="light"
    />
  </React.StrictMode>,
);
