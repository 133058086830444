import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 3rem 4rem;
`;

export const Title = styled.h5``;

export const Text = styled.p``;
