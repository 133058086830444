import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  gap: 2rem;
`;

export const DivSelect = styled.div`
  display: flex;
  gap: 1rem;
`;

export const DivSend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;

  justify-content: space-between;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors?.primary};

  gap: 1rem;
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.danger};
`;

export const TermButton = styled.a`
  font-weight: bold;
  width: 13rem;
  padding-left: 2rem;

  cursor: pointer;
`;
