export default {
  title: 'lignt',

  gray: 0,
  colors: {
    primary: '#0071BD',
    secondary: '#47AAEB',
    disable01: '#BBDCF1',
    success: '#7AC943',
    success02: '#00BA9C',
    aqua: '#00BA9C',
    disable02: '#C1F59D',
    danger: '#FF5A66',
    danger02: '#FC1929',
    disable03: '#F4D4D6',
    warning: '#F29423',
    tertiary: '#F9EC22',
    attention: '#9354E6',
    detail: '#FD8BD1',
    gray01: '#F2F2F2',
    gray02: '#E8E8E8',
    gray03: '#959595',
    gray04: '#707070',
    disable04: '#E6E6E6',
    backgroundColor: '#FFFFFF',
    text: {
      primary: '#707070',
      secondary: '#959595',
      tertiary: '#FFFFFF',
      gray05: '#E6E6E6',
      attention: '#9354E6',
      danger: '#FF5A66',
      danger03: '#F4D4D6',
      success: '#7AC943',
      success02: '#C1F59D',
      aqua: '#C1F59D',
      info01: '#47AAEB',
      info02: '#0071BD',
      info03: '#BBDCF1',
    },
  },
};
