import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import { CardLeftColor } from '../../../../../components/newStudent/Card';
import { ButtonNext } from '../../../../../components/newStudent/Buttons';
import { homeServiceNewStudent } from '../../../../../services/newStudent';
import { IAnnouncements } from '../../../../../services/newStudent/home/home.service';
import {
  NewStudentProviderContext,
  INewStudentProvider,
} from '../../../../../components/newStudent/ProviderNewStudent';

export default function CardNews() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [announcements, setAnnouncements] = useState<IAnnouncements[]>([]);

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);
        const announcementsData = localStorage.getItem('announcementsData');
        if (announcementsData) {
          setAnnouncements(JSON.parse(announcementsData));
        } else {
          const { data, result } = await homeServiceNewStudent.announcements();
          if (result) {
            setAnnouncements(data);
            localStorage.setItem('announcementsData', JSON.stringify(data));
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  return (
    <S.Container hasOnlyOne={announcements && announcements.length <= 1}>
      {announcements &&
        announcements.map((item, index) => (
          <CardLeftColor key={index} heightSize={'large'} color="secondary">
            <S.CardContent>
              <S.Title>{item.subject}</S.Title>
              <S.Content>
                <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                <S.ButtonContent>
                  <ButtonNext
                    color="primary"
                    widthSize="xmedium"
                    outline
                    upperCase
                    onClick={() => item.link && window.open(item.link)}
                  >
                    CONFIRA
                  </ButtonNext>
                </S.ButtonContent>
              </S.Content>
            </S.CardContent>
          </CardLeftColor>
        ))}
    </S.Container>
  );
}
