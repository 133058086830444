import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import * as S from './styles';
import { IconButton } from '@mui/material';

interface Props {
  title: string | ReactNode;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}
export default function TitleDialogMobile({
  title,
  setIsOpen,
  onClose,
}: Props) {
  return (
    <S.Container>
      <S.Content>
        <IconButton
          onClick={() => {
            setIsOpen(false);
            onClose && onClose();
          }}
        >
          <ChevronLeftIcon style={{ fontSize: '40px' }} />
        </IconButton>
        {typeof title === 'string' ? (
          <S.Subtitle>
            <S.H5>{title}</S.H5>
          </S.Subtitle>
        ) : (
          <S.Subtitle>{title}</S.Subtitle>
        )}
      </S.Content>
    </S.Container>
  );
}
