import React from 'react';

import { Print, Refresh } from '@mui/icons-material';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';

import {
  calculateDifferenceDays,
  convertStringToMoment,
  differenceInDays,
} from '../../../../../util/pageUtil';
import { IBillet } from '../../../../../interfaces/student/installments.interface';
import { BodyTable } from '../../../../../components/student/Table/PaymentsTable';
import { warningToast } from '../../../../../components/common/Toast';
import { Button } from '../../../../../components/student/Buttons';
import { financialService } from '../../../../../services';

interface Props {
  allActiveBilletsOpen: IBillet[];
  expiredBillets: IBillet[];
  load: (isLoading: boolean) => void;
}

export default function ChildrenBody({
  allActiveBilletsOpen,
  expiredBillets,
  load,
}: Props) {
  const viewBillet = async (id: number) => {
    load(true);

    const response = await financialService.viewBillet(id);
    if (response.message) {
      warningToast(
        'Não foi possível emitir esta fatura, entre em contato com o suporte através do chat.',
      );
      load(false);
    }
  };

  const updateBillet = async (id: number) => {
    load(true);

    const response = await financialService.updateBillet(id);
    if (!response.result) {
      warningToast(
        'Não foi possível atualizar esta fatura, entre em contato com o suporte através do chat.',
      );
      load(false);
    }
  };

  return (
    <>
      {allActiveBilletsOpen.map((data) => {
        const dataVencimento = data.vencimento_data_atualizada
          ? data.vencimento_data_atualizada
          : data.vencimento_data;

        const vencimentoValor = data.vencimento_valor_atualizado
          ? data.vencimento_valor_atualizado
          : data.vencimento_valor;

        const boletoVencido =
          calculateDifferenceDays(convertStringToMoment(dataVencimento)) > 0;

        const permitidoAtualizar = differenceInDays(
          moment(),
          data.vencimento_data,
          'DD/MM/YYYY',
        );

        const boletoNegociacao =
          data.plano_contas_id === 55 || data.plano_contas_id === 53;

        const boletoAtualizar =
          boletoVencido &&
          permitidoAtualizar < 30 &&
          !data.vencimento_data_atualizada &&
          expiredBillets.length < 2 &&
          data.plano_contas_id !== 55 &&
          data.plano_contas_id !== 53;

        let link;
        let icon;
        let color;
        let button = true;

        if (boletoAtualizar) {
          link = () => updateBillet(data.id);
          icon = <Refresh />;
          color = 'danger';
        } else {
          if (!boletoVencido) {
            link = () => viewBillet(data.id);
            icon = <Print />;
            color = 'info';
          } else {
            button = false;
          }
        }

        return (
          <BodyTable
            id={data.id}
            key={data.id}
            color={boletoVencido ? 'red' : ''}
            icon={boletoNegociacao && <Icon name="handshake" />}
            accountName={data.plano_contas_nome}
            description={data.observacao}
            dueDate={dataVencimento}
            dueDateValue={vencimentoValor}
            status={boletoVencido ? 'Vencido' : 'Em aberto'}
            button={button && <Button link={link} icon={icon} color={color} />}
          />
        );
      })}
    </>
  );
}
