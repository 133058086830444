import styled from 'styled-components';

interface Props {
  justify?: 'center' | 'start' | 'end';
}
export const Container = styled.div<Props>`
  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify};

  color: ${({ theme }) => theme.colors?.text.primary};
  gap: 1rem;
  width: 100%;
`;
