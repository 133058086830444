import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as S from './styles';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import TitleDialogMobile from '../../../../../components/newStudent/Title/TitleDialogMobile';
import Divider from '../../../../../components/common/Divider';
import DataStudent from '../CoursesCommon/components/DataStudent';
import FieldFormik, {
  IFormOptions,
} from '../../../../../components/newStudent/FieldFormik';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { IUserData } from '../../../MyDataNewStudent';
import { addDataCache, getDataCache } from '../../../../../util/common/appUtil';
import {
  certificateServiceNewStudent,
  myDataServiceNewStudent,
} from '../../../../../services/newStudent';
import { Formik } from 'formik';
import {
  courseTypeGraduation,
  courseTypePosGraduation,
} from '../../../../../variables';
import { AletNotfoundLoading } from '../../../../../components/newStudent/Alert';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import MinimumRequerimentsMobile from './components/MinimumRequerimentsMobile';
import CardCourse from './components/CardCourse';
import { ICertificate } from '../../../../../services/newStudent/certificate/certificate.service';

export interface IDataCourseCards {
  courseName: string;
  courseType: string;
  registrationId: number;
  status: string | null;
  type: string | null;
  date: string | null;
  observation: string | null;
  url: string | null;
}
interface Props {
  isGraduation?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function CoursesCommonMobile({
  isGraduation = false,
  isOpen,
  setIsOpen,
}: Props) {
  const { student, setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [reeded, setReeded] = useState<boolean>();
  const [courses, setCourses] = useState<IFormOptions[]>([]);
  const [dataStudent, setDataStudent] = useState<IUserData>();
  const [courseSelected, setCourseSelected] = useState<number>();
  const [initialValues, setInitialValues] = useState({
    courseSelected: 0,
    readed: false,
  });
  const [dataCourseCards, setDataCourseCards] = useState<IDataCourseCards[]>(
    [],
  );
  const [isOpenRequeriments, setIsOpenRequeriments] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const load = async () => {
      try {
        /** Dados dos cursos */
        const { data, result } = await certificateServiceNewStudent.show({
          type: isGraduation
            ? +courseTypeGraduation!
            : +courseTypePosGraduation!,
        });
        if (result) {
          const tmpCourses = data.certificates.reduce<ICertificate[]>(
            (acc, current) => {
              const arr = acc.find(
                (item) => item.registrationId === current.registrationId,
              );
              if (!arr) {
                return acc.concat(current);
              } else return acc;
            },
            [],
          );

          setCourses([
            { value: 0, title: '[ Selecione o curso ]' },
            ...tmpCourses.map((item) => ({
              title: item.courseName,
              value: item.registrationId,
            })),
          ]);
          setDataCourseCards(() =>
            data.certificates.filter((item) => !!item.date),
          );
        }
        /** Dados de aluno */
        const dataStudent = await getDataCache(
          'dataStudent',
          '/new/aluno/dados',
        );
        if (dataStudent && dataStudent.name) {
          setDataStudent(dataStudent);
        } else {
          const { data, result } = await myDataServiceNewStudent.studentData({
            login: student?.dataStudent.cpf
              ? student?.dataStudent.cpf
              : student?.dataStudent.email,
          });
          if (result) {
            const tmpData = {
              name: data.name,
              email: data.email,
              cellPhone: data.cellPhone,
              businessPhone: data.businessPhone,
              homePhone: data.homePhone,
              fatherName: data.fatherName,
              motherName: data.motherName,
              bornDate: data.bornDate,
              rgId: data.rgId,
              cpf: data.cpf,
              colorRace: data.colorRace,
              studentGraduation: data.studentGraduation,
              degree: data.degree,
              graduationYear: data.graduationYear,
              nationality: data.nationality,
              naturalness: data.naturalness,
              university: data.university,
              complement: data.complement,
              city: data.city,
              state: data.state,
              street: data.street,
              cep: data.cep,
              number: data.number,
              neighborhood: data.neighborhood,
              addressCep: data.cep,
              poleId: data.poleId,
            };

            addDataCache('dataStudent', tmpData, '/new/aluno/dados');
            setDataStudent(tmpData);
          }
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw new Error(error);
      }
    };
    load();
  }, []);

  const [target, setTarget] = useState<number>();
  const targetRef = useRef<HTMLDivElement>(null);
  const handleChangeSelect = (value: number) => {
    setCourseSelected(+value);
    setTarget(+value);
  };
  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  useEffect(() => {
    scrollToTarget();
  }, [target]);

  useEffect(() => {
    setInitialValues({ courseSelected: +courseSelected!, readed: false });
  }, [courseSelected]);

  return (
    <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen} fullScreen scroll="body">
      <TitleDialogMobile setIsOpen={setIsOpen} title={'Meus Certificados'} />
      <S.Container>
        <S.TitlePage>
          {isGraduation ? 'Graduação' : 'Pós-Graduação'}
        </S.TitlePage>
        <Divider padding />
        {dataCourseCards.length > 0 && (
          <CardCourse
            dataCourseCards={dataCourseCards}
            onClick={(e: number) => {
              setReeded(true);
              handleChangeSelect(e);
              setIsOpenRequeriments(true);
              setInitialValues({ courseSelected: e, readed: true });
            }}
          />
        )}
        <AletNotfoundLoading toRender={courses.length <= 0} />
        {courses.length > 0 && (
          <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize
          >
            {({ handleChange, setFieldValue, values }) => (
              <>
                <FieldFormik
                  name="courseSelected"
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeSelect(e.target.value);
                    setFieldValue('readed', false);
                  }}
                  type="select"
                  options={courses}
                  widthSize="fullWidth"
                  value={values.courseSelected}
                />
                <DataStudent dataStudent={dataStudent!} noBorder />
                {+courseSelected! > 0 && (
                  <S.CheckboxDiv
                    ref={courseSelected === target ? targetRef : null}
                  >
                    <FieldFormik
                      name="readed"
                      placeholder="Li e confirmo que as informações aqui contidas estão corretas e não precisam de alterações."
                      onChange={(e) => {
                        handleChange(e);
                        setReeded(e.target.checked);
                      }}
                      type="checkbox"
                      checked={values.readed}
                      widthSize="fullWidth"
                      notLimitHeight
                    />
                    <S.Button>
                      <ButtonSimple
                        color="success"
                        onClick={() => setIsOpenRequeriments(true)}
                        widthSize="medium"
                        heightSize="small"
                        disabled={!values.readed}
                      >
                        Confirmar
                      </ButtonSimple>
                    </S.Button>
                  </S.CheckboxDiv>
                )}
              </>
            )}
          </Formik>
        )}
        {+courseSelected! !== 0 && reeded && (
          <div>
            <MinimumRequerimentsMobile
              isOpen={isOpenRequeriments}
              setIsOpen={setIsOpenRequeriments}
              courseSelected={courseSelected}
              setCourseId={setCourseSelected}
              title={isGraduation ? 'Graduação' : 'Pós-Graduação'}
            />
          </div>
        )}
      </S.Container>
    </BasicDialog>
  );
}
