import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';

import { Slider } from '@mui/material';

import * as S from './styles';
import FormField from '../FormField';

interface IMark {
  value: number;
  label: string | ReactNode;
}
interface Props {
  maxValue: number;
  value: string;
  input?: boolean;
  marks?: IMark[];
  title?: string;
  disabled?: boolean;
  setValue: Dispatch<SetStateAction<string>>;
}

export default function SliderComponent({
  maxValue,
  value,
  setValue,
  input,
  marks,
  disabled,
  title,
}: Props) {
  const handleSliderChange = (event: Event, newValue: number) => {
    if (+newValue <= +maxValue) setValue(newValue.toString());
    else setValue(maxValue.toString());
  };
  useEffect(() => {
    if (+value > +maxValue) setValue('0');
  }, [maxValue]);

  return (
    <S.Container>
      <S.DivTitleTop>
        <S.Title>{title}</S.Title>
      </S.DivTitleTop>
      <S.Content>
        <S.ContainerSlider>
          <Slider
            value={+maxValue > 0 ? +value : undefined}
            max={maxValue}
            aria-label="Default"
            disabled={disabled || maxValue <= 1}
            valueLabelDisplay="on"
            marks={marks && marks}
            onChange={handleSliderChange}
          />
        </S.ContainerSlider>
        <>
          {input && (
            <S.ContainerInput>
              <FormField
                item={{
                  name: 'discount',
                  placeholder: 'R$0,00',
                  type: 'number',
                  disabled: disabled || maxValue <= 1,
                  value:
                    maxValue >= 1
                      ? +value > 0
                        ? value
                        : undefined
                      : undefined,
                  handleChange: (e) => {
                    if (+e.target.value <= 0) {
                      return setValue('0');
                    }
                    +e.target.value <= maxValue
                      ? setValue(e.target.value)
                      : setValue(maxValue.toString());
                  },
                  lg: 12,
                  style: {
                    boxShadow: 'none',
                    textAlign: 'end',
                    minWidth: '7rem',
                  },
                }}
              />
            </S.ContainerInput>
          )}
          <S.DivTitleRight>
            <S.Title>{title}</S.Title>
          </S.DivTitleRight>
        </>
      </S.Content>
    </S.Container>
  );
}
