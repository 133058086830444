import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  width: 50vw;
  margin: 3rem 4rem;
  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    width: 70vw;
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 1rem;
`;

export const StyledInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 2rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  gap: 2rem;
  align-self: end;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 100%;
  }
`;
