import React, { useState } from 'react';

import SendIcon from '@mui/icons-material/Send';

import HeaderModal from '../../HeaderModal';
import BasicModal from '../../Modal';
import ButtonSimple from '../ButtonSimple';
import BasicTable from '../../../common/Table/BasicTable';

export default function ButtonOpenTable({ data }) {
  const [isTableOpen, setIsTableOpen] = useState(false);

  return (
    <>
      <ButtonSimple
        icon={<SendIcon />}
        iconLocation="start"
        color="white"
        size="medium"
        onClick={() => setIsTableOpen(!isTableOpen)}
      >
        Abrir
      </ButtonSimple>
      <BasicModal
        isFloat={true}
        isOpen={isTableOpen}
        setIsOpen={setIsTableOpen}
      >
        <div style={{ width: '70vw' }}>
          <HeaderModal setOpen={setIsTableOpen} title="Itens da Venda" />
          <div style={{ margin: '2rem' }}>
            <BasicTable data={data} />
          </div>
        </div>
      </BasicModal>
    </>
  );
}
