import React, { useContext, useState } from 'react';

import * as S from './styles';

import MonitorIcon from '@mui/icons-material/Monitor';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CachedIcon from '@mui/icons-material/Cached';
import PrintIcon from '@mui/icons-material/Print';

import {
  CardInfo,
  CardLeftColor,
} from '../../../../../components/newStudent/Card';
import {
  ButtonNext,
  ButtonSimple,
} from '../../../../../components/newStudent/Buttons';
import { useNavigate } from 'react-router-dom';
import BlockedCourse from '../../../../../components/newStudent/Modal/BlockedCourse';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import {
  formatCurrency,
  formatDate,
  formatMonthDay,
  toCaptalize,
} from '../../../../../util/common/appUtil';
import UpdateBillet from '../../../../../components/newStudent/Modal/UpdateBillet';
import { financialServiceNewStudent } from '../../../../../services/newStudent';
import { IDataStudent } from '../../../../../interfaces/newStudent/dataStudent';

export default function CardHomePageMyCourses() {
  const { student, setIsLoading, setStudent } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [isOpenBlockedCourse, setIsOpenBlockedCourse] = useState(false);
  const [isOpenShouldUpdate, setIsOpenShouldUpdate] = useState(false);
  const [dataShouldUpdate, setDataShouldUpdate] = useState({
    id: 0,
    message: '',
    canUpdate: false,
  });
  const navigate = useNavigate();

  const handleClickInvoice = async (id: number, isUpdate?: boolean) => {
    setIsLoading(true);

    if (isUpdate) {
      await financialServiceNewStudent.handlePrintOutBillet(id);
    } else {
      const { result, data } =
        await financialServiceNewStudent.handleUpdateBillet(id);
      if (result) {
        const dataStudent: IDataStudent =
          localStorage.getItem('dataStudent') &&
          JSON.parse(localStorage.getItem('dataStudent') as string);

        const newDataStudent = {
          ...dataStudent,
          invoice: dataStudent.invoice.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                action: { print: true, update: false },
                dueDate: data.dueDate,
                value: data.value.replace(',', '.'),
              };
            }
            return item;
          }),
        };
        setStudent(newDataStudent);

        localStorage.setItem('dataStudent', JSON.stringify(newDataStudent));
        setIsOpenShouldUpdate(false);
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      <S.Cards
        hasOnlyOne={
          !!(student?.recentCourses && student?.recentCourses.length < 1) ||
          !!(student?.invoice && student?.invoice.length < 1)
        }
      >
        {student?.recentCourses && student?.recentCourses.length >= 1 && (
          <CardInfo
            className={'card_info_courses'}
            title={'Meus Cursos'}
            icon={<MonitorIcon fontSize="large" />}
          >
            <S.P color="secondary">
              Acompanhe e acesse por aqui todos os cursos que você está
              matriculado. Bons estudos!
            </S.P>
            <S.Content>
              {student?.recentCourses.map((course) => (
                <CardLeftColor
                  color={course.active ? 'secondary' : 'danger'}
                  key={course.registrationId}
                >
                  <S.ContentCard>
                    <S.DivName
                      shouldShowFullName={student?.invoice.length <= 1}
                    >
                      <S.H6MyCourses color="primary">
                        {course.name}
                      </S.H6MyCourses>
                    </S.DivName>
                    <ButtonSimple
                      color={course.active ? 'success' : 'danger'}
                      heightSize="small"
                      widthSize="xmedium"
                      upperCase
                      onClick={() => {
                        course.active
                          ? course.moodle
                            ? window.open(course.urlExternal)
                            : navigate(
                                `/new/aluno/cursos?course=${course.registrationId}`,
                              )
                          : setIsOpenBlockedCourse(true);
                      }}
                    >
                      {course.active ? 'Estudar' : 'Bloqueado'}
                    </ButtonSimple>
                  </S.ContentCard>
                </CardLeftColor>
              ))}
              <ButtonNext
                color="primary"
                widthSize="fullWidth"
                upperCase
                outline
                onClick={() => navigate('/new/aluno/cursos')}
              >
                Ver todos
              </ButtonNext>
            </S.Content>
          </CardInfo>
        )}
        {student?.invoice && student?.invoice.length >= 1 && (
          <CardInfo
            className={'card_info_invoice'}
            title={'Financeiro'}
            icon={<AttachMoneyIcon fontSize="large" />}
          >
            <S.P color="secondary">
              Confira sua próxima mensalidade e mantenha em dia seus pagamentos.
              Veja formas de pagamento.
            </S.P>
            <S.Content>
              {student?.invoice.map((invoice, index) => (
                <CardLeftColor
                  className={'card_left_' + index}
                  key={invoice.id}
                  color={invoice.action.print ? 'primary' : 'danger'}
                  hover
                  button={
                    invoice.action.print ? (
                      <PrintIcon fontSize="large" />
                    ) : (
                      <CachedIcon fontSize="large" />
                    )
                  }
                  onClick={() => {
                    if (invoice.action.update) {
                      setIsOpenShouldUpdate(true);
                      setDataShouldUpdate({
                        id: invoice.id,
                        message: invoice.observation,
                        canUpdate: true,
                      });
                    } else if (invoice.action.print) {
                      handleClickInvoice(invoice.id, invoice.action.print);
                    } else {
                      setDataShouldUpdate({
                        id: invoice.id,
                        message: invoice.observation,
                        canUpdate: false,
                      });
                      setIsOpenShouldUpdate(true);
                    }
                  }}
                >
                  <S.ContentCard>
                    <S.DateCard>
                      <S.H6
                        color={invoice.action.print ? 'primary' : 'danger'}
                        style={{ height: '1rem' }}
                      >
                        {toCaptalize(formatMonthDay(invoice.dueDate))}
                      </S.H6>
                      <S.P color={invoice.action.print ? 'primary' : 'danger'}>
                        Vencimento dia {formatDate(invoice.dueDate)}
                      </S.P>
                    </S.DateCard>
                    <S.H6 color={invoice.action.print ? 'primary' : 'danger'}>
                      {formatCurrency(invoice.value)}
                    </S.H6>
                  </S.ContentCard>
                </CardLeftColor>
              ))}
              <ButtonNext
                color="primary"
                widthSize="fullWidth"
                upperCase
                outline
                onClick={() => navigate('/new/aluno/historico-financeiro')}
              >
                Ver todos
              </ButtonNext>
            </S.Content>
          </CardInfo>
        )}
      </S.Cards>
      <BlockedCourse
        isOpen={isOpenBlockedCourse}
        setIsOpen={setIsOpenBlockedCourse}
      />
      <UpdateBillet
        isOpen={isOpenShouldUpdate}
        setIsOpen={setIsOpenShouldUpdate}
        handleSubmit={() => handleClickInvoice(dataShouldUpdate.id, false)}
        canUpdate={dataShouldUpdate.canUpdate}
        message={dataShouldUpdate.message}
      />
    </div>
  );
}
