import React, { ReactNode } from 'react';

import * as S from './styles';
interface Props {
  children?: ReactNode;
  padding?: boolean;
  isVertical?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'disable01'
    | 'success'
    | 'aqua'
    | 'disable02'
    | 'danger'
    | 'danger02'
    | 'disable03'
    | 'warning'
    | 'tertiary'
    | 'attention'
    | 'detail'
    | 'gray01'
    | 'gray02'
    | 'gray03'
    | 'gray04'
    | 'disable04'
    | 'backgroundColor';
}
export default function Divider({
  children,
  padding,
  isVertical,
  color = 'gray03',
}: Props) {
  return isVertical ? (
    <S.VerticalDivider padding={padding} color={color} />
  ) : (
    <S.HorizontalDivider padding={padding} color={color}>
      {children}
    </S.HorizontalDivider>
  );
}
