import styled from 'styled-components';

import { sieadUrl } from '../../../variables';

export const backgroundImg = `${sieadUrl}/content/aluno/bg_full.jpg`;
export const logo = `${sieadUrl}/content/aluno/logo_branca.png`;

export const Container = styled.div`
  background-color: #fff;
  background-position: left center;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  position: relative;
  font-size: 11px;

  background-image: url(${backgroundImg});
`;

export const Logo = styled.div`
  height: 100%;
  width: 48%;
  float: left;
  background-size: auto 135vh;
  background-size: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${logo});

  @media screen and (max-width: 950px) and (min-height: 500px),
    screen and (orientation: portrait) {
    height: 20vh;
    width: 100vw;
  }
  @media screen and (max-device-width: 1080px), screen and (max-width: 500px) {
    height: 20vh;
    width: 100vw;
    background-size: 300px;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

export const Circle = styled.div`
  height: 100vh;
  top: 0;
  right: -50px;
  width: 55%;
  min-width: 650px;
  position: absolute;
  float: right;
  background-size: 200%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 950px) and (min-height: 500px),
    screen and (orientation: portrait) {
    top: initial;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 0;
    position: absolute;
    background-size: 200%;
    background-position: 55% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 1081px) {
    .circleCont {
      justify-content: center;
    }
  }

  @media screen and (max-device-width: 1080px), screen and (max-width: 500px) {
    top: 18%;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 0;
    position: absolute;
    background-size: 200%;
    background-position: 55% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 78vh;
    padding: 0;
  }

  @media screen and (max-height: 600px) and (orientation: landscape) {
  }
`;

export const FormContainer = styled.div`
  width: 245px;
  color: #959595;
  font-size: 1em;
  max-height: 100%;
  position: relative;
`;

export const P = styled.p`
  margin-bottom: 1em;
`;

export const A = styled.a`
  font-size: 11px;
  text-decoration: none;
  color: #1f80e1;
  padding: 0.3rem;
`;

export const Span = styled.span`
  color: #bbb;
  padding: 0;
`;
