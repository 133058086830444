import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Footer, SideBar, Header, Main } from '../components';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../../../styles/GlobalStyle/global';
import { common } from '../../../../styles/GlobalStyle/themes';
import { Box, FormControlLabel, Switch } from '@mui/material';

import * as S from './style';
import { Outlet } from 'react-router-dom';
import Loading from '../../../common/Loading';
import {
  INewStudentProvider,
  NewStudentProviderContext,
  themes,
} from '../../ProviderNewStudent';
import LoginAs from '../components/LoginAs';
import Blip from '../components/Blip';
import { nodeEnv } from '../../../../variables';
import { myDataServiceNewStudent } from '../../../../services/newStudent';

export default function LayoutMainNewStudent() {
  const {
    student,
    loading,
    isSidebarOpen,
    themeSelected,
    accessedAs,
    setThemeSelected,
    setIsLoading,
  } = useContext(NewStudentProviderContext) as INewStudentProvider;

  const [isAccessibilityOpen, setIsAccessibilityOpen] =
    useState<boolean>(false);
  const [layout, setLayout] = useState(true);

  useEffect(() => {
    if (
      student?.preferences.theme &&
      student?.preferences.theme !== themeSelected
    ) {
      setThemeSelected(student?.preferences.theme);
    }
  }, [student?.preferences.theme]);

  const changeLayout = async () => {
    setLayout(false);
    try {
      setIsLoading(true);
      const { result } = await myDataServiceNewStudent.updatePreferences({
        isOldLayout: true,
      });
      if (result) {
        localStorage.removeItem('alunoStorage');
        localStorage.removeItem('dataStudent');

        window.location.href = '/aluno/dashboard';
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  /** Isso aqui vai sair assim que alterarmos pro novo portal do aluno */
  const tmpReferrerIsOldLayout = useMemo(() => {
    const referrer = document.referrer;

    if (referrer) {
      const referrerUrl = new URL(referrer);
      if (
        referrerUrl.pathname.startsWith('/aluno') &&
        window.location.pathname.startsWith('/new/aluno')
      ) {
        return true;
      } else return false;
    }
  }, []);

  return (
    <ThemeProvider theme={{ ...themes[themeSelected], ...common }}>
      {loading && <Loading isBackgroundSolid={tmpReferrerIsOldLayout} />}
      {accessedAs && <LoginAs />}
      <S.Container>
        <GlobalStyle />
        <S.Main className="main">
          <SideBar
            setIsAccessibilityOpen={setIsAccessibilityOpen}
            changeLayout={changeLayout}
            layout={layout}
          />
          <Box
            sx={{
              display: isSidebarOpen ? 'none' : 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
            }}
          >
            <Header
              themeSelected={themeSelected}
              setThemeSelected={setThemeSelected}
              setOpen={setIsAccessibilityOpen}
              open={isAccessibilityOpen}
            />
            {student?.preferences?.canYouAccessOld && (
              <S.TemporarySwitchToChangeLayout>
                <FormControlLabel
                  control={<Switch checked={layout} onChange={changeLayout} />}
                  label={<h4>Novo layout</h4>}
                  labelPlacement="end"
                />
              </S.TemporarySwitchToChangeLayout>
            )}
            <Main>
              <Outlet />
            </Main>
          </Box>
        </S.Main>
        <Footer />
      </S.Container>
      {nodeEnv === 'production' && (
        <Blip
          phone={student?.dataStudent?.cellPhone}
          email={student?.dataStudent?.email}
          name={student?.dataStudent?.name}
          onClose
        />
      )}
    </ThemeProvider>
  );
}
