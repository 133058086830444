import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import BasicModal from '../../../Modal';
import BasicTab from '../../../BasicTab';
import HeaderModal from '../../../HeaderModal';
import {
  TabTCC,
  TabData,
  TabGrades,
  TabCourses,
  TabServices,
  TabDocuments,
  TabFinancial,
} from '../../../Tabs/TabSearchStudent';
import {
  ProviderAgentContext,
  IAgentContextProps,
} from '../../../ProviderAgent';

interface Props {
  isOpen: boolean;
  dataStudent: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isSearch?: boolean;
}

export default function ModalStudentInfo({
  isOpen,
  setIsOpen,
  dataStudent,
  isSearch,
}: Props) {
  const context = useContext(ProviderAgentContext);
  const { isLoading } = context as IAgentContextProps;

  const [studentName, setStudentName] = useState<string>();
  const data = [
    {
      title: 'Dados',
      children: <TabData data={dataStudent} setStudentName={setStudentName} />,
      disabled: isLoading,
    },
    {
      title: 'Cursos',
      children: <TabCourses data={dataStudent} />,
      disabled: isLoading,
    },
    {
      title: 'Financeiro',
      children: <TabFinancial data={dataStudent} />,
      disabled: isLoading,
    },
    {
      title: 'Documentação',
      children: <TabDocuments data={dataStudent} />,
      disabled: isLoading,
    },
    {
      title: 'Notas',
      children: <TabGrades data={dataStudent} />,
      disabled: isLoading,
    },
    {
      title: 'TCC/Estágio',
      children: <TabTCC data={dataStudent} />,
      disabled: isLoading,
    },
    {
      title: 'Atendimento',
      children: <TabServices data={dataStudent} />,
      disabled: isLoading,
    },
  ];

  useEffect(() => {
    !isOpen && setStudentName('');
  }, [isOpen]);

  const titlesToRemove = ['Documentação', 'TCC/Estágio'];

  return (
    <BasicModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <HeaderModal
        setOpen={setIsOpen}
        title={`Ficha de Matrícula${studentName ? ' | ' + studentName : ''}`}
        subtitle
      />
      <BasicTab
        data={
          isSearch
            ? data
            : data.filter((item) => !titlesToRemove.includes(item.title))
        }
      />
    </BasicModal>
  );
}
