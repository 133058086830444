import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import RegistrationStatement from './components/Registration';
import Divider from '../../../components/common/Divider';
import { addDataCache, getDataCache } from '../../../util/common/appUtil';
import { useLocation } from 'react-router-dom';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import BulletPoint from '../../../components/newStudent/BulletPoint';
import ButtonOpen from '../../../components/newStudent/Buttons/ButtonOpen';
import { AletNotfoundLoading } from '../../../components/newStudent/Alert';
import { handleMaxWidth } from '../../../util/newStudent';
import { useTheme } from 'styled-components';
import CourseCompletion from './components/CourseCompletion';
import {
  ICourseCategory,
  IStatementsRequirements,
  statementsServiceNewStudent,
} from '../../../services/newStudent/statements/statements.service';

export default function StatementsNewStudent() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const theme = useTheme();
  const [courseCompletionOpen, setCourseCompletionOpen] = useState(false);

  const [registrationOpen, setRegistrationOpen] = useState(false);
  const [registrationRows, setRegistrationRows] = useState<ICourseCategory[]>();
  const [requirements, setRequirements] = useState<IStatementsRequirements[]>();

  const { pathname } = useLocation();

  useEffect(() => {
    try {
      setIsLoading(true);

      const load = async () => {
        const registrationData = await getDataCache(
          'registrationData',
          pathname,
        );

        if (registrationData) {
          setRegistrationRows(registrationData.statements);
          setRequirements(registrationData.requirements);
        } else {
          const { result, data } = await statementsServiceNewStudent.show();
          if (result) {
            addDataCache('registrationData', data, pathname);
            setRegistrationRows(data.statements);
            setRequirements(data.requirements);
          }
        }
      };
      load();
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  return (
    <S.Container>
      <TitlePage
        titlePage="Declarações"
        title="Declarações - Digital"
        subtitle={`Aqui você tem acesso a todas declarações detalhadas em formato digital de matrícula, 
        curso e conclusão de curso. 
        Escolha abaixo qual declaração deseja emitir.`}
      />
      <Divider padding />
      <AletNotfoundLoading
        toRender={(registrationRows && registrationRows.length <= 0) ?? false}
        notFoundText="Nenhum contrato encontrado."
      />
      <S.RegistrationDiv>
        <BulletPoint
          color="success"
          buttons={
            <ButtonOpen
              color="primary"
              heightSize="small"
              widthSize="xmedium"
              onClick={() => {
                setRegistrationOpen(!registrationOpen);
                setCourseCompletionOpen(false);
              }}
              title="Abrir"
              titleClose="Fechar"
              open={registrationOpen}
            />
          }
          flexDirection={handleMaxWidth(theme.size?.medium) ? 'column' : 'row'}
        >
          <S.DivName>
            <h5>Declaração de Matrícula</h5>
            <h5 className="divider"> | </h5>
            <h5>Cursando</h5>
          </S.DivName>
        </BulletPoint>
        <RegistrationStatement
          registration={registrationRows}
          requirements={requirements}
          isOpen={registrationOpen}
        />
        <BulletPoint
          color="success"
          buttons={
            <ButtonOpen
              color="primary"
              heightSize="small"
              widthSize="xmedium"
              onClick={() => {
                setRegistrationOpen(false);
                setCourseCompletionOpen(!courseCompletionOpen);
              }}
              title="Abrir"
              titleClose="Fechar"
              open={courseCompletionOpen}
            />
          }
          flexDirection={handleMaxWidth(theme.size?.medium) ? 'column' : 'row'}
        >
          <S.DivName>
            <h5>Declaração de Conclusão de Curso</h5>
          </S.DivName>
        </BulletPoint>
        <CourseCompletion
          isOpen={courseCompletionOpen}
          rowsCorses={registrationRows}
        />
      </S.RegistrationDiv>
    </S.Container>
  );
}
