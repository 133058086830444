import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 5rem;
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
`;

export const Button = styled.div`
  display: flex;
  justify-content: end;
`;
