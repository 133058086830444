function checkPermission(
  permissions: string | string[],
  requiredPermission: string,
) {
  if (Array.isArray(permissions) && permissions.includes(requiredPermission)) {
    return true;
  }

  if (typeof permissions === 'object') {
    for (const key in permissions) {
      if (key === requiredPermission) {
        return true;
      }
      const subPermissions = permissions[key];
      if (checkPermission(subPermissions, requiredPermission)) {
        return true;
      }
    }
  }
  return false;
}

export function validatePermissions(
  permissions: string | string[],
  requiredPermission: string,
) {
  return checkPermission(permissions, requiredPermission);
}
