import styled from 'styled-components';

export const ContainerVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 80%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
`;

export const Buttons = styled.div`
  color: ${({ theme }) => theme.colors?.text.info02};
  width: 15rem;
`;

export const BoxStyle = styled.div<{ render: boolean }>`
  display: ${(props) => (props.render ? '' : 'none')};
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: 0;

  @media screen and (max-width: 815px) {
    width: 85%;
  }
  @media screen and (max-width: 485px) {
    width: 110%;
  }
`;
