import React, { Dispatch, SetStateAction, useState } from 'react';

import * as S from './styles';
import BasicModal from '../BasicModal';
import { ButtonSimple } from '../../Buttons';
import InputFeedBack from './InputFeedBack';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function Feedback({ isOpen, setIsOpen }: Props) {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [rating, setRating] = useState<number>();

  const [isSignOutOpen, setIsSignOutOpen] = useState(false);

  return (
    <S.Container>
      <BasicModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isFloat
        ableBackdropClick
      >
        <S.Content>
          <S.DivTitle>
            <S.Title>O que achou do novo portal?</S.Title>
            <S.Subtitle>
              Antes de sair, deixe a sua avaliação. Ela é muito importante pra
              nós
            </S.Subtitle>
          </S.DivTitle>
          <S.FaceContent>
            <S.Img
              src="/assets/img/feedback/veryHappy.png"
              alt="very_happy"
              width="45px"
              onClick={() => {
                setFeedback('/assets/img/feedback/veryHappy.png');
                setIsFeedbackOpen(true);
              }}
            />
            <S.Img
              src="/assets/img/feedback/happy.png"
              alt="happy"
              width="45px"
              onClick={() => {
                setFeedback('/assets/img/feedback/happy.png');
                setIsFeedbackOpen(true);
                setRating(1);
              }}
            />
            <S.Img
              src="/assets/img/feedback/normal.png"
              alt="normal"
              width="45px"
              onClick={() => {
                setFeedback('/assets/img/feedback/normal.png');
                setIsFeedbackOpen(true);
                setRating(2);
              }}
            />
            <S.Img
              src="/assets/img/feedback/sad.png"
              alt="sad"
              width="45px"
              onClick={() => {
                setFeedback('/assets/img/feedback/sad.png');
                setIsFeedbackOpen(true);
                setRating(3);
              }}
            />
          </S.FaceContent>
          <S.FaceContent>
            <ButtonSimple
              color="primary"
              heightSize="small"
              widthSize="medium"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </ButtonSimple>
            <ButtonSimple
              color="primary"
              heightSize="small"
              widthSize="large"
              onClick={() => setIsSignOutOpen(true)}
            >
              Já avaliei e quero sair
            </ButtonSimple>
          </S.FaceContent>
        </S.Content>
      </BasicModal>
      <InputFeedBack
        imageFeedback={feedback}
        isOpen={isFeedbackOpen}
        isSignOutOpen={isSignOutOpen}
        setIsSignOutOpen={setIsSignOutOpen}
        setIsOpen={setIsFeedbackOpen}
        setFeedbackIsOpen={setIsOpen}
        rating={rating}
      />
    </S.Container>
  );
}
