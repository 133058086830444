import React, { ReactNode } from 'react';

import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { NegotiationAlert } from '../../../../Alerts';
import { Container } from '../../styles';

interface Props {
  color: string;
  children: ReactNode;
  eventKey: string;
  titleBox: string;
  message: string;
  iconBar: ReactNode;
}

export default function CustomToggle({
  color,
  children,
  eventKey,
  titleBox,
  message,
  iconBar,
}: Props) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});
  return (
    <div>
      <NegotiationAlert
        color={color}
        title={titleBox}
        message={message}
        iconBar={iconBar}
      >
        <Container type="button" onClick={decoratedOnClick} className={color}>
          <span className={color}>{children}</span>
        </Container>
      </NegotiationAlert>
    </div>
  );
}
