import styled from 'styled-components';

export const Container = styled.div`
  padding: 7rem 0 5rem 3rem;
  z-index: ${(props) => props.theme.zIndex?.main};

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    padding: 10rem 2rem 5rem;
  }
`;
