import React, { ReactNode } from 'react';

import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import * as S from './styles';

interface FileInputProps {
  onFileSelect: (files: File[]) => void;
  icon?: ReactNode | string;
  children?: string;
}

export default function FileInput({
  onFileSelect,
  icon,
  children,
}: FileInputProps) {
  const handleFileChange = (target: EventTarget & HTMLInputElement) => {
    const files = target.files;

    if (files) {
      let indexFile = 0;
      const selected: File[] = Array.from(files);

      for (const file of files) {
        const size = file.size;

        /** Valor de 8MB */
        if (size > 8388608) {
          selected.splice(indexFile, 1);
        }
        indexFile = indexFile + 1;
      }
      onFileSelect(selected);
    }
  };

  return (
    <div>
      <Button
        component="label"
        variant={'contained'}
        startIcon={icon ? icon : <FileUploadIcon />}
        href="#file-upload"
      >
        {children ? children : 'Escolher arquivo'}
        <S.Input
          onChange={({ target }) => handleFileChange(target)}
          type="file"
          multiple
          accept=".pdf,.jpg,.png,.doc,.docx"
        />
      </Button>
    </div>
  );
}
