export const nodeEnv = process.env.NODE_ENV;

export const company = process.env.REACT_APP_COMPANY;
export const keyBlip = process.env.REACT_APP_KEY_BLIP;
export const linkBlip = process.env.REACT_APP_LINK_BLIP;

export const urlSite = process.env.REACT_APP_URL_SITE;
export const apiUrl = process.env.REACT_APP_LINK_API;
export const sieadUrl = process.env.REACT_APP_SIEAD_URL;
export const sieadImagesFolderUrl =
  process.env.REACT_APP_SIEAD_IMAGES_FOLDER_URL;
export const movideskId = process.env.REACT_APP_MOVIDESK_ID;
export const studentArea = process.env.REACT_APP_SIEAD_URL_STUDENT_AREA;

export const pixIugu = process.env.REACT_APP_PAYMENT_PIX_IUGU;
export const invoiceIugu = process.env.REACT_APP_PAYMENT_INVOICE_IUGU;
export const creditCardIugu = process.env.REACT_APP_PAYMENT_CREDIT_CARD_IUGU;

export const lackAccountPlan = process.env.REACT_APP_LACK_ACCOUNT_PLAN;
export const negotiationAccountPlan =
  process.env.REACT_APP_NEGOTIATION_ACCOUNT_PLAN;
export const monthlyPaymentAccountPlan =
  process.env.REACT_APP_MONTHLY_PAYMENT_ACCOUNT_PLAN;
export const advancePaymentAccountPlan =
  process.env.REACT_APP_ADVANCE_PAYMENT_ACCOUNT_PLAN;

export const courseTypeGraduation =
  process.env.REACT_APP_COURSE_TYPE_GRADUATION;
export const courseTypeGraduationEad =
  process.env.REACT_APP_COURSE_TYPE_GRADUATION_EAD;
export const courseTypeFordoc = process.env.REACT_APP_COURSE_TYPE_FORDOC;
export const courseTypePosGraduation =
  process.env.REACT_APP_COURSE_TYPE_POS_GRADUATION;
export const courseTypeMba = process.env.REACT_APP_COURSE_TYPE_MBA;
export const courseTypeCapacitation =
  process.env.REACT_APP_COURSE_TYPE_CAPACITATION;

export const saleItemTypeCourse = process.env.REACT_APP_SALE_ITEM_TYPE_COURSE;
export const saleItemTypeService = process.env.REACT_APP_SALE_ITEM_TYPE_SERVICE;

export const typeDocComplementary =
  process.env.REACT_APP_TYPE_DOC_COMPLEMENTARY;
export const typeDocInternship = process.env.REACT_APP_TYPE_DOC_INTERNSHIP;

export const typeServiceNegotiation =
  process.env.REACT_APP_TYPE_SERVICE_NEGOTIATION;
export const typeServiceExam = process.env.REACT_APP_TYPE_SERVICE_EXAM;
export const typeServiceRegistrationRenewal =
  process.env.REACT_APP_TYPE_SERVICE_REGISTRATION_RENEWAL;
export const typeServiceCancel = process.env.REACT_APP_TYPE_SERVICE_CANCEL;

export const linkHandBookPosGraduation =
  process.env.REACT_APP_LINK_HANDBOOK_POS_GRADUATION;
export const linkHandBookGraduation =
  process.env.REACT_APP_LINK_HANDBOOK_GRADUATION;
export const linkFaqPosGraduation =
  process.env.REACT_APP_LINK_FAQ_POS_GRADUATION;
export const linkFaqGraduation = process.env.REACT_APP_LINK_FAQ_GRADUATION;
export const teamFarmer = process.env.REACT_APP_TEAM_FARMER;

export const coursesWithoutDiscounts =
  process.env.REACT_APP_COURSES_WITHOUT_DISCOUNTS;

export const phoneService = process.env.REACT_APP_PHONE_SERVICE;
export const phoneWhatsapp = process.env.REACT_APP_PHONE_WHATSAPP;
export const emailContract = process.env.REACT_APP_EMAIL_CONTACT;

export const typeFinancialNegotiation =
  process.env.REACT_APP_TYPE_FINANCIAL_NEGOTIATION;
export const typeFinancialAdvance =
  process.env.REACT_APP_TYPE_FINANCIAL_ADVANCE;

export const typeExamMultipleChoice =
  process.env.REACT_APP_EXAM_TYPE_MULTIPLE_CHOICE;

export const typeExamSubjective = process.env.REACT_APP_EXAM_TYPE_SUBJECTIVE;

export const courseDisciplineContent =
  process.env.REACT_APP_COURSE_DISCIPLINE_CONTENT;
export const courseAuxiliaryContent =
  process.env.REACT_APP_COURSE_AUXILIARY_CONTENT;
export const courseAssessmentContent =
  process.env.REACT_APP_COURSE_ASSESSMENT_CONTENT;

export const firstAccessQuestion = process.env.REACT_APP_FIRST_ACCESS_QUESTION;
