import React, { useContext, useState } from 'react';

import * as S from './styles';
import { Formik } from 'formik';

import FieldFormik, {
  IFormOptions,
} from '../../../../../components/newStudent/FieldFormik';
import { IUserData } from '../..';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { authenticatedRequest } from '../../../../../services/api/newStudent/apiStudent.service';
import MyDataConfirmModal from '../../../../../components/newStudent/Modal/MyDataConfirmModal';
import { useTheme } from 'styled-components';
import { handleMaxWidth } from '../../../../../util/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';

interface Props {
  formValues: IUserData;
  verifiedFields?: IUserData;
  showPolo?: boolean;
  showRaceColor?: boolean;
  optionsPolo?: IFormOptions[];
  showButton?: boolean;
  isDataConference?: boolean;
  nameButton?: string;
  handleButtonClick?: (values: IUserData) => void;
}

export default function FormMyData({
  formValues,
  verifiedFields,
  showPolo,
  showRaceColor,
  isDataConference,
  optionsPolo,
  showButton,
  nameButton,
  handleButtonClick,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newField, setNewField] = useState<{
    email?: string;
    phone?: string | number;
  }>({
    email: '',
    phone: '',
  });

  const [typeConfirmation, setTypeConfirmation] = useState<'email' | 'phone'>(
    'email',
  );

  const handleSendEmail = async (email: string) => {
    setIsLoading(true);
    setNewField({ email: email });
    const { result } = await authenticatedRequest(
      '/student/my-data/generate-confirmation-code',
      'POST',
      { email: email },
    );
    if (result) {
      setIsModalOpen(true);
      setTypeConfirmation('email');
    }
    setIsLoading(false);
  };

  const handleSendSms = async (phone: string | number) => {
    setIsLoading(true);
    setNewField({ phone: phone });
    const { result } = await authenticatedRequest(
      '/student/my-data/generate-confirmation-code',
      'POST',
      { phone: phone },
    );
    if (result) {
      setIsModalOpen(true);
      setTypeConfirmation('phone');
    }
    setIsLoading(false);
  };

  const theme = useTheme();
  return (
    <S.Container>
      <Formik initialValues={formValues} onSubmit={() => {}} enableReinitialize>
        {({ handleChange, values }) => (
          <S.StyledForm>
            <FieldFormik
              name="name"
              type="string"
              label="Nome"
              disabled={!isDataConference || !!verifiedFields?.name}
              widthSize="fullWidth"
              onChange={handleChange}
              value={values.name}
            />
            <S.DivLi>
              <FieldFormik
                name="email"
                type="email"
                label="Email"
                widthSize="fullWidth"
                onChange={(e) => {
                  handleChange(e);
                  setNewField({
                    email: values.email,
                    phone: '',
                  });
                }}
                value={values.email}
              />
              <div>
                <ButtonSimple
                  color="primary"
                  widthSize="xmedium"
                  onClick={() => handleSendEmail(values.email)}
                >
                  Enviar e-mail
                </ButtonSimple>
              </div>
            </S.DivLi>
            <S.DivLiPhone>
              <FieldFormik
                className="homePhone"
                name="homePhone"
                type="string"
                label="Telefone Residencial"
                disabled={!isDataConference || !!verifiedFields?.homePhone}
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.homePhone}
                mask="(99)99999-9999"
              />
              <FieldFormik
                className="commercialPhone"
                name="commercialPhone"
                type="string"
                label="Telefone Comercial"
                disabled={!isDataConference || !!verifiedFields?.businessPhone}
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.businessPhone}
                mask="(99)99999-9999"
              />
              <FieldFormik
                className="cellPhone"
                name="cellPhone"
                type="string"
                label="Telefone Celular"
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.cellPhone}
                mask="(99)99999-9999"
              />
              <div>
                <ButtonSimple
                  className="send_sms"
                  color="primary"
                  widthSize={
                    handleMaxWidth(theme.size?.medium) ? 'fullWidth' : 'xmedium'
                  }
                  onClick={() => handleSendSms(values.cellPhone)}
                >
                  Enviar SMS
                </ButtonSimple>
              </div>
            </S.DivLiPhone>
            <S.DivLiRgCpf>
              <FieldFormik
                name="bornDate"
                type="date"
                label="Data de Nascimento"
                disabled={!isDataConference || !!verifiedFields?.bornDate}
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.bornDate}
              />
              <FieldFormik
                name="rgId"
                type="string"
                label="RG"
                disabled={!isDataConference || !!verifiedFields?.rgId}
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.rgId}
              />
              <FieldFormik
                name="cpf"
                type="string"
                label="CPF"
                disabled={!isDataConference || !!verifiedFields?.cpf}
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.cpf}
                mask="999.999.999-99"
              />
            </S.DivLiRgCpf>
            {(showPolo || showRaceColor) && (
              <S.DivLiRgCpf>
                {showPolo && (
                  <S.DivLiRgCpf>
                    <FieldFormik
                      name="poleId"
                      type="select"
                      label="Polo"
                      options={optionsPolo}
                      widthSize="fullWidth"
                      onChange={handleChange}
                      value={values.poleId}
                    />
                  </S.DivLiRgCpf>
                )}
                {showRaceColor && (
                  <FieldFormik
                    name="raceColor"
                    type="select"
                    label="Se declara na cor/raça"
                    disabled={!isDataConference || !!verifiedFields?.raceColor}
                    options={[
                      { title: '[ Selecione ]', value: 0 },
                      { title: 'Branca', value: 1 },
                      { title: 'Preta', value: 2 },
                      { title: 'Amarela', value: 3 },
                      { title: 'Parda', value: 4 },
                      { title: 'Indigena', value: 5 },
                      { title: 'Outra', value: 6 },
                    ]}
                    widthSize="fullWidth"
                    onChange={handleChange}
                    value={values.raceColor}
                  />
                )}
              </S.DivLiRgCpf>
            )}
            <S.DivAddress>
              <div>
                <FieldFormik
                  name="addressName"
                  type="string"
                  label="Endereço"
                  disabled={!isDataConference || !!verifiedFields?.street}
                  widthSize="fullWidth"
                  onChange={handleChange}
                  value={values.street}
                />
              </div>
              <S.DivAddressInfo>
                <div>
                  <FieldFormik
                    name="addressNumber"
                    type="string"
                    label="Número"
                    disabled={!isDataConference || !!verifiedFields?.number}
                    widthSize={theme.size?.medium ? 'fullWidth' : 'medium'}
                    onChange={handleChange}
                    value={values.number}
                  />
                </div>
                <div>
                  <FieldFormik
                    name="addressNeighborhood"
                    type="string"
                    label="Bairro"
                    disabled={
                      !isDataConference || !!verifiedFields?.neighborhood
                    }
                    widthSize={theme.size?.medium ? 'fullWidth' : 'medium'}
                    onChange={handleChange}
                    value={values.neighborhood}
                  />
                </div>
                <div>
                  <FieldFormik
                    name="addressCep"
                    type="string"
                    label="CEP"
                    disabled={!isDataConference || !!verifiedFields?.cep}
                    widthSize={theme.size?.medium ? 'fullWidth' : 'medium'}
                    onChange={handleChange}
                    value={values.cep}
                  />
                </div>
              </S.DivAddressInfo>
            </S.DivAddress>
            <FieldFormik
              name="fatherName"
              type="string"
              label="Nome do Pai"
              disabled={!isDataConference || !!verifiedFields?.fatherName}
              widthSize="fullWidth"
              onChange={handleChange}
              value={values.fatherName}
            />
            <FieldFormik
              name="motherName"
              type="string"
              label="Nome da Mãe"
              disabled={!isDataConference || !!verifiedFields?.motherName}
              widthSize="fullWidth"
              onChange={handleChange}
              value={values.motherName}
            />
            <S.DivGraduation>
              <FieldFormik
                name="studentGraduation"
                type="string"
                label="Curso de Graduação"
                disabled={
                  !isDataConference || !!verifiedFields?.studentGraduation
                }
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.studentGraduation}
              />
              <FieldFormik
                name="university"
                type="string"
                label="Instituição"
                disabled={!isDataConference || !!verifiedFields?.university}
                widthSize="fullWidth"
                onChange={handleChange}
                value={values.university}
              />
              <FieldFormik
                name="graduationYear"
                type="string"
                label="Ano de Graduação"
                disabled={!isDataConference || !!verifiedFields?.graduationYear}
                widthSize={theme.size?.medium ? 'fullWidth' : 'large'}
                onChange={handleChange}
                value={values.graduationYear}
              />
            </S.DivGraduation>
            {showButton && (
              <S.Button>
                <ButtonSimple
                  color="primary"
                  widthSize="large"
                  onClick={() => handleButtonClick && handleButtonClick(values)}
                >
                  {nameButton}
                </ButtonSimple>
              </S.Button>
            )}
          </S.StyledForm>
        )}
      </Formik>
      <MyDataConfirmModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        newField={newField}
        type={typeConfirmation}
      />
    </S.Container>
  );
}
