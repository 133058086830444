import React from 'react';

import * as S from './style';
import { useLocation } from 'react-router-dom';

import { sieadImagesFolderUrl } from '../../../../../variables';

const errorImage = `${sieadImagesFolderUrl}page_error.svg`;
const logo = `${sieadImagesFolderUrl}c81e728d9d4c2f636f067f89cc14862c.jpg`;

export default function BodyErrorAgent() {
  const { pathname } = useLocation();

  return (
    <S.Container>
      <S.Content>
        <S.Img src={errorImage} alt="info" />
        <S.ContentText>
          <S.Title>Ops! Esta página está com problemas.</S.Title>
          <S.Text>Tente novamente mais tarde</S.Text>
          <>
            <S.Bold>Você também pode:</S.Bold>
            <S.Li>Recarregar a página.</S.Li>
            <S.Li>
              Clique{' '}
              <a
                href={
                  pathname.startsWith('/agenciador')
                    ? '/agenciador'
                    : pathname.startsWith('/new')
                    ? '/new/aluno/inicio'
                    : '/aluno/dashboard'
                }
                style={{
                  color: '#47AAEB',
                }}
              >
                aqui{' '}
              </a>
              para voltar a página inicial.
            </S.Li>
            <S.Li>
              Se o erro persistir entre em contato com o{' '}
              {pathname.startsWith('/agenciador') ? 'Suporte' : 'NAE'}.
            </S.Li>
          </>
          <S.DivImg>
            <S.ImgLogo src={logo} alt="logo" />
          </S.DivImg>
        </S.ContentText>
      </S.Content>
    </S.Container>
  );
}
