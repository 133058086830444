import * as api from '../../api/agent/api.agent.service';

export const bankBilletsService = {
  async bankBilletsView(id: number) {
    const request = await api.authenticatedRequest(
      `/student/bank-billets/view/${id}`,
    );
    return request;
  },
};
