import React, { ReactNode } from 'react';

import InfoIcon from '@mui/icons-material/Info';

interface Props {
  icon?: ReactNode;
  children: string | ReactNode;
}
export default function Info({ icon, children }: Props) {
  return (
    <>
      {icon ? icon : <InfoIcon fontSize="large" />} {children}
    </>
  );
}
