import { Paper } from '@mui/material';
import styled from 'styled-components';

export const AccordionSummary = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 0;
`;

export const AccordionAction = styled.div`
  padding: '5rem';
`;

export const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export const PaperStyled = styled(Paper)`
  margin-bottom: 5rem;
`;
