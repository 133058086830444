import React, { useEffect, useState } from 'react';

import * as S from './styles';

import DocumentDegree from './components/DocumentDegree';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import BulletPoint from '../../../../../components/newStudent/BulletPoint';
import {
  typeDocComplementary,
  typeDocInternship,
} from '../../../../../variables';
import ButtonOpen from '../../../../../components/newStudent/Buttons/ButtonOpen';
import { IDocumentsDegree, IUniversityDegree } from '../..';
import { handleMaxWidth } from '../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import DocumentDegreeMobile from './components/DocumentDegreeMobile';

interface Props {
  data: IUniversityDegree;
}
export const defaultDataUniversity = {
  id: '',
  name: '',
  startDate: '',
  endDate: '',
  workload: '',
  file: undefined,
  url: '',
  checked: undefined,
  registrationId: 0,
  universityDegreeId: 0,
};
export default function UniversityDegree({ data }: Props) {
  const [oldDataInternship, setOldDataInternship] = useState<
    IDocumentsDegree[] | []
  >([]);
  const [oldDataComplementaryActivity, setOldDataComplementaryActivity] =
    useState<IDocumentsDegree[] | []>([]);

  const [dataInternship, setDataInternship] = useState<IDocumentsDegree[] | []>(
    [defaultDataUniversity],
  );
  const [dataComplementaryActivity, setDataComplementaryActivity] = useState<
    IDocumentsDegree[] | []
  >([defaultDataUniversity]);

  const [isOpenInternship, setIsOpenInternship] = useState<
    number | undefined
  >();
  const [isOpenComplementaryActivity, setIsOpenComplementaryActivity] =
    useState<number | undefined>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const theme = useTheme();

  useEffect(() => {
    setOldDataInternship(data.internship);
    setOldDataComplementaryActivity(data.complementaryActivity);
  }, [data]);

  return (
    <S.Container>
      <>
        <BulletPoint
          color={data.internship.length > 0 ? 'primary' : 'danger'}
          buttons={
            <S.Buttons>
              <ButtonSimple
                noButton
                color={data.internship.length > 0 ? 'primary' : 'danger'}
                outline
                heightSize="small"
                widthSize="medium"
              >
                {data.internship.length > 0 ? 'Andamento' : 'Pendente'}
              </ButtonSimple>
              <ButtonOpen
                color="primary"
                heightSize="small"
                widthSize="lsmall"
                onClick={() => {
                  setIsOpenInternship(
                    isOpenInternship !== data.courseId
                      ? data.courseId
                      : undefined,
                  );
                  handleMaxWidth(theme.size?.medium) && setIsOpenModal(true);
                }}
                title="Abrir"
                titleClose="Fechar"
                open={isOpenInternship === data.courseId}
              />
            </S.Buttons>
          }
          flexDirection={handleMaxWidth(theme.size?.medium) ? 'column' : 'row'}
        >
          <S.DocumentName>
            Estágio{handleMaxWidth(theme.size?.medium) ? <br /> : ' | '}
            {data.courseName}
          </S.DocumentName>
        </BulletPoint>
        {isOpenInternship &&
          (handleMaxWidth(theme.size?.medium) ? (
            <BasicDialog
              fullScreen
              isOpen={isOpenModal}
              setIsOpen={setIsOpenModal}
            >
              <DocumentDegreeMobile
                title="Estágio"
                courseName={data.courseName}
                setIsOpenModal={setIsOpenModal}
                newData={dataInternship}
                registrationId={data.registrationId}
                oldData={oldDataInternship}
                setNewData={setDataInternship}
                documentDegreeType={+typeDocInternship!}
                universityDegreeId={data.universityDegreeId}
                setWhoIsOpen={setIsOpenInternship}
                setOldData={setOldDataInternship}
                inProgress={data.internship.length > 0}
              />
            </BasicDialog>
          ) : (
            <DocumentDegree
              registrationId={data.registrationId}
              universityDegreeId={data.universityDegreeId}
              oldData={oldDataInternship}
              courseId={data.courseId}
              open={isOpenInternship}
              setNewData={setDataInternship}
              setOldData={setOldDataInternship}
              newData={dataInternship}
              documentDegreeType={+typeDocInternship!}
            />
          ))}
      </>
      <>
        <BulletPoint
          color={data.complementaryActivity.length > 0 ? 'primary' : 'danger'}
          buttons={
            <S.Buttons>
              <ButtonSimple
                noButton
                color={
                  data.complementaryActivity.length > 0 ? 'primary' : 'danger'
                }
                outline
                heightSize="small"
                widthSize="medium"
              >
                {data.complementaryActivity.length > 0
                  ? 'Andamento'
                  : 'Pendente'}
              </ButtonSimple>
              <ButtonOpen
                color="primary"
                heightSize="small"
                widthSize="lsmall"
                onClick={() => {
                  setIsOpenComplementaryActivity(
                    isOpenComplementaryActivity !== data.courseId
                      ? data.courseId
                      : undefined,
                  );

                  handleMaxWidth(theme.size?.medium) && setIsOpenModal(true);
                }}
                title="Abrir"
                titleClose="Fechar"
                open={isOpenComplementaryActivity === data.courseId}
              />
            </S.Buttons>
          }
          flexDirection={handleMaxWidth(theme.size?.medium) ? 'column' : 'row'}
        >
          <S.DocumentName>
            Atividade complementar
            {handleMaxWidth(theme.size?.medium) ? <br /> : ' | '}
            {data.courseName}
          </S.DocumentName>
        </BulletPoint>
        {isOpenComplementaryActivity &&
          (handleMaxWidth(theme.size?.medium) ? (
            <BasicDialog
              fullScreen
              isOpen={isOpenModal}
              setIsOpen={setIsOpenModal}
            >
              <DocumentDegreeMobile
                title="Atividade complementar"
                courseName={data.courseName}
                setIsOpenModal={setIsOpenModal}
                newData={dataComplementaryActivity}
                registrationId={data.registrationId}
                oldData={oldDataComplementaryActivity}
                setNewData={setDataComplementaryActivity}
                documentDegreeType={+typeDocComplementary!}
                universityDegreeId={data.universityDegreeId}
                setWhoIsOpen={setIsOpenComplementaryActivity}
                setOldData={setOldDataComplementaryActivity}
                inProgress={data.complementaryActivity.length > 0}
              />
            </BasicDialog>
          ) : (
            <DocumentDegree
              registrationId={data.registrationId}
              universityDegreeId={data.universityDegreeId}
              oldData={oldDataComplementaryActivity}
              courseId={data.courseId}
              open={isOpenComplementaryActivity}
              setNewData={setDataComplementaryActivity}
              setOldData={setOldDataComplementaryActivity}
              newData={dataComplementaryActivity}
              documentDegreeType={+typeDocComplementary!}
            />
          ))}
      </>
    </S.Container>
  );
}
