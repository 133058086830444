import React, { useContext, useState } from 'react';

import { Button, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';

import FileInput from '../../../../../components/student/Files/Upload';
import { RowText } from '../../../../../components/student/Table/components';

import * as S from './styles';
import { IAnalysis } from '../..';
import { documentStudentChange } from '../../../../../services/documentStudent/document.service';
import {
  errorToast,
  successToast,
} from '../../../../../components/common/Toast';
import {
  StudentProviderContext,
  IStudentContextProps,
} from '../../../../../components/student/StudentProvider';

interface Props {
  oldFiles: IAnalysis[] | [];
  documentTypeId: number;
  canEdit?: boolean;
}

export default function FileDocumentList({
  oldFiles,
  documentTypeId,
  canEdit,
}: Props) {
  const { setIsLoading } = useContext(
    StudentProviderContext,
  ) as IStudentContextProps;

  const [selectedFiles, setSelectedFiles] = useState<
    {
      file: File;
      id?: number;
      status?: string;
      motivo_rejeicao?: string;
      url?: string;
      nome?: string;
    }[]
  >([]);

  const [oldFilesState, setOldFilesState] = useState<IAnalysis[] | []>(
    oldFiles,
  );

  const handleFileSelect = (file: File[]) => {
    setIsLoading(true);
    const sendFile = async () => {
      try {
        const body = {
          type: documentTypeId,
          file: file[0],
        };

        await documentStudentChange.upload({ body }).then((response) => {
          if (response.result) {
            setSelectedFiles((items) => [
              ...items,
              ...file.map((f) => ({ file: f, ...response.data })),
            ]);
            successToast('Arquivo adicionado com sucesso');
          } else {
            response.message &&
              response.message.length > 0 &&
              response.message.forEach((text) => errorToast(text));
          }
          setIsLoading(false);
        });
      } catch (error) {
        setIsLoading(false);
        throw new Error(error);
      }
    };
    sendFile();
  };

  const deleteFile = async (
    index: number,
    old?: boolean,
    item?: { id?: number; name?: string },
  ) => {
    /** Lógica para excluir o arquivo no banco */
    setIsLoading(true);

    try {
      const body = { id: item!.id!, type: documentTypeId };
      await documentStudentChange
        .documentApi({
          type: 'POST',
          body,
          action: 'delete',
        })
        .then((result) => {
          if (result.result) {
            if (!old) {
              const updatedFiles = [...selectedFiles];
              updatedFiles.splice(index, 1);
              return setSelectedFiles(updatedFiles);
            }
            if (oldFilesState) {
              const updatedOldFiles = [...oldFilesState];
              updatedOldFiles.splice(index, 1);
              return setOldFilesState(updatedOldFiles);
            }

            return successToast('Arquivo atualizado com sucesso!');
          }
          return errorToast(result.message);
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const handleFileReplace = async (
    indexToReplace: number,
    newFile?: File | null,
    item?: { id?: number; name?: string },
  ) => {
    if (newFile) {
      setIsLoading(true);
      try {
        const body = { id: item!.id!, type: documentTypeId, file: newFile };
        await documentStudentChange
          .documentApi({
            type: 'POST',
            body,
            action: 'update',
          })
          .then((result) => {
            if (result.result) {
              const updatedFiles = [...selectedFiles];
              updatedFiles[indexToReplace] = {
                file: newFile,
                ...result.data,
              };
              setSelectedFiles(updatedFiles);
              setIsLoading(false);

              return successToast('Arquivo atualizado com sucesso!');
            }
            setIsLoading(false);
            return errorToast(result.message);
          });
      } catch (error) {
        setIsLoading(false);

        throw new Error(error);
      }
    } else {
      errorToast('Arquivo não encontrado');
    }
  };

  const handleCloudReplace = async (
    index: number,
    newFile?: File | null,
    item?: { id?: number; name?: string },
  ) => {
    if (newFile) {
      setIsLoading(true);

      try {
        const body = { id: item!.id!, type: documentTypeId, file: newFile };

        await documentStudentChange
          .documentApi({
            type: 'POST',
            body,
            action: 'update',
          })
          .then((result) => {
            if (result.result) {
              if (oldFilesState) {
                const updatedOldFiles = [...oldFilesState];
                updatedOldFiles.splice(index, 1);
                setOldFilesState(updatedOldFiles);

                setSelectedFiles((items) => [
                  ...items,
                  { file: newFile, ...result.data },
                ]);
              }
              setIsLoading(false);
              return successToast('Arquivo atualizado com sucesso!');
            }
            setIsLoading(false);
            return errorToast(result.message);
          });
      } catch (error) {
        setIsLoading(false);

        throw new Error(error);
      }
    } else {
      errorToast('Arquivo não encontrado');
    }
  };

  return (
    <S.ContainerDocument>
      {canEdit && <FileInput onFileSelect={handleFileSelect} />}
      {/** Aqui ele mostra os arquivos  que foram acabados de inserir*/}
      {selectedFiles.map((item, index) => {
        return (
          <S.Container key={index}>
            <S.ContainerFile>
              <RowText>{item.file.name}</RowText>
            </S.ContainerFile>
            {canEdit && (
              <S.ContainerButton>
                <Button
                  variant="outlined"
                  sx={{
                    width: '9rem',
                    height: '2.5rem',
                    fontSize: '12px',
                    marginRight: '1rem',
                  }}
                  component="label"
                >
                  Substituir
                  <input
                    onChange={(e) => {
                      e.target.files &&
                        handleFileReplace(index, e.target.files[0], item);
                    }}
                    type="file"
                    accept=".pdf,.jpg,.png,.doc,.docx"
                  />
                </Button>
                <IconButton onClick={() => deleteFile(index, false, item)}>
                  <DeleteForeverIcon fontSize="large" sx={{ color: 'red' }} />
                </IconButton>
              </S.ContainerButton>
            )}
          </S.Container>
        );
      })}

      {/** Aqui ele mostra os arquivos que já estão no banco de dados*/}
      {oldFilesState.map((item, index) => {
        return (
          <S.Container key={index}>
            <S.ContainerInfo
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <S.ContainerFile>
                <RowText>{item.name}</RowText>
              </S.ContainerFile>
            </S.ContainerInfo>
            <S.ContainerButton>
              <div style={{ paddingRight: '1.5rem' }}>
                <RowText color={item.observation ? 'red' : ''}>
                  {item.observation ? item.observation : item.status}
                </RowText>
              </div>
              {canEdit &&
                item.status !== 'Aprovado' &&
                item.status !== 'Concluído' && (
                  <div style={{ width: '10rem' }}>
                    <Button
                      variant="outlined"
                      sx={{
                        width: '9rem',
                        height: '2.5rem',
                        fontSize: '12px',
                        marginRight: '1rem',
                      }}
                      component="label"
                    >
                      Substituir
                      <input
                        onChange={(e) => {
                          e.target.files &&
                            handleCloudReplace(index, e.target.files[0], item);
                        }}
                        type="file"
                        accept=".pdf,.jpg,.png,.doc,.docx"
                      />
                    </Button>
                  </div>
                )}
              <a href={item.url} target="_blank" rel="noreferrer">
                <IconButton>
                  <DownloadIcon
                    fontSize="large"
                    sx={{ border: 'none', color: '#1565c0' }}
                  />
                </IconButton>
              </a>
              {canEdit &&
                item.status !== 'Aprovado' &&
                item.status !== 'Concluído' && (
                  <IconButton onClick={() => deleteFile(index, true, item)}>
                    <DeleteForeverIcon fontSize="large" sx={{ color: 'red' }} />
                  </IconButton>
                )}
            </S.ContainerButton>
          </S.Container>
        );
      })}
    </S.ContainerDocument>
  );
}
