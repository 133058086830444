import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
`;

export const Title = styled.b``;

export const Content = styled.div`
  display: flex;

  width: 100%;
  height: 6rem;
  align-items: center;
`;

export const ContainerSlider = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerInput = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;
  width: 40%;
`;

export const DivTitleTop = styled.div`
  display: flex;
  justify-content: center;
  width: 15rem;

  @media screen and (min-width: 426px) {
    display: none;
  }
`;
export const DivTitleRight = styled.div`
  width: 15rem;

  @media screen and (max-width: 426px) {
    display: none;
  }
`;
