import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import HeaderMinimal from '../../../components/newStudent/HeaderMinimal';
import Divider from '../../../components/common/Divider';

import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { IFormOptions } from '../../../components/newStudent/FieldFormik';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormMyData } from '../MyDataNewStudent/components';
import { IUserData } from '../MyDataNewStudent';
import {
  addDataCache,
  cleanCache,
  getDataCache,
} from '../../../util/common/appUtil';
import {
  poleServiceNewStudent,
  myDataServiceNewStudent,
  contractServiceNewStudent,
} from '../../../services/newStudent';
import { AlertWarning } from '../../../components/newStudent/Alert';

export default function DataConferenceNewStudent() {
  const {
    student,
    setIsLoading,
    setStudent,
    unsignedContracts,
    setUnsignedContracts,
  } = useContext(NewStudentProviderContext) as INewStudentProvider;

  const [poloOptions, setPoloOptions] = useState<IFormOptions[] | []>([]);

  const [formValues, setFormValues] = useState<IUserData>();
  const [verifiedFields, setVerifiedFields] = useState<IUserData>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNext = async (values: IUserData) => {
    setIsLoading(true);
    try {
      const { result } = await myDataServiceNewStudent.changeMyData(values);

      if (result) {
        const tmpStudent = student;
        tmpStudent.dataStudent.shouldCheckPersonalInformation = false;
        tmpStudent.dataStudent.poleId = +values.poleId!;

        setStudent(tmpStudent);
        localStorage.removeItem('dataStudent');
        cleanCache('dataStudent');

        const validateUnsignedContracts =
          localStorage.getItem('unsignedContracts');
        if (!validateUnsignedContracts) {
          const contractResponse =
            await contractServiceNewStudent.unsignedContracts();
          if (contractResponse.result) {
            setUnsignedContracts(contractResponse.data);
            if (contractResponse.data.length > 0) {
              localStorage.setItem('unsignedContracts', 'true');
              navigate('/new/aluno/contratos');
            }
          }
        } else if (unsignedContracts && unsignedContracts.length > 0) {
          navigate('/new/aluno/contratos');
        } else {
          navigate('/new/aluno/inicio');
        }
      } else {
        return;
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!student || (student && !student?.dataStudent)) return;

    const fetch = async () => {
      try {
        setIsLoading(true);
        const dataStudent = await getDataCache(
          'dataStudent',
          '/new/aluno/dados',
        );
        if (dataStudent && dataStudent.name) {
          const tmpData = {
            name: dataStudent.name,
            email: dataStudent.email,
            cellPhone: dataStudent.cellPhone,
            businessPhone: dataStudent.businessPhone,
            homePhone: dataStudent.homePhone,
            fatherName: dataStudent.fatherName,
            motherName: dataStudent.motherName,
            bornDate: dataStudent.bornDate,
            rgId: dataStudent.rgId,
            cpf: dataStudent.cpf,
            raceColor: dataStudent.raceColor,
            studentGraduation: dataStudent.studentGraduation,
            degree: dataStudent.degree,
            graduationYear: dataStudent.graduationYear,
            nationality: dataStudent.nationality,
            naturalness: dataStudent.naturalness,
            university: dataStudent.university,
            complement: dataStudent.complement,
            city: dataStudent.city,
            state: dataStudent.state,
            street: dataStudent.street,
            cep: dataStudent.cep,
            number: dataStudent.number,
            neighborhood: dataStudent.neighborhood,
            poleId: dataStudent.poleId,
          };
          setFormValues(tmpData);
          setVerifiedFields(tmpData);
        } else {
          /** Fazer o fetch e colocar no cache */
          const { data, result } = await myDataServiceNewStudent.studentData({
            login: student?.dataStudent.cpf
              ? student?.dataStudent.cpf
              : student?.dataStudent.email,
          });
          if (result) {
            const tmpData = {
              name: data.name,
              email: data.email,
              cellPhone: data.cellPhone,
              businessPhone: data.businessPhone,
              homePhone: data.homePhone,
              fatherName: data.fatherName,
              motherName: data.motherName,
              bornDate: data.bornDate,
              rgId: data.rgId,
              cpf: data.cpf,
              raceColor: data.raceColor,
              studentGraduation: data.studentGraduation,
              degree: data.degree,
              graduationYear: data.graduationYear,
              nationality: data.nationality,
              naturalness: data.naturalness,
              university: data.university,
              complement: data.complement,
              city: data.city,
              state: data.state,
              street: data.street,
              cep: data.cep,
              number: data.number,
              neighborhood: data.neighborhood,
              addressCep: data.cep,
              poleId: data.poleId,
            };

            addDataCache('dataStudent', tmpData, '/new/aluno/dados');
            setFormValues(tmpData);
            setVerifiedFields(tmpData);
          }
        }
        setIsLoading(false);

        const poleDateList = await getDataCache('poleDateList', pathname);
        if (poleDateList) {
          const options: IFormOptions[] = poleDateList.map((item) => ({
            value: item.id,
            title: item.mecName,
          }));

          setPoloOptions([{ title: '[ Selecione ]', value: 0 }, ...options]);
        } else {
          const { data } = await poleServiceNewStudent.getAll();
          const options: IFormOptions[] = data.map((item) => ({
            value: item.id,
            title: item.mecName,
          }));
          addDataCache('poleDateList', data, pathname);
          setPoloOptions([{ title: '[ Selecione ]', value: 0 }, ...options]);
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    fetch();
  }, [student]);

  return (
    <S.Container>
      <HeaderMinimal
        title="Conferência de Dados"
        titlePage="Conferência de Dados"
        subtitle="Olá! Bem-vindo à página de conferência de dados cadastrais. Esse processo é importante para garantirmos a autenticidade e a atualização de contatos, documentos, endereço, etc. Por favor, confirme-os abaixo e prossiga."
      />
      <Divider padding />
      {formValues && (
        <FormMyData
          showPolo={student.dataStudent.hasGraduation}
          showRaceColor
          showButton
          verifiedFields={verifiedFields}
          isDataConference
          formValues={formValues}
          optionsPolo={poloOptions}
          handleButtonClick={handleNext}
          nameButton={
            unsignedContracts && unsignedContracts.length > 0
              ? 'Próximo'
              : 'Confirmar'
          }
        />
      )}
      <AlertWarning title="Caso algum dado esteja incorreto, entre em contato com a gente através do chat de atendimento." />
    </S.Container>
  );
}
