import React, { ReactNode } from 'react';

import ButtonSimple from '../ButtonSimple';

interface Props {
  icon: ReactNode;
  heightSize?:
    | 'small'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'fullHeight';
  widthSize?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'fullWidth';
  color: 'primary' | 'gray' | 'success' | 'danger' | 'aqua';
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}
export default function ButtonIcon({
  icon,
  color,
  disabled,
  onClick,
  widthSize = 'small',
  heightSize = 'fullHeight',
  className,
}: Props) {
  return (
    <ButtonSimple
      className={className}
      disabled={disabled}
      color={color}
      widthSize={widthSize}
      heightSize={heightSize}
      onClick={onClick}
    >
      {icon}
    </ButtonSimple>
  );
}
