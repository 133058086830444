import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import ReactPlayer from 'react-player';

import ClearIcon from '@mui/icons-material/Clear';

import * as S from './styles';
import { Button, IconButton, Modal } from '@mui/material';
import { authenticatedRequest } from '../../../../services/api/newStudent/apiStudent.service';
import FieldFormik from '../../FieldFormik';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../ProviderNewStudent';

export interface IAdvertising {
  id: number;
  url: string;
}

interface Props {
  advertising: IAdvertising | undefined;
  showFooter?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ModalVideo({
  advertising,
  isOpen,
  setIsOpen,
  showFooter,
}: Props) {
  const { student, setStudent } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [dontShowAgain, setDontShowAgain] = useState(false);

  const cleanStorageVideo = () => {
    setIsOpen(false);
    if (!showFooter) {
      return;
    }
    const tmpStudent = student;
    tmpStudent.dataStudent.videos = [];
    setStudent(tmpStudent);

    localStorage.setItem('dataStudent', JSON.stringify(tmpStudent));
  };

  const handleConfirm = async () => {
    if (dontShowAgain) {
      const data = { videoId: advertising?.id };
      await authenticatedRequest(
        '/student/dashboard/dismiss-video',
        'POST',
        data,
      );
    }

    cleanStorageVideo();
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-video"
      aria-describedby="modal-video-description"
      sx={{ backgroundColor: 'rgba(0, 0, 0, .6)' }}
      onClose={cleanStorageVideo}
    >
      <S.BoxStyle render={!!advertising?.url}>
        <S.Header>
          <IconButton sx={{ marginBottom: '5px' }} onClick={cleanStorageVideo}>
            <ClearIcon sx={{ color: '#fff', fontSize: '20px' }} />
          </IconButton>
        </S.Header>
        <S.ContainerVideo>
          {advertising?.url && (
            <ReactPlayer
              controls={true}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
              url={advertising.url}
            />
          )}
        </S.ContainerVideo>
        {showFooter && (
          <S.Footer>
            <S.Buttons>
              <FieldFormik
                name="dontShowAgain"
                onChange={() => setDontShowAgain(!dontShowAgain)}
                type="checkbox"
                placeholder="Não exibir novamente"
                checked={dontShowAgain}
              />
            </S.Buttons>
            <Button onClick={handleConfirm} variant="contained">
              Finalizar
            </Button>
          </S.Footer>
        )}
      </S.BoxStyle>
    </Modal>
  );
}
