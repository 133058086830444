import styled from 'styled-components';

export const Container = styled.div``;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const DivAttachment = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
`;

export const ButtonBackContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    display: none;
  }
`;

export const Key = styled.h6`
  color: ${(props) => props.theme.colors?.text.primary};
  font-weight: bold;
`;

export const Value = styled.h6`
  color: ${(props) => props.theme.colors?.text.secondary};
`;

export const DialogValue = styled.h6`
  color: ${(props) => props.theme.colors?.text.secondary};

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    width: 22rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const KeyValue = styled.div`
  display: flex;

  align-items: baseline;
  gap: 0.5rem;
`;

export const KeyValueMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 0.5rem;
`;

export const DivListMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const DivObservationMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Observation = styled.div`
  max-width: 40rem;
  word-break: break-word;

  @media screen and (min-width: ${(props) => props.theme.size?.medium}) {
    min-width: 16rem;
  }
  @media screen and (min-width: ${(props) => props.theme.size?.large}) {
    min-width: 40rem;
  }
`;

export const DivStatus = styled.div``;

export const ContentDialog = styled.div``;

export const ContainerDialog = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 2rem;
  padding: 2rem;
`;

export const KeyValueDialog = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: start;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    gap: 0;
    flex-direction: column;
  }
`;

export const DialogTitle = styled.h4``;

export const DialogTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: start;
`;
