import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 18px;
  background-color: transparent;
  border: 1px solid #ddd;
`;

export const StyledTh = styled.th`
  text-align: start;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  vertical-align: bottom;
  background-color: #fff !important;
  border-top: 1px solid #ddd;
  border-bottom: 2px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  color: #666666;
`;

export const StyledTd = styled.td`
  text-align: start;
  vertical-align: middle;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  background-color: #fff !important;
  border-right: 1px solid #ddd;
  color: #666666;
`;

export const NotFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
