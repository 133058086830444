import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40rem;

  border-radius: 1rem;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;

  border-radius: 1rem;
`;

export const Div = styled.div`
  cursor: pointer;
`;
