import { infoToast } from '../../../components/common/Toast';
import * as apiAgentService from '../../api/agent/api.agent.service';

const authAgentService = {
  async authenticate(data: object) {
    for (const key in localStorage) {
      if (key !== 'AcessoExterno') {
        localStorage.removeItem(key);
      }
    }
    return await apiAgentService.unauthenticatedRequest(`/login`, 'POST', data);
  },

  async refreshToken() {
    localStorage.setItem('lastRefreshToken', new Date().getTime().toString());

    const response = await apiAgentService.authenticatedRequest(
      '/refresh-token',
      'POST',
    );
    if (response?.result) {
      authAgentService.setLoggedUser(response.access_token);
      localStorage.setItem('userAgent', response.access_token);
      return response;
    }
  },

  setLoggedUser(data: string) {
    localStorage.setItem('userAgent', data);
    localStorage.setItem('lastRefreshToken', new Date().getTime().toString());
  },

  getLoggedUser() {
    return localStorage.getItem('userAgent');
  },

  cleanLoggerUser(e: boolean = false) {
    localStorage.clear();
    if (e) {
      infoToast('Sua sessão expirou, faça login novamente.');
    }

    if (window.location.pathname.includes('/agenciador')) {
      return (window.location.href = '/agenciador');
    } else {
      return (window.location.href = '/new/aluno');
    }
  },
};

export default authAgentService;
