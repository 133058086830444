import React, { useEffect, useState } from 'react';

import {
  SaleDiscount,
  SaleInformation,
  SaleItems,
  SaleObservation,
  SaleRegistration,
} from './components';

import * as S from './styles';
import {
  saleItemTypeCourse,
  saleItemTypeService,
  typeServiceNegotiation,
} from '../../../../../variables';
import { IdontSearchItem } from '../../../../../services/agent/student/interface';

export interface IIsItemsEmpty {
  registration: boolean;
  info: boolean;
  discount: { student: boolean; courses: boolean; combo: boolean };
}

export default function TabSale({ data }) {
  const {
    form,
    send,
    items,
    setItems,
    negotiation,
    setNegotiation,
    valueTotal,
    setValueTotal,
    payment,
    setPayment,
    defaultPayment,
    discount,
    setDiscount,
    maxStudentValue,
    maxPaymentTypeValue,
    maxComboValue,
    idCardDiscount,
    setIdCardDiscount,
    cardDiscount,
    coupon,
    setCoupon,
    isEditSale,
    paymentTypeValue,
  } = data;

  const [valueItems, setValueItems] = useState({ value: 0, qtd: 0, debt: 0 });

  const [dontSearch, setDontSearch] = useState<IdontSearchItem[]>([]);

  const [isItemsEmpty, setIsItemsEmpty] = useState<IIsItemsEmpty>({
    registration: false,
    info: false,
    discount: { student: false, courses: false, combo: false },
  });

  /** Altera o pagamento e matrícula */
  useEffect(() => {
    setNegotiation((old) => ({
      ...old,
      items: items.map((item) => ({
        id: item.description.value,
        discount: item.discount.value,
        value: item.value.value,
        itemType: item.item.value,
        type: item.type.value,
      })),
      discounts: {
        ...old.discounts,
        coupon: { ...old.discounts.coupon, text: discount.coupon?.text },
      },
      registration: payment.registration,
      studentPlan: { ...payment.studentPlan, total: valueItems.value },
    }));
  }, [payment, valueTotal, items, discount.coupon.text]);

  useEffect(() => {
    setPayment((old) => ({
      ...old,
      studentPlan: { ...old.studentPlan, total: valueItems.value },
    }));
  }, [valueItems]);

  /** Regra de bloqueio de desconto */
  useEffect(() => {
    let value = 0;
    let qtd = 0;
    let debt = 0;

    items.map((i) => {
      if (
        +i.item.value === +saleItemTypeService! &&
        +i.type.value === +typeServiceNegotiation!
      ) {
        debt = debt + i.value.value - i.discount.value;
        return (qtd = qtd + 1);
      }
      value = value + (i.value.value - i.discount.value);
      qtd = qtd + 1;
    });
    setValueItems({ value, qtd, debt: debt });

    if (items.length > 0) {
      setIsItemsEmpty(() => ({
        registration: false,
        info: false,
        discount: { student: false, courses: false, combo: false },
      }));
    } else {
      setPayment(defaultPayment);
      setIsItemsEmpty(() => ({
        registration: true,
        info: true,
        discount: { student: true, courses: true, combo: true },
      }));
    }
  }, [items]);

  useEffect(() => {
    const dontSearchItems: IdontSearchItem[] = [];

    if (items) {
      form.courses.content.forEach((e) => {
        dontSearchItems.push({ item: +saleItemTypeCourse!, productId: +e.id });
      });
    }
    items.forEach((item) => {
      if (
        !dontSearchItems.find(
          (i) =>
            +i.item === +item.item.value &&
            +i.productId === +item.description.value,
        )
      ) {
        dontSearchItems.push({
          item: +item.item.value,
          productId: +item.description.value,
        });
      }
    });

    setDontSearch([...dontSearchItems]);
  }, [form.courses.content]);

  return (
    <S.Container>
      <SaleItems
        isEditSale={isEditSale}
        dontSearch={dontSearch}
        setDontSearch={setDontSearch}
        form={form}
        items={items}
        setItems={setItems}
        setNegotiation={setNegotiation}
        negotiation={negotiation}
      />
      <S.ContainerSaleInfo>
        <S.ContainerPayment>
          <SaleDiscount
            negotiation={negotiation}
            isItemsEmpty={isItemsEmpty}
            maxStudentValue={maxStudentValue}
            maxPaymentTypeValue={maxPaymentTypeValue}
            maxComboValue={maxComboValue}
            setNegotiation={setNegotiation}
            idCardDiscount={idCardDiscount}
            setIdCardDiscount={setIdCardDiscount}
            cardDiscount={cardDiscount}
          />
          <SaleRegistration
            isItemsEmpty={isItemsEmpty}
            payment={payment}
            setPayment={setPayment}
            send={send}
            paymentTypeValue={paymentTypeValue}
            valueTotal={valueTotal}
            negotiation={negotiation}
            setNegotiation={setNegotiation}
          />
          <SaleInformation
            isItemsEmpty={isItemsEmpty}
            valueItems={valueItems}
            setNegotiation={setNegotiation}
            registration={{
              value: payment.registration.value
                ? +payment.registration.value
                : 0,
            }}
            form={form}
            discount={discount}
            setDiscount={setDiscount}
            valueTotal={valueTotal}
            setValueTotal={setValueTotal}
            negotiation={negotiation}
            coupon={coupon}
            setCoupon={setCoupon}
          />
        </S.ContainerPayment>
      </S.ContainerSaleInfo>
      <SaleObservation
        negotiation={negotiation}
        setNegotiation={setNegotiation}
      />
    </S.Container>
  );
}
