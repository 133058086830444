import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const ContainerTitle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 75%;

  padding-bottom: 3rem;
`;

export const Title = styled.b`
  font-size: x-large;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

interface ISubtitle {
  error?: boolean;
}
export const SubTitle = styled.p<ISubtitle>`
  text-align: center;

  color: ${(props) =>
    props.error
      ? props.theme.colors?.text.danger
      : props.theme.colors?.text.secondary};
  padding-top: 1rem;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Forgot = styled.section`
  display: flex;
  align-items: center;

  width: 100%;

  justify-content: flex-end;
`;

export const A = styled.a`
  color: ${(props) => props.color ?? props.theme.colors?.text.secondary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;

  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const ButtomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  width: 100%;
  padding-top: 1rem;
`;

export const P = styled.p`
  text-align: justify;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Question = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
  padding-top: 1rem;

  width: 100%;
  color: ${({ theme }) => theme.colors?.primary};
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
`;
