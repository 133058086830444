import FormData from 'form-data';

import * as apiService from '../api/student/api.service';

interface DocumentRequestBody {
  id?: number;
  type: number;
  universityDegreeId?: number;
  file?: File;
}

interface DocumentApiResponse {
  data: string[];
  message: string[];
  result: boolean;
}

export const documentStudentChange = {
  async atvDocApi({
    type,
    body,
    action,
  }: {
    type: string;
    body: DocumentRequestBody;
    action: 'delete' | 'update';
  }): Promise<DocumentApiResponse> {
    try {
      let formData;
      let requestType;
      if (action === 'update') {
        formData = new FormData();
        formData.append('id', body.id && body.id.toString());
        formData.append('type', body.type && body.type.toString());
        if (body.file) {
          const fileBlob = new Blob([body.file], { type: body.file.type });
          formData.append('file', fileBlob, body.file.name);
        }
        requestType = 'file';
      } else {
        formData = body;
        requestType = 'object';
      }
      const response = await apiService.authenticatedRequest(
        '/student/documents/degree-data',
        type,
        formData,
        requestType,
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  async documentApi({
    type,
    body,
    action,
  }: {
    type: string;
    body: DocumentRequestBody;
    action: 'delete' | 'update';
  }): Promise<DocumentApiResponse> {
    try {
      let formData;
      let requestType;
      if (action === 'update') {
        formData = new FormData();
        formData.append('id', body.id && body.id.toString());
        formData.append('type', body.type && body.type.toString());
        if (body.file) {
          const fileBlob = new Blob([body.file], { type: body.file.type });
          formData.append('file', fileBlob, body.file.name);
        }
        requestType = 'file';
      } else {
        formData = body;
        requestType = 'object';
      }
      const response = await apiService.authenticatedRequest(
        '/student/documents/upload',
        type,
        formData,
        requestType,
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  async upload({
    body,
  }: {
    body: DocumentRequestBody;
  }): Promise<DocumentApiResponse> {
    try {
      const formData = new FormData();
      formData.append('type', body.type.toString());
      if (body.file) {
        const fileBlob = new Blob([body.file], { type: body.file.type });
        formData.append('file', fileBlob, body.file.name);
      }
      const response = await apiService.authenticatedRequest(
        '/student/documents/upload',
        'POST',
        formData,
        'file',
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};

interface CertificateApiBody {
  id?: string | number;
  registrationId: number;
  universityDegreeId: number;
  initialDate?: string;
  url?: string;
  finalDate?: string;
  workload?: number;
  type: number | string;
  file: File;
}

export const documentCertificate = {
  async uploadCertificate({ body }: { body: CertificateApiBody }) {
    try {
      const formData = new FormData();
      formData.append('id', body.id ? body.id : '');
      formData.append('type', body.type.toString());
      formData.append(
        'registrationId',
        body.registrationId ? body.registrationId.toString() : null,
      );
      formData.append(
        'universityDegreeId',
        body.universityDegreeId ? body.universityDegreeId.toString() : '',
      );
      formData.append('initialDate', body.initialDate);
      formData.append('url', body.url);
      formData.append('finalDate', body.finalDate);
      formData.append('workload', body.workload && body.workload.toString());
      formData.append('file', body.file);

      const response = await apiService.authenticatedRequest(
        '/student/documents/degree-data',
        'POST',
        formData,
        'file',
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
