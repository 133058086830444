import React from 'react';

import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RowTextBold } from '../../../../../components/student/Table/components';
import { IUniversityDegree, StyledAccordion } from '../..';
import * as Style from '../../styles';
import DocInternship from './components';

interface Props {
  type: string;
  title: string;
  oldFiles: IUniversityDegree[];
  registrationId: number;
  universityDegreeId?: number;
}

export default function DocumentInternship({
  oldFiles,
  title,
  type,
  registrationId,
  universityDegreeId,
}: Props) {
  const isChecked = () => {
    const arrChecked = oldFiles.map((filtered: { conferido: boolean }) => {
      return filtered.conferido === true;
    });
    return arrChecked.includes(true);
  };

  return (
    <>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordion-content"
          id="accordion-header"
        >
          <Style.AccordionSummary>
            <Style.AccordionSummary>
              <RowTextBold>{title}</RowTextBold>
              <RowTextBold color={isChecked() ? '#008000' : '#D75413'}>
                {isChecked() ? 'Em Andamento' : 'Pendente'}
              </RowTextBold>
            </Style.AccordionSummary>
            <div>
              <RowTextBold>Abrir</RowTextBold>
            </div>
          </Style.AccordionSummary>
        </AccordionSummary>
        <DocInternship
          type={type}
          oldFiles={oldFiles}
          registrationId={registrationId}
          universityDegreeId={universityDegreeId!}
        />
      </StyledAccordion>
    </>
  );
}
