import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import * as S from './styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { SideMenu } from './components';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, IconButton, Switch } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from 'styled-components';

import { sieadImagesFolderUrl } from '../../../../../variables';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../ProviderNewStudent';
import Feedback from '../../../Modal/FeedBackModal';

const logo = `${sieadImagesFolderUrl}white_logo.png`;
const avatarCircle = '/assets/img/circle.png';
const avatar = '/img/newStudent/avatar.png';

interface Props {
  setIsAccessibilityOpen: Dispatch<SetStateAction<boolean>>;
  changeLayout: () => void;
  layout: boolean;
}
export default function SideBar({
  setIsAccessibilityOpen,
  changeLayout,
  layout,
}: Props) {
  const { student, isSidebarOpen, setIsSidebarOpen } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [isModalExitOpen, setIsModalExitOpen] = useState(false);

  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <S.Container>
      <S.ContainerDesktop>
        <S.ContainerPerfil>
          {student?.dataStudent ? (
            <>
              <div>
                <S.ImgLogo src={logo} alt="logo" width="150px" />
              </div>
              <S.PrefilContent
                onClick={() => navigate('/new/aluno/meus-dados')}
              >
                <S.ImgAvatar
                  src={
                    student?.dataStudent?.photo
                      ? student?.dataStudent?.photo
                      : avatar
                  }
                  width="100px"
                />
                <S.ImgCircle src={avatarCircle} width="110px" />
              </S.PrefilContent>
              <S.InfoText>
                <S.Name>{student?.dataStudent?.name}</S.Name>
                <S.Registration>
                  Matrícula {student?.dataStudent?.register}
                </S.Registration>
              </S.InfoText>
            </>
          ) : (
            <></>
          )}
        </S.ContainerPerfil>
        <SideMenu />
      </S.ContainerDesktop>
      <S.ContainerMobile>
        <S.MobileButtons>
          <IconButton
            className="button_icon"
            onClick={() => setIsSidebarOpen(true)}
            sx={{
              color: theme.colors?.backgroundColor,
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <div onClick={() => navigate('/new/aluno/inicio')}>
            <S.ImgLogo src={logo} alt="logo" width="100px" />
          </div>
          <div>
            <IconButton
              className="button_icon"
              onClick={() => setIsAccessibilityOpen((old) => !old)}
              sx={{
                color: theme.colors?.backgroundColor,
              }}
            >
              <img
                src="/assets/img/accessibility_white.png"
                width="20px"
                height="20px"
              />
            </IconButton>
            <IconButton
              className="button_icon"
              onClick={() => setIsModalExitOpen(true)}
              sx={{
                color: theme.colors?.backgroundColor,
              }}
            >
              <LogoutIcon fontSize="large" />
            </IconButton>
          </div>
        </S.MobileButtons>
      </S.ContainerMobile>
      <S.MobileSidebar isOpen={isSidebarOpen}>
        <S.ContainerPerfil>
          {!student?.dataStudent ? (
            <></>
          ) : (
            <S.PerfilDiv>
              <IconButton
                className="button_icon button_close"
                onClick={() => setIsSidebarOpen(false)}
              >
                <ArrowBackIosIcon fontSize="large" />
              </IconButton>

              {student?.preferences?.canYouAccessOld && (
                <S.TemporarySwitchToChangeLayout>
                  <FormControlLabel
                    control={
                      <Switch checked={layout} onChange={changeLayout} />
                    }
                    label={<h6>Novo layout</h6>}
                    labelPlacement="end"
                  />
                </S.TemporarySwitchToChangeLayout>
              )}
              <div>
                <S.ImgLogo src={logo} alt="logo" width="150px" />
              </div>
              <S.PrefilContent
                onClick={() => {
                  setIsSidebarOpen(false);
                  navigate('/new/aluno/meus-dados');
                }}
              >
                <S.ImgAvatar
                  src={
                    student?.dataStudent?.photo
                      ? student?.dataStudent?.photo
                      : avatar
                  }
                  width="100px"
                />
                <S.ImgCircle src={avatarCircle} width="110px" />
              </S.PrefilContent>
              <S.InfoText>
                <S.Name>{student?.dataStudent.name}</S.Name>
                <S.Registration>
                  Matrícula {student?.dataStudent.register}
                </S.Registration>
              </S.InfoText>
            </S.PerfilDiv>
          )}
        </S.ContainerPerfil>
        <S.SideMenuDiv>
          <SideMenu setIsSidebarOpen={setIsSidebarOpen} />
        </S.SideMenuDiv>
      </S.MobileSidebar>
      <Feedback isOpen={isModalExitOpen} setIsOpen={setIsModalExitOpen} />
    </S.Container>
  );
}
