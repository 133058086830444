import React, { useState } from 'react';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import { TitlePage } from '../../../components/agent/TitlePage';
import { ButtonSimple } from '../../../components/agent/Buttons';
import { Filter, ListMyBusiness, ModalNewStudentData } from './components';
import ModalSearchNegotiation from '../../../components/agent/ModalSearchNegotiation';

import * as S from './styles';
import { IFiltered } from '../../../services/agent/sale/interface';

export default function MyBusiness() {
  const [filtered, setFiltered] = useState<IFiltered[]>([]);
  const [modalSearchOpen, setModalSearchOpen] = useState<boolean>(false);
  const [modalNewBusinessOpen, setModalNewBusinessOpen] =
    useState<boolean>(false);

  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);

  const agent = localStorage.getItem('agent');
  const agentData = agent ? JSON.parse(agent) : null;
  return (
    <S.Container>
      <S.HeadPage>
        <TitlePage
          title="Meus Negócios"
          icon={<BusinessCenterIcon fontSize="large" />}
        />
        <S.ContainerButtons>
          <ButtonSimple
            color="blue"
            icon={<SearchIcon />}
            onClick={() => setModalSearchOpen(true)}
          >
            Pesquisar aluno
          </ButtonSimple>
          {!agentData?.sales_agent.realiza_venda ? (
            <div></div>
          ) : (
            <ButtonSimple
              color="green"
              icon={<AddIcon />}
              onClick={() => setModalNewBusinessOpen(true)}
            >
              Novo
            </ButtonSimple>
          )}
        </S.ContainerButtons>
      </S.HeadPage>
      <Filter
        setFiltered={setFiltered}
        allowFetch={modalNewBusinessOpen || modalEditOpen}
      />
      <ListMyBusiness
        list={filtered}
        modalEditOpen={modalEditOpen}
        setModalEditOpen={setModalEditOpen}
      />
      <ModalSearchNegotiation
        isOpen={modalSearchOpen}
        setIsOpen={setModalSearchOpen}
        isSearch
      />
      <ModalNewStudentData
        isOpen={modalNewBusinessOpen}
        setIsOpen={setModalNewBusinessOpen}
      />
    </S.Container>
  );
}
