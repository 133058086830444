import styled from 'styled-components';
import { sieadUrl } from '../../../../../variables';

export const backgroundImg = `${sieadUrl}/content/smart-site/images/page6_img1.png`;

export const ContainerHead = styled.header`
  max-width: 1120px;
  margin: auto;
  height: 140px;
  border-bottom: 1px solid #eee !important;
  overflow: hidden;
  min-height: 28px;
  background-image: url(${backgroundImg});
  border-bottom: 1px solid #6d6a69;
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 1px) {
    max-width: 1170px;
  }
  @media (max-width: 1400px) {
    max-width: 1120px;
  }
  @media (max-width: 1200px) {
    max-width: 945px;
  }
  @media (max-width: 992px) {
    max-width: 720px;
  }
  @media (max-width: 768px) {
    max-width: 540px;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  @media only screen and (max-width: 425px) {
    width: 100vh;
    max-width: 425px;
    padding: 0 2rem;
    background-image: none;
  }
`;

export const Div = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 100px;
  margin: 0 auto;

  @media (max-width: 768px) {
    align-self: center;
    justify-self: center;
  }
  @media only screen and (max-width: 425px) {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    height: 70px;
  }
`;

export const Quit = styled.div`
  width: 52px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 1rem;
`;
