import React, { useContext, useEffect, useState } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import HeaderPages from '../../../components/student/HeaderPages';
import * as S from './styles';
import { Notice } from '../../../components/student/Info';
import { DocumentInternship } from './components';
import { authenticatedRequest } from '../../../services/api/student/api.service';
import { warningToast } from '../../../components/common/Toast';
import AccordionDocuments from '../../../components/student/AccordionDocuments';
import { typeDocComplementary, typeDocInternship } from '../../../variables';
import styled from 'styled-components';
import {
  StudentProviderContext,
  IStudentContextProps,
} from '../../../components/student/StudentProvider';

export const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid #ececec`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export interface IAnalysis {
  id: number;
  status: string;
  motivo_rejeicao: string;
  url: string;
  nome: string;
}

export interface IDocumentData {
  id: number;
  name: string;
  status: string;
  analysis: IAnalysis[] | [];
}

export interface IUniversityDegree {
  id: string | number;
  tipo: string | number;
  dataInicio: string;
  dataFim: string;
  cargaHoraria: string;
  url: string;
  conferido: boolean;
  file?: File;
  alterado?: boolean;
}

interface Doc {
  cargaHoraria: string;
  conferido: boolean;
  dataFim: string;
  dataInicio: string;
  id: number;
  tipo: number;
  url: string;
}

interface Internship {
  name: string;
  internship: Doc[] | [];
  complementary: Doc[] | [];
  registrationId: number;
  universityDegreeId: number;
}

export default function Documents() {
  const { setIsLoading } = useContext(
    StudentProviderContext,
  ) as IStudentContextProps;
  const [dataType, setDataType] = useState<IDocumentData[] | []>([]);
  const [docInternship, setDocInternship] = useState<Internship[] | []>([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const { data, result, message } = await authenticatedRequest(
          '/student/documents/show',
        );
        if (result) {
          setDataType(data.documents);

          setDocInternship(
            data.universityDegree.map((obj) => ({
              name: obj.courseName,
              internship: obj.degreeData.filter(
                (item) => item.tipo === +typeDocInternship!,
              ),
              complementary: obj.degreeData.filter(
                (item) => item.tipo === +typeDocComplementary!,
              ),
              registrationId: obj.registrationId,
              universityDegreeId: obj.universityDegreeId,
            })),
          );
        } else {
          warningToast(message);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      <HeaderPages title={'Documentos'} />
      <Notice
        title="Atenção"
        rows={[
          {
            subtitle: 'Documentos Rejeitados: ',
            text: `Caso algum documento esteja com o status 'Rejeitado', a situação dele estará como 'Rejeitado'. Para visualizar basta clicar em 'Abrir' e verificar o motivo.`,
          },
          {
            subtitle: 'Importante: ',
            text: `Certidão de Nascimento/Casamento deverá ser a atualizada, pois será o documento de maior relevância para a emissão do Certificado.`,
          },
          {
            subtitle: 'Lembrando que: ',
            text: `O Certificado será emitido conforme a documentação enviada. Em caso de quaisquer alterações de dados após a emissão, será cobrada uma taxa para a correção do certificado.`,
          },
        ]}
      />
      <AccordionDocuments dataType={dataType} />

      {docInternship.map((item) => (
        <div key={item.registrationId}>
          <Notice
            title={`Documentação para diploma do curso ${item.name}`}
            rows={[
              {
                subtitle: `Documentos Específicos do diploma: `,
                text: `Para emissão do diploma, é necessário preencher as Atividades Complementares e Estágio Obrigatório. Após preenchimento, não será possível alterar os dados no portal.`,
              },
              {
                subtitle: `Não esqueça de salvar as mudanças realizadas! `,
              },
            ]}
          />
          <S.PaperStyled>
            {/* Render documentos para Estágio*/}
            <DocumentInternship
              oldFiles={item.internship}
              title={'Estágio'}
              type={'internship'}
              registrationId={item.registrationId}
              universityDegreeId={item.universityDegreeId}
            />
            {/* Render documentos para Atividades Complementares*/}
            <DocumentInternship
              oldFiles={item.complementary}
              title={'Atividades Complementares'}
              type={'complementary'}
              registrationId={item.registrationId}
              universityDegreeId={item.universityDegreeId}
            />
          </S.PaperStyled>
        </div>
      ))}
    </div>
  );
}
