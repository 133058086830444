import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2rem;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;

  margin: 3rem 0 0 2rem;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const Request = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  height: 2rem;
  gap: 0.5rem;
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const RowTableDiv = styled.div``;
