import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;
export const Div = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  padding-top: 2rem;
`;
