import React, { useEffect, useState } from 'react';

import * as S from './styles';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { handleMaxWidth } from '../../../util/newStudent';
import { useTheme } from 'styled-components';

interface ChartProps {
  series: number[];
  options: ApexOptions;
}

export interface IInfoChartProps {
  color: string;
  label: string;
  value: number;
}
interface IData {
  series: number[];
  labels: string[];
  colors: string[];
}
interface Props {
  dataItems: IInfoChartProps[];
  nameShow?: boolean;
  valueShow?: boolean;
  showLegend?: boolean;
}

export default function ChartMultipleRadialBar({
  showLegend = true,
  nameShow = true,
  valueShow = true,
  dataItems = [],
}: Props) {
  const theme = useTheme();

  const [data, setData] = useState<IData>({
    series: [],
    labels: [],
    colors: [],
  });

  /** Formata os dados passador por props */
  useEffect(() => {
    let tmpSeries: number[] = [];
    let tmpLabels: string[] = [];
    let tmpColors: string[] = [];

    dataItems.map((item) => {
      tmpSeries = [...tmpSeries, item.value];
      tmpLabels = [...tmpLabels, item.label];
      tmpColors = [...tmpColors, item.color];
    });
    setData({ series: tmpSeries, labels: tmpLabels, colors: tmpColors });
  }, [dataItems]);

  const options: ChartProps = {
    series: data.series,
    options: {
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '10%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: nameShow,
            },
            value: {
              show: valueShow,
            },
          },
        },
      },
      colors: data.colors,
      labels: data.labels,
      legend: {
        show: showLegend,
        floating: true,
        fontSize: handleMaxWidth(theme.size?.medium) ? '14px' : '16px',
        position: 'left',
        offsetX: 20,
        offsetY: 20,
        labels: {
          useSeriesColors: true,
        },

        itemMargin: {
          vertical: 3,
        },
        formatter: function (seriesName, opts) {
          return (
            seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + '%'
          );
        },
      },
      /** Responsividade da tela */
      responsive: [
        {
          breakpoint: 769,
          options: {
            legend: {
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
        {
          breakpoint: 524,
          options: {
            legend: {
              offsetX: 0,
            },
          },
        },
        {
          breakpoint: 426,
          options: {
            legend: {
              offsetX: -20,
              offsetY: 10,
            },
          },
        },
        {
          breakpoint: 376,
          options: {
            legend: {
              offsetX: -30,
              offsetY: 5,
            },
          },
        },
        {
          breakpoint: 321,
          options: {
            legend: {
              offsetX: -50,
              offsetY: 5,
            },
          },
        },
      ],
    },
  };

  return (
    <S.Container>
      <ReactApexChart
        options={options.options}
        series={options.series}
        type="radialBar"
        height={handleMaxWidth(theme.size?.medium) ? 270 : 390}
      />
    </S.Container>
  );
}
