import { defaultReturn } from '../../../util/newStudent';
import * as api from '../../api/agent/api.agent.service';
import { IDataConsultStudent, IStudent } from './interface';

export const studentDataService = {
  async data(data: IDataConsultStudent): Promise<{
    data: IStudent;
    message: string[];
    result: boolean;
  }> {
    const response = await api.authenticatedRequest(
      `/helper/student/data`,
      'POST',
      data,
    );

    return defaultReturn(response);
  },
};
