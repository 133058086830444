import React, {
  useState,
  useEffect,
  SetStateAction,
  createContext,
  Dispatch,
} from 'react';

import { authAgentService } from '../../../services/auth';
import { authenticatedRequest } from '../../../services/api/agent/api.agent.service';
import { sieadUrl } from '../../../variables';
import ErrorBoundary from '../../common/ErrorBoundary';
interface IAgent {
  sales_agent: {
    id: number;
    nome: string;
    cpf: string;
    email: string;
    status: number;
    usuario_id: number;
    aluno_id: null | number;
    polos_gerenciados: {
      id: number;
      name: string;
    }[];
    realiza_venda: boolean;
    endereco: null | string;
    usuario: {
      id: number;
      email: string;
      username: string;
      primeiro_acesso: string;
      ultimo_acesso: string;
      status: number;
      usuario_grupo_id: number;
      usuario_perfil_id: number;
      empresa_id: number;
      avatar_url: null | string;
    };
  };
  permissions: {
    agenciador: {
      agenciador: string[];
      comissao: string[];
      dashboard: string[];
      dashboard_bi: string[];
      dashboard_vestibular: string[];
      fechamento_comissao: string[];
      negocio: string[];
      relatorio: string[];
      relatorio_bi: string[];
      requerimento: string[];
    };
  };
}

export interface IAgentContextProps {
  redirectTo: string;
  setRedirectTo: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  agentLogged: boolean;
  agentData: IAgent | undefined;
}

export const ProviderAgentContext = createContext<IAgentContextProps>(
  {} as IAgentContextProps,
);
export default function ProviderAgent({ children }) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [agentLogged, setAgentLogged] = useState<boolean>(false);
  const [login, setLogin] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [agentData, setAgentData] = useState<IAgent | undefined>();

  const queryParameters = new URLSearchParams(window.location.search);
  const acessoExterno = queryParameters.get('acessoExterno');

  if (acessoExterno) {
    localStorage.setItem('AcessoExterno', acessoExterno);
  }

  const _acessoExterno = localStorage.getItem('AcessoExterno');
  const userAgent = authAgentService.getLoggedUser();
  const agent = localStorage.getItem('agent');

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    setIsLoading(true);
    const handleLogin = async () => {
      if (acessoExterno) {
        const response = await authAgentService.authenticate({
          acesso: acessoExterno,
        });

        if (response.result) {
          authAgentService.setLoggedUser(response.access_token);
          setLogin(true);
        }
      }
    };

    handleLogin();
  }, [acessoExterno]);

  useEffect(() => {
    setIsLoading(true);
    const handleVerification = async () => {
      /** useEfect para limpar o cache */
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      if (_acessoExterno || userAgent) {
        if (agent) {
          return;
        }

        const { data, result } = await authenticatedRequest(
          '/sales-agent/dashboard/show',
          'get',
        );

        if (result && data) {
          setAgentLogged(true);
          setIsLoading(false);
          localStorage.setItem('agent', JSON.stringify(data));
          setRedirectTo('/agenciador/negocio');
          setAgentData(data);
        } else {
          setIsLoading(false);
          window.location.href = sieadUrl + '/agenciador/conta';
        }
      } else {
        window.location.href = sieadUrl + '/agenciador/conta';
      }
    };

    const fetchData = async () => {
      if (!_acessoExterno || !userAgent) {
        await delay(3000);
      }
      handleVerification();
    };
    fetchData();
  }, [login]);

  return (
    <ErrorBoundary>
      <ProviderAgentContext.Provider
        value={{
          redirectTo: redirectTo,
          setRedirectTo: setRedirectTo,
          agentLogged: agentLogged,
          isLoading: isLoading,
          setIsLoading: setIsLoading,
          agentData: agentData,
        }}
      >
        {children}
      </ProviderAgentContext.Provider>
    </ErrorBoundary>
  );
}
