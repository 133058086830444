import styled from 'styled-components';

export const Container = styled.div``;

export const PdfDiv = styled.div`
  width: 100%;
`;

export const TitlePdfDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  margin: 0;
`;

export const TitleIconPdfDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.5rem;

  margin: 0;
`;

export const TitlePdfH5 = styled.h4`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const DivContent = styled.div``;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const DivTable = styled.div``;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DivTableMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Key = styled.h6`
  color: ${(props) => props.theme.colors?.text.primary};
  font-weight: bold;
`;

export const Value = styled.h6`
  max-width: 23rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors?.text.secondary};
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
`;

export const KeyValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const DivHistoricMobile = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const DivButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  gap: 0.5rem;
`;
