import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import * as S from './style';
import { TitlePage } from '../TitlePage';

interface Props {
  title: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleClose?: () => void;
  subtitle?: boolean;
  itemTitle?: ReactNode;
  search?: ReactNode;
}

export default function HeaderModal({
  title,
  setOpen,
  subtitle,
  handleClose,
  itemTitle,
  search,
}: Props) {
  return (
    <S.Title>
      <S.Container>
        <S.ComponentTitle>
          <TitlePage title={title} subtitle={subtitle} />
          {itemTitle}
        </S.ComponentTitle>
        {search && <S.Search>{search}</S.Search>}
      </S.Container>
      <S.Div
        onClick={() => {
          handleClose && handleClose();
          setOpen(false);
        }}
      >
        <CloseIcon />
      </S.Div>
    </S.Title>
  );
}
