import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 52px;
  border: none;
  width: 100%;
  bottom: 0;
  background: #2a2725;
  align-items: center;
  padding-left: 1rem;
`;

export const P = styled.p`
  color: white;
`;
