import { defaultReturn } from '../../../util/newStudent';
import * as api from '../../api/newStudent/apiStudent.service';

export interface ICourses {
  id: number;
  courseName: string;
}

export interface IMyGardesShow {
  type: string;
  courses: ICourses[];
}

export interface IDisciplineGrades {
  disciplineId: number;
  disciplineName: string;
  grade: number | string;
  attempt: number;
  status: string;
}
export interface IDisciplineGardesShow {
  moduleName: string;
  disciplines: IDisciplineGrades[];
}

export const myGradesServiceNewStudent = {
  async show(): Promise<{
    data: IMyGardesShow[];
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-grades/show',
        'GET',
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },
  async grades(id: number | string): Promise<{
    data: IDisciplineGardesShow[];
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/student/my-grades/grades',
        'POST',
        { id },
      );

      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },
};
