import React, { ReactNode } from 'react';

import * as S from './styles';

interface Props {
  icon?: ReactNode;
  children: ReactNode;
  justify?: 'center' | 'start' | 'end';
}
export default function HeaderCard({ icon, children, justify }: Props) {
  return (
    <S.Container justify={justify}>
      {icon && icon}
      {children}
    </S.Container>
  );
}
