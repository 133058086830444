import styled from 'styled-components';

const propsIcon = {
  width: '30px',
  height: '30px',
  display: 'block',
  margin: '0 auto',
  opacity: '0.5',
  'background-size': ' contain',
  'background-repeat': ' no-repeat',
  'background-position': ' center',
};

export const Header = styled.div``;

export const H3 = styled.h3`
  font-size: 1.8em;
  padding-left: 3px;
  font-weight: normal;
  margin-bottom: 1em;
  color: #777;
  text-align: left;

  @media screen and (max-width: 1080px) and (min-height: 500px),
    screen and (orientation: portrait) {
    font-size: 1.8em;
    padding-left: 3px;
    font-weight: normal;
    margin-bottom: 1em;
    color: #fff;
    text-align: left;
  }
  @media screen and (max-device-width: 1080px), screen and (max-width: 500px) {
    font-size: 1.8em;
    padding-left: 3px;
    font-weight: normal;
    margin-bottom: 1em;
    color: #fff;
    text-align: left;
  }
`;

export const IconBox = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: stretch;
  justify-content: space-between;
`;

export const ItemContent = styled.div`
  flex-basis: 33%;
  flex-shrink: 2;
  flex-grow: 1;
  text-align: center;
  padding-bottom: 15px;
`;

export const SpanRem = styled.span`
  ${() => propsIcon};
  background-image: url('/img/aluno/aluno.svg');

  @media screen and (max-width: 1080px) and (min-height: 500px),
    screen and (orientation: portrait) {
    width: 100%;
    background-image: url('/img/aluno/aluno_b.svg');
  }
`;

export const SpanGrade = styled.span`
  ${() => propsIcon};
  background-image: url('/img/aluno/check.svg');

  @media screen and (max-width: 1080px) and (min-height: 500px),
    screen and (orientation: portrait) {
    width: 100%;
    background-image: url('/img/aluno/check_b.svg');
  }
`;

export const SpanBill = styled.span`
  ${() => propsIcon};
  background-image: url('/img/aluno/boletos.svg');

  @media screen and (max-width: 1080px) and (min-height: 500px),
    screen and (orientation: portrait) {
    width: 100%;
    background-image: url('/img/aluno/boletos_b.svg');
  }
`;

export const Em = styled.em`
  font-style: normal;
  font-size: 11px;

  @media screen and (max-width: 1080px) and (min-height: 500px),
    screen and (orientation: portrait) {
    color: #fff;
  }
`;
