import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { Link, List, ListItemButton } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import * as S from '../../styles';
import { NavLink } from 'react-router-dom';

interface IItem {
  title: string;
  icon?: ReactNode;
  children?: IItem[];
  link?: string | (() => string) | (() => void);
}

type OnLinkClickType = (url: string) => Promise<void>;

interface Props {
  list: IItem;
  index: number;
  open: boolean;
  whoSelected?: number;
  setSelect: Dispatch<SetStateAction<number | undefined>>;
  setSelectedChild: Dispatch<SetStateAction<number | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean | undefined>>;
  selected: boolean;
  onLinkClick: OnLinkClickType;
}

export default function MenuList({
  open,
  list,
  whoSelected,
  setSelectedChild,
  setSelect,
  setOpen,
  index,
  selected,
  onLinkClick,
}: Props) {
  const [collapse, setCollapse] = useState(false);
  const [childSelect, setChildSelect] = useState<number | undefined>();

  const listItemStyle = {
    justifyContent: open ? 'initial' : 'center',
    alignItems: 'center',
    px: 2.5,
    '&.Mui-selected': {
      color: 'white',
      background: 'none',
    },
  };

  return (
    <S.ListItemMain
      open={!!open}
      disablePadding
      sx={{
        display: 'block',
        width: open ? '230px' : '100%',
      }}
    >
      <ListItemButton
        selected={index === whoSelected || selected}
        style={{ height: '39px', paddingLeft: '1rem' }}
        sx={listItemStyle}
        onClick={() => {
          setCollapse(!collapse);
          setSelect(() => index);
          setOpen(true);
          if (whoSelected !== index) {
            setChildSelect(undefined);
            setSelectedChild(undefined);
          }
        }}
      >
        <>
          {typeof list.link === 'string' ? (
            <NavLink
              to={list.link && list.link}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <S.ContainerItem>
                {list.icon && list.icon}
                {open && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '180px',
                    }}
                  >
                    <S.TextList
                      style={{ opacity: open ? 1 : 0, paddingLeft: '1rem' }}
                    >
                      {list.title}
                    </S.TextList>
                    {list.children &&
                      (collapse ? (
                        <div
                          style={{
                            paddingRight: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
                        </div>
                      ) : (
                        <div
                          style={{
                            paddingRight: 0,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <AddBoxOutlinedIcon fontSize="small" />
                        </div>
                      ))}
                  </div>
                )}
              </S.ContainerItem>
            </NavLink>
          ) : (
            <Link
              onClick={() => {
                if (typeof list.link === 'function') {
                  const url = list.link();
                  if (typeof url === 'string') {
                    onLinkClick(url);
                  }
                } else if (typeof list.link === 'string') {
                  onLinkClick(list.link);
                }
              }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <S.ContainerItem>
                {list.icon && list.icon}
                {open && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '180px',
                    }}
                  >
                    <S.TextList
                      style={{ opacity: open ? 1 : 0, paddingLeft: '1rem' }}
                    >
                      {list.title}
                    </S.TextList>
                    {list.children &&
                      (collapse ? (
                        <div
                          style={{
                            paddingRight: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
                        </div>
                      ) : (
                        <div
                          style={{
                            paddingRight: 0,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <AddBoxOutlinedIcon fontSize="small" />
                        </div>
                      ))}
                  </div>
                )}
              </S.ContainerItem>
            </Link>
          )}
        </>
      </ListItemButton>

      {list.children && (
        <S.CustomCollapse
          unmountOnExit
          in={open && collapse && index === whoSelected}
        >
          <List sx={{ color: '#a8a8a8' }}>
            {list.children.map((collapseItem, childIndex) => (
              <S.CustomListItem key={childIndex} disablePadding>
                <ListItemButton
                  onClick={() => {
                    setChildSelect(childIndex);
                    setSelectedChild(childIndex);
                    setSelect(() => index);
                  }}
                  selected={childIndex === childSelect && index === whoSelected}
                  sx={listItemStyle}
                >
                  <S.TitleDiv>
                    <S.TextList
                      style={{ opacity: open ? 1 : 0, paddingLeft: '1rem' }}
                    >
                      {collapseItem.title}
                    </S.TextList>
                  </S.TitleDiv>
                </ListItemButton>
              </S.CustomListItem>
            ))}
          </List>
        </S.CustomCollapse>
      )}
    </S.ListItemMain>
  );
}
