import styled from 'styled-components';

export const Container = styled.div``;

export const RegistrationDiv = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const DivName = styled.div`
  display: flex;
  align-items: baseline;

  gap: 0.5rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;

    .divider {
      display: none;
    }
    padding-bottom: 0.5rem;
  }
`;
