import { IPolo } from '../../interfaces/student/aluno.interfaces';
import * as apiService from '../api/student/api.service';

const poloService = {
  async getAll(): Promise<{
    data: IPolo[];
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.authenticatedRequest(
      '/administrator/polo/get-list-polo-presencial',
    );
    return response;
  },
};

export default poloService;
