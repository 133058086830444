import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import WarningIcon from '@mui/icons-material/Warning';

import * as S from './styles';

import FieldFormik from '../../../../../components/newStudent/FieldFormik';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { authNewStudentService } from '../../../../../services/auth';
import { IDataLogin } from '../../../../../services/auth/newStudent/auth.newStudent.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { useNavigate } from 'react-router-dom';
import {
  cleanCache,
  cpfIsValid,
  emailIsValid,
} from '../../../../../util/common/appUtil';
import { handleMaxWidth } from '../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import { query, useQuery } from '../../../../../util/common/queryUtil';
import { toastMessage } from '../../../../../util/newStudent/toast';
import ModalVideo from '../../../../../components/newStudent/Modal/ModalVideo';
import { firstAccessQuestion } from '../../../../../variables';

interface Props {
  setRecovery: Dispatch<SetStateAction<boolean>>;
  setCreateFirstPassword: Dispatch<SetStateAction<boolean>>;
  setFieldLogin: Dispatch<SetStateAction<string>>;
  fieldLogin: string | null;
}

export default function FormLogin({
  setRecovery,
  setCreateFirstPassword,
  fieldLogin,
  setFieldLogin,
}: Props) {
  const { setIsLoading, setAccessedAs } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const theme = useTheme();

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [errorSend, setErrorSend] = useState<string>('');
  const [questionModal, setQuestionModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const param = useQuery();

  const validationSchemaLogin = Yup.object({
    login: Yup.string().required('O login é obrigatório.'),
    password: Yup.string().required('A senha é obrigatória.'),
  });

  const initialValuesLogin = {
    login: '',
    password: '',
  };

  const handleSubmit = (values: IDataLogin) => {
    setIsLoading(true);
    setErrorSend('');

    const login = async () => {
      try {
        const { result, data, message } =
          await authNewStudentService.authenticate({
            login: values.login,
            password: values.password,
            userType: 2,
          });

        if (result) {
          authNewStudentService.setLoggedUser(data.access_token);
          navigate('/new/aluno/inicio');
        } else {
          setErrorSend(message[0]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    login();
  };

  const checkFirstAccess = async () => {
    if (cpfIsValid(fieldLogin!) || emailIsValid(fieldLogin!)) {
      const { result, data } = await authNewStudentService.firstAccess({
        login: fieldLogin,
        userType: 2,
      });
      if (result && data.firstAccess) {
        setCreateFirstPassword(data.firstAccess);
      }
    }
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      if (fieldLogin) {
        setTimeout(() => {
          !!fieldLogin && checkFirstAccess();
        }, 500);
      }
    }, 500);

    setTimer(newTimer);
    return () => {
      if (newTimer) {
        clearTimeout(newTimer);
      }
    };
  }, [fieldLogin]);

  useEffect(() => {
    cleanCache();

    const masterAccess = param.get('masterAccess');
    const accessMyStudent = param.get('accessMyStudent');

    const login = async () => {
      query.deleteOneParam('masterAccess');
      query.deleteOneParam('accessMyStudent');

      setIsLoading(true);
      try {
        const { result, data, message } =
          await authNewStudentService.authenticate({
            masterAccess: masterAccess ?? accessMyStudent,
          });

        if (result) {
          authNewStudentService.setLoggedUser(data.access_token);
          navigate('/new/aluno/inicio');

          if (masterAccess) {
            setAccessedAs(true);
            localStorage.setItem('accessedAs', 'true');
          }
        } else {
          toastMessage(message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    (!!masterAccess || !!accessMyStudent) && login();
  }, []);

  return (
    <S.Container>
      <Formik
        initialValues={initialValuesLogin}
        validationSchema={validationSchemaLogin}
        onSubmit={handleSubmit}
      >
        {({ handleChange }) => (
          <S.ContainerForm>
            <S.ContainerTitle>
              <S.Title>Olá, aluno!</S.Title>
              {!errorSend ? (
                <>
                  <S.SubTitle>
                    Faça o login para ter acesso a sua conta.
                  </S.SubTitle>
                </>
              ) : (
                <S.SubTitle
                  error
                  dangerouslySetInnerHTML={{ __html: errorSend }}
                />
              )}
            </S.ContainerTitle>
            <S.StyledForm>
              <S.FormFields>
                <FieldFormik
                  name={'login'}
                  type={'string'}
                  widthSize="fullWidth"
                  heightSize="large"
                  placeholder={
                    handleMaxWidth(theme.size?.small)
                      ? 'Login'
                      : 'Login | Informe seu CPF / Email'
                  }
                  onChange={(e) => {
                    handleChange(e);
                    setFieldLogin(e.target.value);
                  }}
                  leftIcon={<PersonIcon />}
                  error
                />
                <FieldFormik
                  name={'password'}
                  type={'password'}
                  widthSize="fullWidth"
                  heightSize="large"
                  placeholder={
                    handleMaxWidth(theme.size?.small)
                      ? 'Senha'
                      : 'Senha | Informe sua Senha'
                  }
                  onChange={handleChange}
                  leftIcon={<LockIcon />}
                  error
                />
                <S.Forgot>
                  <S.A onClick={() => setRecovery(true)}>
                    Esqueceu a sua senha?
                  </S.A>
                </S.Forgot>
              </S.FormFields>
              <S.ButtomContainer>
                <ButtonSimple
                  type="submit"
                  color="primary"
                  font="h4"
                  heightSize="large"
                >
                  Entrar
                </ButtonSimple>
              </S.ButtomContainer>
              <S.Question>
                <S.A
                  color={theme.colors?.primary}
                  onClick={() => setQuestionModal(true)}
                >
                  <WarningIcon />
                  Dúvidas no primeiro acesso?
                </S.A>
              </S.Question>
            </S.StyledForm>
          </S.ContainerForm>
        )}
      </Formik>
      <ModalVideo
        advertising={{
          id: 0,
          url: firstAccessQuestion!,
        }}
        isOpen={questionModal}
        setIsOpen={setQuestionModal}
      />
    </S.Container>
  );
}
