import React, { Dispatch, SetStateAction, useContext } from 'react';

import * as S from './styles';
import BasicModal from '../BasicModal';
import { Formik } from 'formik';
import FieldFormik from '../../FieldFormik';
import { ButtonSimple } from '../../Buttons';
import { myDataServiceNewStudent } from '../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../ProviderNewStudent';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function PasswordChange({ isOpen, setIsOpen }: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const handleSend = async (values) => {
    setIsLoading(true);

    try {
      const { result } = await myDataServiceNewStudent.changePassword({
        body: values,
      });
      if (result) {
        return setIsOpen(false);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicModal isOpen={isOpen} setIsOpen={setIsOpen} ableBackdropClick isFloat>
      <S.Container>
        <S.Title>Alterar Senha de acesso</S.Title>
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            passwordConfirmation: '',
          }}
          onSubmit={handleSend}
        >
          {({ handleChange, values }) => (
            <S.StyledForm>
              <S.StyledInputs>
                <FieldFormik
                  name="oldPassword"
                  type="password"
                  label="Senha atual"
                  widthSize="fullWidth"
                  onChange={handleChange}
                  value={values.password}
                />
                <FieldFormik
                  name="newPassword"
                  type="password"
                  label="Nova Senha"
                  widthSize="fullWidth"
                  onChange={handleChange}
                  value={values.newPassword}
                />
                <FieldFormik
                  name="passwordConfirmation"
                  type="password"
                  label="Repetir Nova Senha"
                  widthSize="fullWidth"
                  onChange={handleChange}
                  value={values.passwordConfirmation}
                />
              </S.StyledInputs>
              <S.Buttons>
                <ButtonSimple
                  color="gray"
                  onClick={() => setIsOpen(false)}
                  outline
                >
                  Cancelar
                </ButtonSimple>
                <ButtonSimple color="primary" type="submit">
                  Confirmar
                </ButtonSimple>
              </S.Buttons>
            </S.StyledForm>
          )}
        </Formik>
      </S.Container>
    </BasicModal>
  );
}
