import React, { ReactNode, SyntheticEvent, useState } from 'react';

import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import * as S from './styles';

interface IData {
  title: string;
  children: ReactNode;
  disabled?: boolean;
}
interface Props {
  data: IData[];
}

export default function BasicTab({ data }: Props) {
  const [value, setValue] = useState('1');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const styleTitle = {
    '&.Mui-selected': {
      color: '#3f3f3f',
      fontWeight: 'bold',
    },
    fontSize: '13px',
    color: '#333',
    textTransform: 'capitalize',
    textDecoration: 'none',
  };

  return (
    <S.Container>
      <S.Content>
        <TabContext value={value}>
          <Box sx={{ borderBottom: '1px solid #e0e0e0' }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
            >
              {data.map((item, index) => {
                return (
                  <Tab
                    disabled={item.disabled}
                    sx={styleTitle}
                    label={item.title}
                    value={(index + 1).toString()}
                    key={(index + 1).toString()}
                  />
                );
              })}
            </TabList>
          </Box>
          {data.map((item, index) => {
            return (
              <TabPanel value={(index + 1).toString()} key={index}>
                {item.children}
              </TabPanel>
            );
          })}
        </TabContext>
      </S.Content>
    </S.Container>
  );
}
