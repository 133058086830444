import styled from 'styled-components';

import { TableCell, TableContainer } from '@mui/material';

export const TitleTable = styled.b`
  font-size: 14px;
`;

export const ContainerLine = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const TableContainerStyle = styled(TableContainer)`
  padding-top: 2rem;
`;

export const CellRow = styled(TableCell)`
  max-width: 10rem;
`;

export const Cell = styled.div`
  display: flex;
  justify-content: center;
`;

export const B = styled.b`
  font-size: 13px;
`;

export const Small = styled.small`
  font-size: 12px;
`;

export const TableCellStyle = styled(TableCell)`
  display: flex;
  flex-direction: column;
`;
