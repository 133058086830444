import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 2rem;
`;

export const Form = styled.div`
  padding-top: 1.5rem;
  font-size: 15px;
`;
