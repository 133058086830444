import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div``;

export const Title = styled.h6``;

export const DivTable = styled.div`
  padding: 1rem 0;
`;

export const DivExpand = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.colors?.primary};
`;

export const DivColumn = styled.div`
  width: 10rem;
  text-align: center;
`;

export const H6Subject = styled.a`
  cursor: pointer;
`;

export const Subtitle = styled.h6`
  color: ${({ theme }) => theme.colors?.text.info01};

  &:hover {
    color: ${({ theme }) => theme.colors?.text.info02};
  }
`;

export const Focus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  opacity: 0;
  gap: 1rem;

  animation: fadeIn 1s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 1;
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: space-between;
  gap: 0.5rem;
`;
