import * as apiService from '../api/student/api.service';
import { IRegistrationRows } from '../../pages/student/RegistrationsContracts';
import { IDataContract } from '../../pages/student/RegistrationsContracts/components/RegistrationTable';
// import { errorToast } from '../../components/common/Toast';

const contractService = {
  async signedContracts(): Promise<{
    data: {
      contratos: IRegistrationRows[];
      termos_de_aceite: IRegistrationRows[];
    };
    message: string[];
    result: boolean;
  }> {
    const response = await apiService
      .authenticatedRequest(`/student/contract/signed-contracts`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return console.error(error);
      });

    return response;
  },

  async getContractPdf(data: IDataContract): Promise<{
    data: string;
    message: string[];
    result: boolean;
  }> {
    const response = await apiService.authenticatedRequest(
      `/student/contract/get-contract-pdf`,
      'POST',
      data,
    );
    response.result && window.open(response.data, '_blank');

    return response;
  },
};

export default contractService;
