import React, { useRef, useEffect } from 'react';
import * as S from './styles';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

interface Props {
  url?: string;
  timeWached?: number;
}

export default function YoutubeVideo({ url, timeWached }: Props) {
  /** Expressão regular para encontrar o ID do vídeo do YouTube */
  function extractYouTubeVideoId(url: string) {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);

    return match && match[1] ? match[1] : null;
  }

  const videoPlayer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let player: Plyr | null = null;

    const initializePlayer = () => {
      if (videoPlayer.current) {
        player = new Plyr(videoPlayer.current, {
          controls: [
            'play',
            'current-time',
            'play-large',
            'duration',
            'progress',
            'mute',
            'volume',
            'captions',
            'settings',
            'fullscreen',
          ],
          youtube: {
            noCookie: true,
            rel: 0,
            showinfo: 0,
            iv_load_policy: 3,
            modestbranding: 1,
            customControls: true,
          },
          keyboard: { focused: false, global: false },
          tooltips: { controls: false, seek: true },
          seekTime: 10,
          invertTime: false,
        });
      }
    };

    initializePlayer();

    /** Limpar o player quando o componente for desmontado */
    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [url, timeWached]);

  return (
    <S.Container>
      {url && (
        <div
          key={url}
          className="player"
          ref={videoPlayer}
          data-plyr-provider="youtube"
          data-plyr-embed-id={url ? extractYouTubeVideoId(url) : undefined}
        ></div>
      )}
    </S.Container>
  );
}
