import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import ClearIcon from '@mui/icons-material/Clear';

import * as S from './styles';
import { Button, IconButton, Modal } from '@mui/material';
import { authenticatedRequest } from '../../../../services/api/student/api.service';

interface IAdvertising {
  id: number;
  url: string;
  title: string;
}

interface Props {
  advertising: IAdvertising[];
}

export default function ModalVideo({ advertising }: Props) {
  const [open, setOpen] = useState(true);

  const cleanStorageVideo = () => {
    const newStorage = JSON.parse(localStorage.getItem('alunoStorage')!);
    newStorage.videos = [];

    localStorage.setItem('alunoStorage', JSON.stringify(newStorage));
  };

  const handleConfirm = async () => {
    const data = { video_id: advertising[0].id };
    const response = await authenticatedRequest(
      '/student/dashboard/dismiss-video',
      'POST',
      data,
    );

    if (response.result) {
      cleanStorageVideo();
    }
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-video"
      aria-describedby="modal-video-description"
      sx={{ backgroundColor: 'rgba(0, 0, 0, .6)' }}
    >
      <S.BoxStyle sx={{ p: 4 }}>
        <S.Header>
          <IconButton
            sx={{ marginBottom: '5px' }}
            onClick={() => setOpen(false)}
          >
            <ClearIcon sx={{ color: '#fff', fontSize: '20px' }} />
          </IconButton>
        </S.Header>
        <S.ContainerVideo>
          <ReactPlayer
            controls={true}
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
            url={advertising[0].url}
          />
        </S.ContainerVideo>
        <S.Footer>
          <Button onClick={handleConfirm} variant="contained">
            Não exibir novamente
          </Button>
        </S.Footer>
      </S.BoxStyle>
    </Modal>
  );
}
