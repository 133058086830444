export default {
  title: 'contrast',

  gray: 0,
  colors: {
    primary: '#FF8E42',
    secondary: '#B85514',
    disable01: '#44230E',
    success: '#8536BC',
    success02: '#FF4563',
    aqua: '#FF4563',
    disable02: '#3E0A62',
    danger: '#00A599',
    danger02: '#036ED6',
    disable03: '#0B2B29',
    warning: '#0D6BDC',
    tertiary: '#0613DD',
    attention: '#6CAB19',
    detail: '#02742E',
    gray01: '#0D0D0D',
    gray02: '#171717',
    gray03: '#6A6A6A',
    gray04: '#8F8F8F',
    disable04: '#191919',
    backgroundColor: '#000000',
    text: {
      primary: '#8F8F8F',
      secondary: '#6A6A6A',
      tertiary: '#000000',
      gray05: '#191919',
      attention: '#6CAB19',
      danger: '#00A599',
      danger03: '#0B2B29',
      success: '#8536BC',
      success02: '#3E0A62',
      aqua: '#3E0A62',
      info01: '#B85514',
      info02: '#FF8E42',
      info03: '#44230E',
    },
  },
};
