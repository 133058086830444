import styled from 'styled-components';

export const Container = styled.section`
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1.5rem 0;
  }

  > section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;

    .message {
      margin: 0 3rem 0;
    }

    h4 {
      font-weight: 600;
      line-height: normal;
      font-size: 20px;
    }
  }
  svg {
    font-size: 5rem;
  }

  section.success {
    background-color: ${({ theme }) => theme.alert.success.main};
    color: ${({ theme }) => theme.fontColor.success.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.success.main};
  }
  section.info {
    background-color: ${({ theme }) => theme.alert.info.main};
    color: ${({ theme }) => theme.fontColor.info.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.info.main};
  }
  section.warning {
    background-color: ${({ theme }) => theme.alert.warning.main};
    color: ${({ theme }) => theme.fontColor.warning.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.warning.main};
  }
  section.danger {
    background-color: ${({ theme }) => theme.alert.danger.main};
    color: ${({ theme }) => theme.fontColor.white.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.danger.main};
  }
`;
