import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const HeadPage = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 150px;

  @media screen and (max-width: 525px) {
    overflow: hidden;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem 5rem 0;

  @media screen and (max-width: 380px) {
    flex-direction: column;

    button {
      margin-top: 1rem;
    }
  }
`;
