import { TabPanel } from '@mui/lab';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;

  .MuiTabs-indicator {
    display: none;
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  background-color: ${({ theme }) => theme.colors?.gray01};
  min-height: 40rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    min-height: 100%;
  }
`;
