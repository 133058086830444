import { IDataCourseStudent } from '../../../components/newStudent/MinimumRequirements';
import { defaultReturn } from '../../../util/newStudent';
import * as apiService from '../../api/newStudent/apiStudent.service';

interface IDataCertificateShow {
  data: {
    certificates: ICertificate[];
    requirements: IDataRequirement;
  };
  message: string | string[];
  result: boolean;
}

interface IDataCourseStudentShow {
  data: IDataCourseStudent;
  message: string | string[];
  result: boolean;
}

export interface ICertificate {
  courseName: string;
  courseType: string;
  registrationId: number;
  status: string;
  type: string;
  date: string;
  observation: string;
  url: string;
}

export interface IDataRequirement {
  title: string;
  data: IRequirement[];
}

interface IRequirement {
  title: string;
  status: string;
}

export const certificateServiceNewStudent = {
  async show(body: { type: number }): Promise<IDataCertificateShow> {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/certificate/show',
        'POST',
        body,
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async requirements(courseId: number): Promise<IDataCourseStudentShow> {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/certificate/certificate-requirements/' + courseId,
        'POST',
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async requestCertificate(
    body: {
      sendType?: 'CORREIOS' | 'RETIRADA' | null;
      secondCopy?: boolean | null;
      concrete?: boolean | null;
      digital?: boolean | null;
      paymentType: string;
    },
    courseId: number,
  ): Promise<{
    data: string | boolean;
    result: boolean;
    message: string | string[];
  }> {
    try {
      const request = await apiService.authenticatedRequest(
        '/student/certificate/request-certificate/' + courseId,
        'POST',
        body,
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },
};
