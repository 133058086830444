import React, { ReactNode } from 'react';

import * as S from './styles';
import ButtonSimple from '../ButtonSimple';

interface Props {
  title: ReactNode;
  color?: 'primary' | 'gray' | 'success' | 'danger';
  heightSize?:
    | 'small'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'fullHeight';
  widthSize?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'lsmall'
    | 'medium'
    | 'xmedium'
    | 'large'
    | 'xlarge'
    | 'fullWidth';
  onClick?: () => void;
  disabled?: boolean;
  open?: boolean;
  noIcon?: boolean;
  titleClose?: string;
  styles?: object;
  font?: 'p' | 'b' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export default function ButtonOpen({
  title,
  color = 'primary',
  heightSize = 'small',
  widthSize = 'xmedium',
  onClick,
  disabled,
  open,
  noIcon,
  titleClose,
  styles,
  font,
}: Props) {
  return (
    <S.Container>
      <ButtonSimple
        color={color}
        font={font}
        heightSize={heightSize}
        widthSize={widthSize}
        onClick={() => {
          onClick && onClick();
        }}
        disabled={disabled}
        icon={
          !noIcon &&
          (open ? (
            <img src="/assets/icons/arrowUp.png" width="20px" />
          ) : (
            <img src="/assets/icons/arrowDown.png" width="20px" />
          ))
        }
        styles={styles}
      >
        {titleClose ? (open ? titleClose : title) : title}
      </ButtonSimple>
    </S.Container>
  );
}
