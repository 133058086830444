import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 3rem 5rem;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-top: 2rem;
  gap: 1rem;

  width: 100%;
`;
