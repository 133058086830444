import styled from 'styled-components';

export const StyledTable = styled.table<Props>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: ${(props) => (!props.noBorder ? '1px solid #ddd' : 'none')};
  border-radius: 12px;

  tr td:last-child,
  tr th:last-child {
    border-right: none;
  }
  tr:last-child > td {
    border-bottom: none;
  }
`;

export const Tr = styled.tr``;

interface Props {
  first?: boolean;
  last?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
  align?: 'center' | 'left' | 'right' | 'justify' | 'char' | string;
}
export const StyledTh = styled.th<Props>`
  text-align: ${(props) => props.align};
  padding: ${(props) => (!props.noPadding ? '1.5rem 2rem' : '0')};
  color: ${({ theme }) => theme.colors?.text.secondary};

  border-bottom: ${(props) => (!props.noBorder ? '1px solid #ddd' : 'none')};
  border-right: ${(props) => (!props.noBorder ? '1px solid #ddd' : 'none')};
`;

export const StyledTd = styled.td<Props>`
  /* text-align: ${(props) => props.align}; */
  padding: ${(props) => (!props.noPadding ? '0.5rem 2rem' : '0')};

  color: ${({ theme }) => theme.colors?.text.secondary};
  border-bottom: ${(props) => (!props.noBorder ? '1px solid #ddd' : 'none')};
  border-right: ${(props) => (!props.noBorder ? '1px solid #ddd' : 'none')};
`;

export const H6 = styled.h6`
  font-weight: bold;
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const NotFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
