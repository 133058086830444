import styled from 'styled-components';

export const ContainerTerm = styled.div`
  padding: 0.8rem 0;
`;

export const Load = styled.div`
  padding-top: 20rem;
`;

export const Alert = styled.div`
  padding-top: 2rem;
`;
