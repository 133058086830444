import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';

import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import { ButtonNext } from '../../../components/newStudent/Buttons';
import { AlertBar } from '../../../components/newStudent/Alert';
import Banner from '../../../components/newStudent/Banner';
import { CardDashboard, CardNews } from './components';
import {
  company,
  linkFaqGraduation,
  linkFaqPosGraduation,
  linkHandBookGraduation,
  linkHandBookPosGraduation,
  studentArea,
} from '../../../variables';
import { useTheme } from 'styled-components';
import { handleMaxWidth } from '../../../util/newStudent';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { bannerServiceNewStudent } from '../../../services/newStudent';
import { IBanners } from '../../../services/newStudent/banner/banner.service';
import { addDataCache, getDataCache } from '../../../util/common/appUtil';
import ModalVideo from '../../../components/newStudent/Modal/ModalVideo';

export default function HomeNewStudent() {
  const { student, accessedAs, videoShow, loading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [modalVideoOpen, setModalVideoOpen] = useState(true);

  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [banners, setBanners] = useState<IBanners>();
  useEffect(() => {
    if (videoShow) {
      setModalVideoOpen(true);
    } else {
      setModalVideoOpen(false);
    }
  }, [videoShow]);

  useEffect(() => {
    const load = async () => {
      const bannersData = await getDataCache('bannersData', pathname);
      if (bannersData) {
        setBanners(bannersData);
      } else {
        const { data } = await bannerServiceNewStudent.getAll();

        const newData = {
          desktop: data.desktop?.filter((item) => !!item.urlImage),
          mobile: data.mobile?.filter((item) => !!item.urlImage),
        };

        addDataCache('bannersData', newData, pathname);
        setBanners(newData);
      }
    };
    load();
  }, [student]);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [loading, accessedAs]);

  return (
    <S.Container>
      <TitlePage
        title="Seja bem-vindo ao seu portal"
        titlePage="Início"
        subtitle={
          <S.SubtitleTitle>
            <h6>
              Aqui, você tem acesso à toda sua jornada acadêmica. <br />
              Todas as informações sobre sua rotina estudantil estão no Manual
              do Aluno.
            </h6>
            <ButtonNext
              color="gray"
              outline
              upperCase
              style={{ alignSelf: 'end' }}
              onClick={() => window.open(studentArea)}
            >
              Manual do aluno
            </ButtonNext>
          </S.SubtitleTitle>
        }
      />
      <Banner
        banners={
          handleMaxWidth(theme.size?.medium)
            ? banners?.mobile
            : banners?.desktop
        }
      />
      {student?.pendingDocument && (
        <AlertBar
          color="danger"
          text="Há pendências em sua documentação. Não perca tempo e envie suas cópias."
          button={
            theme.size?.large && handleMaxWidth(theme.size?.large) ? (
              <ButtonNext
                color="danger"
                border
                widthSize="xmedium"
                onClick={() => navigate('/new/aluno/documentos')}
              >
                Envie
              </ButtonNext>
            ) : (
              <ButtonNext
                color="danger"
                border
                onClick={() => navigate('/new/aluno/documentos')}
              >
                Envie agora
              </ButtonNext>
            )
          }
        />
      )}
      <S.CardContainer>
        <CardDashboard />
      </S.CardContainer>
      <S.CardContainer>
        <CardNews />
      </S.CardContainer>
      <AlertBar
        color="tertiary"
        text="Para saber sobre Prazos de Entrega e Orientações acesse os links abaixo para mais informações."
      />
      <S.Docs>
        <S.A href={linkHandBookPosGraduation} target="_blank">
          Manual Estudantil - Pós-graduação
        </S.A>
        {company?.toLocaleLowerCase() !== 'ipb' && (
          <>
            <S.Divider className="divider">|</S.Divider>
            <S.A href={linkHandBookGraduation} target="_blank">
              Manual Estudantil - Graduação
            </S.A>
          </>
        )}
        <S.Divider className="divider">|</S.Divider>
        <S.A href={linkFaqPosGraduation} target="_blank">
          FAQ - Pós-graduação
        </S.A>
        {company?.toLocaleLowerCase() !== 'ipb' && (
          <>
            <S.Divider className="divider">|</S.Divider>

            <S.A href={linkFaqGraduation} target="_blank">
              FAQ - Graduação
            </S.A>
          </>
        )}
      </S.Docs>
      {!accessedAs && (
        <ModalVideo
          advertising={videoShow}
          isOpen={modalVideoOpen}
          setIsOpen={setModalVideoOpen}
          showFooter
        />
      )}
    </S.Container>
  );
}
