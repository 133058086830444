import React, { Dispatch, SetStateAction, useContext } from 'react';

import * as S from '../Login/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import LockIcon from '@mui/icons-material/Lock';

import FieldFormik from '../../../../../components/newStudent/FieldFormik';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { authNewStudentService } from '../../../../../services/auth';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';

interface Props {
  setCreateFirstPassword: Dispatch<SetStateAction<boolean>>;
  fieldLogin: string;
  setFieldLogin: Dispatch<SetStateAction<string>>;
}
export default function CreateFirstPassword({
  setCreateFirstPassword,
  fieldLogin,
  setFieldLogin,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const validationSchemaCreate = Yup.object({
    newPassword: Yup.string().required('A nova senha é obrigatória.'),
    passwordConfirmation: Yup.string()
      .required('A confirmação da senha é obrigatória.')
      .oneOf([Yup.ref('newPassword'), ''], 'As senhas devem ser iguais.'),
  });

  const initialValuesCreate = {
    newPassword: '',
    confirmationPassword: '',
  };

  const handleSubmit = async (value) => {
    try {
      setIsLoading(true);
      const { result } = await authNewStudentService.createFirstPassword({
        login: fieldLogin,
        newPassword: value.newPassword,
        passwordConfirmation: value.passwordConfirmation,
      });
      if (result) {
        setCreateFirstPassword(false);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <Formik
        initialValues={initialValuesCreate}
        validationSchema={validationSchemaCreate}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit }) => (
          <S.ContainerForm className="create_password">
            <S.ContainerTitle>
              <S.Title>Crie sua senha de acesso!</S.Title>
            </S.ContainerTitle>
            <S.StyledForm className="create_password">
              <S.FormFields>
                <FieldFormik
                  name={'newPassword'}
                  type={'password'}
                  widthSize="fullWidth"
                  heightSize="large"
                  placeholder="Nova Senha"
                  onChange={handleChange}
                  leftIcon={<LockIcon />}
                  error
                />
                <FieldFormik
                  name={'passwordConfirmation'}
                  type={'password'}
                  widthSize="fullWidth"
                  heightSize="large"
                  placeholder="Confirme sua Senha"
                  onChange={handleChange}
                  leftIcon={<LockIcon />}
                  error
                />
                <S.Forgot>
                  <S.A
                    onClick={() => {
                      setFieldLogin('');
                      setCreateFirstPassword(false);
                    }}
                  >
                    Cancelar
                  </S.A>
                </S.Forgot>
              </S.FormFields>
              <S.ButtomContainer>
                <S.P>
                  A senha deve conter de 8 à 20 caracteres com uma combinação de
                  letras maiúsculas e minúsculas, números e símbolos. (A senha
                  não pode conter ponto, espaço e aspas).
                </S.P>
                <ButtonSimple
                  type="submit"
                  color="primary"
                  font="h4"
                  heightSize="large"
                  onClick={handleSubmit}
                >
                  Criar
                </ButtonSimple>
              </S.ButtomContainer>
            </S.StyledForm>
          </S.ContainerForm>
        )}
      </Formik>
    </S.Container>
  );
}
