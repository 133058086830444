import React, { Dispatch, SetStateAction, useState } from 'react';

import * as S from './styles';

import BulletPoint from '../../../../../components/newStudent/BulletPoint';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { IDocumentFiles, IDocuments } from '../..';
import ButtonOpen from '../../../../../components/newStudent/Buttons/ButtonOpen';
import { handleMaxWidth } from '../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import DocumentsLayoutDesktop from './components/DocumentsLayoutDesktop';
import DocumentsLayoutMobile from './components/DocumentsLayoutMobile';
import TitleDialogMobile from '../../../../../components/newStudent/Title/TitleDialogMobile';

export interface FileItem {
  file: File;
  id?: number;
  url?: string;
}

export interface NamedItem {
  file: {
    name: string;
  };
  id?: number;
  url?: string;
}
interface Props {
  data: IDocuments;
  index: number;
  documentType: number;
  open: number | undefined;
  setOpen: Dispatch<SetStateAction<number | undefined>>;
}
export default function InputFile({
  data,
  index,
  open,
  setOpen,
  documentType,
}: Props) {
  const [oldFiles, setOldFiles] = useState<IDocumentFiles[] | []>(
    data.analysis,
  );
  const [selectedFiles, setSelectedFiles] = useState<FileItem[] | NamedItem[]>([
    { file: { name: '' } },
  ]);
  const [textToOpen, setTextToOpen] = useState<number | undefined>(undefined);
  const status = {
    Rejeitado: 'danger',
    Pendente: 'danger',
    'Em Análise': 'primary',
    Aprovado: 'success',
    Concluído: 'success',
  };
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [wasChanged, setWasChanged] = useState<boolean>(false);

  const verifyStatus = () => {
    if (wasChanged) {
      if (oldFiles.length <= 0 && selectedFiles.length === 1) {
        return 'Pendente';
      }
      return 'Em Análise';
    } else {
      return data.status;
    }
  };
  return (
    <S.Container>
      <S.Content>
        <S.ContentBullet>
          <BulletPoint
            color={status[verifyStatus()]}
            flexDirection={
              handleMaxWidth(theme.size?.medium) ? 'column' : 'row'
            }
            buttons={
              <S.Buttons>
                <ButtonSimple
                  noButton
                  color={status[verifyStatus()]}
                  outline
                  heightSize="small"
                  widthSize="medium"
                >
                  {verifyStatus()}
                </ButtonSimple>
                <ButtonOpen
                  color={
                    status[data.status] === 'success' ? 'success' : 'primary'
                  }
                  heightSize="small"
                  widthSize="lsmall"
                  onClick={() => {
                    handleMaxWidth(theme.size?.medium)
                      ? setIsDialogOpen(true)
                      : setIsDialogOpen(false);
                    setOpen(open !== index ? index : undefined);
                  }}
                  title="Abrir"
                  titleClose="Fechar"
                  open={open === index}
                />
              </S.Buttons>
            }
          >
            <S.DocumentName>{data.name}</S.DocumentName>
          </BulletPoint>
          {open === index &&
            (handleMaxWidth(theme.size?.medium) ? (
              <BasicDialog
                fullScreen
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
              >
                <TitleDialogMobile
                  title={'Documentos'}
                  setIsOpen={setIsDialogOpen}
                  onClose={() => setOpen(-1)}
                />

                <DocumentsLayoutMobile
                  status={status[verifyStatus()]}
                  statusDescription={verifyStatus()}
                  title={data.name}
                  documentType={documentType}
                  oldFiles={oldFiles}
                  setOldFiles={setOldFiles}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  textToOpen={textToOpen}
                  setWasChanged={setWasChanged}
                  setTextToOpen={setTextToOpen}
                />
              </BasicDialog>
            ) : (
              <DocumentsLayoutDesktop
                status={status[verifyStatus()]}
                documentType={documentType}
                oldFiles={oldFiles}
                setOldFiles={setOldFiles}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                textToOpen={textToOpen}
                setTextToOpen={setTextToOpen}
                setWasChanged={setWasChanged}
              />
            ))}
        </S.ContentBullet>
      </S.Content>
    </S.Container>
  );
}
