import { errorToast, successToast } from '../../../components/common/Toast';

export function toastMessage(message: string | string[], success?: boolean) {
  if (message && message.length <= 0) return;

  if (typeof message === 'string')
    return message && (success ? successToast(message) : errorToast(message));
  else
    return message.forEach((text) => {
      message && (success ? successToast(text) : errorToast(text));
    });
}

export function defaultReturn(request) {
  if (request.result) {
    toastMessage(request.message, request.result);
    return request;
  } else {
    toastMessage(request.message);
    return request;
  }
}
