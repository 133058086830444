export function numberToReal(number) {
  if (number === undefined || isNaN(number)) {
    return 'R$0,00';
  }
  return parseFloat(number).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
}
