import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const DivKeySelectType = styled.div`
  width: 15rem;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const Key = styled.h6`
  color: ${(props) => props.theme.colors?.text.primary};
  font-weight: bold;
`;

export const Value = styled.h6`
  color: ${(props) => props.theme.colors?.text.secondary};
`;

export const KeyValue = styled.div`
  display: flex;

  align-items: baseline;
  gap: 0.5rem;
`;

export const DivCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors?.text.secondary};

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    display: flex;

    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
`;

export const DivFinalReport = styled.div`
  width: 30rem;
`;

export const DivDismissInternship = styled.div`
  width: 26rem;
`;

export const SelectType = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
`;

export const A = styled.a`
  cursor: pointer;

  color: ${(props) => props.theme.colors?.text.info02};
`;

export const InputFile = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors?.primary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors?.backgroundColor};

  cursor: pointer;
  margin-top: 0.5rem;

  max-width: 15rem;
  width: 100%;
  height: 3rem;

  input[type='file'] {
    display: none;
  }
`;

export const DivWarning = styled.div``;

export const TextWarning = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Text = styled.h6`
  max-width: 19rem;
  width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
`;

export const ContainerFile = styled.div`
  display: flex;

  flex-direction: row;

  align-items: center;
  gap: 1rem;
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.danger};
`;

export const ButtonSave = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors?.text.info01};

  gap: 1rem;

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    flex-direction: column;
  }
`;

export const DivSelect = styled.div`
  display: flex;
  gap: 1rem;
`;

export const DivReaded = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  gap: 0.5rem;

  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const DialogComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 2rem;
  margin: 3rem 2rem;
`;

export const KeyValueDialog = styled.div`
  display: flex;
  flex-direction: column;

  align-items: baseline;
`;

export const DialogTitle = styled.h4``;

export const DialogTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;
