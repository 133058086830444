import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';

import WarningIcon from '@mui/icons-material/Warning';

import { ButtonSimple } from '../../Buttons';
import { BasicDialog } from '../../Dialog';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  count: number;
  minimum: number;
}
export default function AlertExamminimumChar({
  isOpen,
  setIsOpen,
  count,
  minimum,
}: Props) {
  return (
    <S.Container>
      <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <S.Header>
          <S.ContentHeader>
            <WarningIcon fontSize="large" />
            <S.H4Title>Atenção!</S.H4Title>
          </S.ContentHeader>
        </S.Header>
        <S.Content>
          <S.H6>
            A prova está com {count} caracteres, mas deve ter no mínimo{' '}
            {minimum} caracteres.
          </S.H6>
          <S.Button>
            <ButtonSimple
              color="primary"
              widthSize="large"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Voltar
            </ButtonSimple>
          </S.Button>
        </S.Content>
      </BasicDialog>
    </S.Container>
  );
}
