import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import * as S from './styles';
import FieldFormik from '../../../FieldFormik';
import { ButtonSimple } from '../../../Buttons';
import { confirmation } from '../../../../../services/newStudent/myData/myData.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../ProviderNewStudent';

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  newField: { email?: string; phone?: string | number };
}

export default function InputDataConfirm({ setIsOpen, newField }: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [code, setCode] = useState('');

  const handleSend = async () => {
    setIsLoading(true);
    try {
      await confirmation.confirmCode({
        ...newField,
        code: code,
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <S.H5>Campo de confirmação</S.H5>
      <FieldFormik
        type="string"
        placeholder="Insira o código"
        name="code"
        onChange={(e) => setCode(e.target.value)}
        widthSize="fullWidth"
        heightSize="medium"
      />
      <S.ContainerButton>
        <ButtonSimple
          heightSize="medium"
          outline
          color="gray"
          onClick={() => setIsOpen(false)}
        >
          Cancelar
        </ButtonSimple>
        <ButtonSimple
          heightSize="medium"
          type="submit"
          color="primary"
          onClick={() => handleSend()}
        >
          Confirmar
        </ButtonSimple>
      </S.ContainerButton>
    </S.Container>
  );
}
