import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 0;
  gap: 2rem;
`;

export const TableH6 = styled.h6`
  padding: 1rem;
`;

export const TitleH6 = styled.h6`
  font-weight: bold;

  padding: 1rem;
  font-size: 15px;
`;

export const TableDivButton = styled.div`
  padding: 1rem;
`;

export const TableDiv = styled.div`
  width: 20rem;
`;
