import React, { useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import { Container } from './styles';

interface ModalCustomProps {
  newValue: string;
  modalOpen: boolean;
  title: string;
  text: string;
}

export default function ModalCustom({
  newValue,
  modalOpen,
  title,
  text,
}: ModalCustomProps) {
  const [open, setOpen] = useState<boolean>(modalOpen);

  const handleClearSearch = () => {
    const win = window.open();
    if (win) {
      win.window.location.href = newValue;
      win.focus();
    }
    setOpen(false);
    window.location.reload();
  };

  return (
    <Container
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      style={{
        position: 'fixed',
        display: 'flex',
        margin: 'auto',
      }}
    >
      <Header icon="archive" content={title} />
      <Modal.Content>
        <p>{text}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => setOpen(false)}>
          <Icon name="remove" /> Não
        </Button>
        <Button color="green" onClick={() => handleClearSearch()}>
          <Icon name="checkmark" /> Sim
        </Button>
      </Modal.Actions>
    </Container>
  );
}
