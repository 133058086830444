import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 2px;
  align-items: center;
  margin-bottom: 5px;

  > section {
    display: flex;
    min-height: 4.5rem;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: flex-start;
    border-radius: 3px;
    text-shadow: none;
  }

  section.default {
    border-left: solid 5px ${({ theme }) => theme.fontColor.default.main};
    background-color: ${({ theme }) => theme.alert.default.main};
    color: ${({ theme }) => theme.fontColor.default.main};
  }
  section.success {
    border-left: solid 5px ${({ theme }) => theme.fontColor.success.main};
    background-color: ${({ theme }) => theme.alert.success.main};
    color: ${({ theme }) => theme.fontColor.success.main};
  }
  section.info {
    border-left: solid 5px ${({ theme }) => theme.fontColor.info.main};
    background-color: ${({ theme }) => theme.alert.info.main};
    color: ${({ theme }) => theme.fontColor.info.main};
  }
  section.warning {
    border-left: solid 5px ${({ theme }) => theme.fontColor.warning.main};
    background-color: ${({ theme }) => theme.alert.warning.main};
    color: ${({ theme }) => theme.fontColor.warning.main};
  }
  section.danger {
    border-left: solid 5px ${({ theme }) => theme.fontColor.danger.main};
    background-color: ${({ theme }) => theme.alert.danger.main};
    color: ${({ theme }) => theme.fontColor.white.main};
  }
`;
