import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  gap: 1rem;

  width: 35rem;

  padding: 2rem 3rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 30rem;
    padding: 2rem;
  }
`;

export const H5 = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const ContainerButton = styled.div`
  display: flex;

  flex-direction: row;
  gap: 1rem;

  width: 100%;
`;
