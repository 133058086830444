import styled from 'styled-components';

export const ButtonStyle = styled.button`
  border-radius: 2px;
  display: inline-block;
  font-weight: 700;
  height: 30px;
  width: 30px;
  padding: 2px;
  color: #6d6a69;
  border-color: #bfbfbf;
`;
