import React, { ReactNode, useContext } from 'react';
import * as S from './styles';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../ProviderNewStudent';

interface IItem {
  noPadding?: boolean;
  item?: ReactNode | string | number;
  style?: object;
  align?: 'center' | 'left' | 'right' | 'justify' | 'char' | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface TableItem {
  [key: string]: IItem;
}

export interface ITableData {
  title?: string[] | IItem[] | undefined;
  content?: TableItem[];
}
interface Props {
  data: ITableData;
  titleNotFound?: string;
  titleLoading?: boolean;
  isLoading?: boolean;
  noBorder?: boolean;
  unableNotFound?: boolean;
  exception?: string[] | [];
}

export default function TableBasic({
  data,
  titleNotFound,
  titleLoading,
  exception = ['fieldFormTake'],
  noBorder,
  unableNotFound,
}: Props) {
  const { loading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const { title, content } = data;

  if (!content || content?.length <= 0) {
    if (unableNotFound) return;
    return (
      <>
        <S.NotFound>
          <h6>
            {titleLoading || loading
              ? 'Carregando...'
              : titleNotFound
              ? titleNotFound
              : 'Nenhum dado encontrado.'}
          </h6>
        </S.NotFound>
      </>
    );
  }

  return (
    <S.StyledTable noBorder={noBorder}>
      {title && title.length > 0 && (
        <thead>
          <S.Tr>
            {title.map((item, index) => (
              <>
                {typeof item === 'string' ? (
                  <S.StyledTh key={index} noBorder={noBorder}>
                    <S.H6>{item}</S.H6>
                  </S.StyledTh>
                ) : (
                  <S.StyledTh
                    key={index}
                    align={item?.align}
                    noPadding={item?.noPadding}
                    noBorder={noBorder}
                    style={item.style}
                  >
                    {item.item}
                  </S.StyledTh>
                )}
              </>
            ))}
          </S.Tr>
        </thead>
      )}
      <tbody>
        {content?.map((row, rowIndex) => (
          <>
            <tr key={rowIndex}>
              {Object.keys(row).map(
                (key, colIndex) =>
                  exception.every((out) => key !== out) && (
                    <S.StyledTd
                      align={row[key]?.align}
                      noPadding={row[key]?.noPadding}
                      key={colIndex}
                      noBorder={noBorder}
                      style={row[key]?.style}
                    >
                      {row[key]?.item}
                    </S.StyledTd>
                  ),
              )}
            </tr>
          </>
        ))}
      </tbody>
    </S.StyledTable>
  );
}
