import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { ButtonSimple } from '../../../../../components/agent/Buttons';

import * as S from './styles';
import { Form } from 'react-bootstrap';
import FormField from '../../../../../components/agent/FormField';
import {
  IAgentContextProps,
  ProviderAgentContext,
} from '../../../../../components/agent/ProviderAgent';
import { saleNegotiationService } from '../../../../../services/agent';
import { IFiltered } from '../../../../../services/agent/sale/interface';

interface Props {
  setFiltered: Dispatch<SetStateAction<IFiltered[] | undefined>>;
  allowFetch?: boolean;
}

export default function Filter({ setFiltered, allowFetch }: Props) {
  const context = useContext(ProviderAgentContext);
  const { agentLogged, setIsLoading } = context as IAgentContextProps;

  const today = new Date();

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const formattedStartOfMonth = startOfMonth.toISOString().slice(0, 10);

  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const formattedEndOfMonth = endOfMonth.toISOString().slice(0, 10);

  const [error, setError] = useState('');
  const [form, setForm] = useState({
    name: undefined,
    email: undefined,
    cpf: undefined,
    startPeriod: formattedStartOfMonth,
    endPeriod: formattedEndOfMonth,
    registration: undefined,
    ordering: undefined,
    hasPeriod: true,
  });

  /** Filtro de Negociação */
  const handleFilterNegotiation = async (first?: boolean) => {
    setFiltered([]);
    setError('');

    try {
      setIsLoading(true);
      const isPeriod = form.hasPeriod
        ? { startPeriod: form.startPeriod, endPeriod: form.endPeriod }
        : { startPeriod: undefined, endPeriod: undefined };
      const body = {
        name: form.name,
        email: form.email,
        cpf: form.cpf,
        ...isPeriod,
        registration: form.registration,
        ordering: form.ordering,
      };

      const startDate = new Date(form.startPeriod);
      const endDate = new Date(form.endPeriod);

      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const differenceInDays = Math.floor(
        differenceInMilliseconds / (24 * 60 * 60 * 1000),
      );

      if (form.hasPeriod && differenceInDays > 30) {
        setError('A diferença entre as datas deve ser de pelo menos 30 dias.');
        return;
      }

      const request = await saleNegotiationService.filterSales(body, first);

      if (request?.result) {
        setFiltered(request?.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!allowFetch) {
      handleFilterNegotiation(true);
    }
  }, [agentLogged, allowFetch]);

  const imputFormName = [
    {
      title: 'Nome',
      name: 'name',
      placeholder: 'Nome',
      type: 'string',
      value: form.name,
      handleChange: (e) => {
        setForm((old) => ({
          ...old,
          name: e.target.value,
        }));
      },
    },
    {
      title: 'Email',
      name: 'email',
      placeholder: 'E-mail',
      type: 'string',
      value: form.email,
      handleChange: (e) => {
        setForm((old) => ({
          ...old,
          email: e.target.value,
        }));
      },
    },
    {
      title: 'CPF',
      name: 'cpf',
      placeholder: 'CPF',
      type: 'number',
      value: form.cpf,
      handleChange: (e) => {
        setForm((old) => ({
          ...old,
          cpf: e.target.value,
        }));
      },
    },
  ];

  const period = {
    start: {
      title: (
        <>
          <input
            style={{ margin: 0 }}
            checked={!!form.hasPeriod}
            onChange={(e) =>
              setForm((old) => ({
                ...old,
                hasPeriod: e.target.checked,
                startPeriod: !e.target.checked
                  ? formattedStartOfMonth
                  : old.startPeriod,
                endPeriod: !e.target.checked
                  ? formattedEndOfMonth
                  : old.endPeriod,
              }))
            }
            type="checkbox"
          />
          <b> Período de Cadastro</b>
        </>
      ),
      name: 'startPeriod',
      type: 'date',
      disabled: !form.hasPeriod,
      value: form.startPeriod,
      isInvalid: !!error,
      handleChange: (e) => {
        setForm((old) => ({
          ...old,
          startPeriod: e.target.value,
        }));
      },
      style: { marginBottom: '2rem' },
    },
    end: {
      name: 'endPeriod',

      type: 'date',
      disabled: !form.hasPeriod,
      value: form.endPeriod,
      isInvalid: !!error,
      handleChange: (e) => {
        setForm((old) => ({
          ...old,
          endPeriod: e.target.value,
        }));
      },
      style: {},
    },
  };

  const registrationStructure = [
    {
      title: 'Sit. Matrícula',
      name: 'registration',
      type: 'select',
      value: form.registration,
      handleChange: (e) => {
        setForm((old) => ({
          ...old,
          registration: e.target.value,
        }));
      },
      options: [
        { value: 0, title: 'Todos' },
        { value: 1, title: 'Aberta' },
        { value: 2, title: 'Liquidada' },
      ],
      style: { heigth: '320px' },
    },
    {
      title: 'Ordenação',
      name: 'ordering',
      type: 'select',
      value: form.ordering,
      handleChange: (e) => {
        setForm((old) => ({
          ...old,
          ordering: e.target.value,
        }));
      },
      options: [
        { value: '', title: '[ SELECIONAR ]' },
        { value: 'name', title: 'Nome' },
        { value: 'register', title: 'Data de Cadastro' },
      ],
    },
  ];

  return (
    <S.Container>
      <S.RowForm>
        <S.RowName>
          <S.Item>
            {imputFormName.map((e, i) => (
              <FormField item={e} key={i} />
            ))}
          </S.Item>
        </S.RowName>
      </S.RowForm>
      <S.RowForm>
        <S.Row>
          <S.ContainerRowDate>
            <S.RowDate>
              <S.ItemDate>
                <FormField item={period.start} />
              </S.ItemDate>
              <Form.Label className={'itemDate'}>até</Form.Label>
              <S.ItemDate>
                <FormField item={period.end} />
              </S.ItemDate>
            </S.RowDate>
          </S.ContainerRowDate>
          <S.Registration>
            {registrationStructure.map((item, i) => (
              <S.ItemDate key={i}>
                <FormField item={item} />
              </S.ItemDate>
            ))}
          </S.Registration>
        </S.Row>
      </S.RowForm>
      <S.ContainerFilter>
        <div>{error && <S.Error> {error} </S.Error>}</div>
        <div>
          <ButtonSimple
            onClick={() => handleFilterNegotiation(false)}
            color="blue"
            icon={<FilterAltIcon />}
            size="medium"
          >
            Filtrar
          </ButtonSimple>
        </div>
      </S.ContainerFilter>
    </S.Container>
  );
}
