import React, { ReactNode } from 'react';

import * as S from './styles';
import AlertBarSimple from '../AlertBarSimple';
import WarningIcon from '@mui/icons-material/Warning';

interface Props {
  color: 'danger' | 'warning' | 'tertiary' | 'white' | 'aqua';
  text: ReactNode;
  button?: ReactNode;
  icon?: ReactNode;
  iconColor?: 'danger' | 'warning' | 'tertiary' | 'white';
  noWarning?: boolean;
  noWarningIcon?: boolean;
  onClick?: () => void;
  pointer?: boolean;
  flexDirection?: 'column' | 'row';
}

export default function AlertBar({
  color: colorSelected = 'danger',
  text,
  button,
  noWarning,
  icon,
  iconColor,
  pointer,
  onClick,
  noWarningIcon,
  flexDirection = 'row',
}: Props) {
  {
    return (
      <AlertBarSimple
        color={colorSelected}
        style={{
          cursor: pointer ? 'pointer' : 'default',
          flexDirection: flexDirection,
        }}
        onClick={onClick && onClick}
      >
        <S.Warning>
          {<S.Icon iconColor={iconColor}>{icon}</S.Icon>}
          {!noWarningIcon && <WarningIcon fontSize="large" />}
          {typeof text === 'string' ? (
            <S.P>
              {!noWarning && <strong>Atenção!</strong>} {text}
            </S.P>
          ) : (
            <>
              {!noWarning && <strong>Atenção!</strong>} {text}
            </>
          )}
        </S.Warning>
        {button}
      </AlertBarSimple>
    );
  }
}
