import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  bottom: 0;

  height: 12rem;

  background-color: ${({ theme }) => theme.colors?.primary};
  z-index: ${(props) => props.theme.zIndex?.footer};

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    height: 20rem;
  }
`;

export const Social = styled.div`
  display: flex;
  margin-left: 8rem;

  gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    margin-left: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  width: 30rem;
`;

export const InfoImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 3rem;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    gap: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column;
  }
`;

export const Img = styled.img`
  max-width: 22rem;
`;

export const Icon = styled.img`
  cursor: pointer;
`;

export const ImgContainer = styled.div`
  margin-right: 10rem;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    margin-right: 0;
  }
`;

export const P = styled.p`
  color: ${({ theme }) => theme.colors?.text.tertiary};

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    text-align: center;
  }
`;

export const B = styled.b`
  font-size: 14px;
  color: ${({ theme }) => theme.colors?.text.tertiary};
`;
