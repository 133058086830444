import React, { ReactNode } from 'react';

import { MyTableCell, MyTableRow, RowText } from '../../../components';
// import { TableCell } from '@mui/material';

export interface BodyTableProps {
  color?: string;
  id: number;
  accountName: string;
  dueDate: string;
  dueDateValue: string;
  check?: ReactNode;
  currentValue?: string;
  currentPenalityValue?: string;
  showMulta?: boolean;
}

export default function BodyTableNegotiation({
  color,
  id,
  accountName,
  dueDate,
  dueDateValue,
  check,
  currentValue,
  currentPenalityValue,
  showMulta,
}: BodyTableProps) {
  return (
    <>
      <MyTableRow key={id}>
        <MyTableCell scope="lancamento" sx={{ color: color }}>
          <RowText>{accountName}</RowText>
        </MyTableCell>
        <MyTableCell align="center" sx={{ color: color }}>
          <RowText>{dueDate}</RowText>
        </MyTableCell>
        <MyTableCell
          className="dueBilletValue"
          align="center"
          sx={{ color: color }}
        >
          <RowText>{dueDateValue}</RowText>
        </MyTableCell>
        <MyTableCell
          className="billetValueUpdated"
          align="center"
          sx={{ color: color }}
        >
          <RowText>{currentValue}</RowText>
        </MyTableCell>
        {showMulta && (
          <MyTableCell
            className="billetValue"
            align="center"
            sx={{ color: color }}
          >
            <RowText>{currentPenalityValue}</RowText>
          </MyTableCell>
        )}

        <MyTableCell align="center" sx={{ color: color }}>
          <RowText>{check}</RowText>
        </MyTableCell>
      </MyTableRow>
    </>
  );
}
