import styled from 'styled-components';

export const Container = styled.div``;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    padding-bottom: 0.5rem;
  }
`;

interface Props {
  color?: 'danger' | 'warning' | 'success';
}
export const RowColor = styled.div<Props>`
  padding: 2rem 0;

  color: ${(props) =>
    props.theme.colors?.[props.color ? props.color : 'gray03']};
`;

export const ContentMovimentation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;

  gap: 1rem;
`;

export const ContainerFocus = styled.div`
  padding-top: 1rem;

  animation: fadeIn 1s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 1;
`;

export const ContentFocus = styled.div``;

export const DivAnexos = styled.div`
  display: flex;

  align-self: center;
  gap: 1rem;
`;

export const H6 = styled.h6``;

export const H6Break = styled.h6`
  word-break: break-word;
`;
