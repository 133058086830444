import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 0 0;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
`;

export const H5 = styled.h5`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;
