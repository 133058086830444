import styled from 'styled-components';

export const Warning = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;
  width: 100%;
  flex-direction: row;
  gap: 1rem;
  /* 
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis; */
`;

interface Props {
  iconColor?: 'danger' | 'warning' | 'tertiary' | 'white';
}
export const Icon = styled.div<Props>`
  color: ${(props) =>
    props.theme.colors?.[props.iconColor ? props.iconColor : 'gray01']};
`;

export const H6 = styled.h6`
  font-weight: bold;
  text-transform: uppercase;
`;

export const P = styled.p``;
