import React, { useState, useEffect } from 'react';
import * as S from './styles';
import ButtonPrint from '../../../../../../../components/agent/Buttons/ButtonPrint';
import { BasicTable } from '../../../../../../../components/common/Table';
import { TableItem } from '../../../../../../../interfaces/common/Table';

interface Table {
  title?: string[] | [];
  content?: TableItem[] | [];
}

export default function TableCourse() {
  const [tableData, setTableData] = useState<Table>({
    title: [],
    content: [],
  });

  useEffect(() => {
    const tableCourses = [
      {
        course: { item: 'Superior de Tecnologia em Gestão Financeira' },
        status: { item: 'Emitido - Assinado' },
        type: { item: 'Certificado Digital' },
        dateRequest: { item: 224 },
        dueDate: { item: 'Isento' },
        download: {
          item: (
            <a
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              href="https://sistema.alunodigital.com.br/DownloadArquivo.aspx?CLIENTE=69586b6644554f425663343d&ID=2f377835496f64577234733d"
            >
              <ButtonPrint />
            </a>
          ),
        },
        observation: { item: '' },
      },
    ];

    setTableData({
      title: [
        'Curso',
        'Status',
        'Tipo de Certificado',
        'Data de Solicitação',
        'Data de Pagamento',
        'Download',
        'Observação',
      ],
      content: tableCourses.map((item, index) => ({
        course: {
          item: (
            <S.RowText key={index} align="left">
              {item.course.item}
            </S.RowText>
          ),
        },
        status: {
          item: (
            <S.RowText key={index} align="left">
              {item.status.item}
            </S.RowText>
          ),
        },
        type: {
          item: (
            <S.RowText key={index} align="left">
              {item.type.item}
            </S.RowText>
          ),
        },
        dateRequest: {
          item: (
            <S.RowText key={index} align="left">
              {item.dateRequest.item} dias atrás
            </S.RowText>
          ),
        },
        dueDate: {
          item: (
            <S.RowText key={index} align="left">
              {item.dueDate.item}
            </S.RowText>
          ),
        },
        download: {
          item: item.download.item,
        },
        observation: {
          item: (
            <S.RowText key={index} align="left">
              {item.observation.item}
            </S.RowText>
          ),
        },
      })),
    });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <S.RowText color="red" style={{ paddingBottom: '1rem' }}>
        Após solicitar a emissão do seu certificado, ele ficará disponível para
        download em até 7 dias úteis.
      </S.RowText>
      <BasicTable data={tableData} />
    </div>
  );
}
