import { TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import styled from 'styled-components';

interface IRowTextProps {
  color?: string;
  align?: string;
}
interface ITableTitleRow {
  props?: {
    backgroundColor?: string;
    height?: string;
  };
}
interface ITitleTable {
  color?: string;
  align?: string;
}

interface ISubtitleTable {
  color?: string;
}

export const TitleTable = styled.b`
  text-align: ${(props: ITitleTable) => props.align || 'center'};
  color: ${(props: ITitleTable) => props.color || 'black'};
  font-size: 16px;
`;

export const SubtitleTable = styled.b`
  color: ${(props: ISubtitleTable) => props.color || 'black'};
  font-size: 14px;
`;

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MyTableRow = styled(TableRow)(
  () => ({
    '&:nth-of-type(odd)': {
      border: 'black',
    },
    '&:last-child td, &:last-child th': {
      border: 1,
    },
  }),
  `height: 5rem
  `,
);

export const RowText = styled.b<IRowTextProps>`
  text-align: ${(props) => props.align || 'center'};
  color: ${(props) => props.color};
  font-weight: normal;
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const RowTextBold = styled.p<IRowTextProps>`
  text-align: ${(props) => props.align || 'center'};
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 14px;
`;

export const MyTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableTitleRow = styled(TableRow)<ITableTitleRow>`
  background-color: ${({ props }) => props?.backgroundColor || 'white'};

  height: ${({ props }) => props?.height || '5rem'};
`;
