import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 0;
  gap: 2rem;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const TitleDiv = styled.div`
  padding: 0 2rem;
`;

export const DivData = styled.div`
  width: 100%;
  padding: 3rem 2rem;

  border: 1px solid ${({ theme }) => theme.colors?.gray03};
  border-radius: 12px;
`;

export const DivInformaiton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    grid-template-columns: 1fr;
  }
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;

  height: 2rem;
  gap: 0.5rem;
`;

export const Key = styled.h6`
  height: 2rem;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  height: 2rem;
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const TableH6 = styled.h6`
  padding: 1rem;
`;

export const TitleH6 = styled.h6`
  font-weight: bold;

  padding: 1rem;
  font-size: 15px;
`;

export const TableDivButton = styled.div`
  padding: 1rem;
`;

export const TableDiv = styled.div`
  width: 20rem;
`;
