import React from 'react';

import { Dimmer, Image, Loader, Segment } from 'semantic-ui-react';

interface Props {
  text?: string;
}

export default function LoaderComponent({ text }: Props) {
  return (
    <Segment>
      <Dimmer active inverted>
        <Loader size="large">{text}</Loader>
      </Dimmer>
      <Image src={'/img/backgrounds/short-paragraph.png'} />
    </Segment>
  );
}
