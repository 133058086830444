import styled from 'styled-components';

export const Container = styled.div`
  width: 45rem;
`;

export const Subtitle = styled.h5`
  text-align: center;
  color: ${({ theme }) => theme.colors?.text.primary};
`;

interface Props {
  justify?: 'center' | 'start';
}
export const SubtitleH6 = styled.h6<Props>`
  text-align: ${(props) => props.justify};
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const ButtonCheckbox = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors?.text.info02};
`;
