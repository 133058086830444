import styled from 'styled-components';

export const Container = styled.div``;

export const TitlePdfDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  margin: 0;
`;

export const TitleIconPdfDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.5rem;

  margin: 0;
`;

export const TitlePdfH5 = styled.h4`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const InfoDiv = styled.div`
  display: flex;
  align-items: baseline;

  gap: 0.5rem;
`;

export const Key = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
  line-height: 2rem;
  text-align: justify;
`;

export const Link = styled.h6``;
