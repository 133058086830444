import React from 'react';

import OutputIcon from '@mui/icons-material/Output';
import { Stack } from '@mui/material';

import { sieadUrl, sieadImagesFolderUrl } from '../../../../../variables';
import { ButtonIcon } from '../../../Buttons';

import * as S from './styles';

export default function Header() {
  const logo = `${sieadImagesFolderUrl}c81e728d9d4c2f636f067f89cc14862c.jpg`;

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = sieadUrl + '/agenciador/conta/logout';
  };
  return (
    <S.Container>
      <div>
        <img src={logo} alt="logo" height={'100%'} />
      </div>
      <Stack
        direction="row"
        spacing={1}
        style={{
          width: '180px',
          height: '100px',
          justifyContent: 'flex-end',
          padding: '1rem 2rem',
        }}
      >
        <ButtonIcon onClick={handleLogout} color="white">
          <OutputIcon fontSize="large" />
        </ButtonIcon>
      </Stack>
    </S.Container>
  );
}
