import styled from 'styled-components';

interface Props {
  pointer?: boolean;
}
export const Container = styled.div<Props>`
  display: flex;
  width: 100%;

  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  gap: 2rem;
  padding: 1rem 2rem;

  width: 100%;
  min-height: 4.5rem;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;

  background-color: ${({ theme }) => theme.colors?.gray01};

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    gap: 1rem;
  }
`;

interface IData {
  flexDirection: 'row' | 'column';
}
export const Data = styled.div<IData>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => props.flexDirection};
  width: 100%;
`;

interface IPoint {
  color?: 'success' | 'danger' | 'primary';
}
export const Point = styled.div<IPoint>`
  color: ${(props) => props.theme.colors?.[props.color ? props.color : '']};
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;
