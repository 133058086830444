export default {
  title: 'gray',

  gray: 1,
  colors: {
    primary: '#575757',
    secondary: '#939393',
    disable01: '#D4D4D4',
    success: '#A3A3A3',
    success02: '#7F7F7F',
    aqua: '#7F7F7F',
    disable02: '#DCDCDC',
    danger: '#8D8D8D',
    danger02: '#5F5F5F',
    disable03: '#DEDEDE',
    warning: '#A4A4A4',
    tertiary: '#DADADA',
    attention: '#777777',
    detail: '#B5B5B5',
    gray01: '#F2F2F2',
    gray02: '#E8E8E8',
    gray03: '#959595',
    gray04: '#707070',
    disable04: '#E6E6E6',
    backgroundColor: '#FFFFFF',
    text: {
      primary: '#707070',
      secondary: '#959595',
      tertiary: '#FFFFFF',
      gray05: '#E6E6E6',
      attention: '#777777',
      danger: '#8D8D8D',
      danger03: '#DEDEDE',
      success: '#A3A3A3',
      success02: '#DCDCDC',
      aqua: '#DCDCDC',
      info01: '#939393',
      info02: '#575757',
      info03: '#D4D4D4',
    },
  },
};
