import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled.div`
  padding: 1.5rem 0;
  font-size: 15px;
`;

export const Button = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;

  .css-ke5b6m-MuiButtonBase-root-MuiButton-root {
    background-color: #739e73;

    :hover {
      background-color: #659265;
    }
  }
`;
