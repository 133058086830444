import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: 4px;
  font-weight: 700;
  display: flex;
  flex-direction: row;

  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;

  svg {
    font-size: 2.5rem !important;
    margin-right: 0.4rem;
  }

  &:disabled {
    opacity: 0.5;
  }

  :hover {
    background-color: #ccc;
    opacity: 0.8;
  }

  span.default {
    color: ${({ theme }) => theme.color.default.main};
  }
  span.primary {
    color: ${({ theme }) => theme.color.primary.main};
  }
  span.success {
    color: ${({ theme }) => theme.color.success.main};
  }
  span.info {
    color: ${({ theme }) => theme.color.info.main};
  }
  span.warning {
    color: ${({ theme }) => theme.color.warning.main};
    border-color: ${({ theme }) => theme.color.warning.main};
  }
  span.danger {
    color: ${({ theme }) => theme.color.danger.main};
  }
`;

export const CustomAccordion = styled(Accordion)`
  .card {
    --bs-card-title-spacer-y: 0;
    --bs-card-cap-padding-y: 0;
    --bs-card-cap-padding-x: 0;
    --bs-card-img-overlay-padding: 0;
  }

  section {
    margin-bottom: 0;
  }
`;
