import moment, { Moment } from 'moment';
import { convertStringToMoment } from './pageUtil';

export function getFeeValuePenality(
  dueDate: string,
  penality: number = 0,
  fees: number = 0,
  installmentValue: string,
) {
  const penalityValue = getPenalityValue(
    dueDate,
    penality,
    parseFloat(installmentValue),
  );
  const feeValue = getFeeValue(dueDate, fees, parseFloat(installmentValue));

  return rounding(penalityValue + feeValue);
}

export function getPenalityValue(
  dueDate: string,
  penality: number,
  parcelValue: number,
) {
  const atualDate = new Date();

  if (
    penality <= 0 ||
    getDaysLate(convertStringToMoment(dueDate), atualDate) <= 0
  ) {
    return 0;
  }

  const decimalPenalityDecimal = penality / 100;
  const penalityValue = decimalPenalityDecimal * parcelValue;

  return rounding(penalityValue);
}

export function getFeeValue(
  dueDate: string,
  feeRate: number,
  parcelValue: number,
) {
  const dayLate = getDaysLate(
    convertStringToMoment(dueDate),
    moment().valueOf(),
  );

  if (feeRate <= 0 || dayLate <= 0) {
    return 0;
  }

  const decimalPenalityDecimal = feeRate / 100;
  const feeValue = (decimalPenalityDecimal / 30) * dayLate * parcelValue;

  return rounding(feeValue);
}

export function getDiscountValue(
  discountRate: number | undefined,
  parcelValue: string,
) {
  if (!discountRate || discountRate <= 0) {
    return 0;
  }
  discountRate = discountRate / 100;

  const discountValue = discountRate * parseFloat(parcelValue);

  return rounding(discountValue);
}

function getDayOfWeek(dueDate: Moment) {
  return moment(dueDate).weekday();
}

function getDaysLate(dueDate: Moment, reciveDate: number | Date) {
  const dayOfWeek = getDayOfWeek(dueDate);

  if (dayOfWeek === 6) {
    dueDate = dueDate.add(2, 'days');
  }

  if (dayOfWeek === 0) {
    dueDate = dueDate.add(1, 'days');
  }

  const diffInMilliseconds =
    reciveDate.valueOf() - parseFloat(dueDate.format('x'));

  return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
}

function rounding(valor: number | undefined) {
  return valor !== undefined ? Math.round(valor * 100) / 100 : 0;
}
