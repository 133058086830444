import styled from 'styled-components';

export const Container = styled.div``;

export const Item = styled.div`
  padding-left: 2rem;
`;

export const Expires = styled.h6`
  color: ${({ theme }) => theme.colors?.primary};
`;

export const DivTable = styled.div`
  padding: 1rem 0;
`;
export const ItemExpires = styled.div``;

export const DivContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  gap: 1rem;

  padding-bottom: 2rem;
`;

export const H6 = styled.h6`
  font-weight: bold;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;

  align-items: center;
  gap: 1rem;
`;

export const Title = styled.div`
  @media screen (max-width: ${(props) => props.theme.size?.medium}) {
    text-align: center;
  }
`;
