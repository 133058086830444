import React from 'react';

import * as S from './styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export interface IBanner {
  id: number;
  link: string;
  urlImage: string;
}
interface Props {
  banners?: IBanner[];
}
export default function Banner({ banners }: Props) {
  if (!banners) return;

  return (
    banners.length > 0 && (
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showArrows={banners.length > 1}
        showIndicators={banners.length > 1}
        interval={8000}
      >
        {banners.map((banner) => (
          <S.Div key={banner.id} onClick={() => window.open(banner.link)}>
            <S.Img src={banner.urlImage} />
          </S.Div>
        ))}
      </Carousel>
    )
  );
}
