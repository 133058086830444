import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from './styles';
import { BasicDialog } from '../../../../../../../components/newStudent/Dialog';
import TitleDialogMobile from '../../../../../../../components/newStudent/Title/TitleDialogMobile';
import MinimumRequirements, {
  IDataCourseStudent,
} from '../../../../../../../components/newStudent/MinimumRequirements';
import Divider from '../../../../../../../components/common/Divider';
import { certificateServiceNewStudent } from '../../../../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../components/newStudent/ProviderNewStudent';
import CertificateRequest from '../../../CoursesCommon/components/CertificateRequest';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  courseSelected?: number;
  title: string;
  setCourseId: Dispatch<SetStateAction<number>>;
}
export default function MinimumRequerimentsMobile({
  isOpen,
  setIsOpen,
  courseSelected,
  setCourseId,
  title,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const [dataStudentCourse, setDataStudentCourse] =
    useState<IDataCourseStudent>();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      try {
        if (courseSelected) {
          const { result, data } =
            await certificateServiceNewStudent.requirements(+courseSelected);
          if (result) {
            setDataStudentCourse(data);
          }
        } else setDataStudentCourse(undefined);
      } catch (error) {
        throw new Error(error);
      }
      setIsLoading(false);
    };
    load();
  }, [courseSelected]);

  return (
    <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen} fullScreen scroll="body">
      <TitleDialogMobile setIsOpen={setIsOpen} title={'Meus Certificados'} />
      <S.Container>
        <S.TitlePage>{title}</S.TitlePage>
        <Divider padding />
        {dataStudentCourse && (
          <div>
            <MinimumRequirements dataStudentCourse={dataStudentCourse} />
            <CertificateRequest
              dataStudentCourse={dataStudentCourse}
              courseId={+courseSelected!}
              setCourseId={setCourseId}
            />
          </div>
        )}
      </S.Container>
    </BasicDialog>
  );
}
