import React from 'react';

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
} from '@mui/material';

import {
  TitleTable,
  SubtitleTable,
  MyTableCell,
  TableTitleRow,
} from '../../../components';

interface Props {
  tableTitle: string;
  colorTable: string;
  children: React.ReactNode;
}

export default function HeaderTable({
  tableTitle,
  children,
  colorTable,
}: Props) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableTitleRow props={{ backgroundColor: colorTable }}>
              <MyTableCell
                sx={{ background: colorTable }}
                align="center"
                colSpan={12}
                size={'medium'}
              >
                <TitleTable color="white">{tableTitle}</TitleTable>
              </MyTableCell>
            </TableTitleRow>
            <TableTitleRow
              props={{ height: '3rem', backgroundColor: colorTable }}
            >
              <MyTableCell>
                <SubtitleTable color="white">Referência</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="left">
                <SubtitleTable color="white">Observação</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="white">Vencimento</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="white">Valor</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center">
                <SubtitleTable color="white">Status</SubtitleTable>
              </MyTableCell>
              <MyTableCell align="center"></MyTableCell>
            </TableTitleRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
