import styled from 'styled-components';

export const Container = styled.div`
  max-height: 100vh;
`;

export const TitleTable = styled.h6`
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow-y: auto;
  padding-right: 2rem;
  padding-bottom: 4rem;

  gap: 2rem;
`;

export const Div = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 1rem;
`;
export const DivName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const H4 = styled.h4`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors?.text.primary};
`;
interface IQuestion {
  inReview?: boolean;
}
export const H6Question = styled.h6<IQuestion>`
  color: ${(props) =>
    props.inReview
      ? props.theme.colors?.text.primary
      : props.theme.colors?.primary};
`;

export const CountChar = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const H6Response = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const H6 = styled.h6`
  width: 100%;

  color: ${({ theme }) => theme.colors?.text.secondary};

  @media screen and (min-width: ${(props) => props.theme.size?.large}) {
    &:before {
      content: '| ';
    }
  }
`;

export const QuestionDiv = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;

  gap: 1rem;
`;

export const DivResponse = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`;

interface IDivIsCorrect {
  isCorrect?: boolean;
}
export const DivIsCorrect = styled.h6<IDivIsCorrect>`
  color: ${(props) =>
    props.isCorrect ? props.theme.colors?.success : props.theme.colors?.danger};
`;

export const ButtonExam = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 2rem;

  width: 100%;
`;

export const Button = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 2rem;

  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    padding-top: 4rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    padding-top: 6rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  padding-top: 2rem;

  width: 100%;
`;

export const FieldDiv = styled.div`
  color: ${({ theme }) => theme.colors?.danger};
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  gap: 0.5rem;
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const DivKeyValue = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
`;

interface Props {
  color: 'danger' | 'success';
}
export const Result = styled.h6<Props>`
  color: ${(props) => props.theme.colors?.[props.color]};
  text-align: center;
`;
