import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from './style';

import BasicModal from '../Modal';
import FormField from '../FormField';
import { ButtonSimple } from '../Buttons';
import HeaderModal from '../HeaderModal';

import { ModalConfirm, ModalStudentInfo } from './components';
import {
  ICourseData,
  IItem,
  IFormDataStudent,
} from '../../../services/agent/student/interface';
import { IAgentContextProps, ProviderAgentContext } from '../ProviderAgent';
import {
  courseTypeMba,
  courseTypePosGraduation,
  company,
} from '../../../variables';
import { getPhone } from '../../../util/common/appUtil';
import { warningToast } from '../../common/Toast';
import {
  saleNegotiationService,
  studentDataService,
} from '../../../services/agent/';

interface Props {
  isOpen: boolean;
  isSearch?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
  form?: IFormDataStudent;
  defaultForm?: IFormDataStudent;
  setForm?: Dispatch<SetStateAction<IFormDataStudent>>;
  setVerifyFields?: Dispatch<SetStateAction<IFormDataStudent>>;
  newNegotiation?: IItem[] | [];
  setNewNegotiation?: Dispatch<SetStateAction<IItem[] | []>>;
  handleClose?: () => void;
}

export default function ModalSearchNegotiation({
  isOpen,
  form,
  isSearch,
  defaultForm,
  setIsOpen,
  setIsOpenModal,
  setForm,
  newNegotiation,
  setNewNegotiation,
  setVerifyFields,
  handleClose,
}: Props) {
  const [search, setSearch] = useState({ type: 'cpf', value: '' });
  const [error, setError] = useState('');

  const [isOpenDataStudent, setIsOpenDataStudent] = useState(false);
  const [openConfirmData, setOpenConfirmData] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const [tempForm, setTempForm] = useState<IFormDataStudent>();

  const context = useContext(ProviderAgentContext);
  const { setIsLoading, isLoading } = context as IAgentContextProps;

  const handleCleanNegotiation = () => {
    setDataConfirm({});
    setNewNegotiation && setNewNegotiation([]);
    setOpenConfirmData(false);
    setForm && setForm(tempForm!);
    setIsOpen(false);
  };

  const handleClean = () => {
    setDataConfirm({});
    setNewNegotiation && setNewNegotiation([]);
    setOpenConfirmData(false);
    setForm && setForm(tempForm!);
    setIsOpenModal && setIsOpenModal(false);
    setIsOpen(false);
  };

  const handleCheckPosGraduation = () => {
    const coursesForm = tempForm?.courses.content.filter(
      (item: ICourseData) =>
        +item.courseTypeId! === +courseTypePosGraduation! ||
        +item.courseTypeId! === +courseTypeMba!,
    );

    const newArray = newNegotiation?.filter(
      (itemObj: IItem) =>
        +itemObj.type.value === +courseTypePosGraduation! ||
        +itemObj.type.value === +courseTypeMba!,
    );

    if (
      (newArray ? newArray.length : 0) +
        (coursesForm ? coursesForm.length : 0) >
      5
    ) {
      warningToast(
        'Alguns itens podem ter sido removidos, pois o aluno não pode ter mais que cinco pós-graduações ativas!',
      );

      setNewNegotiation &&
        setNewNegotiation(
          newNegotiation?.filter(
            (item: IItem) =>
              !newArray?.some(
                (item2: IItem) => item2.type.value === item.type.value,
              ),
          ) || [],
        );
    }
  };

  const handleHasItems = () => {
    setDataConfirm({
      title: 'Aluno encontrado',
      name: tempForm?.dataStudent?.name,
      text: 'Deseja limpar a simulação anterior?',
      button1: { name: 'Limpar', action: () => handleCleanNegotiation() },
      button2: {
        name: 'Manter',
        action: () => {
          if (setForm && tempForm) {
            if (newNegotiation && setNewNegotiation) {
              const newArr = newNegotiation.filter(
                (item: IItem) => +item!.item.value === 1,
              );
              setNewNegotiation(newArr);
            }
            setForm(tempForm);
            handleCheckPosGraduation();
            setOpenConfirmData(false);
            setIsLoading(false);
            setIsOpen(false);
          }
          setDataConfirm({});
        },
      },
    });

    setIsLoading(false);
    return setOpenConfirmData(true);
  };

  const handleOtherBase = () => {
    setDataConfirm({
      title: `Aluno já cadastrado na base ${tempForm!.dataLocal}`,
      name: tempForm?.dataStudent?.name,
      text: 'Deseja continuar a venda aqui mesmo?',
      button1: { name: 'Não', action: () => handleClean() },
      button2: {
        name: 'Sim',
        action: () => {
          if (newNegotiation && newNegotiation?.length > 0) {
            return handleHasItems();
          }
          if (setForm && tempForm) {
            setForm(tempForm);
            setOpenConfirmData(false);
            setIsLoading(false);
            setIsOpen(false);
          }
          setDataConfirm({});
        },
      },
    });
    setIsLoading(false);
    return setOpenConfirmData(true);
  };

  /** Se acha o aluno e ja começou uma negociação antes, abre um modal para perguntar se quer manter a negociação ou limpar */
  const handleConfirm = () => {
    if (JSON.stringify(form) === JSON.stringify(tempForm)) {
      setIsOpen(false);
      return setIsLoading(false);
    }

    if (
      tempForm?.dataLocal &&
      tempForm?.dataLocal !== '' &&
      tempForm?.dataLocal !== company
    ) {
      return handleOtherBase();
    }
    if (newNegotiation && newNegotiation.length > 0) {
      return handleHasItems();
    } else {
      if (setForm && tempForm) {
        setForm(tempForm);
        setIsLoading(false);
        /** Fecha a Confirmação */
        setOpenConfirmData(false);

        /** Fecha a Pesquisa */
        setIsOpen(false);
      }
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  /** Pesquisa o cpf ou o email do aluno no bando de dados */
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setError('');

      if (!search.value) {
        setIsLoading(false);
        return setError(`Favor inserir um ${search.type.toUpperCase()} válido`);
      }

      if (
        search.type === 'cpf' &&
        search.value.replace(/[^0-9]/g, '').length !== 11
      ) {
        setIsLoading(false);
        return setError('Favor inserir um CPF válido');
      }

      const field = {
        login: search.value,
      };

      if (isSearch) {
        const fetchStudent = async () => {
          const response = await studentDataService.data(field);
          if (response) {
            return setIsOpenDataStudent(true);
          } else {
            setIsLoading(false);
          }
        };
        fetchStudent();
      }
      const { data, message } =
        await saleNegotiationService.consultStudent(field);

      if (data.student) {
        const newForm: IFormDataStudent = {
          ...tempForm,
          percentStudentDiscount: data.percentStudentDiscount,
          dataStudent: {
            isStudent: !!(data.student.id && data.local === company),
            studentId: data.student?.id,

            name: data.student?.nome,
            isForeign: !!data.student?.estrangeiro,
            cpf: data.student?.cpf,
            email: data.student?.email,
            rgId: data.student?.rg,
            bornDate:
              data.student.data_nascimento !== '0000-00-00'
                ? data.student.data_nascimento
                : undefined,
            gender: data.student?.sexo,
            pcd: data.student?.pcd ? data.student?.pcd : 10,
            colorRace: data.student?.raca,
            fatherName: data.student?.pai,
            motherName: data.student?.mae,
            nationality: data.student?.nacionalidade,
            nativeness: data.student?.naturalidade,

            degree: data.student?.graduacao_curso,
            graduationYear: data.student?.graduacao_ano,
            university: data.student?.graduacao_instituicao,
            ceremony:
              data.student.graduacao_colacao !== '0000-00-00'
                ? data.student.graduacao_colacao
                : undefined,
            highSchoolGraduation: data.student?.ano_conclusao_ensino_medio,

            cep: data.student.endereco?.cep,
            address: data.student.endereco?.logradouro,
            addressNumber: data.student.endereco?.numero,
            addressComplement: data.student.endereco?.complemento,
            neighborhood: data.student.endereco?.bairro,
            city: data.student.endereco?.cidade,
            uf: data.student.endereco?.estado,
            municipalCode: String(data.student.endereco?.codigo_municipio),
            homePhone: getPhone(data.student.telefone, 'homePhone'),
            cellPhone: getPhone(data.student.telefone, 'cellPhone'),
            businessPhone: getPhone(data.student.telefone, 'businessPhone'),

            companyName: data.student.empresa_contribuinte_nome,
            cnpj: String(data.student.empresa_contribuinte_cnpj),
            knowled: Number(data.studentMedia?.midia),
            knowledName: data.studentMedia?.indicacao,
            knowledPhone: String(data.studentMedia?.telefone),
          },
          courses: {
            title: ['Tipo', 'Curso'],
            content: data.courses?.map((e) => {
              return {
                id: e.id,
                courseTypeId: String(e.course_type_id),
                type: { item: e.course_type },
                course: { item: e.name },
              };
            }),
          },
          previousSaledata: data.sales?.map((sale) => ({
            id: sale.id,
            agent: sale.agent,
            date: sale.date,
            amount: sale.amount,
            items: sale.items.map((item) => ({
              item: item.item,
              description: item.description,
              quantity: item.quantity,
              unitaryValue: item.unitary_value,
              discount: item.discount,
              totalItemValue: item.total_item_value,
              status: item.status,
            })),
          })),
          dataLocal: data.local || '',
        };

        setTempForm(newForm);
        setVerifyFields && setVerifyFields(newForm);
      } else {
        setForm &&
          setForm({
            ...defaultForm!,
            dataStudent: {
              ...defaultForm?.dataStudent,
              isStudent: false,
              cpf: search.type === 'cpf' ? search.value : undefined,
              email: search.type === 'email' ? search.value : undefined,
            },
            percentStudentDiscount: data.percentStudentDiscount,
          });
        !isSearch && setIsLoading(false);
        isSearch && setError(message[0]);
        if (!isSearch) {
          setIsOpen(false);
          setSearch({ type: 'cpf', value: '' });
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      !isSearch && setIsLoading(false);
    }
  };

  const handleCleanError = () => {
    setError('');
    setSearch((old) => ({ ...old, value: '' }));
  };

  /** Filtra os cursos que a pessoa tem no cadastro, e limpa da negociação */
  useEffect(() => {
    if (newNegotiation && newNegotiation.length > 0 && form) {
      const filteredNegotiations = newNegotiation.filter((item: IItem) => {
        const itemId = item.description.value!;
        const isCourseInForm = form.courses.content.some(
          (course: ICourseData) => course.id === +itemId,
        );
        return !isCourseInForm;
      });
      setNewNegotiation?.(
        filteredNegotiations !== undefined
          ? filteredNegotiations
          : ([] as IItem[] | []),
      );
      setIsOpen(false);
    }
  }, [form?.courses]);

  useEffect(() => {
    !!tempForm && !isSearch && handleConfirm();
  }, [tempForm]);

  useEffect(() => {
    if (!isOpenDataStudent && isSearch) {
      setSearch({ type: 'cpf', value: '' });
      setIsOpen(false);
    }
  }, [isOpenDataStudent]);

  return (
    <>
      <BasicModal
        zIndex={1300}
        isFloat={true}
        isOpen={!!isOpen}
        setIsOpen={setIsOpen}
        handleOnClose={() => {
          handleCleanError();
          handleClose && handleClose();
        }}
      >
        <S.ComponentModal>
          <HeaderModal
            setOpen={setIsOpen}
            title="Pesquisar Aluno"
            subtitle
            handleClose={() => {
              handleClose && handleClose();
              handleCleanError();
            }}
          />
          <S.Content>
            <FormField
              item={{
                title: (
                  <S.FormTitle>
                    <b>Selecione a forma de pesquisa:</b>
                    <S.ContainerSwitch>
                      <b>CPF</b>
                      <S.CustomSwitch
                        onChange={(e) =>
                          setSearch({
                            ...search,
                            type: e.target.checked ? 'email' : 'cpf',
                          })
                        }
                      />
                      <b>Email</b>
                    </S.ContainerSwitch>
                  </S.FormTitle>
                ),
                placeholder: search.type === 'email' ? 'E-mail' : 'CPF',
                isInvalid: !!error,
                name: search.type,
                type: 'string',
                value: search.value,
                handleChange: (e) => {
                  setError('');
                  setSearch({
                    ...search,
                    value: e.target.value,
                  });
                },
                lg: 8,
              }}
            />
            {error && (
              <div style={{ color: 'red', alignSelf: 'center' }}>{error}</div>
            )}
            <S.ContainerButton>
              <ButtonSimple
                type="submit"
                color="blue"
                size="medium"
                onClick={(e) => handleSearch(e)}
                disabled={isLoading}
              >
                Pesquisar
              </ButtonSimple>
            </S.ContainerButton>
          </S.Content>
        </S.ComponentModal>
      </BasicModal>
      <ModalStudentInfo
        isOpen={!!isOpenDataStudent}
        setIsOpen={setIsOpenDataStudent}
        dataStudent={search.value}
        isSearch
      />
      <ModalConfirm
        isOpen={openConfirmData}
        setIsOpen={setOpenConfirmData}
        data={dataConfirm}
      />
    </>
  );
}
