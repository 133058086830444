import React from 'react';

import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Container } from './styles';

interface ITitle {
  title: string;
  isNotMain?: boolean;
}

export default function HeaderPages({ title, isNotMain }: ITitle) {
  const navigate = useNavigate();
  function handleBack() {
    navigate(-1);
  }
  return (
    <Container>
      <div className="title">
        <span>{title}</span>
        {!isNotMain && (
          <span className="back">
            <a onClick={handleBack}>
              <ArrowBackIosIcon />
              Voltar
            </a>
          </span>
        )}
      </div>
      <div className="divider">
        <Divider
          style={{ background: 'black' }}
          sx={{ height: 1 }}
          variant="fullWidth"
        />
      </div>
    </Container>
  );
}
