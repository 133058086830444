import React, { ReactNode } from 'react';

import * as S from './styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface Props {
  title: string | ReactNode;
}
export default function AlertWarning({ title }: Props) {
  return (
    <S.Container>
      <S.Div>
        <ErrorOutlineIcon />
        <p>{title}</p>
      </S.Div>
    </S.Container>
  );
}
