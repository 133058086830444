import { useLocation } from 'react-router-dom';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const query = {
  /** Remove um parâmetro específico da URL atual */
  deleteOneParam(param: string) {
    const url = new URL(document.URL);
    const params = new URLSearchParams(url.search);
    params.delete(param);
    url.search = params.toString();

    const newUrl = url.toString();
    window.history.pushState(null, '', newUrl);
  },

  /** Romove os parâmetros da URL que não estão na lista de parâmetros a serem mantidos */
  cleanOrKeepSomeQueryParams(paramToKeep?: string | string[]) {
    const url = new URL(document.URL);
    const params = new URLSearchParams(url.search);

    /** Convertendo paramToKeep em uma matriz, se não for uma matriz */
    const paramsToKeepArray = Array.isArray(paramToKeep)
      ? paramToKeep
      : [paramToKeep];

    /** For para pegar todas chaves que devem ser excluídas */
    const paramsToDelete: string[] = [];
    for (const key of params.keys()) {
      if (!paramsToKeepArray.includes(key)) {
        paramsToDelete.push(key);
      }
    }

    /** For para limpar todas chaves que devem ser excluídas */
    for (const key of paramsToDelete) {
      params.delete(key);
    }
    url.search = params.toString();

    const newUrl = url.toString();
    window.history.pushState(null, '', newUrl);
  },

  /** Adiciona um novo parâmetro à Url atual */
  addParam(key: string, value: string | number) {
    const url = new URL(window.location.href);
    url.searchParams.set(key, String(value));

    window.history.pushState({}, '', url);
  },
};
