import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors?.primary};

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    flex-direction: column-reverse;

    gap: 3rem;
    padding: 3rem;
  }

  .background_img_text {
    display: none;

    @media screen and (max-width: ${(props) => props.theme.size?.large}) {
      display: flex;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    height: 100%;
  }
`;

export const BackgroundImg = styled.img`
  height: 100vh;
`;

export const BackgroundImgText = styled.img`
  height: 100%;
  display: none;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 90%;
  }
`;

export const Content = styled.div`
  width: 40rem;
  height: 50rem;
  background-color: ${({ theme }) => theme.colors?.backgroundColor};
  border-radius: 15px;
  padding: 3rem;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 100%;
    height: 42rem;
  }
`;

export const ContainerImg = styled.div`
  height: 100vh;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    height: 100%;

    .background_img {
      display: none;
    }
  }
`;

export const Img = styled.img`
  max-width: 20rem;
`;

export const ContainerInfo = styled.div`
  position: relative;
  bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 3rem;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: static;
  }

  @media screen and (min-width: ${(props) => props.theme.size?.large}) {
    display: none;
  }
`;

export const Info = styled.div`
  display: flex;
  width: 30rem;
`;

export const P = styled.p`
  color: ${({ theme }) => theme.colors?.text.tertiary};

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    text-align: center;
  }
`;

export const B = styled.b`
  font-size: 14px;
  color: ${({ theme }) => theme.colors?.text.tertiary};
`;
