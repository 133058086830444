import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import * as S from '../../styles';
import { certificateServiceNewStudent } from '../../../../../../../services/newStudent';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../components/newStudent/ProviderNewStudent';
import { IDataCourseStudent } from '../../../../../../../components/newStudent/MinimumRequirements';
import { ButtonSimple } from '../../../../../../../components/newStudent/Buttons';
import { handleMaxWidth } from '../../../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import { Formik } from 'formik';
import { formatCurrency } from '../../../../../../../util/common/appUtil';
import FieldFormik from '../../../../../../../components/newStudent/FieldFormik';
import { AlertBar } from '../../../../../../../components/newStudent/Alert';
import {
  creditCardIugu,
  invoiceIugu,
  pixIugu,
} from '../../../../../../../variables';

interface Props {
  dataStudentCourse: IDataCourseStudent;
  courseId: number;
  setCourseId: Dispatch<SetStateAction<number>>;
  setReload?: Dispatch<SetStateAction<number>>;
}

export default function CertificateRequest({
  dataStudentCourse,
  courseId,
  setCourseId,
  setReload,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const [paymentType, setPaymentType] = useState('');

  const theme = useTheme();
  const handleSendRequest = async (
    sendType?: 'CORREIOS' | 'RETIRADA' | null,
  ) => {
    setIsLoading(true);
    try {
      const { result, data } =
        await certificateServiceNewStudent.requestCertificate(
          {
            secondCopy: dataStudentCourse.certificateCanBeRequested?.secondCopy,
            concrete: dataStudentCourse.certificateCanBeRequested?.concrete,
            digital: dataStudentCourse.certificateCanBeRequested?.digital,
            sendType,
            paymentType,
          },
          courseId,
        );
      if (result) {
        data !== true && window.open(String(data));

        setCourseId(0);
        setReload && setReload(Math.floor(Math.random() * 10));
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <S.Container>
      {dataStudentCourse?.certificateCanBeRequested?.digital ||
      dataStudentCourse?.certificateCanBeRequested?.secondCopy ||
      dataStudentCourse?.certificateCanBeRequested?.concrete ? (
        <S.DivButtons>
          {dataStudentCourse.certificateCanBeRequested?.digital && (
            <S.DivDigital>
              <h6>Solicite seu certificado:</h6>
              <ButtonSimple
                widthSize={
                  handleMaxWidth(theme.size?.medium) ? 'medium' : 'xlarge'
                }
                color="success"
                onClick={handleSendRequest}
              >
                Solicitar Digital
              </ButtonSimple>
            </S.DivDigital>
          )}
          {dataStudentCourse.certificateCanBeRequested?.secondCopy && (
            <Formik
              onSubmit={() => {}}
              initialValues={{
                send: false,
                takeOut: false,
                selectPaymentType: undefined,
              }}
            >
              {({ values, setFieldValue }) => (
                <S.DivDigital>
                  <h6>Solicite seu certificado:</h6>
                  <S.DivSelect>
                    <S.H6>Forma de Pagamento: </S.H6>
                    <FieldFormik
                      name="selectPaymentType"
                      type="select"
                      value={values.selectPaymentType}
                      onChange={(e) => {
                        setPaymentType(e.target.value);
                        setFieldValue('selectOptionsParcels', 0);
                      }}
                      options={[
                        { title: '[ SELECIONE ]', value: 0 },
                        { title: 'BOLETO', value: Number(invoiceIugu) },
                        { title: 'CARTÃO', value: Number(creditCardIugu) },
                        { title: 'PIX', value: Number(pixIugu) },
                      ]}
                      widthSize="xxlarge"
                    />
                  </S.DivSelect>
                  <S.Button>
                    <ButtonSimple
                      widthSize={
                        handleMaxWidth(theme.size?.medium) ? 'medium' : 'xlarge'
                      }
                      color="success"
                      onClick={handleSendRequest}
                      disabled={Number(paymentType) === 0 || !paymentType}
                    >
                      Segunda via
                    </ButtonSimple>
                  </S.Button>
                </S.DivDigital>
              )}
            </Formik>
          )}
          {dataStudentCourse.certificateCanBeRequested?.concrete && (
            <Formik
              onSubmit={() => {}}
              initialValues={{
                send: false,
                takeOut: false,
                selectPaymentType: undefined,
              }}
            >
              {({ values, setFieldValue }) => (
                <S.DivRequest>
                  <div>
                    <S.Title>
                      Deseja solicitar seu Certificado Impresso?
                    </S.Title>
                    <S.Value>
                      O prazo de envio é de até 7 dias úteis, e o valor da
                      emissão é de {formatCurrency(100)}
                    </S.Value>
                  </div>
                  <S.DivSendType>
                    <FieldFormik
                      name="send"
                      type="checkbox"
                      placeholder="Envio pelos correios"
                      widthSize="fullWidth"
                      checked={values.send}
                      onChange={() => {
                        setFieldValue('send', !values.send);
                        values.takeOut && setFieldValue('takeOut', false);
                      }}
                    />
                    <FieldFormik
                      name="takeOut"
                      type="checkbox"
                      placeholder="Retirada na Faculdade"
                      widthSize="fullWidth"
                      checked={values.takeOut}
                      onChange={() => {
                        setFieldValue('takeOut', !values.takeOut);
                        values.send && setFieldValue('send', false);
                      }}
                    />
                  </S.DivSendType>
                  <S.DivSelect>
                    <S.H6>Forma de Pagamento: </S.H6>
                    <FieldFormik
                      name="selectPaymentType"
                      type="select"
                      value={values.selectPaymentType}
                      onChange={(e) => {
                        setPaymentType(e.target.value);
                        setFieldValue('selectOptionsParcels', 0);
                      }}
                      options={[
                        { title: '[ SELECIONE ]', value: 0 },
                        { title: 'BOLETO', value: Number(invoiceIugu) },
                        { title: 'CARTÃO', value: Number(creditCardIugu) },
                        { title: 'PIX', value: Number(pixIugu) },
                      ]}
                      widthSize="xxlarge"
                    />
                  </S.DivSelect>
                  <S.Button>
                    <ButtonSimple
                      color="success"
                      widthSize="xlarge"
                      disabled={
                        (!values.takeOut && !values.send) ||
                        Number(paymentType) === 0 ||
                        !paymentType
                      }
                      onClick={() =>
                        handleSendRequest(
                          values.takeOut
                            ? 'RETIRADA'
                            : values.send
                            ? 'CORREIOS'
                            : null,
                        )
                      }
                    >
                      Solicitar Certificado Físico
                    </ButtonSimple>
                  </S.Button>
                </S.DivRequest>
              )}
            </Formik>
          )}
        </S.DivButtons>
      ) : (
        <AlertBar
          color="tertiary"
          text={dataStudentCourse?.certificateCanBeRequested?.observation}
        />
      )}
    </S.Container>
  );
}
