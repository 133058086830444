import styled from 'styled-components';

export const ContentNotice = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleNotice = styled.b`
  font-size: medium;
  color: #a90329;
  padding-bottom: 1rem;
`;

export const TextNotice = styled.li`
  font-size: small;
  font-weight: 500;
  padding-bottom: 0.2rem;
  color: #404040 !important;
`;
