import React, { useEffect, useState } from 'react';

import * as S from './styles';
import { RegistrationTable } from './components';
import HeaderPages from '../../../components/student/HeaderPages';
import contractService from '../../../services/contract/contract.service';
import { LoaderSimpleCenter } from '../../../components/student/Loaders';
import { AlertBar } from '../../../components/student/Alerts';
import { errorToast } from '../../../components/common/Toast';

export interface IRegistrationColumns {
  title: string;
  titleAction: string;
}

export interface IRegistrationRows {
  id: number;
  nome: string;
  curso_tipo: string;
  curso_documento_tipo: string;
  curso_documento_tipo_id: number;
}

export default function RegistrationsContracts() {
  const [load, setLoad] = useState<boolean>(false);
  const [contracts, setContracts] = useState<IRegistrationRows[]>([]);
  const [terms, setTerms] = useState<IRegistrationRows[]>([]);

  const columnsContracts: IRegistrationColumns = {
    title: 'Curso',
    titleAction: 'Contrato',
  };

  const columnsTerm: IRegistrationColumns = {
    title: 'Curso',
    titleAction: 'Termo de Aceite',
  };

  useEffect(() => {
    setLoad(true);
    const fetchData = async () => {
      const response = await contractService.signedContracts();
      if (response.result) {
        if (response.data.contratos) {
          setContracts(response.data.contratos);
          setLoad(false);
        }
        if (response.data.termos_de_aceite) {
          setTerms(response.data.termos_de_aceite);
          setLoad(false);
        }
      } else {
        response.message &&
          response.message.length > 0 &&
          response.message.forEach((text) => {
            return errorToast(text);
          });
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderPages title="Contratos" />
      {load ? (
        <S.Load>
          <LoaderSimpleCenter text="Carregando" />
        </S.Load>
      ) : (
        <>
          {contracts.length > 0 ? (
            <>
              <div>
                <RegistrationTable
                  rows={contracts}
                  columns={columnsContracts}
                />
              </div>
              {terms.length > 0 && (
                <S.ContainerTerm>
                  <HeaderPages title={'Termos de Aceite'} isNotMain={true} />
                  <RegistrationTable rows={terms} columns={columnsTerm} />
                </S.ContainerTerm>
              )}
            </>
          ) : (
            <S.Alert>
              <AlertBar message="Nenhum contrato encontrado" color="default" />
            </S.Alert>
          )}
        </>
      )}
    </>
  );
}
