import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import PrintIcon from '@mui/icons-material/Print';

import TableBasic from '../../../../../components/newStudent/Table';
import ButtonIcon from '../../../../../components/newStudent/Buttons/ButtonIcon';
import {
  AlertWarning,
  AletNotfoundLoading,
} from '../../../../../components/newStudent/Alert';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { certificateServiceNewStudent } from '../../../../../services/newStudent';
import { IDataRequirement } from '../../../../../services/newStudent/certificate/certificate.service';
import { courseTypeCapacitation } from '../../../../../variables';

export interface ITableCapacitation {
  title: string;
  subtitle: string;
  url: string | null;
}

export default function ContentCapacitation() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [dataTableCapacitation, setDataTableCapacitation] = useState<
    ITableCapacitation[] | []
  >([]);

  const [requirements, setRequirements] = useState<IDataRequirement>();

  const dataTable = {
    title: [
      { item: <h5>Curso</h5> },
      { item: <h5>Certificado</h5>, align: 'center' },
    ],
    content: dataTableCapacitation.map((item) => ({
      course: {
        item: (
          <S.RowTableDiv>
            <S.Key>{item.title}</S.Key>
            <S.Value>{item.subtitle}</S.Value>
          </S.RowTableDiv>
        ),
      },
      certificate: {
        item: item.url ? (
          <ButtonIcon
            icon={<PrintIcon fontSize="large" />}
            color="success"
            onClick={() => window.open(item.url)}
          />
        ) : 'Indisponível',
        align: 'center',
      },
    })),
  };
  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);
        const { data, result } = await certificateServiceNewStudent.show({
          type: +courseTypeCapacitation!,
        });

        if (result) {
          setDataTableCapacitation(() =>
            data.certificates.map((certificate) => ({
              title: certificate.courseName,
              subtitle: certificate.courseType,
              url: certificate.url,
            })),
          );
          setRequirements(data.requirements);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  return (
    <S.Container>
      {dataTableCapacitation.length <= 0 ? (
        <AletNotfoundLoading toRender />
      ) : (
        <>
          {requirements?.data && requirements?.data.length > 0 && (
            <S.TitleContent className="requirements">
              <S.Title>{requirements.title}</S.Title>
              <S.Request>
                {requirements.data.map((item, index) => (
                  <S.Row key={index}>
                    <S.Key>{item.title} </S.Key>
                    <S.Value>{item.status}</S.Value>
                  </S.Row>
                ))}
              </S.Request>
            </S.TitleContent>
          )}
          <TableBasic data={dataTable} />
          {dataTableCapacitation.length > 0 && (
            <AlertWarning title="Caso tenha alguma dúvida para emitir a declaração, nos chame pelo chat online." />
          )}
        </>
      )}
    </S.Container>
  );
}
