import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled.div`
  padding: 1.5rem 0;
  font-size: 15px;
`;

export const Button = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;

  .css-ke5b6m-MuiButtonBase-root-MuiButton-root {
    background-color: #739e73;

    :hover {
      background-color: #659265;
    }
  }
`;

export const Observation = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  width: 100%;
`;

export const H6 = styled.h6`
  font-weight: bold;
`;

export const DivObservation = styled.div`
  text-align: justify;
  width: 100%;
`;
