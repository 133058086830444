import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 4rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    padding: 3rem 1rem;
  }

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    padding: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;

  gap: 2rem;
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    flex-direction: column-reverse;
    padding-bottom: 2rem;
  }
`;

export const TitleDiv = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

export const Tabs = styled.div`
  width: 40%;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 100%;
  }
`;

export const ContentSelected = styled.div`
  width: 60%;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 100%;
  }
`;

export const DivTitle = styled.div`
  padding: 1rem 1rem 0 1rem;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h5 {
    color: ${({ theme }) => theme.colors?.text.primary};
  }

  h6 {
    color: ${({ theme }) => theme.colors?.text.secondary};
  }
`;
