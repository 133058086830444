import React, { useState, ChangeEvent, useEffect } from 'react';
import * as S from './styles';
import { Col, Form, Row } from 'react-bootstrap';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';

import {
  SubtitleTable,
  RowText,
  DivRow,
  MyTableRow,
  MyTableCell,
} from '../../../../../../../components/student/Table/components';
import gradeService from '../../../../../../../services/grade/grade.service';
import { LoaderSimpleCenter } from '../../../../../../../components/student/Loaders';
import { warningToast } from '../../../../../../../components/common/Toast';

type Modulo = {
  modulo_id: number;
  modulo_nome: string;
};

type Turma = {
  id: number;
  nome: string;
  modulos: Modulo[];
};

interface Props {
  rows: {
    aluno_id: number;
    turmas: Turma[];
  };
}

export interface Grades {
  disciplina: string;
  nota: string;
}

export interface DataGraduationGrades {
  aluno_id: number;
  turma_id: number;
  modulo_id: number;
}

export default function TableSubjectGraduation({ rows }: Props) {
  const [load, setLoad] = useState<boolean>(false);
  const [selectedTurma, setSelectedTurma] = useState<number>(0);
  const [selectedModulo, setSelectedModulo] = useState<number>(0);
  const [grades, setGrades] = useState<Grades[]>([]);

  const handleTurmaChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const turmaId = parseInt(event.target.value);
    setSelectedTurma(turmaId);
    setSelectedModulo(0);
  };

  const handleModuloChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const moduloId = parseInt(event.target.value);
    setSelectedModulo(moduloId);
  };

  let turmaOptions: JSX.Element[] = [];
  let moduloOptions: JSX.Element[] = [];

  if (Array.isArray(rows.turmas)) {
    turmaOptions = rows.turmas.map((row) => (
      <option key={row.id} value={row.id}>
        {row.nome}
      </option>
    ));

    if (selectedTurma !== null) {
      const selectedTurmaData = rows.turmas.find(
        (row) => row.id === selectedTurma,
      );
      if (selectedTurmaData) {
        moduloOptions = selectedTurmaData.modulos.map((modulo) => (
          <option key={modulo.modulo_id} value={modulo.modulo_id}>
            {modulo.modulo_nome}
          </option>
        ));
      }
    }
  }

  useEffect(() => {
    const data = {
      turma_id: selectedTurma,
      modulo_id: selectedModulo,
      aluno_id: rows.aluno_id,
    };
    const fetchData = async () => {
      if (selectedModulo) {
        setLoad(true);
        const response = await gradeService.graduationGrades(data);
        if (response.result) {
          setGrades(response.data);
          setLoad(false);
        } else {
          warningToast('Notas não encontradas para a Turma e Módulo informado');
          setLoad(false);
        }
      }
    };

    fetchData();
  }, [selectedModulo]);

  return (
    <>
      <S.Form>
        <Form.Group>
          <Row>
            <Col>
              <label htmlFor="turma">Turma</label>
              <Form.Select
                size="lg"
                id="turma"
                onChange={handleTurmaChange}
                value={selectedTurma || ''}
              >
                <option value="">Selecione a turma</option>
                {turmaOptions}
              </Form.Select>
            </Col>
            <Col>
              <label htmlFor="modulo">Módulo</label>
              <Form.Select
                size="lg"
                id="modulo"
                onChange={handleModuloChange}
                value={selectedModulo || ''}
              >
                <option value="">Selecione o módulo</option>
                {moduloOptions}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
      </S.Form>
      <>
        {load ? (
          <S.Load>
            <LoaderSimpleCenter text="Carregando" />
          </S.Load>
        ) : (
          <S.Container>
            {grades.length > 0 && (
              <TableContainer>
                <Table component={Paper}>
                  <TableHead>
                    <MyTableRow>
                      <TableCell align="left" className="title">
                        <SubtitleTable color="gray">Disciplina</SubtitleTable>
                      </TableCell>
                      <MyTableCell align="right" className="grade">
                        <SubtitleTable color="gray">Nota</SubtitleTable>
                      </MyTableCell>
                    </MyTableRow>
                  </TableHead>
                  <TableBody>
                    {grades.map((grade, index) => (
                      <MyTableRow key={index}>
                        <TableCell scope="row" className="title-row">
                          <RowText align="left">{grade.disciplina}</RowText>
                        </TableCell>
                        <TableCell scope="row" className="grade-row">
                          <DivRow>
                            <RowText align="left">{grade.nota}</RowText>
                          </DivRow>
                        </TableCell>
                      </MyTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </S.Container>
        )}
      </>
    </>
  );
}
