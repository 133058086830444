import React, { useEffect } from 'react';

import { INoticeRows } from '../../../../../components/student//Info/Notice';
import { statementService } from '../../../../../services';
import { Notice } from '../../../../../components/student/Info';
import { AlertBar } from '../../../../../components/student/Alerts';
import { ChartStatement } from '../../../../../components/student/Charts';
import * as S from './styles';
import { Form } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';

export default function ConclusionStatement() {
  const rowsNotice: INoticeRows[] = [
    {
      subtitle: 'Nome: ',
      text: 'Rafael D. Testee',
    },
    {
      subtitle: 'CPF (Caso não estrangeiro): ',
      text: '10161590640.',
    },
    {
      subtitle: 'RG: ',
      text: '10161590640.',
    },
    {
      subtitle: 'Data de Nascimento: ',
      text: '01/01/1990',
    },
    {
      subtitle: 'Naturalidade: ',
      text: 'Itaunense',
    },
    {
      subtitle: 'Nome dos Pais: ',
      text: 'Pai Teste e Mãe Teste',
    },
  ];

  const rowsNoticeRequirements: INoticeRows[] = [
    {
      subtitle: 'Tempo Mínimo de Curso: ',
      text: '180 dias',
    },
    {
      subtitle: 'Documentos Entregues: ',
      text: [
        'Histórico de Graduação (Cópia Simples)',
        'Diploma de Curso Superior (Cópia simples)',
      ],
    },
    {
      subtitle: 'Plano Estudantil: ',
      text: 'Finalizado',
    },
    {
      subtitle: 'Avaliações Concluídas: ',
      text: '10',
    },
    {
      subtitle: 'Status do TCC: ',
      text: 'Aprovado',
    },
    {
      subtitle: 'Data da colação de grau: ',
      text: 'Data de colação menor que o tempo mínimo de curso. Faltam 157 dias',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await statementService.statementShow();
    };

    fetchData();
  }, []);

  return (
    <>
      <Notice title="Antes de continuar confira seu dados:" rows={rowsNotice} />

      <AlertBar
        color={'default aviso'}
        message={
          <p>
            Caso as informações estejam incorretas, entre em contato com a gente
            pelo chat no canto inferior direito.
          </p>
        }
      />

      <S.Form>
        <Form.Group>
          <Form.Select size="lg" id="typeStatement">
            <option selected>Selecione o curso</option>
            <option>Declaração de Matrícula/Cursando</option>
            <option>Declaração de Conclusão de Curso</option>
          </Form.Select>
        </Form.Group>
      </S.Form>

      <Notice
        title="Antes de continuar confira seu dados:"
        rows={rowsNoticeRequirements}
      />

      <S.Button>
        <Button variant="contained" color="success" startIcon={<Download />}>
          Imprimir Declaração
        </Button>
      </S.Button>

      <ChartStatement />
    </>
  );
}
