import React, { useContext } from 'react';

import * as S from './styles';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../ProviderNewStudent';

export default function LoginAs() {
  const { student } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  return (
    <S.Container>
      <S.Section className="aviso" style={{ margin: 0 }}>
        <S.H2> Acessado Como: {student?.dataStudent?.name}</S.H2>
      </S.Section>
    </S.Container>
  );
}
