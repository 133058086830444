import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import * as S from './styles';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import TitleDialogMobile from '../../../../../../../components/newStudent/Title/TitleDialogMobile';
import { ButtonSimple } from '../../../../../../../components/newStudent/Buttons';
import Divider from '../../../../../../../components/common/Divider';
import FieldFormik from '../../../../../../../components/newStudent/FieldFormik';
import { IDocumentsDegree } from '../../../..';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../components/newStudent/ProviderNewStudent';
import { documentCertificate } from '../../../../../../../services/newStudent/documentStudent/document.service';
import { cleanCache } from '../../../../../../../util/common/appUtil';
import ItemDegree from './ItemDegree';
import { defaultDataUniversity } from '../..';

interface Props {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setWhoIsOpen: Dispatch<SetStateAction<number | undefined>>;
  title: string;
  inProgress: boolean;
  courseName: string;
  documentDegreeType: number;
  registrationId: number;
  universityDegreeId: number;
  setNewData?: Dispatch<SetStateAction<IDocumentsDegree[] | []>>;
  newData?: IDocumentsDegree[];
  setOldData?: Dispatch<SetStateAction<IDocumentsDegree[] | []>>;
  oldData?: IDocumentsDegree[];
}

export default function DocumentDegreeMobile({
  setIsOpenModal,
  setWhoIsOpen,
  title,
  inProgress,
  courseName,
  oldData,
  documentDegreeType,
  registrationId,
  universityDegreeId,
  newData,
  setNewData,
  setOldData,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [dataItem, setDataItem] = useState<IDocumentsDegree>(
    defaultDataUniversity,
  );

  const handlePickFile = (file: File) => {
    setDataItem({ ...dataItem, name: file.name, file: file });
  };

  const handleSave = async (itemForm, isOld?: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        id: String(itemForm.id),
        url: itemForm.url,
        file: itemForm.file,
        type: documentDegreeType,
        finalDate: itemForm.endDate,
        workload: +itemForm.workload,
        initialDate: itemForm.startDate,
        registrationId: registrationId!,
        universityDegreeId: universityDegreeId!,
      };
      const request = await documentCertificate.uploadCertificate({ body });
      if (request.result) {
        cleanCache('dataDocuments');
        let tmpData = dataItem;
        if (!isOld) {
          tmpData = {
            ...dataItem,
            id: request.data.id,
            url: request.data.url,
          };
        }
        const updateData = [...newData!, tmpData];
        setNewData && setNewData(updateData);
        setDataItem(defaultDataUniversity);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: number, isOld?: boolean) => {
    if (typeof id !== 'number') {
      const tmpData = newData?.filter((oldItem) => oldItem.id !== id) ?? [];
      setNewData && setNewData([...tmpData, defaultDataUniversity]);
      setDataItem(defaultDataUniversity);
    } else {
      try {
        setIsLoading(true);
        const body = {
          id: String(id),
          type: documentDegreeType,
          registrationId: registrationId!,
          universityDegreeId: universityDegreeId!,
        };
        const request = await documentCertificate.uploadCertificate({ body });
        if (request.result) {
          cleanCache('dataDocuments');
          if (!isOld) {
            const tmpData = newData?.filter((oldItem) => oldItem.id !== id) ?? [
              defaultDataUniversity,
            ];
            setNewData && setNewData([...tmpData]);
            setDataItem(defaultDataUniversity);
          } else {
            const tmpData =
              oldData?.filter((oldItem) => +oldItem.id !== +id) ?? [];

            setOldData && setOldData(tmpData);
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <TitleDialogMobile
        title={'Documentos'}
        setIsOpen={setIsOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setWhoIsOpen(undefined);
        }}
      />
      <S.Container>
        <S.DivTitle>
          <S.H5>{title}</S.H5>
          <S.H6>{courseName}</S.H6>
          <ButtonSimple
            color={inProgress ? 'primary' : 'danger'}
            noButton
            outline
          >
            {inProgress ? 'Andamento' : 'Pendente'}
          </ButtonSimple>
          <Divider padding />

          {!dataItem?.file ? (
            <>
              <S.DivArchive>
                <S.InputFile>
                  <span> Escolher arquivo </span>
                  <input
                    type="file"
                    className="input-file"
                    onChange={({ target }) => handlePickFile(target.files![0])}
                  />
                </S.InputFile>
                <S.SelectArchive>
                  <S.H6>Nenhum arquivo escolhido</S.H6>
                </S.SelectArchive>
                <>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={({ target }) => handlePickFile(target.files![0])}
                  />
                  <label htmlFor="fileInput">
                    <S.BtnSuccess>
                      <AddCircleOutlineOutlinedIcon fontSize="large" />
                    </S.BtnSuccess>
                  </label>
                </>
              </S.DivArchive>
              <Divider padding />
            </>
          ) : (
            <S.KeyValue>
              <S.H5>{dataItem.name}</S.H5>
              <div style={{ display: 'flex' }}>
                <S.ButtonSave onClick={() => handleSave(dataItem)}>
                  <UploadFileIcon fontSize="large" />
                </S.ButtonSave>
                <S.BtnDanger onClick={() => setDataItem(defaultDataUniversity)}>
                  <DeleteForeverIcon fontSize="large" color="error" />
                </S.BtnDanger>
              </div>
            </S.KeyValue>
          )}
          {dataItem?.file && (
            <S.CardContent>
              <S.KeyValue>
                <S.Key>Data Início: </S.Key>
                <FieldFormik
                  name="startDate"
                  onChange={({ target }) =>
                    setDataItem((old) => ({ ...old, startDate: target.value }))
                  }
                  type="date"
                  value={dataItem.startDate}
                  disabled={dataItem.checked}
                />
              </S.KeyValue>
              <S.KeyValue>
                <S.Key>Data fim: </S.Key>
                <FieldFormik
                  name="endDate"
                  onChange={({ target }) =>
                    setDataItem((old) => ({ ...old, endDate: target.value }))
                  }
                  type="date"
                  value={dataItem.endDate}
                  disabled={dataItem.checked}
                />
              </S.KeyValue>
              <S.KeyValue>
                <S.Key>Carga Horária: </S.Key>
                <FieldFormik
                  name="workload"
                  onChange={({ target }) =>
                    setDataItem((old) => ({ ...old, workload: target.value }))
                  }
                  type="number"
                  value={dataItem.workload}
                  disabled={dataItem.checked}
                />
              </S.KeyValue>
              <Divider padding />
            </S.CardContent>
          )}

          {newData &&
            newData?.length > 0 &&
            newData?.map(
              (item: IDocumentsDegree, index: number) =>
                item.url && (
                  <>
                    <ItemDegree
                      key={index}
                      handleSave={handleSave}
                      item={item}
                      handleDelete={handleDelete}
                    />
                    {index + 1 < newData?.length + (oldData?.length ?? 0) && (
                      <Divider padding />
                    )}
                  </>
                ),
            )}
          {oldData &&
            oldData?.length > 0 &&
            oldData?.map((item: IDocumentsDegree, index: number) => (
              <>
                <ItemDegree
                  key={index}
                  handleSave={handleSave}
                  item={item}
                  handleDelete={handleDelete}
                  isOld
                />
                {index + 1 < oldData?.length && <Divider padding />}
              </>
            ))}
        </S.DivTitle>
      </S.Container>
    </>
  );
}
