import styled from 'styled-components';

export const Container = styled.section`
  > section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;
    background-color: #c26565;
    color: #fff;
    text-shadow: none;
    font-size: 1.4rem !important;
  }
  div {
    align-items: center;
  }
  h4 {
    font-weight: 600;
    line-height: normal;
    font-size: 2rem;
  }

  svg {
    margin-left: 0.3em;
    float: right !important;
    font-size: 3em;
  }

  section.success {
    background-color: ${({ theme }) => theme.alert.success.main};
    color: ${({ theme }) => theme.fontColor.success.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.success.main};
  }
  section.info {
    background-color: ${({ theme }) => theme.alert.info.main};
    color: ${({ theme }) => theme.fontColor.info.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.info.main};
  }
  section.warning {
    background-color: ${({ theme }) => theme.alert.warning.main};
    color: ${({ theme }) => theme.fontColor.warning.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.warning.main};
  }
  section.danger {
    background-color: ${({ theme }) => theme.alert.danger.main};
    color: ${({ theme }) => theme.fontColor.white.main};
    border-left: solid 5px ${({ theme }) => theme.fontColor.danger.main};
  }
`;
