import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';

import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import Divider from '../../../components/common/Divider';
import BulletPoint from '../../../components/newStudent/BulletPoint';
import { formatDate } from '../../../util/common/appUtil';
import { ButtonSimple } from '../../../components/newStudent/Buttons';
import BlockedCourse from '../../../components/newStudent/Modal/BlockedCourse';
import ButtonOpen from '../../../components/newStudent/Buttons/ButtonOpen';
import Subjects from './components/Subjects';
import { query, useQuery } from '../../../util/common/queryUtil';
import { handleMaxWidth } from '../../../util/newStudent';
import { useTheme } from 'styled-components';
import { AletNotfoundLoading } from '../../../components/newStudent/Alert';
import {
  ICourses,
  myCoursesServiceNewStudent,
} from '../../../services/newStudent/myCourses/myCourses.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';

export default function MyCoursesNewStudent() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [modalBlockedIsOpen, setModalBlockedIsOpen] = useState<boolean>(false);
  const [subjectOpen, setSubjectOpen] = useState<number>();
  const [courses, setCourses] = useState<ICourses[]>([]);
  const params = useQuery();

  /** Função para abrir as Disciplinas */
  const handleOpenSubjects = (id: number) => {
    if (subjectOpen !== id) {
      setSubjectOpen(id);
      query.addParam('course', id);
    } else {
      setSubjectOpen(undefined);
      query.deleteOneParam('course');
    }
  };

  /** Função para pegar o ppc no servidor de documentos */
  const handleGetPpc = async (courseId: number) => {
    setIsLoading(true);
    try {
      const { result, data } =
        await myCoursesServiceNewStudent.getPpc(courseId);

      result && window.open(data.url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const theme = useTheme();

  /** UseEfect para trazer os cursos que o aluno está cadastrado */
  useEffect(() => {
    try {
      setIsLoading(true);
      const load = async () => {
        const { data, result } = await myCoursesServiceNewStudent.show();
        if (result) {
          setCourses(data);
        } else setCourses([]);
        setIsLoading(false);
      };
      load();

      const courseId = params.get('course');
      if (courseId) {
        setSubjectOpen(+courseId);
      }

      query.cleanOrKeepSomeQueryParams('course');
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  }, []);

  return (
    <S.Container>
      <TitlePage
        title="Meus Cursos"
        titlePage="Meus Cursos"
        subtitle="Aqui, você encontrará tudo o que precisa para navegar pelos seus estudos de maneira eficiente e organizada. 
        Este menu proporciona acesso fácil e rápido a informações cruciais sobre os cursos em que você está matriculado."
      />

      {courses.length <= 0 && (
        <>
          <Divider padding />
          <AletNotfoundLoading toRender />
        </>
      )}
      <S.DivTable>
        {courses.map((item: ICourses) => (
          <>
            <Divider padding>
              <ButtonSimple
                outline
                noButton
                color="gray"
                widthSize="large"
                heightSize="medium"
              >
                {item.type}
              </ButtonSimple>
            </Divider>
            {item.courses.map((course, index) => (
              <div key={index}>
                <S.DivContent>
                  <BulletPoint
                    color={course.accessCourse ? 'success' : 'danger'}
                    buttons={
                      course.accessCourse ? (
                        <S.Buttons>
                          <ButtonOpen
                            color={'success'}
                            heightSize="small"
                            widthSize="xmedium"
                            onClick={() => {
                              if (!course.urlExternal) {
                                handleOpenSubjects(course.id);
                              } else {
                                window.open(course.urlExternal);
                              }
                            }}
                            title={'ESTUDAR'}
                            titleClose={'ESTUDAR'}
                            open={subjectOpen === course.id}
                          />
                          <ButtonSimple
                            color="primary"
                            toolltip="Projeto Pedagógico de Curso"
                            heightSize="small"
                            widthSize="small"
                            onClick={() => handleGetPpc(course.courseId)}
                          >
                            PPC
                          </ButtonSimple>
                        </S.Buttons>
                      ) : (
                        <S.Buttons>
                          <ButtonSimple
                            color="danger"
                            heightSize="small"
                            toolltip="Projeto Pedagógico de Curso"
                            widthSize="xmedium"
                            onClick={() => setModalBlockedIsOpen(true)}
                          >
                            BLOQUEADO
                          </ButtonSimple>
                          <ButtonSimple
                            color="primary"
                            toolltip="Projeto Pedagógico de Curso"
                            heightSize="small"
                            widthSize="small"
                            onClick={() => handleGetPpc(course.courseId)}
                          >
                            PPC
                          </ButtonSimple>
                        </S.Buttons>
                      )
                    }
                    flexDirection={
                      handleMaxWidth(theme.size?.medium) ? 'column' : 'row'
                    }
                  >
                    <h6>
                      {`${course.courseName.toUpperCase()} | ${
                        course.workload
                      } horas | Expira em: ${formatDate(
                        course.expirationDate,
                      )}`}
                    </h6>
                  </BulletPoint>
                </S.DivContent>
                {subjectOpen === course.id && <Subjects id={course.id} />}
              </div>
            ))}
          </>
        ))}
      </S.DivTable>
      <BlockedCourse
        isOpen={modalBlockedIsOpen}
        setIsOpen={setModalBlockedIsOpen}
      />
    </S.Container>
  );
}
