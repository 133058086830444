import * as React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Modal } from '@mui/base/Modal';
import styled from 'styled-components';

import clsx from 'clsx';

import { ButtonText } from '../../Buttons';
import { Container } from './styles';

interface BackdropProps {
  className?: string;
  open?: boolean;
}

const BackdropUnstyled = React.forwardRef<HTMLDivElement, BackdropProps>(
  function BackdropUnstyled(props, ref) {
    const { open, className, ...other } = props;
    return (
      <div
        className={clsx({ 'MuiBackdrop-open': open }, className)}
        ref={ref}
        {...other}
      />
    );
  },
);

const ModalStyled = styled(Modal)`
  position: absolute;
  width: 100%;
  z-index: 1000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  padding: 15px;
  margin: 0.5rem;
  overflow: auto;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  overflow: auto;
`;

interface ModalCardProps {
  title?: string;
  children: React.ReactNode;
  style?: object;
}

export default function ModalCard({ title, children, style }: ModalCardProps) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonText title={'Termo de Aceite'} link={handleOpen} />
      <Container>
        <ModalStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          slots={{ backdrop: Backdrop }}
        >
          <Box sx={style}>
            <h2>{title}</h2>
            <Typography component="div">
              <span className="text">{children}</span>
            </Typography>
          </Box>
        </ModalStyled>
      </Container>
    </>
  );
}
