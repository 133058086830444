import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';

import CollapseGrades from './components/CollapseGrades';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import Divider from '../../../components/common/Divider';
import { myGradesServiceNewStudent } from '../../../services/newStudent';
import {
  ICourses,
  IMyGardesShow,
} from '../../../services/newStudent/myGrades/myGrades.service';
import { ButtonSimple } from '../../../components/newStudent/Buttons';
import { AletNotfoundLoading } from '../../../components/newStudent/Alert';

export default function MyNotesNewStudent() {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const [courses, setCourses] = useState<IMyGardesShow[] | []>([]);
  const [isOpen, setIsOpen] = useState<number | undefined>(undefined);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);

        const { result, data } = await myGradesServiceNewStudent.show();
        if (result) {
          setCourses(data);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, []);

  return (
    <S.Container>
      <TitlePage
        title="Minhas Notas"
        titlePage="Minhas Notas"
        subtitle="De forma organizada e direta, você terá acesso direto ao seu desempenho acadêmico aqui. Veja suas notas, avaliações e feedbacks dos professores aqui."
      />
      {courses.length <= 0 && (
        <>
          <Divider padding />
          <AletNotfoundLoading toRender />
        </>
      )}
      <S.Content>
        {courses.map((item: IMyGardesShow, index: number) => (
          <div key={index}>
            <Divider padding>
              <ButtonSimple
                outline
                noButton
                color="gray"
                widthSize="large"
                heightSize="medium"
              >
                {item.type}
              </ButtonSimple>
            </Divider>
            <S.Content>
              {item.courses.map((course: ICourses, i) => (
                <CollapseGrades
                  key={i}
                  index={index}
                  item={course}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />
              ))}
            </S.Content>
          </div>
        ))}
      </S.Content>
    </S.Container>
  );
}
