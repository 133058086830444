import styled from 'styled-components';
import { Switch } from '@mui/material';

export const ComponentModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  min-height: 20rem;
  border: 1px solid #e5e5e5;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  @media screen and (max-width: 525px) {
    width: 90vw;
  }
`;

export const CustomSwitch = styled(Switch)`
  && {
    .MuiSwitch-switch {
      color: #568491; /* Cor quando desligado */
    }
    .MuiSwitch-thumb {
      background-color: #90baf9; /* Cor do polegar quando ligado */
    }
    .MuiSwitch-track {
      background-color: #568491;
    }
  }
`;

export const ContainerButton = styled.div`
  padding-top: 1rem;
`;

export const Content = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50rem;
  padding-bottom: 2rem;
`;

export const FormTitle = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
