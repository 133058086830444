import React from 'react';

import { Container } from './styles';

interface Props {
  boxTitle: string;
  backgroundColor: string;
}

export default function AlertBox({ boxTitle, backgroundColor }: Props) {
  return (
    <Container>
      <header className={backgroundColor}>
        <span className="boxTitle">{boxTitle}</span>
      </header>
    </Container>
  );
}
