import React from 'react';
import { useLocation } from 'react-router-dom';

import Breadcrumb, {
  INavigates,
} from '../../../../../components/student/Breadcrumbs';
import HeaderPages from '../../../../../components/student/HeaderPages';
import { TableSubject, TableSubjectGraduation } from './components';
import {
  courseTypeGraduationEad,
  courseTypeGraduation,
} from '../../../../../variables';

export interface IInfoCourseRow {
  code: number;
  title: string;
  subject: string;
  grade?: number | null;
  status: string;
  attempt: number;
}

export default function SubjectGrades() {
  const location = useLocation();
  const { courseData, course } = location.state;

  const courseGraduationEad =
    courseTypeGraduationEad && +courseTypeGraduationEad;
  const courseGraduation = courseTypeGraduation && +courseTypeGraduation;

  const bradcrumbs: INavigates[] = [
    {
      title: 'Início',
      link: '/aluno/dashboard',
    },
    {
      title: 'Minhas Notas',
      link: '/aluno/minhas-notas',
    },
    {
      title: course.nome,
    },
  ];

  return (
    <>
      <Breadcrumb navigates={bradcrumbs} />
      <HeaderPages title={course.nome} />
      {course.curso_tipo_id !== courseGraduationEad &&
      course.curso_tipo_id !== courseGraduation ? (
        <TableSubject rows={courseData} />
      ) : (
        <TableSubjectGraduation rows={courseData} />
      )}
    </>
  );
}
