import styled from "styled-components";

export const Container = styled.div`
  .carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }

  .glyphicon-chevron-left,
  .glyphicon-chevron-right {
    width: 3rem;
    height: 3rem;
    margin-top: -15px;
    font-size: 30px;
  }

  .carousel-indicators {
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    list-style: none;
    text-align: center;
  }

  .carousel-indicators .active {
    margin: 0;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
  }

  .carousel-indicators button {
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    text-indent: -999px;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
  }
`;

export const ContainerSenha = styled.div`
  margin-top: 1.5rem;
`;
