import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  body {
    height: 100%;
  }

  @keyframes hideAnimation {
    0% {
      opacity: 1;
      visibility: visible;
    }
    99% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }

  .main {
    display: flex;
    margin: 0 8rem;
    min-height: 100vh;
    height: 100%;
    color: ${({ theme }) => theme.colors?.text.primary};

    @media screen and (max-width: ${(props) => props.theme.size?.large}) {
      margin: 0;
    }
  }

  /* Styles para as fontes */
  h1 { font-size: 40px; }
  h2 { font-size: 30px; }
  h3 { font-size: 25px; }
  h4 { font-size: 20px; }
  h5 { font-size: 17px; }
  h6 { font-size: 14px; }
`;
