import React from 'react';

import { isNullOrEmpty } from '../../../../util/pageUtil';
import { Container, Docs } from './styles';
import { Block } from '@mui/icons-material';
import { IDocuments } from '../../../../interfaces/student/dashboard.interfaces';

interface Props {
  title: string;
  message: string;
  documentos: IDocuments[];
}

export default function AlertDocumentation({
  title,
  message,
  documentos,
}: Props) {
  return (
    <Container>
      <section className="section-header">
        <div>
          <h4>{title}</h4>
          <p>{message}</p>
        </div>
        <Block />
      </section>
      <section className="section-body">
        {documentos
          .filter(
            (doc) => doc.status === 'Rejeitado' || doc.status === 'Pendente',
          )
          .map((document) => {
            return (
              <Docs key={document.id}>
                <div>
                  <span>
                    <Block />
                  </span>
                  <p>
                    {document.nome} - <b>{document.status}</b>
                    {!isNullOrEmpty(document.observacao)
                      ? ':' + document.observacao
                      : null}
                  </p>
                </div>
              </Docs>
            );
          })}
      </section>
    </Container>
  );
}
