import React, { ReactNode } from 'react';

import { Container } from './styles';
import { ModalCard } from '../../Modals';

interface Props {
  color: string;
  button: ReactNode;
  checkbox: ReactNode;
  term: ReactNode;
}

export default function AlertNegotiation({
  color,
  button,
  checkbox,
  term,
}: Props) {
  const style = {
    position: 'fixed',
    width: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: 'thin solid currentColor',
    padding: '0 32px 24px',

    '@media(max-width: 780px)': {
      width: '100%',
    },
  };

  return (
    <Container>
      <section className={color}>
        <div>
          <span className="check">{checkbox}</span>

          <span className="text">
            <span>
              <ModalCard style={style}>{term}</ModalCard>
            </span>
            <div>
              <p>
                Li e estou de acordo com os termos para a negociação das faturas
                selecionadas.
              </p>
            </div>
          </span>
        </div>
        {button}
      </section>
    </Container>
  );
}
