import React, { useContext } from 'react';

import * as S from './styles';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../ProviderNewStudent';

interface Props {
  notFoundText?: string;
  toRender: boolean;
}
export default function AletNotfoundLoading({ notFoundText, toRender }: Props) {
  const { loading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  return (
    toRender && (
      <S.Container>
        <S.Alert>
          {loading
            ? 'Carregando...'
            : notFoundText
            ? notFoundText
            : 'Nenhum Item encontrado.'}
        </S.Alert>
      </S.Container>
    )
  );
}
