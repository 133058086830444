import React, { useContext, useState } from 'react';

import * as S from './styles';

import CardInfo from '../CardInfo';
import MonitorIcon from '@mui/icons-material/Monitor';
import Divider from '../../../common/Divider';

import {
  contractServiceNewStudent,
  IDataUnsignedContracts,
} from '../../../../services/newStudent/contract/contract.service';
import { formatDate } from '../../../../util/common/appUtil';
import ModalContract from '../../../common/Modal/ModalContract';
import FieldFormik from '../../FieldFormik';
import { ButtonSimple } from '../../Buttons';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../ProviderNewStudent';

interface Props {
  contract: IDataUnsignedContracts;
  index: number;
}
export default function CardUsignedContract({ contract, index }: Props) {
  const { setUnsignedContracts, unsignedContracts, setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [contractData, setContractData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [agree, setAgree] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const { result, data } = await contractServiceNewStudent.getContractContent(
      contract.id,
    );
    if (result) {
      setContractData(data.content);
      setIsOpenModal(true);
    }
    setIsLoading(false);
  };
  const handleSignContract = async () => {
    setIsLoading(true);
    try {
      const { result } = await contractServiceNewStudent.signContract(
        contract.id,
      );
      if (result) {
        setAgree(false);
        const tmpContracts = unsignedContracts;
        tmpContracts.splice(index, 1);
        setUnsignedContracts(tmpContracts);

        tmpContracts.length > 0
          ? localStorage.setItem('unsignedContracts', 'true')
          : localStorage.removeItem('unsignedContracts');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <CardInfo
        icon={<MonitorIcon fontSize="large" />}
        title={contract.courseTypeName}
        justify="center"
      >
        <Divider color="gray03" />
        <S.Subtitle>{contract.courseName}</S.Subtitle>
        <S.SubtitleH6 justify="center">
          Inscrição em {formatDate(contract.registrationDate)}
        </S.SubtitleH6>
        <Divider color="gray03" />
        <S.SubtitleH6>
          Ao clicar em <b>Assinar</b> o&nbsp;
          <a onClick={() => fetchData()} href="#">
            Termo de Aceite
          </a>
          , você estará assinando eletronicamente o nosso contrato.
        </S.SubtitleH6>
        <Divider padding />
        <S.ButtonCheckbox>
          <FieldFormik
            name="agree"
            onChange={() => setAgree(!agree)}
            type="checkbox"
            placeholder="Li e Concordo."
            checked={agree}
            widthSize="fullWidth"
          />
          <ButtonSimple
            color="primary"
            onClick={handleSignContract}
            disabled={!agree}
          >
            Confirmar
          </ButtonSimple>
        </S.ButtonCheckbox>
      </CardInfo>
      {contractData && (
        <ModalContract
          open={isOpenModal}
          setOpen={setIsOpenModal}
          title="Termo de aceite"
        >
          <div dangerouslySetInnerHTML={{ __html: contractData }} />
        </ModalContract>
      )}
    </S.Container>
  );
}
