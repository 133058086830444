import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 0;
  gap: 2rem;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const TitleDiv = styled.div``;

interface Props {
  noBorder?: boolean;
}

export const DivData = styled.div<Props>`
  width: 100%;
  padding: ${(props) => (props.noBorder ? '3rem 0' : '3rem 2rem')};

  border: ${(props) =>
    props.noBorder ? '' : `1px solid ${props.theme.colors?.gray03}`};
  border-radius: 12px;
`;

export const DivInformaiton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    grid-template-columns: 1fr;
  }
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const KeyValueList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DivDoc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ValueDoc = styled.h6`
  padding-left: 1rem;
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors?.primary};

  gap: 1rem;
`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivDigital = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    width: 100%;
    align-items: center;
  }
`;

export const DivRequest = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2rem;
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;
export const DivSelect = styled.div`
  display: flex;
  gap: 1rem;
`;
export const DivSendType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors?.primary};

  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    gap: 1rem;
  }
  @media screen and (min-width: ${(props) => props.theme.size?.small}) {
    flex-direction: row;
  }
`;

export const Button = styled.div`
  display: flex;

  justify-content: center;

  width: 100%;
`;
