import { Box, Paper, BoxProps, SxProps } from '@mui/material';

import styled from 'styled-components';

interface BoxStyleProps extends BoxProps {
  isFloat?: boolean;
}

export const BoxStyle: React.ComponentType<BoxStyleProps & SxProps> = styled(
  Box,
)<BoxStyleProps>`
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  display: flex;
  padding: 5px;
  max-width: 100%;
`;

interface IPaperProps {
  noPadding?: boolean;
}
export const PaperStyled = styled(Paper)<IPaperProps>`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: ${(props) => (props.noPadding ? '100vw' : '100%')};
  height: 100%;

  @media screen and (max-width: 485px) {
    width: ${(props) => (props.noPadding ? '100vw' : '130%')};
  }
`;
