import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import * as S from './styles';

interface ChartState {
  series: number[];
  options: ApexOptions;
}

export default function ChartStatement({ pillar }) {
  const [chartState, setChartState] = useState<ChartState>({
    series: [100, 70, 50, 30],
    options: {
      colors: ['#331e54', '#002147', '#00539f', '#0095dd'],
      labels: ['Tempo Mínimo', 'Documentação', 'Financeiro', 'Avaliações'],
      chart: {
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '10%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: true,
              color: '#000',
            },
          },
        },
      },

      legend: {
        show: true,
        floating: true,
        fontSize: '14px',
        fontWeight: '600',
        position: 'left',
        offsetX: 150,
        offsetY: 8,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          width: 8,
          height: 8,
        },
        formatter: function (seriesName, opts) {
          return (
            seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + '%'
          );
        },
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              offsetX: 60,
              offsetY: 15,
            },
          },
        },
        {
          breakpoint: 524,
          options: {
            legend: {
              offsetX: 0,
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    const updatedSeries = pillar
      ? [
          pillar.courseMinimumTime !== null ? pillar.courseMinimumTime : 0,
          pillar.documentationDelivered !== null
            ? pillar.documentationDelivered
            : 0,
          pillar.studentPlanPaid !== null
            ? pillar.studentPlanPaid
              ? 1
              : 0
            : 0,
          pillar.completedEvaluations !== null
            ? pillar.completedEvaluations
            : 0,
        ]
      : [0, 0, 0, 0];

    setChartState((prevState) => ({
      ...prevState,
      series: updatedSeries,
    }));
  }, [pillar]);

  return (
    <div id="chart">
      <S.Chart>
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="radialBar"
          height={300}
          pillar={pillar}
        />
      </S.Chart>
    </div>
  );
}
