import styled from 'styled-components';

export const Container = styled.div`
  margin: 2rem;
`;

export const TitlePage = styled.h5`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors?.primary};

  gap: 1rem;
`;

export const Button = styled.div`
  display: flex;
  justify-content: end;
`;
