import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Block } from '@mui/icons-material';
import { Navigate } from 'react-router';
import Carousel from 'react-bootstrap/Carousel';

import { IBanner } from '../../../interfaces/student/dashboard.interfaces';
import ModalVideo from '../../../components/student/Modals/ModalVideo';
import { LoaderComponent } from '../../../components/student/Loaders';
import { authStudentService } from '../../../services/auth';
import {
  IStudentContextProps,
  StudentProviderContext,
} from '../../../components/student/StudentProvider';
import { DashboardInfo } from './components';
import { Container } from './styles';

import {
  AlertMessage,
  AlertBox,
  Deadlines,
  AlertDocumentation,
} from '../../../components/student/Alerts';
import { successToast } from '../../../components/common/Toast';
import { useNavigate } from 'react-router-dom';

type DataInfo = {
  banners: IBanner[];
  empresaConfiguracao: string[];
};

export default function Dashboard() {
  const queryParameters = new URLSearchParams(window.location.search);
  const message = queryParameters.get('message');
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const context = useContext(StudentProviderContext);
  const {
    aluno,
    documents,
    setIsLoading,
    registrationWithoutContracts,
    shouldCheckPersonalInformation,
  } = context as IStudentContextProps;

  const dataStorageValue = localStorage.getItem('dataStorage');
  let dataInfo: DataInfo | null = null;
  if (dataStorageValue !== null) {
    dataInfo = JSON.parse(dataStorageValue);
  }
  const banners = useMemo(() => dataInfo?.banners ?? [], [dataInfo]);
  const memoizedBanners = useMemo(() => banners.sort(), [banners]);
  const empresaConfiguracao = useMemo(
    () => dataInfo?.empresaConfiguracao ?? [],
    [dataInfo],
  );
  const navigate = useNavigate();

  useEffect(() => {
    const hasMessageParam = new URLSearchParams(location.search).has('message');

    if (hasMessageParam && message === 'true') {
      successToast(
        'Parabéns, você foi aprovado! O seu curso foi desbloqueado e você poderá acessá-lo após assinatura do contrato.',
      );

      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('message');
      navigate(searchParams.toString());
    }
  }, [location.search, history]);

  useEffect(() => {
    const fetchData = async () => {
      const loggedUser = authStudentService.getLoggedUser();
      if (!loggedUser) {
        setRedirectTo('/new/aluno');
        return;
      }
      if (loggedUser && aluno.id !== 0) {
        if (
          registrationWithoutContracts.length > 0 ||
          shouldCheckPersonalInformation()
        ) {
          setRedirectTo('/aluno/conferencia-dados');
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [aluno, registrationWithoutContracts]);

  return (
    <>
      {redirectTo ? (
        <Navigate to={redirectTo} />
      ) : (
        <>
          {aluno?.videos && aluno.videos[0]?.url && (
            <ModalVideo advertising={aluno.videos} />
          )}
          <div id="conteudo" className="texto">
            {aluno?.status === 5 && (
              <AlertMessage
                key={aluno.id}
                icon={<Block />}
                title={'Atenção:'}
                color={'info'}
                message={
                  <>
                    Seu acesso esta restrito, acesse o <b>Chat</b> no canto
                    inferior direito para mais informações.
                  </>
                }
              />
            )}
            {banners.length <= 0 ? (
              <LoaderComponent text={''} />
            ) : (
              <Container>
                <Carousel
                  fade
                  nextIcon={
                    <span
                      aria-hidden="true"
                      className="glyphicon glyphicon-chevron-right"
                    />
                  }
                  prevIcon={
                    <span
                      aria-hidden="true"
                      className="glyphicon glyphicon-chevron-left"
                    />
                  }
                >
                  {memoizedBanners.map((banner, index) => {
                    if (banner.link_url) {
                      return (
                        <Carousel.Item key={index}>
                          <a href={banner.link}>
                            <img
                              className="d-block w-100"
                              src={banner.link_url}
                              alt="Banner Aluno"
                            />
                          </a>
                        </Carousel.Item>
                      );
                    }
                    return null;
                  })}
                </Carousel>
              </Container>
            )}

            <DashboardInfo />

            {/* Tabela de Documentos Pendentes */}
            {documents.length > 0 &&
              documents.filter(
                (doc) =>
                  doc.status === 'Pendente' || doc.status === 'Rejeitado',
              ).length > 0 && (
                <AlertDocumentation
                  title="Documentação"
                  message={`Você possui ${
                    documents.filter(
                      (doc) =>
                        doc.status === 'Pendente' || doc.status === 'Rejeitado',
                    ).length
                  } documento(s) obrigatório(s) Pendentes(s)`}
                  documentos={documents}
                />
              )}
            <>
              <AlertBox
                boxTitle="PRAZOS DE ENTREGAS E ORIENTAÇÕES"
                backgroundColor={'default'}
              />
              {/* Prazos de Entrega e Orientações */}
              <Deadlines data={empresaConfiguracao} />
            </>
          </div>
        </>
      )}
    </>
  );
}
