import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Checkbox from '@mui/material/Checkbox';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { TaskAlt } from '@mui/icons-material';
import moment from 'moment';

import {
  AlertBar,
  AlertNegotiation,
} from '../../../../../components/student/Alerts';
import { creditCardIugu, company } from '../../../../../variables';
import { AccordionCustom } from '../../../../../components/student/Accordions';
import { getDiscountValue } from '../../../../../util/calculationUtil';
import { NegotiationTerm } from '../../../../../components/student/Terms';
import { LoaderComponent } from '../../../../../components/student/Loaders';
import { BasicButton } from '../../../../../components/student/Buttons';
import { CheckCustom, Container } from '../../styles';
import { BasicSelect } from '../../../../../components/student/Select';

import { authStudentService } from '../../../../../services/auth';
import { differenceInDays, toLocaleString } from '../../../../../util/pageUtil';
import {
  HeaderTableNegotiation,
  BodyTableNegotiation,
} from '../../../../../components/student/Table/NegotiationTable';
import { payment as payments } from '../../../../../util/financialUtil';
import { Value } from '../';
import {
  IBankAccount,
  IBillet,
  IPayment,
} from '../../../../../interfaces/student/installments.interface';
import { infoToast } from '../../../../../components/common/Toast';
import { financialService } from '../../../../../services';

const student = JSON.parse(localStorage.getItem('alunoStorage') || 'null');

interface InstallmentsAdvanceProps {
  activeBilletsProp: (billets: IBillet[]) => void;
  bankAccountProp?: IBankAccount[];
  numberOfParcels: number;
  advanceBilletsProp: Ticket[];
}

export interface Ticket {
  id: number;
  vencimento_data_atualizada?: string;
  vencimento_data: string;
  vencimento_valor_atualizado?: string;
  vencimento_valor: string;
  plano_contas_nome: string;
}

export default function InstallmentsAdvance({
  activeBilletsProp,
  bankAccountProp,
  numberOfParcels,
  advanceBilletsProp,
}: InstallmentsAdvanceProps) {
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [totalDiscount, setTotalDiscound] = useState<number>(0);
  const [valueDiscount, setValueDiscount] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [checkedConfirm, setCheckedConfirm] = useState<boolean>(false);
  const [billets, setBillets] = useState<number[]>([]);
  const [payment, setPayment] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<IPayment>();
  const [loading, setLoading] = useState<boolean>(false);
  const [parcels, setParcels] = useState<number>(1);

  useEffect(() => {
    const loggedUser = authStudentService.getLoggedUser();
    if (!loggedUser) {
      setRedirectTo('/new/aluno');
    }
  }, []);

  async function installmentsAdvance() {
    setLoading(true);

    const data = {
      id: billets,
      forma_pagamento: payment,
    };

    const response = await financialService.installmentsAdvance(data);
    activeBilletsProp(response);
  }

  function handleCheck(e, ticket: Ticket) {
    const dueDate = ticket.vencimento_data_atualizada
      ? ticket.vencimento_data_atualizada
      : ticket.vencimento_data;

    const getDiscount = differenceInDays(dueDate, moment(), 'DD/MM/YYYY') > 10;

    const discountValue = getDiscount
      ? getDiscountValue(paymentType?.desconto, ticket.vencimento_valor)
      : 0;

    const dueValue = ticket.vencimento_valor_atualizado
      ? parseFloat(ticket.vencimento_valor_atualizado.replace(',', '.'))
      : parseFloat(ticket.vencimento_valor.replace(',', '.'));

    const valueWithDiscount = dueValue - discountValue;

    const value = getDiscount ? valueWithDiscount : dueValue;

    if (e.target.checked) {
      setTotal(total + dueValue);
      setTotalDiscound(totalDiscount + value);
      setValueDiscount(valueDiscount + discountValue);
      setBillets((billet) => [...billet, ticket.id]);
    } else {
      setChecked(false);
      setTotal(total - dueValue);
      setTotalDiscound(totalDiscount - value);
      setValueDiscount(valueDiscount - discountValue);
      setBillets(billets.filter((item) => item !== ticket.id));
    }
  }

  function handleParcelsChange(selectedOption) {
    setParcels(selectedOption.value);
  }

  function handleChange(e) {
    setPaymentType(e);
    setTotal(0);
    setTotalDiscound(0);
    setValueDiscount(0);
    setBillets([]);
    setChecked(false);
    setCheckedConfirm(false);

    if (paymentType?.id !== e.value) {
      setPayment(e.value);
    } else if (!e.value) {
      setPayment(0);
    }
  }

  const selectAllCheckboxes = (event) => {
    const check: number[] = [];
    let total = 0;
    let totalWithDiscoun = 0;
    let discount = 0;

    if (paymentType?.id && paymentType?.id > 0) {
      if (event.target.checked) {
        advanceBilletsProp.forEach((billet: Ticket) => {
          const dueDate = billet.vencimento_data_atualizada
            ? billet.vencimento_data_atualizada
            : billet.vencimento_data;

          const takeDiscount =
            differenceInDays(dueDate, moment(), 'DD/MM/YYYY') > 10;

          const discountValue = takeDiscount
            ? getDiscountValue(paymentType?.desconto, billet.vencimento_valor)
            : 0;

          const dueValue = billet.vencimento_valor_atualizado
            ? parseFloat(billet.vencimento_valor_atualizado)
            : parseFloat(billet.vencimento_valor);

          const valueWithDiscount = dueValue - discountValue;
          const value = takeDiscount ? valueWithDiscount : dueValue;

          check.push(billet.id);

          discount += discountValue;
          total += dueValue;
          totalWithDiscoun += value;
        });
        setBillets(check);
        setTotal(total);
        setTotalDiscound(totalWithDiscoun);
        setValueDiscount(discount);
        setChecked(true);
      } else {
        setBillets([]);
        setTotal(0);
        setTotalDiscound(0);
        setValueDiscount(0);
        setChecked(false);
      }
    } else {
      infoToast('Favor selecione a Forma de Pagamento', 1);
    }
  };

  const paymentOptions: IPayment[] = payments.selectPayment(bankAccountProp);

  const maxParcels = 10;
  const parcelsOptions = Array.from(
    {
      length:
        payment.toString() === creditCardIugu ? maxParcels : numberOfParcels,
    },
    (_, i) => ({
      value: i + 1,
      label: `${i + 1}x`,
    }),
  );

  const acceptParcels = payment.toString() === creditCardIugu;
  return redirectTo ? (
    <Navigate to={redirectTo} />
  ) : (
    <>
      <AccordionCustom
        color={'success'}
        icon={<MonetizationOnIcon />}
        iconBar={<TaskAlt />}
        title={'Antecipar'}
        titleBox={
          advanceBilletsProp.length > 1
            ? `Você possui ${advanceBilletsProp.length} faturas em aberto!`
            : `Você possui ${advanceBilletsProp.length} fatura em aberto!`
        }
        message={
          advanceBilletsProp.length > 1
            ? 'Considere adiantar suas parcelas ou quitar o seu plano estudantil com desconto.'
            : 'Considere adiantar sua parcela com desconto.'
        }
      >
        <AlertBar
          message={
            'Valor de desconto só é aplicado para pagamentos com mais de 10 dias para a data de vencimento.'
          }
          color={'info'}
        />
        {loading ? (
          <LoaderComponent text={'Carregando'} />
        ) : (
          <Form>
            <Container>
              <BasicSelect
                label={'Forma de Pagamento'}
                title={'Selecione a forma de pagamento'}
                payment={paymentOptions}
                onChange={handleChange}
                value={paymentType}
              />
              {acceptParcels && (
                <BasicSelect
                  label={'Número de Parcelas'}
                  title={'Selecione o número de parcelas'}
                  payment={parcelsOptions}
                  defaultValue={parcelsOptions[0]}
                  onChange={handleParcelsChange}
                  value={{ value: parcels, label: `${parcels}X` }}
                />
              )}
            </Container>
            <HeaderTableNegotiation
              selectAll={
                <CheckCustom>
                  <Checkbox
                    id="checkAll"
                    name="checkAll"
                    className="checkAll"
                    checked={checked}
                    onClick={selectAllCheckboxes}
                  />
                </CheckCustom>
              }
            >
              {advanceBilletsProp.map((billet, index) => {
                const dueValue = billet.vencimento_valor_atualizado
                  ? billet.vencimento_valor_atualizado
                  : billet.vencimento_valor;

                const currentValue =
                  paymentType?.id && paymentType?.id > 0
                    ? toLocaleString(
                        getDiscountValue(paymentType?.desconto, dueValue),
                      )
                    : 'R$ 0,00';

                return (
                  <BodyTableNegotiation
                    id={billet.id}
                    key={billet.id + '_' + index}
                    accountName={billet.plano_contas_nome}
                    dueDate={
                      billet.vencimento_data_atualizada
                        ? billet.vencimento_data_atualizada
                        : billet.vencimento_data
                    }
                    dueDateValue={`R$ ${dueValue}`}
                    currentValue={currentValue}
                    check={
                      <Checkbox
                        id="check"
                        name="check"
                        value={billet.id}
                        key={billet.id}
                        className="check"
                        checked={billets.includes(billet.id)}
                        onClick={() => {
                          !paymentType?.id &&
                            infoToast(
                              'Favor selecione a Forma de Pagamento',
                              1,
                            );
                        }}
                        onChange={(e) => {
                          paymentType?.id &&
                            paymentType?.id > 0 &&
                            handleCheck(e, billet);
                        }}
                      />
                    }
                  />
                );
              })}
            </HeaderTableNegotiation>

            <Value
              data={[
                {
                  title: 'Valor',
                  value: total ? toLocaleString(total) : 'R$ 0,00',
                },
                { title: 'Desconto', value: toLocaleString(valueDiscount) },
                {
                  title: 'Valor Total',
                  value: toLocaleString(totalDiscount),
                },
              ]}
            />

            {
              <AlertNegotiation
                term={
                  <NegotiationTerm
                    studentName={student && student.nome}
                    companyName={student && student.empresa_id === 2 && company}
                  />
                }
                checkbox={
                  <span
                    style={{
                      pointerEvents: payment ? 'auto' : 'none',
                    }}
                  >
                    <Checkbox
                      checked={checkedConfirm}
                      className="checkConfirm"
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                      onChange={(e) => {
                        setCheckedConfirm(e.target.checked);
                      }}
                    />
                  </span>
                }
                color={'success'}
                button={
                  <BasicButton
                    type="button"
                    title={'Li e concordo'}
                    color={'success'}
                    link={installmentsAdvance}
                    disabled={checkedConfirm === true ? false : true}
                  />
                }
              />
            }
          </Form>
        )}
      </AccordionCustom>
    </>
  );
}
