import React from 'react';

import * as S from './styles';
import { ITicketSimple } from '../TicketAlert';
import Divider from '../../../../../components/common/Divider';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import { PrintOutlined } from '@mui/icons-material';

interface Props {
  ticket: ITicketSimple;
  color: 'danger' | 'success' | 'warning';
}
export default function TicketDescription({ ticket, color }: Props) {
  return (
    <S.Container>
      <S.KeyValue>
        <S.Key>Situação: </S.Key>
        <S.Value color={color}>{ticket.status}</S.Value>
      </S.KeyValue>
      <S.KeyValue>
        <S.Key>Protocolo nº: </S.Key>
        <S.Value color={color}>{ticket.protocol}</S.Value>
      </S.KeyValue>
      <S.KeyValue>
        <S.Key>Movimentações: </S.Key>
      </S.KeyValue>
      {ticket.actions.map((item, index) => (
        <S.KeyValueItem key={index}>
          <S.Key>
            Movimentação n°{index + 1} incluída por {item.businessName} no dia{' '}
            {item.createdDate}
          </S.Key>
          {item.attachments.length > 0 &&
            item.attachments.map((attachment) => (
              <S.DivAnexos key={attachment.number}>
                <S.H6>{attachment.fileName}</S.H6>
                <ButtonSimple
                  color="success"
                  heightSize="small"
                  widthSize="small"
                  onClick={() => window.open(attachment.link)}
                >
                  <PrintOutlined fontSize="large" />
                </ButtonSimple>
              </S.DivAnexos>
            ))}
          <div
            dangerouslySetInnerHTML={{ __html: item.description }}
            style={{ wordBreak: 'break-word' }}
          />
          <Divider color="gray02" />
        </S.KeyValueItem>
      ))}
    </S.Container>
  );
}
