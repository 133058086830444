import {
  calculateDifferenceDays,
  convertStringToMoment,
  differenceInDays,
} from '../../../../util/pageUtil';
import { IBillet } from '../../../../interfaces/student/installments.interface';
import moment from 'moment';
import {
  advancePaymentAccountPlan,
  lackAccountPlan,
  monthlyPaymentAccountPlan,
  negotiationAccountPlan,
} from '../../../../variables';
import * as apiService from '../../../../services/api/student/api.service';

export async function loadInvoices() {
  const paidBillets: IBillet[] = [];
  const expiredBillets: IBillet[] = [];
  const allActiveBillets: IBillet[] = [];
  const allActiveBilletsOpen: IBillet[] = [];
  const activeBilletsAntecipation: IBillet[] = [];
  const expiredBilletsForNegotiation: IBillet[] = [];

  const planoContas = [
    lackAccountPlan,
    negotiationAccountPlan,
    advancePaymentAccountPlan,
    monthlyPaymentAccountPlan,
  ];

  try {
    const response = await apiService.authenticatedRequest(
      '/student/financial/historic',
    );
    if (!response.result) {
      return {
        data: {
          paidBillets,
          expiredBillets,
          allActiveBillets,
          allActiveBilletsOpen,
          activeBilletsAntecipation,
          expiredBilletsForNegotiation,
          contaBancaria: response.data.conta_bancaria,
          numeroParcelasMaxima: response.data.numero_parcelas_maximo,
        },
      };
    }

    if (response?.data.lancamentos) {
      response.data.lancamentos.map((lancamento: IBillet) => {
        if (lancamento.status === 'Ativo') {
          allActiveBillets.push(lancamento);
        }

        const boletoComissao = lancamento.comissao === 1;
        const dataVencimento = lancamento.vencimento_data_atualizada
          ? lancamento.vencimento_data_atualizada
          : lancamento.vencimento_data;
        const status = lancamento.status === 'Ativo';
        const pagamentoVazio =
          (lancamento.pagamento_data && lancamento.pagamento_valor) == null;
        const boletoVencido =
          calculateDifferenceDays(convertStringToMoment(dataVencimento)) > 0;

        const existeNoPlanoContas = planoContas.filter((val: string) => {
          return parseInt(val) === lancamento.plano_contas_id;
        });
        const naoAntecipa =
          differenceInDays(dataVencimento, moment(), 'DD/MM/YYYY') <= 5;

        if (status && pagamentoVazio) {
          allActiveBilletsOpen.push(lancamento);
        }

        /** --- Boletos Ativos ---  */
        if (
          status &&
          pagamentoVazio &&
          !naoAntecipa &&
          !boletoVencido &&
          !boletoComissao &&
          existeNoPlanoContas.length > 0
        ) {
          activeBilletsAntecipation.push(lancamento);
        }

        /** --- BOLETOS PAGOS --- */
        if (status && !pagamentoVazio) {
          paidBillets.push(lancamento);
        }

        /** --- BOLETOS VENCIDOS --- */
        if (status && pagamentoVazio && boletoVencido) {
          expiredBillets.push(lancamento);
        }

        /** --- BOLETOS APTOS PARA NEGOCIAÇÃO --- */
        if (
          status &&
          pagamentoVazio &&
          boletoVencido &&
          !boletoComissao &&
          lancamento.plano_contas_id ===
            parseInt(process.env.REACT_APP_MONTHLY_PAYMENT_ACCOUNT_PLAN || '')
        ) {
          expiredBilletsForNegotiation.push(lancamento);
        }
      });
    }

    return {
      data: {
        paidBillets,
        expiredBillets,
        allActiveBillets,
        allActiveBilletsOpen,
        activeBilletsAntecipation,
        expiredBilletsForNegotiation,
        contaBancaria: response.data.conta_bancaria,
        numeroParcelasMaxima: response.data.numero_parcelas_maximo,
      },
    };
  } catch {
    throw new Error('Erro ao buscar os Boletos, Tente novamente mais tarde!');
  }
}
