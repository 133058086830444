import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import { Dialog } from '@mui/material';

interface Props {
  children: ReactNode;
  fullScreen?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  scroll?: 'body' | 'paper';
  handleOnClose?: () => void;
}
export default function BasicDialog({
  children,
  fullScreen = false,
  isOpen,
  setIsOpen,
  scroll,
  handleOnClose,
}: Props) {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={() => {
        handleOnClose && handleOnClose();
        setIsOpen(false);
      }}
      scroll={scroll}
    >
      {children}
    </Dialog>
  );
}
