import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { ContainerSenha } from './styles';
import HeaderPages from '../../../components/student/HeaderPages';
import { AlertBar } from '../../../components/student/Alerts';
import { LoaderComponent } from '../../../components/student/Loaders';
import { studentService } from '../../../services';

export function ChangePassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const [messageWarning, setMessageWarning] = useState<string>('');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [senhaAtual, setSenhaAtual] = useState<string>('');
  const [novaSenha, setNovaSenha] = useState<string>('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState<string>('');

  const changePassword = async () => {
    setLoading(true);
    setMessageWarning('');
    setMessageSuccess('');

    const data = {
      old_password: senhaAtual,
      new_password: novaSenha,
      confirm_password: confirmarNovaSenha,
    };

    const response = await studentService.changePassword(data);
    if (response.result) {
      setSenhaAtual('');
      setNovaSenha('');
      setConfirmarNovaSenha('');
      setMessageSuccess(response.data);
      setLoading(false);
    } else {
      setMessageWarning(response.message);
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderPages title="Alterar Senha de Acesso" />

      {loading ? (
        <LoaderComponent />
      ) : (
        <ContainerSenha>
          {(messageWarning || messageSuccess) && (
            <AlertBar
              color={messageWarning ? 'warning' : 'success'}
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: messageWarning ? messageWarning : messageSuccess,
                  }}
                />
              }
            />
          )}
          <Form>
            <fieldset style={{ paddingTop: 0 }}>
              <Row>
                <Col xs={12} sm={4} lg={4} style={{ marginTop: 10 }}>
                  <Form.Group>
                    <Form.Label>Senha Atual</Form.Label>
                    <Form.Control
                      type="password"
                      name="senhaAtual"
                      value={senhaAtual}
                      onChange={(e) => setSenhaAtual(e.target.value)}
                      placeholder="Senha Atual"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} lg={4} style={{ marginTop: 10 }}>
                  <Form.Group>
                    <Form.Label>Nova Senha</Form.Label>
                    <Form.Control
                      type="password"
                      name="novaSenha"
                      value={novaSenha}
                      onChange={(e) => setNovaSenha(e.target.value)}
                      placeholder="Nova Senha"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} lg={4} style={{ marginTop: 10 }}>
                  <Form.Group>
                    <Form.Label>Repetir Nova Senha</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmarNovaSenha"
                      value={confirmarNovaSenha}
                      onChange={(e) => setConfirmarNovaSenha(e.target.value)}
                      placeholder="Repetir Nova Senha"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row
                xs={12}
                sm={12}
                lg={12}
                style={{
                  marginTop: 5,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Col
                  xs={12}
                  sm={3}
                  lg={3}
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                  }}
                >
                  <label> </label>
                  <button
                    className="btn btn-primary mw-100"
                    onClick={(e) => {
                      e.preventDefault();
                      changePassword();
                    }}
                  >
                    Salvar
                  </button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </ContainerSenha>
      )}
    </>
  );
}

export default ChangePassword;
