import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
`;

export const H1 = styled.h1`
  font-size: 3rem;
  font-weight: bold;
`;

export const Button = styled.button`
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
`;
