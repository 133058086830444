import React, { useContext, useEffect, useMemo, useState } from 'react';

import * as S from './styles';
import TableBasic from '../../../../../components/newStudent/Table';
import FieldFormik from '../../../../../components/newStudent/FieldFormik';
import { Formik } from 'formik';
import {
  cleanCache,
  formatCurrency,
  formatDate,
} from '../../../../../util/common/appUtil';
import {
  getInterestByDate,
  handleCalcPercent,
  sumNumbers,
} from '../../../../../util/newStudent/financialHistory';
import NegotiationTerm from '../NegotiationTerm';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';
import {
  financialServiceNewStudent,
  IInfoDebtAndNegotiationBillets,
} from '../../../../../services/newStudent/financial/financial.service';
import { IPaymentType } from '../../../../../services/newStudent/helper/helper.service';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { company } from '../../../../../variables';

interface Props {
  dataInvoice: IInfoDebtAndNegotiationBillets[];
  isNegotiation?: boolean;
  paymentType: IPaymentType[];
}

export default function AdvanceAndDebtNegotiation({
  dataInvoice,
  isNegotiation,
  paymentType,
}: Props) {
  const { setIsLoading, student } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [readed, setReaded] = useState<boolean>();
  const [billets, setBillets] = useState<number[]>([]);

  const [selectedPaymentType, setSelectedPaymentType] = useState<
    number | undefined
  >(0);

  const [selectedOptionsParcels, setSelectedOptionsParcels] = useState<
    number | undefined
  >(0);

  const optionsPaymentType = useMemo(() => {
    return [
      { title: '[ Selecione a forma de pagamento ]', value: undefined },
      ...(paymentType.length > 0
        ? paymentType
            .filter((item) => item.description)
            .map((item) => ({
              title: item.description!.split(' ')[0],
              value: item.id,
            }))
        : []),
    ];
  }, [paymentType]);

  const option = useMemo(() => {
    return selectedPaymentType
      ? paymentType.find((item) => item.id === selectedPaymentType)
      : undefined;
  }, [selectedPaymentType]);

  const parcelOptionSelected = useMemo(() => {
    return selectedOptionsParcels
      ? option?.parcels.find((item) => item.quantity === selectedOptionsParcels)
      : undefined;
  }, [option, selectedOptionsParcels]);

  const optionsParcels = useMemo(() => {
    return [
      { title: '[ Número de parcelas ]', value: undefined },
      ...(option?.parcels
        ? option.parcels.map((item) => ({
            title: item.quantity + 'X',
            value: item.quantity,
          }))
        : []),
    ];
  }, [option]);

  const dataFiltered = useMemo(() => {
    if (isNegotiation) return dataInvoice;

    return dataInvoice.filter((item) => billets.includes(item.id));
  }, [billets]);

  const optionTableTitle = isNegotiation
    ? {
        item: <h6 style={{ fontWeight: 'bold' }}>Juros e multa</h6>,
        align: 'center',
      }
    : {
        item: (
          <input
            type="checkbox"
            checked={billets?.length === dataInvoice?.length}
            onChange={() => {
              if (billets?.length === dataInvoice?.length) {
                setBillets([]);
              } else {
                setBillets(dataInvoice?.map((item) => +item.id));
              }
            }}
          />
        ),
        align: 'center',
      };

  const dataTable = {
    title: [
      {
        item: <h6 style={{ fontWeight: 'bold' }}>Referência</h6>,
        align: 'center',
      },
      {
        item: <h6 style={{ fontWeight: 'bold' }}>Vencimento</h6>,
        align: 'center',
      },
      {
        item: <h6 style={{ fontWeight: 'bold' }}>Valor</h6>,
        align: 'center',
      },
      {
        item: <h6 style={{ fontWeight: 'bold' }}>Desconto</h6>,
        align: 'center',
      },
      optionTableTitle,
    ],
    content:
      dataInvoice &&
      dataInvoice.map((item) => {
        return {
          invoiceReference: {
            item: <h6>{item.invoiceReference}</h6>,
            align: 'center',
          },
          dueDate: {
            item: <h6>{formatDate(item.dueDate)}</h6>,
            align: 'center',
          },
          value: {
            item: <h6>{formatCurrency(item.value)}</h6>,
            align: 'center',
          },
          discount: {
            item: (
              <h6>
                {formatCurrency(
                  handleCalcPercent(
                    item.value,
                    parcelOptionSelected?.discount ?? 0,
                  ),
                )}
              </h6>
            ),
            align: 'center',
          },
          interest: {
            item: (
              <h6>
                {formatCurrency(
                  handleCalcPercent(
                    item.value,
                    parcelOptionSelected?.penalty ?? 0,
                  ) +
                    getInterestByDate(
                      item.value,
                      parcelOptionSelected?.interest ?? 0,
                      item.daysOfDelay,
                    ),
                )}
              </h6>
            ),
            align: 'center',
          },
          checkbox: {
            item: (
              <input
                type="checkbox"
                checked={billets.includes(item.id)}
                onChange={({ target }) => {
                  if (target.checked) {
                    setBillets((old) => [...old, item.id]);
                  } else {
                    const updateBillets = [...billets];
                    updateBillets.splice(billets.indexOf(item.id), 1);
                    return setBillets(updateBillets);
                  }
                }}
              />
            ),
            align: 'center',
          },
        };
      }),
  };

  /** Variável que seleciona se vai mostrar os juros ou não */
  const optionTableTotal = isNegotiation
    ? [
        {
          item: <h6 style={{ fontWeight: 'bold' }}>Juros e multa</h6>,
          align: 'center',
        },
        {
          item: <h6 style={{ fontWeight: 'bold' }}>Valor Total</h6>,
          align: 'center',
        },
      ]
    : [
        {
          item: <h6 style={{ fontWeight: 'bold' }}>Valor Total</h6>,
          align: 'center',
        },
      ];

  const dataTotalTable = {
    title: [
      {
        item: <h6 style={{ fontWeight: 'bold' }}>Valor</h6>,
        align: 'center',
      },
      {
        item: <h6 style={{ fontWeight: 'bold' }}>Desconto</h6>,
        align: 'center',
      },
      ...optionTableTotal,
    ],
    content: [
      {
        value: {
          item: (
            <h6>
              {formatCurrency(
                sumNumbers(dataFiltered?.map((item) => +item.value)),
              )}
            </h6>
          ),
          align: 'center',
        },
        discount: {
          item: (
            <h6>
              {formatCurrency(
                sumNumbers(
                  dataFiltered?.map((item) =>
                    handleCalcPercent(
                      item.value,
                      parcelOptionSelected?.discount ?? 0,
                    ),
                  ),
                ),
              )}
            </h6>
          ),
          align: 'center',
        },
        interest: {
          item: (
            <h6>
              {formatCurrency(
                sumNumbers(
                  dataFiltered?.map(
                    (item) =>
                      handleCalcPercent(
                        item.value,
                        parcelOptionSelected?.penalty ?? 0,
                      ) +
                      getInterestByDate(
                        item.value,
                        parcelOptionSelected?.interest ?? 0,
                        item.daysOfDelay,
                      ),
                  ),
                ),
              )}
            </h6>
          ),
          align: 'center',
        },
        totalValue: {
          item: (
            <h6>
              {formatCurrency(
                sumNumbers(
                  dataFiltered?.map(
                    (item) =>
                      item.value +
                      (isNegotiation
                        ? handleCalcPercent(
                            item.value,
                            parcelOptionSelected?.penalty ?? 0,
                          ) +
                          getInterestByDate(
                            item.value,
                            parcelOptionSelected?.interest ?? 0,
                            item.daysOfDelay,
                          )
                        : 0) -
                      handleCalcPercent(
                        item.value,
                        parcelOptionSelected?.discount ?? 0,
                      ),
                  ),
                ),
              )}
            </h6>
          ),
          align: 'center',
        },
      },
    ],
  };

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const body = {
        id: billets,
        paymentType: selectedPaymentType ?? 0,
        parcel: selectedOptionsParcels ?? 0,

        total: sumNumbers(
          dataFiltered?.map(
            (item) =>
              item.value +
              (isNegotiation
                ? handleCalcPercent(
                    item.value,
                    parcelOptionSelected?.penalty ?? 0,
                  ) +
                  getInterestByDate(
                    item.value,
                    parcelOptionSelected?.interest ?? 0,
                    item.daysOfDelay,
                  )
                : 0) -
              handleCalcPercent(
                item.value,
                parcelOptionSelected?.discount ?? 0,
              ),
          ),
        ),
      };

      const { result } = isNegotiation
        ? await financialServiceNewStudent.installmentsNegotiation(body)
        : await financialServiceNewStudent.installmentsAdvance(body);

      if (result) {
        cleanCache('dataContracts');
        localStorage.removeItem('dataStudent');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isNegotiation) return;
    setBillets(dataInvoice.map((item) => item.id));
  }, []);

  const validPaymentType = (id?: number) => {
    const validate = optionsPaymentType.find((item) => item.value === +id!);
    if (validate) {
      if (
        validate.title.toLowerCase().includes('pix') ||
        validate.title.toLowerCase().includes('bole')
      ) {
        setSelectedOptionsParcels(1);
        return false;
      }
    }
    return true;
  };
  return (
    <S.Container>
      <Formik
        initialValues={{
          selectPaymentType: undefined,
          selectOptionsParcels: undefined,
        }}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ handleChange, setFieldValue, values }) => (
          <S.DivSelect>
            <S.H6>Forma de Pagamento: </S.H6>
            <FieldFormik
              name="selectPaymentType"
              type="select"
              value={values.selectPaymentType}
              onChange={(e) => {
                handleChange(e);
                setSelectedPaymentType(+e.target.value);
                setSelectedOptionsParcels(undefined);
                setReaded(undefined);
                setFieldValue('selectOptionsParcels', 0);
              }}
              options={optionsPaymentType}
              widthSize="xxlarge"
            />
            {!!selectedPaymentType &&
              validPaymentType(values.selectPaymentType) && (
                <FieldFormik
                  name="selectOptionsParcels"
                  type="select"
                  value={values.selectOptionsParcels}
                  onChange={(e) => {
                    handleChange(e);
                    setReaded(undefined);
                    setSelectedOptionsParcels(+e.target.value);
                  }}
                  options={optionsParcels}
                  widthSize="large"
                />
              )}
          </S.DivSelect>
        )}
      </Formik>
      <TableBasic
        titleNotFound=" "
        data={dataTable}
        exception={isNegotiation ? ['checkbox'] : ['interest']}
      />
      <TableBasic
        titleNotFound=" "
        data={dataTotalTable}
        exception={isNegotiation ? [''] : ['interest']}
      />

      <S.DivSend>
        <S.CheckboxDiv>
          <S.TermButton onClick={() => setIsModalOpen(true)}>
            Termo de Aceite.
          </S.TermButton>
          <FieldFormik
            name="readed"
            placeholder="Li e confirmo que as informações aqui contidas estão corretas e não precisam de alterações."
            onChange={(e) => {
              setReaded(e.target.checked);
            }}
            disabled={!selectedOptionsParcels}
            checked={readed}
            type="checkbox"
            widthSize="fullWidth"
          />
        </S.CheckboxDiv>
        <ButtonSimple
          color="primary"
          widthSize="xmedium"
          disabled={!readed}
          onClick={() => handleSend()}
        >
          Confirmar
        </ButtonSimple>
      </S.DivSend>
      <NegotiationTerm
        name={student.dataStudent.name}
        company={company ?? 'Famart'}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        ableBackdropClick
      />
    </S.Container>
  );
}
