import React from 'react';

import * as S from './styles';
import { IDataCourseCards } from '../..';
import { CardLeftColor } from '../../../../../../../components/newStudent/Card';
import { ButtonSimple } from '../../../../../../../components/newStudent/Buttons';
import Divider from '../../../../../../../components/common/Divider';
import { formatDate } from '../../../../../../../util/common/appUtil';

interface Props {
  dataCourseCards: IDataCourseCards[];
  onClick: (e: number) => void;
}
export default function CardCourse({ dataCourseCards, onClick }: Props) {
  return (
    <S.Container>
      {dataCourseCards.map((item: IDataCourseCards, index: number) => (
        <>
          <CardLeftColor key={index} color="primary" heightSize="fullHeight">
            <S.CardContent>
              <S.KeyValue>
                <S.Key>Curso: </S.Key>
                <S.Value>{item.courseName}</S.Value>
              </S.KeyValue>
              <S.KeyValue>
                <S.Key>Status: </S.Key>
                <S.Value>{item.status}</S.Value>
              </S.KeyValue>
              <S.KeyValue>
                <S.Key>Tipo: </S.Key>
                <S.Value>{item.type}</S.Value>
              </S.KeyValue>
              <S.KeyValue>
                <S.Key>Data de Solicitação: </S.Key>
                <S.Value>{formatDate(item.date ?? '')}</S.Value>
              </S.KeyValue>
              {item.observation && <S.Value>{item.observation}</S.Value>}
              {item.url && (
                <S.Button>
                  {item.type === 'Certificado Digital' ? (
                    <ButtonSimple
                      color="success"
                      heightSize="medium"
                      widthSize="medium"
                      onClick={() => window.open(item.url ?? '')}
                    >
                      Baixar certificado
                    </ButtonSimple>
                  ) : (
                    <div></div>
                  )}
                  <ButtonSimple
                    color="primary"
                    heightSize="medium"
                    widthSize="medium"
                    onClick={() => onClick(item.registrationId)}
                  >
                    Certificado Físico
                  </ButtonSimple>
                </S.Button>
              )}
            </S.CardContent>
          </CardLeftColor>
          <Divider padding color="gray02" />
        </>
      ))}
    </S.Container>
  );
}
