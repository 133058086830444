import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  title: string | undefined;
  subtitle: string | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleOnClose: () => void;
}
export default function TitleModal({
  title,
  subtitle,
  setIsOpen,
  handleOnClose,
}: Props) {
  return (
    <S.Container>
      <S.TitleSubtitleDiv>
        <S.DivName>
          <S.H4>{title?.toUpperCase()}</S.H4>
        </S.DivName>
        <S.Div>
          <S.H6>{subtitle}</S.H6>
        </S.Div>
      </S.TitleSubtitleDiv>
      <S.ButtonDiv>
        <S.ButtonClose
          onClick={() => {
            handleOnClose();
            setIsOpen(false);
          }}
        >
          <CloseIcon fontSize="large" />
        </S.ButtonClose>
      </S.ButtonDiv>
    </S.Container>
  );
}
