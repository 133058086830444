import { IPole } from '../../../interfaces/newStudent/dataStudent';
import * as api from '../../api/newStudent/apiStudent.service';

export const poleServiceNewStudent = {
  async getAll(): Promise<{
    data: IPole[];
    message: string[];
    result: boolean;
  }> {
    try {
      const response = await api.authenticatedRequest(
        '/administrator/pole/list-in-person',
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
