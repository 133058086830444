import React, { useContext, useEffect, useState } from 'react';

import { BasicTable } from '../../../../common/Table';
import {
  ProviderAgentContext,
  IAgentContextProps,
} from '../../../ProviderAgent';
import { errorToast } from '../../../../common/Toast';
import studentDataService from '../../../../../services/student/student.data.service';
import { IStudentDocuments } from '../../../../../services/agent/student/interface';

export default function TabDocuments({ data: login }: { data: string }) {
  const context = useContext(ProviderAgentContext);
  const { isLoading, setIsLoading } = context as IAgentContextProps;

  const [documents, setDocuments] = useState<IStudentDocuments[] | []>([]);

  useEffect(() => {
    setIsLoading(true);

    const load = async () => {
      const { data, result, message } = await studentDataService.documents({
        login: login,
      });

      if (result) {
        setDocuments(data);
      } else {
        message.length > 0 &&
          message.forEach((text) => {
            errorToast(text);
          });
      }
      setIsLoading(false);
    };

    load();
  }, [login]);

  const structureColor = {
    Concluído: '#008000',
    Pendente: '#F00',
  };

  const tableData = {
    content: documents.map((item) => ({
      name: {
        item: <b>{item.title}</b>,
      },
      status: {
        item: (
          <b style={{ color: structureColor[item.status] }}>{item.status}</b>
        ),
      },
    })),
  };

  return (
    <>
      <BasicTable data={tableData} titleLoading={isLoading} />
    </>
  );
}
