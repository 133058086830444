import styled from 'styled-components';

export const Container = styled.div`
  text-align: justify;

  .tituloTermo {
    margin-top: 2.7rem;
    font-weight: 900;
    text-align: center;
  }
  .textoTermo {
    padding-top: 1rem;
    font-size: 1.4rem;
  }

  .destaqueTexto {
    font-weight: bold;
  }
`;
