import React, { Dispatch, SetStateAction, useMemo } from 'react';

import * as S from './styles';
import BasicTab, { IDataBasicTab } from '../../../../BasicTab';
import TabLesson from './components/TabLesson';
import { ISubjectContentData } from '../..';
import { TabExam } from './components';
import { IClassInfo } from '../../../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';
import {
  IExam,
  ISubjectContent,
} from '../../../../../../services/newStudent/myCourses/myCourses.service';
import {
  courseAssessmentContent,
  courseAuxiliaryContent,
  courseDisciplineContent,
} from '../../../../../../variables';

interface Props {
  blockedContent: boolean;
  setBlockedContent: Dispatch<SetStateAction<boolean>>;
  contentSelected: string;
  setContentSelected: Dispatch<SetStateAction<string>>;
  /** Conteúdo da Disciplina  */
  setSubjectContent: Dispatch<SetStateAction<ISubjectContentData>>;
  /** Conteúdo da Disciplina  */
  setSubjectAuxiliaryContent: Dispatch<SetStateAction<ISubjectContentData>>;
  /** Contúdo da Prova */
  setSubjectExam: Dispatch<SetStateAction<IExam>>;
  classInfo: IClassInfo;
}

export default function TabClass({
  blockedContent,
  setBlockedContent,
  contentSelected,
  setContentSelected,
  setSubjectContent,
  setSubjectExam,
  classInfo,
  setSubjectAuxiliaryContent,
}: Props) {
  const findId = (id: string | number) => {
    if (!classInfo.content) return 0;

    const filteredContent = classInfo.content.filter(
      (item: ISubjectContent) => +item?.contentType === +id,
    );

    if (filteredContent.length > 0) {
      return +filteredContent[0].contentId;
    }

    return 0;
  };

  const dataTab: IDataBasicTab[] | [] = useMemo(() => {
    if (!classInfo.content || classInfo.content.length <= 0) return [];

    const data = {
      [courseDisciplineContent!]: {
        title: 'Conteúdo da Disciplina',
        children: (
          <TabLesson
            setSubjectContent={setSubjectContent}
            classInfo={classInfo}
            setBlockedContent={setBlockedContent}
            contentIdSelected={findId(courseDisciplineContent!)}
          />
        ),
        id: +courseDisciplineContent!,
        disabled: blockedContent,
      },
      [courseAuxiliaryContent!]: {
        title: 'Conteúdo Auxiliar',
        children: (
          <TabLesson
            setSubjectContent={setSubjectAuxiliaryContent}
            classInfo={classInfo}
            setBlockedContent={setBlockedContent}
            contentIdSelected={findId(courseAuxiliaryContent!)}
          />
        ),
        id: +courseAuxiliaryContent!,
        disabled: blockedContent,
      },
      [courseAssessmentContent!]: {
        title: 'Avaliações',
        children: (
          <TabExam
            setSubjectExam={setSubjectExam}
            setBlockedContent={setBlockedContent}
            blockedContent={blockedContent}
            classInfo={classInfo}
            contentIdSelected={findId(courseAssessmentContent!)}
          />
        ),
        id: +courseAssessmentContent!,
        disabled: false,
      },
    };

    return classInfo.content.map(
      (item: ISubjectContent) => data[+item.contentType],
    );
  }, [classInfo, blockedContent]);

  return (
    <S.Container>
      <BasicTab
        data={dataTab}
        defaultValue={contentSelected}
        setValueSelected={setContentSelected}
      />
    </S.Container>
  );
}
