import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';

import { IMyGradesCore } from '../../../../../../../services/grade/grade.service';
import {
  SubtitleTable,
  TitleTable,
  RowText,
  DivRow,
  RowTextBold,
  MyTableRow,
  MyTableCell,
} from '../../../../../../../components/student/Table/components';
import * as S from './styles';

interface Props {
  rows: IMyGradesCore[];
}

export default function TableSubject({ rows }: Props) {
  const situationMap = {
    'Em Andamento': { situacao: 'Em Andamento', color: '#000' },
    Aprovado: { situacao: 'Aprovado', color: '#008000' },
    'Em Recuperação': { situacao: 'Em Recuperação', color: '#D75413' },
    Reprovado: { situacao: 'Reprovado', color: '#F00' },
    Dispensado: { situacao: 'Dispensado', color: '#008000' },
  };

  const groupByModule = (data: IMyGradesCore[]) => {
    const groupedData = {};
    data?.forEach((myGrades: IMyGradesCore) => {
      const moduleName = myGrades.modulo_nome;
      if (!groupedData[moduleName]) {
        groupedData[moduleName] = [];
      }
      groupedData[moduleName].push(myGrades);
    });
    return groupedData;
  };

  const groupedRows = groupByModule(rows);

  return (
    <div style={{ marginBottom: '5rem' }}>
      {Object.keys(groupedRows).map((moduleName, index: number) => {
        const moduleData = groupedRows[moduleName];
        return (
          <span key={index}>
            <S.ContainerTitle>
              <TitleTable>{moduleName}</TitleTable>
            </S.ContainerTitle>
            <TableContainer>
              <Table component={Paper}>
                <TableHead>
                  <MyTableRow>
                    <TableCell align="left" className="title">
                      <SubtitleTable color="gray">Disciplina</SubtitleTable>
                    </TableCell>
                    <MyTableCell align="right" className="grade">
                      <SubtitleTable color="gray">Nota</SubtitleTable>
                    </MyTableCell>
                    <MyTableCell align="center" className="status">
                      <SubtitleTable color="gray">Tentativa</SubtitleTable>
                    </MyTableCell>
                    <MyTableCell align="center" className="status">
                      <SubtitleTable color="gray">Situação</SubtitleTable>
                    </MyTableCell>
                  </MyTableRow>
                </TableHead>
                <TableBody>
                  {moduleData.map((myGrades: IMyGradesCore, index: number) => (
                    <MyTableRow key={index}>
                      <TableCell scope="row" className="title-row">
                        <RowText align="left">
                          {myGrades.disciplina_nome}
                        </RowText>
                      </TableCell>
                      <TableCell scope="row" className="grade-row">
                        <DivRow>
                          <RowText align="left">
                            {myGrades.nota ? myGrades.nota : '-'}
                          </RowText>
                        </DivRow>
                      </TableCell>
                      <TableCell scope="row" className="status-row">
                        <S.SituationRow>
                          <RowTextBold>
                            {myGrades.tentativas}ª Tentativa
                          </RowTextBold>
                        </S.SituationRow>
                      </TableCell>
                      <TableCell>
                        <RowTextBold
                          color={situationMap[myGrades.situacao]?.color}
                        >
                          {situationMap[myGrades.situacao]?.situacao}
                        </RowTextBold>
                      </TableCell>
                    </MyTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </span>
        );
      })}
    </div>
  );
}
