import React from 'react';

import * as S from './styles';
import ChartMultipleRadialBar, {
  IInfoChartProps,
} from '../../common/ChartMultipleRadialBar';
import { useTheme } from 'styled-components';

interface Pillar {
  minimumTime: number;
  documentation: number;
  financial: number;
  exam: number;
}
export interface IDataCourseStudent {
  title: string;
  structure: {
    title: string;
    value: number | string | {
      completed: boolean;
      obrigatory: boolean;
      status: string;
      title: string;
      value: string;
    }[];
  }[];
  certificateCanBeRequested?: {
    digital: boolean;
    concrete?: boolean;
    secondCopy?: boolean;
    observation?: string;
  };
  pillar: Pillar;
}

interface Props {
  dataStudentCourse: IDataCourseStudent;
}
export default function MinimumRequirements({ dataStudentCourse }: Props) {
  const theme = useTheme();

  const dataChart: IInfoChartProps[] = [
    {
      color: theme.colors?.primary ?? '',
      label: 'Tempo Mínimo',
      value: dataStudentCourse.pillar.minimumTime ?? 0,
    },
    {
      color: theme.colors?.secondary ?? '',
      label: 'Documentação',
      value: dataStudentCourse.pillar.documentation ?? 0,
    },
    {
      color: theme.colors?.attention ?? '',
      label: 'Financeiro',
      value: dataStudentCourse.pillar.financial ?? 0,
    },
    {
      color: theme.colors?.detail ?? '',
      label: 'Avaliação',
      value: dataStudentCourse.pillar.exam ?? 0,
    },
  ];

  return (
    <S.Container>
      <S.TitleDiv>
        <S.Title>{dataStudentCourse.title}:</S.Title>
      </S.TitleDiv>
      <S.DivInformaiton>
        <div style={{ paddingBottom: '1rem' }}>
          {dataStudentCourse?.structure.map((item, index) => (
            <S.KeyValue key={index} isArray={Array.isArray(item.value)}>
              <S.Key>{item.title}: </S.Key>
              {Array.isArray(item.value) ? (
                <S.DivDoc>
                  {item.value.map((item2, i) => (
                    <S.ValueDoc key={i}>{item2.title}</S.ValueDoc>
                  ))}
                </S.DivDoc>
              ) : (
                <S.Value>{item.value}</S.Value>
              )}
            </S.KeyValue>
          ))}
        </div>
        <div>
          <ChartMultipleRadialBar dataItems={dataChart} />
        </div>
      </S.DivInformaiton>
    </S.Container>
  );
}
