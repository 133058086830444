import React, { ReactNode } from 'react';

import { Container } from './styles';

interface Props {
  color: string;
  title: string;
  message: string;
  children: ReactNode;
  iconBar: ReactNode;
}

export default function NegotiationAlert({
  color,
  title,
  message,
  children,
  iconBar,
}: Props) {
  return (
    <Container>
      <section className={color}>
        <div className="content">
          <span className="icon">{iconBar}</span>
          <span className="message">
            <h4>{title}</h4>
            <p>{message}</p>
          </span>
        </div>

        <span className="button">{children}</span>
      </section>
    </Container>
  );
}
