import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #0071bd;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 481px) {
    padding: 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;
  gap: 2rem;

  @media screen and (max-width: 481px) {
    width: 25rem;
    table {
      display: none;
    }
  }
`;

export const DivImg = styled.div`
  display: flex;
  flex-direction: row;
  width: 35rem;

  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 481px) {
    flex-direction: column;
    width: 25rem;
  }
`;

export const Img = styled.img`
  max-width: 15rem;

  @media screen and (max-width: 481px) {
    max-width: 15rem;
  }
`;

export const TitleError = styled.h1`
  font-size: 50px;
  color: white;
  font-weight: 500;

  line-height: 1;

  @media screen and (max-width: 481px) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.h5`
  color: white;
`;

export const Text = styled.h6`
  color: white;
`;

export const Td = styled.td`
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
`;

export const DivPhone = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  color: white;
  padding: 1rem 2rem;
  @media screen and (max-width: 481px) {
    padding: 0;
  }
`;

export const Phone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 12px;

  tr td:last-child,
  tr th:last-child {
    border-right: none;
  }
  tr:last-child > td {
    border-bottom: none;
  }
`;

export const Row = styled.tr``;

export const DivMobile = styled.div`
  display: none;
  @media screen and (max-width: 481px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ImgLogo = styled.img`
  max-width: 15rem;
`;

export const DivImgLogo = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 426px) {
    justify-content: center;
  }
`;
