import React, { ReactNode } from 'react';

import * as S from './styles';

import CircleIcon from '@mui/icons-material/Circle';

interface Props {
  children: ReactNode;
  color?: 'danger' | 'success' | 'primary';
  flexDirection?: 'row' | 'column';
  noPoint?: boolean;
  pointer?: boolean;
  buttons?: ReactNode;
  onClick?: () => void;
  className?: string;
}
export default function BulletPoint({
  children,
  color,
  noPoint,
  buttons,
  onClick,
  pointer,
  flexDirection = 'row',
  className,
}: Props) {
  return (
    <S.Container
      onClick={onClick && onClick}
      pointer={pointer}
      className={className}
    >
      <S.Content>
        {!noPoint && (
          <S.Point color={color}>
            <CircleIcon fontSize="small" />
          </S.Point>
        )}
        <S.Data flexDirection={flexDirection}>
          {typeof children === 'string' ? (
            <S.H6>{children}</S.H6>
          ) : Array.isArray(children) ? (
            <S.H6>{children!.toString().replace(/,/g, '')}</S.H6>
          ) : (
            children
          )}
          {buttons}
        </S.Data>
      </S.Content>
    </S.Container>
  );
}
