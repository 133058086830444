import styled from 'styled-components';

export const Container = styled.div`
  height: 52px;
  background: #2a2725;

  width: 100vw;
  position: fixed;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
`;
