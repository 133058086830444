import React from 'react';

import HeaderPages from '../../../components/student/HeaderPages';
import { AlertBar } from '../../../components/student/Alerts';
import { Container } from './styles';

export default function StudentService() {
  return (
    <>
      <HeaderPages title="Atendimento ao Aluno" />

      <Container>
        <AlertBar
          color={'info aviso'}
          message={
            <p>
              <b>E-mail Atendimento:</b> centraldoaluno@famart.edu.br
            </p>
          }
        />
        <AlertBar
          color={'info aviso'}
          message={
            <p>
              <b>Telefone Atendimento:</b> 0800 942 5006
            </p>
          }
        />

        <AlertBar
          color={'info aviso'}
          message={
            <p>
              Caso seu motivo não esteja listado acima, você pode entrar em
              contato com a gente pelo chat no canto inferior direito.
            </p>
          }
        />
      </Container>
    </>
  );
}
