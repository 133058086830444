import React, { ReactNode } from 'react';

import { Col, Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import * as S from './styles';

export interface IFieldOptions {
  title?: string;
  value?: boolean | string | number;
}

export interface IFieldForm {
  title?: string | ReactNode;
  placeholder?: string;
  name?: string;
  type: string;
  value?: boolean | string | number | Date;
  checked?: boolean;
  handleChange: (e) => void;
  lg?: number;
  disabled?: boolean;
  isInvalid?: boolean;
  maxLength?: number;
  options?: IFieldOptions[];
  rows?: number;
  style?: object;
  flexDirection?: 'row' | 'column' | 'row-reverse';
}

interface Props {
  item: IFieldForm;
}

export default function FormField({ item }: Props) {
  const textStyle = {
    backgroundColor: 'white',
    minHeight: '3.5rem',
    color: item.disabled ? '#b6b6b6' : '#1b1b1b',
    borderColor: item.disabled ? '#dadada' : '#a5a4a4',
    borderRadius: '1rem',
    ...item?.style,
  };
  const max = item.maxLength;
  return (
    <Col lg={item.lg} style={{ marginTop: 10 }}>
      <Form.Group
        style={{
          display: 'flex',
          flexDirection: item.flexDirection ? item.flexDirection : 'column',
          gap: item.flexDirection ? '1rem' : undefined,
          alignItems: item.flexDirection ? 'center' : undefined,
        }}
      >
        {item.title && <Form.Label>{item.title}</Form.Label>}
        {item.name?.replace('P', 'p').includes('phone') ? (
          <Form.Label
            className="input"
            style={{ width: '35rem', ...textStyle }}
          >
            <InputMask
              placeholder="(00) 00000-0000"
              mask="(99) 99999-9999"
              name={item.name}
              isInvalid={item.isInvalid}
              disabled={item.disabled}
              type={item.type}
              onChange={(e) => item.handleChange(e)}
              value={item.value?.toString() ?? ''}
              style={textStyle}
            />
          </Form.Label>
        ) : item.type!.includes('date') ? (
          <S.Style>
            <Form.Control
              style={{
                ...textStyle,
              }}
              isInvalid={item.isInvalid}
              name={item.name}
              disabled={item.disabled}
              type={item.type}
              defaultValue={item?.value?.toString()}
              onChange={(e) => item.handleChange(e)}
              value={
                typeof item.value === 'string' || typeof item.value === 'number'
                  ? item.value
                  : ''
              }
            />
          </S.Style>
        ) : (
          (item.type!.includes('string') || item.type!.includes('number')) && (
            <S.Style>
              <Form.Control
                style={{
                  ...textStyle,
                }}
                isInvalid={item.isInvalid}
                onInvalid={(e) => e.preventDefault()}
                name={item.name}
                disabled={item.disabled}
                type={item.type}
                maxLength={max}
                value={item.value?.toString() ?? ''}
                placeholder={item.placeholder}
                onChange={(e) => item.handleChange(e)}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
              />
            </S.Style>
          )
        )}

        {item.type! === 'text' && (
          <S.ContainerText>
            <Form.Control
              style={{ ...textStyle }}
              name={item.name}
              isInvalid={item.isInvalid}
              disabled={item.disabled}
              as="textarea"
              rows={item.rows}
              maxLength={max}
              value={item.value?.toString() ?? ''}
              placeholder={item.placeholder}
              onChange={(e) => item.handleChange(e)}
            />
          </S.ContainerText>
        )}
        {item.type! === 'file' && (
          <Form.Control
            style={{ ...textStyle }}
            name={item.name}
            isInvalid={item.isInvalid}
            disabled={item.disabled}
            type={item.type}
            onChange={(e) => item.handleChange(e)}
          />
        )}
        {item.type === 'checkbox' && (
          <Form.Check
            style={{ fontSize: '20px', ...textStyle }}
            isInvalid={item.isInvalid}
            checked={item.checked}
            type={item.type}
            disabled={item.disabled}
            value={item.value?.toString() ?? ''}
            onChange={(e) => item.handleChange(e)}
          />
        )}
        {item.type === 'select' && (
          <Form.Select
            name={item.name}
            isInvalid={item.isInvalid}
            disabled={item.disabled}
            onChange={(e) => item.handleChange(e)}
            style={{ fontSize: '13px', ...textStyle }}
            value={
              item.value !== undefined && item.value !== null
                ? item.value.toString()
                : ''
            }
          >
            {item.options?.map((item, index) => {
              return (
                item && (
                  <option
                    key={index}
                    value={
                      item.value !== undefined ? item.value.toString() : ''
                    }
                  >
                    {item.title}
                  </option>
                )
              );
            })}
          </Form.Select>
        )}
      </Form.Group>
    </Col>
  );
}
