import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import * as S from './styles';

import TitleModal from '../../Title/TitleModal';
import Divider from '../../../common/Divider';
import ContentSelected from './components/ContentSelected';
import TabClass from './components/TabClass';

import { BasicDialog } from '../../Dialog';
import TitleDialogMobile from '../../Title/TitleDialogMobile';
import { handleMaxWidth } from '../../../../util/newStudent';
import { useTheme } from 'styled-components';
import { handleMinWidth } from '../../../../util/newStudent/windowWidthSize';
import { query } from '../../../../util/common/queryUtil';
import { IClassInfo } from '../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';
import { IExam } from '../../../../services/newStudent/myCourses/myCourses.service';

export interface ISubjectContentData {
  contentId: string | number;
  type: 'video' | 'archive' | 'audiobook' | 'customized' | 'internship' | 'tcc';
  url: string;
  title?: string;
  customized?: string;
  resourceId?: string | number;
}

export interface ISubjectAuxiliaryContent {
  id: number;
  title: string;
  authors?: string[];
  completed?: boolean;
  url: string;
}

export interface IExamAnswerSheet {
  number: number;
  response: string;
  result: boolean;
  date: string;
}
interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  classInfo: IClassInfo;
}
export default function ClassModal({ isOpen, setIsOpen, classInfo }: Props) {
  const [contentSelected, setContentSelected] = useState('1');
  const [blockedContent, setBlockedContent] = useState(
    classInfo.examInProgress,
  );
  const theme = useTheme();

  const [subjectContent, setSubjectContent] = useState<ISubjectContentData>();
  const [subjectAuxiliaryContent, setSubjectAuxiliaryContent] =
    useState<ISubjectContentData>();
  const [subjectExam, setSubjectExam] = useState<IExam>();

  /** Se o aluno estiver bloqueado, força a ir para a avaliação */
  useEffect(() => {
    setBlockedContent(classInfo.examInProgress);
    if (classInfo.examInProgress) {
      setContentSelected('3');
    }
  }, [contentSelected, classInfo.examInProgress]);

  useEffect(() => {
    if (classInfo.examInProgress) {
      setContentSelected('3');
    } else {
      setContentSelected(String(classInfo.content?.[0]?.contentType));
    }
  }, [classInfo]);

  const handleClose = () => {
    setIsOpen(false);
    setBlockedContent(false);
    setSubjectExam(undefined);
    setSubjectContent(undefined);
    setSubjectAuxiliaryContent(undefined);
    query.cleanOrKeepSomeQueryParams('course');
  };

  return (
    <BasicDialog
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      handleOnClose={handleClose}
      fullScreen
      scroll="body"
    >
      {handleMaxWidth(theme.size?.medium) && (
        <>
          <TitleDialogMobile
            setIsOpen={setIsOpen}
            title="Meus Cursos"
            onClose={handleClose}
          />
          <S.DivTitle>
            <h5>{classInfo?.title}</h5>
            <h6>{classInfo?.subtitle}</h6>
          </S.DivTitle>
        </>
      )}
      <S.Container>
        {handleMinWidth(theme.size?.medium) && (
          <S.TitleDiv>
            <TitleModal
              title={classInfo?.title}
              subtitle={classInfo?.subtitle}
              setIsOpen={setIsOpen}
              handleOnClose={handleClose}
            />
          </S.TitleDiv>
        )}
        <Divider />
        <S.Content>
          <S.ContentSelected>
            <ContentSelected
              selected={contentSelected}
              subjectContent={subjectContent!}
              subjectAuxiliaryContent={subjectAuxiliaryContent!}
              subjectExam={subjectExam!}
              setSubjectExam={setSubjectExam}
              setContentSelected={setContentSelected}
              setBlockedContent={setBlockedContent}
              classInfo={classInfo}
            />
          </S.ContentSelected>
          {handleMaxWidth(theme.size?.medium) && <Divider padding />}
          <S.Tabs>
            <TabClass
              contentSelected={contentSelected}
              setContentSelected={setContentSelected}
              setSubjectContent={setSubjectContent}
              setSubjectAuxiliaryContent={setSubjectAuxiliaryContent}
              setSubjectExam={setSubjectExam}
              blockedContent={blockedContent}
              setBlockedContent={setBlockedContent}
              classInfo={classInfo}
            />
          </S.Tabs>
        </S.Content>
      </S.Container>
    </BasicDialog>
  );
}
