import styled from 'styled-components';

export const Container = styled.div``;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem 0;
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  gap: 0.5rem;
`;

export const Key = styled.h6`
  color: ${({ theme }) => theme.colors?.text.primary};
`;

export const Value = styled.h6`
  color: ${({ theme }) => theme.colors?.text.secondary};
`;

export const Unavailable = styled.h6`
  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const Button = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
