import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from './styles';
import { BasicDialog } from '../../../../../components/newStudent/Dialog';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../components/newStudent/ProviderNewStudent';
import { certificateServiceNewStudent } from '../../../../../services/newStudent';
import { courseTypeCapacitation } from '../../../../../variables';
import { IDataRequirement } from '../../../../../services/newStudent/certificate/certificate.service';
import { ITableCapacitation } from '../ContentCapacitation';
import TitleDialogMobile from '../../../../../components/newStudent/Title/TitleDialogMobile';
import Divider from '../../../../../components/common/Divider';
import { CardLeftColor } from '../../../../../components/newStudent/Card';
import { ButtonSimple } from '../../../../../components/newStudent/Buttons';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function ContentCapacitationMobile({
  isOpen,
  setIsOpen,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [dataTableCapacitation, setDataCapacitation] = useState<
    ITableCapacitation[] | []
  >([]);

  const [requirements, setRequirements] = useState<IDataRequirement>();

  useEffect(() => {
    setIsLoading(true);
    const load = async () => {
      const { data, result } = await certificateServiceNewStudent.show({
        type: +courseTypeCapacitation!,
      });

      if (result) {
        setDataCapacitation(() =>
          data.certificates.map((certificate) => ({
            title: certificate.courseName,
            subtitle: certificate.courseType,
            url: certificate.url,
          })),
        );
        setRequirements(data.requirements);
      }

      setIsLoading(false);
    };
    load();
  }, []);
  return (
    <BasicDialog isOpen={isOpen} setIsOpen={setIsOpen} fullScreen scroll="body">
      <TitleDialogMobile setIsOpen={setIsOpen} title={'Meus Certificados'} />
      <S.Container>
        <S.TitlePage>Capacitação</S.TitlePage>
        <Divider padding />
        {requirements?.data && requirements?.data.length > 0 && (
          <S.TitleContent className="requirements">
            <S.Title>{requirements.title}</S.Title>
            <S.Request>
              {requirements.data.map((item, index) => (
                <S.Row key={index}>
                  <S.Key>{item.title}: </S.Key>
                  <S.Value>{item.status}</S.Value>
                </S.Row>
              ))}
            </S.Request>
          </S.TitleContent>
        )}
        <Divider padding color="gray02" />
        {dataTableCapacitation.map(
          (item: ITableCapacitation, index: number) => (
            <>
              <CardLeftColor
                key={index}
                color="primary"
                heightSize="fullHeight"
              >
                <S.CardContent>
                  <div>
                    <S.Key>{item.title}</S.Key>
                    <S.Value>{item.subtitle}</S.Value>
                  </div>
                  {item.url ? (
                    <S.Button>
                      <ButtonSimple
                        color="success"
                        heightSize="small"
                        widthSize="large"
                        onClick={() => window.open(item.url ?? '')}
                      >
                        Baixar certificado
                      </ButtonSimple>
                    </S.Button>
                  ) : (
                    <S.Button>
                      <S.Unavailable>Indisponível</S.Unavailable>
                    </S.Button>
                  )}
                </S.CardContent>
              </CardLeftColor>
              <Divider padding color="gray02" />
            </>
          ),
        )}
      </S.Container>
    </BasicDialog>
  );
}
