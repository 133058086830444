import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
`;

export const Input = styled.input`
  width: 200px;
`;

export const InputDate = styled.input`
  width: 100%;
  height: 32px;
  padding: 8px 10px;
  border: 1px solid;
  border-radius: 3px;
  background: #fff;

  font:
    13px/16px 'Open Sans',
    Helvetica,
    Arial,
    sans-serif;
`;

export const RowForm = styled.div`
  display: flex;
  justify-content: start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1.5rem;
  background-color: #fff;

  @media screen and (max-width: 985px) {
    justify-content: center;
  }
`;

export const RowName = styled.div`
  margin: 1rem 2rem;
  width: 100%;
  gap: 2rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 985px) {
    flex-direction: column;
  }
`;

export const ContainerRowDate = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin: 2rem 0 0 1rem;

  @media screen and (max-width: 985px) {
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 1rem 0;
  }
`;

export const RowDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .itemDate {
    padding: 1rem 1rem 0 1rem;
  }

  @media screen and (max-width: 525px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .itemDate {
      padding: 0;
    }
  }
  @media screen and (max-width: 424px) {
  }
`;

export const ContainerFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6rem;
  background: #f8f8f8e6;
  padding: 2rem;
`;

export const Item = styled.div`
  gap: 2rem;
  width: 100%;
  display: flex;

  @media screen and (max-width: 525px) {
    flex-direction: column;
  }
`;

export const ItemDate = styled.div`
  width: 15rem;

  @media screen and (max-width: 525px) {
    margin: 0;
    width: 35rem;
  }

  @media screen and (max-width: 424px) {
    margin: 0;
    width: 30rem;
  }
  @media screen and (max-width: 335px) {
    margin: 0;
    width: 24rem;
  }
`;

export const Error = styled.h6`
  color: red;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 5px 0;
  width: 15rem;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: 985px) {
    flex-direction: column;
    margin: auto;
    padding: 2rem;
  }

  @media screen and (max-width: 985px) {
    flex-direction: column;
    margin: auto;
    padding: 2rem;
  }
`;

export const Registration = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 525px) {
    flex-direction: column;
  }
`;
