import styled from 'styled-components';

export const Container = styled.div``;

export const Focus = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  gap: 2rem;

  opacity: 0;
  animation: focus 1s;
  @keyframes focus {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  opacity: 1;
`;

export const FocusCard = styled.div`
  opacity: 0;

  animation: heightFocus 1s;
  @keyframes heightFocus {
    from {
      opacity: 0;
    }
    to {
      opacity: 100%;
    }
  }

  opacity: 1;
`;

export const TitleCard = styled.h5`
  padding-top: 0.5rem;
  font-weight: bold;
`;

export const ContentRequest = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 0;

  height: 100%;
  gap: 0.5rem;
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 1rem;
`;

interface Props {
  color?: 'success' | 'danger';
}
export const Key = styled.h6<Props>`
  font-weight: bold;
  color: ${(props) =>
    props.color
      ? props.theme.colors?.text[props.color]
      : props.theme.colors?.text.primary};

  text-align: center;
`;

export const Value = styled.h6<Props>`
  color: ${(props) =>
    props.color
      ? props.theme.colors?.text[props.color]
      : props.theme.colors?.text.primary};

  @media screen and (max-width: ${({ theme }) => theme.size?.medium}) {
    text-align: center;
  }
`;

export const Div = styled.div`
  display: flex;
  gap: 0.5rem;

  color: ${({ theme }) => theme.colors?.text.danger};
`;

export const RegistrationRow = styled.div``;

export const Row = styled.div`
  display: flex;

  justify-content: space-between;
`;

export const RowKey = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
`;

export const RowValue = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;

  width: 20rem;
  padding-left: 1rem;

  gap: 1rem;
`;
