export function getPhone(
  phone: string,
  position: 'homePhone' | 'cellPhone' | 'businessPhone',
) {
  const select = {
    homePhone: 0,
    cellPhone: 1,
    businessPhone: 2,
  };

  const splited = (phone && phone.split(';')) || null;

  if (!splited) {
    return '';
  }

  return splited[select[position]];
}

export async function addDataCache(
  cacheName: string,
  data: object,
  url: string,
  expirationTimeSeconds?: number,
) {
  try {
    const newData = new Response(JSON.stringify(data), {
      headers: { 'Cache-Control': `max-age=${expirationTimeSeconds}` },
    });

    if ('caches' in window) {
      const cache = await caches.open(cacheName);
      await cache.put(url, newData);
    }
  } catch (error) {
    console.error('Erro ao adicionar dados ao cache:', error);
  }
}

export function cleanCache(key?: string) {
  if (key) {
    caches.delete(key);
  } else {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
}

export async function getDataCache(cacheName: string, url: string) {
  try {
    if ('caches' in window) {
      const cache = await caches.open(cacheName);
      const cachedResponse = await cache.match(url);

      if (cachedResponse) {
        const cacheExpiration = cachedResponse.headers.get('Expires');
        if (cacheExpiration && new Date(cacheExpiration) < new Date()) {
          await cache.delete(url);
          return null;
        } else {
          const data = await cachedResponse.json();
          return data;
        }
      }
    }
    return null;
  } catch (error) {
    console.error('Erro ao obter dados do cache:', error);
    return null;
  }
}

export function formatDate(date?: string, timesTamp: string = 'pt-br') {
  if (!date) return '';
  return new Date(
    !date.includes(':') ? date + 'T00:00:00' : date,
  ).toLocaleDateString(timesTamp);
}

export function formatDateHour(dateTimeString?: string) {
  if (!dateTimeString) return '';

  const dateTime = new Date(
    !dateTimeString.includes(':')
      ? dateTimeString + 'T00:00:00'
      : dateTimeString,
  );

  // Formata o input para data e hora (pt-BR)
  return dateTime
    .toLocaleString('pt-BR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(',', '');
}

export function formatMonthDay(dateTimeString: string) {
  if (!dateTimeString) return '';

  const dateTime = new Date(
    !dateTimeString.includes(':')
      ? dateTimeString + 'T00:00:00'
      : dateTimeString,
  );

  // Formata o input para data e hora (pt-BR)
  return dateTime
    .toLocaleString('pt-BR', {
      month: 'long',
      year: 'numeric',
    })
    .replace(' de ', '/');
}

export function formatCpf(cpf?: string | number) {
  if (!cpf) return;

  if (typeof cpf !== 'number' && cpf.length !== 11) return cpf;

  let newCpf = cpf;
  newCpf = newCpf.toString();
  // Remove caracteres não numéricos
  newCpf = newCpf.replace(/[^\d]/g, '');

  // Adiciona a máscara
  return newCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatCurrency(
  value: number | string,
  currency: string = 'BRL',
) {
  const newValue = typeof value === 'string' ? parseFloat(value) : value;

  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: currency,
  }).format(newValue);
}

export function toCaptalize(text: string) {
  return text.trim().charAt(0).toUpperCase() + text.trim().slice(1);
}

export function toUpperCase(text: string) {
  return text.toLocaleUpperCase('pt-BR');
}

export function cpfIsValid(cpfString: string) {
  const cpfClean = cpfString.replace(/[^\d]/g, '');

  if (cpfClean.length !== 11 || /^(\d)\1{10}$/.test(cpfClean)) {
    return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpfClean.substring(i - 1, i)) * (11 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpfClean.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpfClean.substring(i - 1, i)) * (12 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpfClean.substring(10, 11))) {
    return false;
  }

  return true;
}

export function emailIsValid(emailString: string) {
  if (emailString) {
    return (
      emailString !== '' &&
      emailString !== null &&
      /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,25}([.][a-z]{2,5})?[.][a-z]{2,5}/g.test(
        emailString,
      )
    );
  }
}
