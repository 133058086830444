import React, { useContext, useEffect } from 'react';

import * as S from './styles';
import { Main, Header, Footer } from './components';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Loading from '../../common/Loading';
import { IAgentContextProps, ProviderAgentContext } from '../ProviderAgent';

export default function LayoutAgent() {
  const context = useContext(ProviderAgentContext);
  const { isLoading, setIsLoading } = context as IAgentContextProps;

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <S.Container>
        <Header />
        <Main>
          <Box sx={{ flexGrow: 1, paddingLeft: 2, minHeight: '100vh' }}>
            <Outlet />
          </Box>
          <Footer />
        </Main>
      </S.Container>
    </>
  );
}
