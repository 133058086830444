import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import WarningIcon from '@mui/icons-material/Warning';

import BasicModal from '../BasicModal';
import { ButtonSimple } from '../../Buttons';
import { phoneService } from '../../../../variables';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function BlockedCourse({ isOpen, setIsOpen }: Props) {
  return (
    <S.Container>
      <BasicModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isFloat
        ableBackdropClick
      >
        <S.Header>
          <S.ContentHeader>
            <WarningIcon fontSize="large" />
            <S.H4Title>Bloqueio temporário do curso</S.H4Title>
          </S.ContentHeader>
        </S.Header>
        <S.Content>
          <S.H4>Prezado aluno,</S.H4>
          <S.H6>
            Informamos que o seu acesso está suspenso.<br></br>
            Entre em contato para que possamos verificar em nossa plataforma de
            ensino.
          </S.H6>
          <div>
            <S.H6 style={{ fontWeight: 'bold' }}>Canais de atendimento:</S.H6>
            <div className="contact">
              <S.H6>centraldoaluno@famart.edu.br</S.H6>
              <S.H6 className="divider"> | </S.H6>
              <S.H6>{phoneService}</S.H6>
            </div>
          </div>
          <div className="button_close">
            <ButtonSimple onClick={() => setIsOpen(false)} color="primary">
              Fechar
            </ButtonSimple>
          </div>
        </S.Content>
      </BasicModal>
    </S.Container>
  );
}
