import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 2rem;
  height: 8rem;
  border-top: 1px solid #e5e5e5;
  justify-content: flex-end;
`;

export const Button = styled.div`
  padding: 0.5rem;
`;
