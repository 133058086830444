import { IDataStudent } from '../../../interfaces/newStudent/dataStudent';
import { defaultReturn } from '../../../util/newStudent';
import * as api from '../../api/newStudent/apiStudent.service';

export interface IAnnouncements {
  text: string;
  subject: string;
  link: string;
}
export const homeServiceNewStudent = {
  async show(): Promise<{
    data: IDataStudent;
    message: string[];
    result: boolean;
  }> {
    try {
      const request = await api.authenticatedRequest(
        '/new/student/home/show',
        'GET',
      );
      return defaultReturn(request);
    } catch (error) {
      throw new Error(error);
    }
  },

  async announcements(): Promise<{
    data: IAnnouncements[];
    message: string[];
    result: boolean;
  }> {
    const request = await api.authenticatedRequest(
      '/student/dashboard/announcements',
      'GET',
    );
    return request;
  },
};
