import React, { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';

import { Notice } from '../../../../../components/student/Info';
import { INoticeRows } from '../../../../../components/student/Info/Notice';
import { TableCourse } from './components';

export default function Graduation() {
  const [selectedCourse, setSelectedCourse] = useState<number | string>();
  const [dataStudent, setDataStudent] = useState<INoticeRows[] | []>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>();

  const coursesOptions = [
    { title: '[ Selecione o curso ]', value: 0 },
    { title: 'Pedagogia - Ead', value: 1 },
  ];
  useEffect(() => {
    // fazer o fetch e colocar na variavel

    setDataStudent([
      { subtitle: 'Nome: ', text: 'Jersom Henrique de Souza' },
      { subtitle: 'CPF (Caso não estrangeiro): ', text: '11243717610' },
      { subtitle: 'RG: ', text: '17520890' },
      { subtitle: 'Data de Nascimento: ', text: '26/11/1991' },
      { subtitle: 'Naturalidade: ', text: 'Belo Horizonte' },
      { subtitle: 'Nacionalidade: ', text: 'Brasileiro' },
      {
        subtitle: 'Nome dos Pais: ',
        text: 'José Maria de Souza e Altair Gomes de Oliveira Souza',
      },
      { subtitle: 'Logradouro: ', text: 'Rua Tupiniquins' },
      { subtitle: 'Número: ', text: '93' },
      { subtitle: 'Complemento: ', text: 'casa' },
      { subtitle: 'Bairro: ', text: 'São Salvador' },
      { subtitle: 'Cidade: ', text: 'Belo Horizonte' },
      { subtitle: 'Estado: ', text: 'MG' },
      { subtitle: 'CEP: ', text: '30881060' },
    ]);
  }, [selectedCourse]);

  const changeSelect = (e) => {
    if (selectedOption === e) {
      setSelectedOption(null);
    } else {
      setSelectedOption(e);
    }
  };

  return (
    <div style={{ paddingBottom: '2rem' }}>
      <TableCourse />
      <Notice title="Selecione o curso para emissão do certificado:" noBorder />
      <Form.Select
        style={{
          backgroundColor: 'white',
          minHeight: '3rem',
          fontSize: '13px',
        }}
        onChange={({ target }) => setSelectedCourse(target.value)}
      >
        {coursesOptions.map((item, index) => {
          return (
            item && (
              <option
                key={index}
                value={item.value !== undefined ? item.value.toString() : ''}
              >
                {item.title}
              </option>
            )
          );
        })}
      </Form.Select>
      <Notice
        title="Antes de continuar confira seu dados:"
        rows={dataStudent}
        noBorder
        split={2}
      />
      <div style={{ paddingTop: '1rem' }}>
        <b style={{ color: '#A90329' }}> Selecione o curso!</b>
        <div style={{ display: 'flex', paddingTop: '2rem', gap: '1rem' }}>
          <input
            onChange={() => changeSelect(0)}
            type="checkbox"
            style={{ marginBottom: '.2rem', fontSize: '1.5rem' }}
            checked={selectedOption === 0}
          />
          <p style={{ fontSize: '1.5rem', color: 'green' }}>
            Li e Confirmo que as informações aqui contidas estão corretas e não
            precisam de alterações
          </p>
        </div>
        <div style={{ display: 'flex', paddingTop: '2rem', gap: '1rem' }}>
          <input
            onChange={() => changeSelect(1)}
            type="checkbox"
            style={{ marginBottom: '.2rem', fontSize: '1.5rem' }}
            checked={selectedOption === 1}
          />
          <p style={{ fontSize: '1.5rem', color: 'green' }}>
            Preciso de alterações
          </p>
        </div>
      </div>
    </div>
  );
}
