import styled from 'styled-components';

interface ICards {
  hasOnlyOne: boolean;
}
export const Cards = styled.div<ICards>`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(1 1fr);
  grid-template-columns: ${(props) => (props.hasOnlyOne ? '1fr' : '1fr 1fr')};
  gap: 2rem;

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: ${(props) => props.theme.size?.small}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

interface TextProps {
  color: 'primary' | 'secondary' | 'danger' | 'success';
}
export const P = styled.p<TextProps>`
  color: ${(props) => props.theme.colors?.text[props.color]};
`;

export const H5 = styled.h5<TextProps>`
  color: ${(props) => props.theme.colors?.text[props.color]};
`;

export const H6 = styled.h6<TextProps>`
  color: ${(props) => props.theme.colors?.text[props.color]};
`;

export const H6MyCourses = styled.h6<TextProps>`
  color: ${(props) => props.theme.colors?.text[props.color]};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 1.6rem;
`;

export const H4 = styled.h4<TextProps>`
  color: ${(props) => props.theme.colors?.text[props.color]};
`;

interface IDivName {
  shouldShowFullName: boolean;
}
export const DivName = styled.div<IDivName>`
  max-width: ${(props) => (props.shouldShowFullName ? '65rem' : '19rem')};

  @media screen and (max-width: ${(props) => props.theme.size?.xlarge}) {
    max-width: ${(props) => (props.shouldShowFullName ? '30rem' : '19rem')};
  }
  @media screen and (max-width: ${(props) => props.theme.size?.large}) {
    max-width: ${(props) => (props.shouldShowFullName ? '40rem' : '19rem')};
  }
  @media screen and (max-width: ${(props) => props.theme.size?.medium}) {
    max-width: 15rem;
  }
  @media screen and (max-width: ${(props) => props.theme.size?.xsmall}) {
    max-width: 11rem;
  }
`;

export const BarCode = styled.div`
  display: flex;

  flex-direction: column;
  height: 6rem;
`;

export const DateCard = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 1.5rem;
`;
