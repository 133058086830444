import React, { ReactNode } from 'react';

import * as S from './styles';

interface Props {
  children?: ReactNode;
  color: 'blue' | 'green' | 'white' | 'icon';
  disabled?: boolean;
  hover?: boolean;
  onClick: () => void | Promise<void>;
}

export default function ButtonIcon({
  children,
  color,
  disabled,
  hover,
  onClick,
}: Props) {
  const propColors = {
    blue: {
      backgroundColor: '#275b89',
      color: '#fff',
      border: '1px solid #1f496d',
    },
    green: {
      backgroundColor: '#5b835b',
      border: '1px solid #4c6e4c',
      color: '#383838',
    },
    white: {
      backgroundColor: '#f9f9f9',
      border: '1px solid #bfbfbf',
    },
    icon: {
      backgroundColor: '#f9f9f9',
      border: 'none',
    },
  };

  const handleClick = async () => {
    onClick();
  };

  return (
    <S.ButtonStyle
      style={{
        ...propColors[color],
        boxShadow: hover ? 'inset 0 0 4px 1px rgba(0, 0, 0, 0.5)' : '',
      }}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </S.ButtonStyle>
  );
}
