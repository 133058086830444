export class Parcels {
  id: number;
  value: number;
  label: string;

  constructor({ id, value, label }) {
    this.id = id;
    this.value = value;
    this.label = label;
  }
}

export class Payment {
  id: number;
  value: number;
  label: string;

  constructor(id, value, label) {
    this.id = id;
    this.value = value;
    this.label = label;
  }
}
