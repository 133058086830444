import React from 'react';
import * as S from './styles';
import { BasicTable } from '../../../../common/Table';

export default function TabListCourses({ data: listData, exception }) {
  return (
    <S.Content>
      <BasicTable data={listData} exception={exception} />
    </S.Content>
  );
}
