import React, { Dispatch, SetStateAction } from 'react';

import * as S from './styles';
import { ISubjectContentData } from '../..';
import { LessonContent, ExamContent } from './components';
import { IExam } from '../../../../../../services/newStudent/myCourses/myCourses.service';
import {
  courseAssessmentContent,
  courseAuxiliaryContent,
  courseDisciplineContent,
} from '../../../../../../variables';
import { IClassInfo } from '../../../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';

interface Props {
  selected: string | number;
  subjectContent?: ISubjectContentData;
  subjectAuxiliaryContent?: ISubjectContentData;
  subjectExam?: IExam;
  setSubjectExam?: Dispatch<SetStateAction<IExam>>;
  setContentSelected: Dispatch<SetStateAction<string>>;
  setBlockedContent: Dispatch<SetStateAction<boolean>>;
  classInfo: IClassInfo;
}
export default function ContentSelected({
  selected,
  subjectContent,
  subjectAuxiliaryContent,
  subjectExam,
  setSubjectExam,
  setContentSelected,
  setBlockedContent,
  classInfo,
}: Props) {
  const noContetSelected = () => {
    return (
      <S.Div>
        <h5>Nenhum item selecionado.</h5>
      </S.Div>
    );
  };

  function subjectLessonContent() {
    return subjectContent?.title || subjectContent?.url ? (
      <LessonContent data={subjectContent} classInfo={classInfo} />
    ) : (
      noContetSelected()
    );
  }

  function subjectLessonAuxiliaryContent() {
    return subjectAuxiliaryContent?.title || subjectAuxiliaryContent?.url ? (
      <LessonContent data={subjectAuxiliaryContent} classInfo={classInfo} />
    ) : (
      noContetSelected()
    );
  }

  function subjectFinalExamContent() {
    return subjectExam ? (
      <ExamContent
        data={subjectExam}
        setData={setSubjectExam}
        setContentSelected={setContentSelected}
        setBlockedContent={setBlockedContent}
      />
    ) : (
      noContetSelected()
    );
  }

  const contentSelected = {
    [+courseDisciplineContent!]: subjectLessonContent(),
    [+courseAuxiliaryContent!]: subjectLessonAuxiliaryContent(),
    [+courseAssessmentContent!]: subjectFinalExamContent(),
  };
  return <S.Container>{contentSelected[selected]}</S.Container>;
}
