import React, { useContext } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import { IMyGradesRow } from '../..';
import { TitleTable } from '../../../../../components/student/Table/components';
import gradeService from '../../../../../services/grade/grade.service';
import {
  StudentProviderContext,
  IStudentContextProps,
} from '../../../../../components/student/StudentProvider';
import { warningToast } from '../../../../../components/common/Toast';

interface Props {
  rows: IMyGradesRow[];
}

export default function TableGrades({ rows }: Props) {
  const context = useContext(StudentProviderContext);
  const { setIsLoading } = context as IStudentContextProps;
  const navigate = useNavigate();

  const handleRowClick = async (row: IMyGradesRow) => {
    setIsLoading(true);
    const response = await gradeService.myGrades(row.aluno_curso_id);
    if (response.data) {
      navigate(`/aluno/minhas-notas/disciplina/${row.aluno_curso_id}`, {
        state: { courseData: response.data, course: row },
      });
    } else {
      response.message.forEach((text) => {
        warningToast(text);
      });
    }
    setIsLoading(false);
  };

  return (
    <S.Container>
      {rows.length > 0 ? (
        <TableContainer>
          <Table component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TitleTable>Cursos</TitleTable>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.aluno_curso_id}>
                    <TableCell scope="row" className="cell">
                      <S.ContainerLinkCourse>
                        <a
                          className="buttonText"
                          onClick={() => handleRowClick(row)}
                        >
                          <S.LinkCourse>
                            <SendIcon />
                            <S.B>{row.nome}</S.B>
                          </S.LinkCourse>
                        </a>
                      </S.ContainerLinkCourse>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <S.NotFound>
          <h5>Nenhum curso ativo encontrado...</h5>
        </S.NotFound>
      )}
    </S.Container>
  );
}
