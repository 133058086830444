import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import { TitlePage } from '../../../components/newStudent/Title/TitlePage';
import { InfoContainer, FormMyData } from './components';
import Divider from '../../../components/common/Divider';
import { addDataCache, getDataCache } from '../../../util/common/appUtil';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import { useLocation } from 'react-router-dom';
import {
  myDataServiceNewStudent,
  poleServiceNewStudent,
} from '../../../services/newStudent';
import { IFormOptions } from '../../../components/newStudent/FieldFormik';
export interface IUserData {
  name: string;
  email: string;
  cellPhone: number | string;
  businessPhone: number | string;
  homePhone: number | string;
  fatherName: string;
  motherName: string;
  bornDate: string;
  rgId: number | string;
  cpf: number | string;
  colorRace?: string;
  raceColor?: number;
  studentGraduation: number | string;
  degree: string;
  graduationYear: number | string;
  nationality: string;
  naturalness: string;
  university: string;
  street: string;
  complement: string;
  city: string;
  state: string;
  cep: string;
  number: number | string;
  neighborhood: string;
  poleId: number | string;
}

export default function MyDataNewStudent() {
  const { student, setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [poloOptions, setPoloOptions] = useState<IFormOptions[] | []>([]);

  const [formValues, setFormValues] = useState<IUserData>();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!student || (student && !student?.dataStudent)) return;
    setIsLoading(true);

    const fetch = async () => {
      const poleDateList = await getDataCache('poleDateList', pathname);
      if (poleDateList) {
        const options: IFormOptions[] = poleDateList.map((item) => ({
          value: item.id,
          title: item.mecName,
        }));

        setPoloOptions(options);
      } else {
        const { data } = await poleServiceNewStudent.getAll();
        const options: IFormOptions[] = data.map((item) => ({
          value: item.id,
          title: item.mecName,
        }));
        addDataCache('poleDateList', data, pathname);
        setPoloOptions(options);
      }

      const dataStudent = await getDataCache('dataStudent', '/new/aluno/dados');
      if (dataStudent && dataStudent.name) {
        setFormValues({
          name: dataStudent.name,
          email: dataStudent.email,
          cellPhone: dataStudent.cellPhone,
          businessPhone: dataStudent.businessPhone,
          homePhone: dataStudent.homePhone,
          fatherName: dataStudent.fatherName,
          motherName: dataStudent.motherName,
          bornDate: dataStudent.bornDate,
          rgId: dataStudent.rgId,
          cpf: dataStudent.cpf,
          raceColor: dataStudent.raceColor,
          studentGraduation: dataStudent.studentGraduation,
          degree: dataStudent.degree,
          graduationYear: dataStudent.graduationYear,
          nationality: dataStudent.nationality,
          naturalness: dataStudent.naturalness,
          university: dataStudent.university,
          complement: dataStudent.complement,
          city: dataStudent.city,
          state: dataStudent.state,
          street: dataStudent.street,
          cep: dataStudent.cep,
          number: dataStudent.number,
          neighborhood: dataStudent.neighborhood,
          poleId: dataStudent.poleId,
        });
      } else {
        /** Fazer o fetch e colocar no cache */
        const { data, result } = await myDataServiceNewStudent.studentData({
          login: student?.dataStudent.cpf
            ? student?.dataStudent.cpf
            : student?.dataStudent.email,
        });
        if (result) {
          const tmpData = {
            name: data.name,
            email: data.email,
            cellPhone: data.cellPhone,
            businessPhone: data.businessPhone,
            homePhone: data.homePhone,
            fatherName: data.fatherName,
            motherName: data.motherName,
            bornDate: data.bornDate,
            rgId: data.rgId,
            cpf: data.cpf,
            raceColor: data.raceColor,
            studentGraduation: data.studentGraduation,
            degree: data.degree,
            graduationYear: data.graduationYear,
            nationality: data.nationality,
            naturalness: data.naturalness,
            university: data.university,
            complement: data.complement,
            city: data.city,
            state: data.state,
            street: data.street,
            cep: data.cep,
            number: data.number,
            neighborhood: data.neighborhood,
            poleId: data.poloId,
          };

          addDataCache('dataStudent', tmpData, '/new/aluno/dados');
          setFormValues(tmpData);
        }
      }

      setIsLoading(false);
    };
    fetch();
  }, [student]);

  return (
    <S.Container>
      <TitlePage title="Meus Dados" titlePage="Meus Dados" />
      <InfoContainer poleId={formValues?.poleId} poleOptions={poloOptions} />
      <Divider padding />
      {formValues && <FormMyData formValues={formValues} showRaceColor />}
    </S.Container>
  );
}
